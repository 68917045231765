import { render, staticRenderFns } from "./InputAttachFile.vue?vue&type=template&id=5c73f141&scoped=true"
import script from "./InputAttachFile.vue?vue&type=script&lang=js"
export * from "./InputAttachFile.vue?vue&type=script&lang=js"
import style0 from "./InputAttachFile.vue?vue&type=style&index=0&id=5c73f141&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c73f141",
  null
  
)

export default component.exports