import { API_METHODS } from "../../constant";
export const travelApi = (
  _getAll,
  _get,
  _update,
  _create,
  _delete,
  paramsIn
) => {
  return {
    [API_METHODS.GET_TREVELRS]: () => {
      let urlReq = `/Person/${paramsIn.id}`;
      return _get(urlReq);
    },
    [API_METHODS.GET_ALL_TREVELRS]: () => {
      let urlReq = `/Person`;
      return _getAll(urlReq);
    }
  };
};
