import {
    CREATE,
    READ,
    UPDATE,
    DELETE,
    SET_MAIN_PAGINATION_TOTAL
} from "../../../../store/type";
import { ENTITY_NAME, GET_LIST_ENTITY } from "./type";
import { api } from "../../../../util/api";
import { API_METHODS, CATEGORY_MENU } from "../../../../constant";
import clearReactive from "../../../../helpers/clearReactive"
export const actions = {
    [ENTITY_NAME + READ]: async({ state, rootState, commit },
        paramsIn = null
    ) => {
        try {
            if (paramsIn.id) {
                return await api(API_METHODS.GET_ENTITY, paramsIn, rootState);
            } else {
                const res = await api(API_METHODS.GET_ALL_ENTITY, paramsIn, rootState);
                commit(ENTITY_NAME + READ, {
                    entity: paramsIn.entity,
                    data: res.object,
                    fieldInfoList: res.fieldInfoList
                });
                if (!(paramsIn.noPagination == false)) {
                    commit(SET_MAIN_PAGINATION_TOTAL, {
                        countPage: res.countPage
                    });
                }
                return res;
            }
        } catch (err) {
            return err;
        }
    },
    [GET_LIST_ENTITY]: async({ rootState, commit }, self = null) => {
        let paramsIn = { self: self };
        try {
            let res = []
            const listMenu = await api(API_METHODS.GET_ALL_REF_ENTITY, paramsIn, rootState)
            if (listMenu.object && listMenu.object.length > 0) {
                for (const element of listMenu.object) {
                    const loadData = await api(
                        API_METHODS.LIST_ENTITY, {...paramsIn, category: element.code },
                        rootState
                    );
                    res.push({ list: loadData.object, parent: element })
                }
            }
            res = clearReactive(res)
            commit(GET_LIST_ENTITY, res);
            return res;
        } catch (err) {
            return err;
        }
    },
    [ENTITY_NAME + UPDATE]: async({ commit }, paramsIn = null) => {
        try {
            return await api(API_METHODS.UPDATE_ENTITY, paramsIn);
        } catch (err) {
            return err;
        }
    },
    [ENTITY_NAME + CREATE]: async({ commit }, paramsIn = null) => {
        try {
            return await api(API_METHODS.UPDATE_ENTITY, paramsIn);
        } catch (err) {
            return err;
        }
    },
    [ENTITY_NAME + DELETE]: async({}, paramsIn = null) => {
        try {
            return await api(API_METHODS.DELETE_ENTITY, paramsIn);
        } catch (err) {
            return err;
        }
    }
};