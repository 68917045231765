<template>
  <div class="d-flex justify-content-between flex-wrap paginatorblock">
    <div class="paginator_info">
      <p class="card-category ml-1" style="display: inline-block">
        {{
          $t("Pagination", [
            (currentPage - 1) * perPage + 1,
            (currentPage - 1) * perPage + perPage,
            total * perPage
          ])
        }}
      </p>
    </div>
    <l-pagination
      class="pagination-no-border"
      v-model="currentPage"
      :per-page="perPage"
      :total="total * perPage"
    >
    </l-pagination>
    <the-per-page 
      :paramsPaginator="paramsPaginator" 
      @set-perp="$emit('set-perpage', $event)"
    />
  </div>
</template>
<script>
import { Pagination as LPagination } from "../../../index";
import ThePerPage from "./ThePerPage.vue"
export default {
  name: "ThePaginator",
  components: {
    LPagination,
    ThePerPage
  },
  props: {
    paramsPaginator: { require: true }
  },
  computed: {
    pagination() {
      return this.paramsPaginator
        ? this.paramsPaginator
        : { page: 1, size: 1, countPage: 1 };
    },
    perPage() {
      return this.pagination.size;
    },
    total() {
      return this.pagination.countPage;
    },
    currentPage: {
      get() {
        return this.pagination.page;
      },
      set(val) {
        this.$emit("set-page", val);
      }
    }
  }
};
</script>
