<template>
  <div
    class="d-flex justify-content-between flex-wrap paginatorblock"
  >
    <template v-if="manualMode == false">
      <div class="paginator_info">
        <p class="card-category ml-1" style="display: inline-block">
          {{
            $t("Pagination", [
              (currentPage - 1) * pagination.perPage + 1,
              (currentPage - 1) * pagination.perPage + pagination.perPage,
              pagination.total
            ])
          }}
        </p>
      </div>
      <l-pagination
        class="pagination-no-border"
        v-model="currentPage"
        :per-page="pagination.perPage"
        :total="pagination.total"
      >
      </l-pagination>
    </template>
    <template v-if="manualMode == true">
      <div class="paginator_info">
        <p class="card-category ml-1" style="display: inline-block">
          {{
            $t("Pagination", [
              (manualCurentPage - 1) * manualPaginator.perPage + 1,
              (manualCurentPage - 1) * manualPaginator.perPage +
                manualPaginator.perPage,
              manualPaginator.total
            ])
          }}
        </p>
      </div>
      <l-pagination
        class="pagination-no-border"
        v-model="manualCurentPage"
        :per-page="manualPaginator.perPage"
        :total="manualPaginator.total"
      >
      </l-pagination>
    </template>
        <div class="perPageText ml-4" >
          {{ $t("Отображать") }}
          <el-select
            class="select-default selectPerPage"
            v-model="perPage"
            @change="setPerPage()"
            placeholder="Per page"
          >
            <el-option
              class="select-default"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          {{ $t("записей") }}
        </div>
  </div>
</template>
<script>
import { Pagination as LPagination } from "src/components/index";
import { SET_MAIN_PAGINATION_PAGE , SET_MAIN_PAGINATION_PER_PAGE } from "src/store/type";
import { SET_HISTORY_PAGE } from "../../modules/history/store/type";
import { Select, Option } from "element-ui";

export default {
  name: "PaginationBlock",
  components: {
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option
  },
  props: {
    updateData: { type: Function, required: true },
    historyMode: { type: Boolean, default: false },
    name: {},
    manualMode: { type: Boolean, default: false },
    allPaginator: { type: Object },
setPerPage: {}

  },
  data() {
    return {
      block: true
    };
  },
  computed: {
    pagination() {
      if (this.historyMode) {
        return this.$store.state.history.pagination;
      }
      return this.$store.state.pagination.pagination;
    },
    currentPage: {
      get() {
        return this.pagination.currentPage;
      },
      set(val) {
        if (val !== this.currentPage) {
          this.block = false;
          const nameAction = this.historyMode
            ? SET_HISTORY_PAGE
            : SET_MAIN_PAGINATION_PAGE;
          this.$store.commit(nameAction, val);
        }
      }
    },
    perPage: {
      get() {
        return this.pagination.perPage;
      },
      set(val) {
        const nameAction = this.historyMode
          ? SET_HISTORY_PER_PAGE
          : SET_MAIN_PAGINATION_PER_PAGE;
        this.$store.commit(nameAction, val);
      }
    },
    manualPaginator() {
      return this.allPaginator.pagination;
    },
    manualCurentPage: {
      get() {
        return this.allPaginator
          ? this.allPaginator.pagination.currentPage
          : null;
      },
      set(val) {
        this.allPaginator.pagination.currentPage = val;
      }
    }
  },
  watch: {
    currentPage(now, prev) {
      if (now !== prev && !this.block) {
        this.updateData();
      }
      this.block = true;
    },
    manualCurentPage() {
      this.updateData();
    }
  }
};
</script>
