import { API_METHODS } from "../../constant";
export const receiverSystemsApi = (
  _getAll,
  _get,
  _update,
  _create,
  _delete,
  paramsIn
) => {
  return {
    [API_METHODS.GET_RECEIVER_SYSTEM]: () => {
      let urlReq = `/ReceiverSystem/${paramsIn.id}`;
      return _get(urlReq);
    },
    [API_METHODS.GET_ALL_RECEIVER_SYSTEM]: () => {
      let urlReq = `/ReceiverSystem`;
      return _getAll(urlReq);
    },
    [API_METHODS.UPDATE_RECEIVER_SYSTEM]: () => {
      let urlReq = `/ReceiverSystem`;
      return _update(urlReq);
    },
    [API_METHODS.CREATE_RECEIVER_SYSTEM]: () => {
      let urlReq = `/ReceiverSystem`;
      return _create(urlReq);
    },
    [API_METHODS.DELETE_RECEIVER_SYSTEM]: () => {
      let urlReq = `/ReceiverSystem/${paramsIn.id}`;
      return _delete(urlReq);
    },



    [API_METHODS.GET_CONNECT_DIRECTORY]: () => {
    
      let urlReq = `/${paramsIn.ReceiverSystemId}/ModuleNames/${paramsIn.id}`;
      return _get(urlReq);
    },
    [API_METHODS.GET_ALL_CONNECT_DIRECTORY]: () => {
      
      let urlReq = `/${paramsIn.ReceiverSystemId}/ModuleNames`;
      return _getAll(urlReq);
    },
    [API_METHODS.UPDATE_CONNECT_DIRECTORY]: () => {
      let urlReq = `/${paramsIn.ReceiverSystemId}/ModuleNames`;
      return _update(urlReq);
    },
    [API_METHODS.CREATE_CONNECT_DIRECTORY]: () => {
      let urlReq = `/${paramsIn.ReceiverSystemId}/ModuleNames`;
      return _create(urlReq);
    },
    [API_METHODS.DELETE_CONNECT_DIRECTORY]: () => {
      let urlReq = `/${paramsIn.ReceiverSystemId}/ModuleNames/${paramsIn.id}`;
      return _delete(urlReq);
    }




  };
};

// Справочник Системы-приемники (Receiver systems)
// Общий роут
// 	api/ReceiverSystem
// 1. GET списка:
// 	api/ReceiverSystem/
// 2. GET записи:
// 	api/ReceiverSystem/{ReceiverSystemId}
// 3. Create:
// 	POST api/ReceiverSystem/
// 	(причем здесь SubjectId должен быть 0)
// 4. Update
// 	PUT api/ReceiverSystem/	
// 	(SubjectId тоже менять не нужно, ошибка будет)
// 5. Delete
// 	PUT api/ReceiverSystem/{ReceiverSystemId}


// HrFeed/Fields:
// Общий роут, где ReceiverSystemId из справочника ReceiverSystem выше
// 	api/{ReceiverSystemId}/ModuleNames
// 1.GET списка
// 	api/{ReceiverSystemId}/ModuleNames
// 2. GET записи
// 	api/{ReceiverSystemId}/ModuleNames/{ModuleNamesId}
// 3. Create
// 	POST api/{ReceiverSystemId}/ModuleNames
// 4. Update
// 	PUT api/{ReceiverSystemId}/ModuleNames
// 5. Delete
// 	PUT api/ReceiverSystem/{ReceiverSystemId}	
// Для Create и Update Subject должен быть типа TableName, как в GET в FieldInfo передается