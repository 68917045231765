<template>
  <div>
    <show-code :code="fieldInfoList"></show-code>
    <show-code :code="preResult"></show-code>
    <load-block :load="!load"></load-block>
    <the-edit-page
      :scheme="scheme"
      :isEntity="true"
      :loadData="loadData"
      v-if="load"
      :title="title"
      :pageMode="pageMode"
    >
      <the-controls-edit-page
        :scheme="scheme"
        :loadData="loadData"
        :saveData="saveData"
        :pathBack="pathBack"
        :show-history="!($route.name == 'CreateCodeEnum')"
        :open-history="openHistory"
        :hasHistory="false"
        configName="codeEnum"
         :roleName="nameRoleEntity"
        :pageMode="pageMode"
        :wait="wait"
      ></the-controls-edit-page>
    </the-edit-page>
    <history-code-enum
      v-if="!($route.name == 'CreateCodeEnum')"
      :modals="modals"
      :toggleModal="toggleModal"
      :closeHistory="closeHistory"
    ></history-code-enum>
  </div>
</template>
<script>
import TheEditPage from "../../../TheEditPage.vue";
import { CODE_ENUM } from "../store/type";
import { READ, UPDATE } from "../../../../store/type";
import TheControlsEditPage from "../../../TheControlsEditPage.vue";
import clearReactive from "../../../../helpers/clearReactive";
import ShowCode from "../../../shared/ShowCode.vue";
import convertFieldInfoListToScheme from "../../../../helpers/converters/convetFieldInfoListToScheme";
import clearNull from "../../../../../src/helpers/clearNull";
import { mapState } from "vuex";

import { TYPE_ERRORS, FIELD_LANG_CODE_ENUM } from "../../../../constant";
import notify from "../../../../helpers/notify/notify";
import LoadBlock from "../../../shared/LoadBlock.vue";

import { checkValidate } from "../../../../mixins/validate/checkValidate";
import HistoryCodeEnum from "../../history/HistoryCodeEnum.vue";
import { pageMode } from "../../../../mixins/editPage/pageMode";
import {NAME_OBJECT} from "../../../../RoleConstanans"
export default {
  name: "EditPageCodeEnum",
  components: {
    HistoryCodeEnum,
    LoadBlock,
    ShowCode,
    TheControlsEditPage,
    TheEditPage
  },
  mixins: [checkValidate, pageMode],
  data() {
    return {
      wait: false,
      scheme: {},
      loadData: null,
      fieldInfoList: null,
      preResult: null,
      load: true,
      modals: {
        history: false
      },
      routeNameShow: "CodeEnumShow",
      routeNameCreate: "CreateCodeEnum"
    };
  },
  computed: {
    ...mapState({
      lang: state => state.common.lang
    }),
    pathBack() {  
      if(this.$route.name  == "ContractCodeStructorEnumCreate") {
        return `/contract/${this.$route.params.ContractId}/ContractCodeStructor/${this.$route.params.CodeId}/edit`
      }
      if(this.$route.params.ContractId >=0 ) {
        return `/contract/${this.$route.params.ContractId}/ContractCodeBudget/${this.$route.params.CodeId}/edit`
      }
      return `/code/${this.$route.params.CodeId}/edit` 
      },
    nameRoleEntity(){
        return NAME_OBJECT.codeEnum
    },
    title() {
      return this.pageTitle(
        this.$t("Просмотр элемента перечисления"),
        this.$t("Создание элемента перечисления"),
        this.$t("Редактирование элемента перечисления")
      );
    }
  },
  watch: {
    lang() {
      this.initData();
    }
  },
  created() {
    this.initData();
  },
  methods: {
    toggleModal(name) {
      this.modals[name] = !this.modals[name];
    },
    openHistory() {
      this.toggleModal("history");
    },
    closeHistory() {
      this.toggleModal("history");
      this.initData();
    },

    initData() {
      this.load = false;
      this.$store
        .dispatch(CODE_ENUM + READ, {
          id: this.$route.params.CodeEnumId || "0",
          self: this,
          CodeId: this.$route.params.CodeId
        })
        .then(res => {
          this.loadData = res.object;
          let fieldInfoList = res.fieldInfoList;

          this.fieldInfoList = fieldInfoList; //del in future
          this.scheme.configForm = convertFieldInfoListToScheme({
            fieldInfoList,
            langFieldName: FIELD_LANG_CODE_ENUM
          });
          this.preResult = convertFieldInfoListToScheme({
            fieldInfoList,
            langFieldName: FIELD_LANG_CODE_ENUM
          }); //del in future

          this.loadData.codeId = this.$route.params.CodeId;

          this.load = true;
        });
    },
    saveData() {
      if (this.validateData() == false) {
        return;
      }
      let saveData = clearReactive(this.loadData);
      saveData = clearNull(saveData);
      this.wait = true;
      this.$store
        .dispatch(CODE_ENUM + UPDATE, {
          id: this.$route.params.CodeEnumId,
          saveData: saveData,
          self: this,
          CodeId: this.$route.params.CodeId
        })
        .then(res => {
          this.wait = false;
          if (res.errors.length == 0) {
            notify(this, this.$t("Успешно"), TYPE_ERRORS.SUCCESS);
            this.$router.push({ path: this.pathBack });
          }
        })
        .catch(err => {
          console.error(err);
          this.wait = false;
        });
    }
  }
};
</script>
