<template>
  <div class="row">
     <div class="col-md-1">
         <label>&nbsp;</label>
      <l-button type="info" class="smallBtn"  wide block @click="reset()">{{
        $t("Сброс")
      }}</l-button>
    </div>
    <div class="col-md-9">
      <dynamic-generator-form
        :form="loadData"
        v-if="loadData"
        :configData="configData"
        :isCreateForm="true"
        :pageMode="pageMode"
      ></dynamic-generator-form>
    </div>
    <div class="col-md-2">
         <label>&nbsp;</label>
      <l-button type="success" wide block @click="filterData(loadData)">{{
        $t("Применить")
      }}</l-button>
    </div>
  </div>
</template>

<script>
import { PAGE_MODE } from "../../../constant";
import DynamicGeneratorForm from "../generator/DynamicGeneratorForm";
import convertFieldInfoListToScheme from "../../../helpers/converters/convetFieldInfoListToScheme";
import convertJsonShemeToData from "../../../helpers/generatorForm/convertJsonShemeToData";
import ShowCode from "../../shared/ShowCode";
import clearReactive from "../../../helpers/clearReactive"
export default {
  components: {
    DynamicGeneratorForm,
    ShowCode
  },
  props: {
    filterList: { required: true },
    filterData: {type: Function}
  },
  data() {
    return {
      loadData: null,
      pageMode: PAGE_MODE.CREATE,
      configData: [],
      defaultData: null
    };
  },
  created() {
    let fieldInfoList = this.filterList;
    this.configData = convertFieldInfoListToScheme({
      fieldInfoList,
      hasLang: false
    });
    this.loadData = convertJsonShemeToData(this.configData);
    this.loadData.dateFrom =   this.$moment().subtract(1, 'days').format('YYYY-MM-DD')
    this.loadData.dateTo =   this.$moment().format('YYYY-MM-DD')
    this.defaultData = clearReactive(this.loadData) 
  },
  methods:{
    reset(){
     this.loadData = clearReactive(this.defaultData)
    }
  }
};
</script>