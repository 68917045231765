import { READ, UPDATE, DELETE, SET_MAIN_PAGINATION_TOTAL } from '../../../../store/type'
import { CREDIT_CARD } from "./type";
import { api } from "../../../../util/api";
import { API_METHODS } from "../../../../constant";

export const actions = {

    [CREDIT_CARD + READ]: async({ state, rootState, commit }, paramsIn = null) => {
        try {
            if (paramsIn.id) {
                return await api(API_METHODS.GET_CREDITCARD, paramsIn, rootState)
            } else {
                const res = await api(API_METHODS.GET_ALL_CREDITCARD, paramsIn, rootState)
                commit(CREDIT_CARD + READ, {
                    data: res.object,
                    fieldInfoList: res.fieldInfoList
                })
                commit(SET_MAIN_PAGINATION_TOTAL, {
                    countPage: res.countPage,
                })
                return res
            }
        } catch (err) {
            return (err)
        }
    },

    [CREDIT_CARD + UPDATE]: async({ commit, rootState }, paramsIn = null) => {
        try {
            return await api(API_METHODS.UPDATE_CREDITCARD, paramsIn, rootState)
        } catch (err) {
            return (err)
        }
    },

    [CREDIT_CARD + DELETE]: async({ rootState }, paramsIn = null) => {
        try {
            return await api(API_METHODS.DELETE_CREDITCARD, paramsIn, rootState)
        } catch (err) {
            return (err)
        }
    },
};