<template>
  <common-page-edit
    :title="title"
    :nameRoleEntity="nameRoleEntity"
    :apiNameGet="apiNameGet"
    :apiNameCreate="apiNameCreate"
    :apiNameUpdate="apiNameUpdate"
    routeNameShow="ShowPerson"
    routeNameCreate="CreateDocument"
    :keyRoute="'PersonId'"
    :backUrl="pathBack"
    @set-loadData="setLoadData"
    @set-sheme="setSheme"
    :readDataFromParent="true"
    :loadDataIn="loadData"
    :schemeIn="scheme"
    :convertLoadData="convertLoadData"
    :paramsSave="paramsSave"
    :isDefaultConverter="false"
    :paramsSend="paramsSend"

    :loadIn="load"
    :controlLoad="true"  
    @set-load="setLoad"  

  >
  </common-page-edit>
</template>
<script>
import { NAME_OBJECT } from "../../../../../RoleConstanans";
import CommonPageEdit from "../../../commonElement/CommonPageEdit.vue";
import convertFieldInfoListToScheme from "../../../../../helpers/converters/convetFieldInfoListToScheme";
import clearReactive from "../../../../../helpers/clearReactive"
import {
  API_METHODS,
  CODE_TYPE_CONTACT_EMAIL,
  CODE_TYPE_CONTACT_PHONE,
  CODE_TYPE_CONTACT_MOB_PHONE
} from "../../../../../constant";
import {api} from "../../../../../util/api"
export default {
  name: "EditPageContact",
  components: {
    CommonPageEdit
  },
  data() {
    return {
      wait: false,
      scheme: {},
      loadData: null,
      fieldInfoList: null,
      preResult: null,
      load: true,

      routeNameShow: "ShowContact",
      routeNameCreate: "CreateContact",
      pathBack: `/contract/${this.$route.params.ContractId}/person/${this.$route.params.PersonId}/edit`,
      
      apiNameGet: API_METHODS.GET_CONTACT,
      apiNameCreate: API_METHODS.CREATE_CONTACT,
      apiNameUpdate: API_METHODS.UPDATE_CONTACT,
      loadData: null,

      oldLoadData: null,

      contactList: [],
      controlLoad: false
    };
  },
  computed: {
    nameEnity() {
      return this.$route.params.clientPerson;
    },
    title() {
      let text1 = this.$t("Просмотр контакта");
      let text2 = this.$t("Просмотр контакта");
      let text3 = this.$t("Редактирование контакта");

      let findShow = this.$route.path.indexOf("show");
      if (findShow != -1) {
        return text1;
      }
      let findCreate = this.$route.path.indexOf("create");
      if (findCreate != -1) {
        return text2;
      }
      let findEdit = this.$route.path.indexOf("edit");
      if (findEdit != -1) {
        return text3;
      }
      return text1;
    },
    nameRoleEntity() {
      const nameRoute = this.$route.name;
      let role = NAME_OBJECT.clientPersonContact;
      switch (nameRoute) {
        case "AgentCreatePageContact":
        case "AgentShow":
        case "AgentEdit": {
          role = NAME_OBJECT.ContactAgency;
          break;
        }
        case "ClientCreatePageContact":
        case "ClientShow":
        case "ClientEdit": {
          role = NAME_OBJECT.ContactClient;
          break;
        }
      }

      return role;
    },
    paramsSend() {
      return {
        id: this.$route.params.ContactId || "0",
        SubjectId: this.$route.params.PersonId
      };
    }
  },

  watch: {
    loadData: {
      deep: true,
      handler() {
        let oldVal = this.oldLoadData;
        let newVal = this.loadData;
        if (oldVal && newVal && newVal.contactTypeId !== oldVal.contactTypeId) {
          this.configFormTypeContact(newVal.contactTypeId);
        }
        this.oldLoadData = clearReactive(this.loadData);
      }
    }
  },
  methods: {
    convertLoadData(res) {
      
      let loadData = res.object;
      let fieldInfoList = res.fieldInfoList;
      let scheme = { configForm: [] };
      this.oldLoadData = clearReactive(this.loadData);
      scheme.configForm = convertFieldInfoListToScheme({
        fieldInfoList,
        hasLang: false
      });
     
      loadData.subjectId = this.$route.params.PersonId;
      this.scheme = scheme
      this.loadData = loadData
      this.configFormTypeContact(loadData.contactTypeId, false);
      return {
        loadData,
        fieldInfoList,
        scheme
      };
    },
    setLoadData(loadData) {
      
      this.loadData = loadData;
    },
    setSheme(scheme) {  
      this.scheme = scheme;
    },
    setLoad(load){
      this.load = load;
    },  
    paramsSave() {
      return {
        id: this.$route.params.ContactId,
        SubjectId: this.$route.params.PersonId
      };
    },

    /**
     * тип - почта	Создание контакта		проверка, что внесен корректный email
     * тип - телефон	Создание контакта	если есть какие-то стандарты	только цифры
     * тип - телефон мобильный 	Создание контакта	если есть какие-то стандарты	только цифры  11
     *
     *
     */
    configFormTypeContact(typeContactId, resetData = true) {
      this.load = false;
      const typeContactIdPhone = this.getContactTypeEntityId(
        CODE_TYPE_CONTACT_PHONE
      );
      const typeContactIdMobPhone = this.getContactTypeEntityId(
        CODE_TYPE_CONTACT_MOB_PHONE
      );
      const typeContactIdEmail = this.getContactTypeEntityId(
        CODE_TYPE_CONTACT_EMAIL
      );
      this.scheme.configForm = this.scheme.configForm.map(item => {
        if (item.fieldName === "value") {
          if (typeContactId === typeContactIdPhone) {
            return {
              ...item,
              validationRule: {
                required: true,
                numeric: true
              }
            };
          }
          if (typeContactId === typeContactIdMobPhone) {
            return {
              ...item,
              validationRule: {
                required: true,
                numeric: true
              }
            };
          }
          if (typeContactId === typeContactIdEmail) {
            return {
              ...item,
              validationRule: {
                required: true,
                regex: "^[a-zA-Z0-9_\.\+\-]+@[a-zA-Z0-9\-]+\.[a-zA-Z0-9\-\.]+$",
                email: true
              }
            };
          }
        }
        return item;
      });
      if (resetData) {
        this.loadData.value = "";
        this.loadData.contactTypeCode = this.getContactTypeEntityCode(
          typeContactId
        );
      }
      this.load = false;
      this.$nextTick(() => {
        this.load = true;
      });
    },

    getContactTypeEntityId(entityCode) {
      const find = this.contactList.find(
        item => item.entityCode === entityCode
      );
      return find ? find.entityId : 0;
    },
    getContactTypeEntityCode(entityId) {
      const find = this.contactList.find(item => item.entityId === entityId);
      return find ? find.entityCode : "";
    },

    async loadDropDownListContact() {
      try {
        const paramIn = {
          self: this,
          relative: "ContactType"
        };
        let res = await api(API_METHODS.BASE_V_SELECT_LOAD_DATA, paramIn);
        this.contactList = res.object;
      } catch (e) {
        console.error("неудалось получить список", e);
      }
    }
  },
  async created(){
    await this.loadDropDownListContact();
  }
};
</script>
