import CounterpartiesTable from "./CounterpartiesTablePage"
import CounterpartiesEditPageDynamic from "./CounterpartiesEditPageDynamic.vue"
import CounterpartiesAgreementsEditPage from "./CounterpartiesAgreementsEditPage.vue"
import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue"

export default {
  path: "/crm",
  component: DashboardLayout,
  children: [{
    path: "counterparty",
    name: "CounterpartiesTable",
    component: CounterpartiesTable
  },
  {
    path: "counterparty/:CounterpartyId/edit",
    name: "CounterpartyEdit",
    component: CounterpartiesEditPageDynamic,
  },
  {
    path: "counterparty/:CounterpartyId/delete",
    name: "CounterpartyDelete",
    component: CounterpartiesEditPageDynamic,
  },
  {
    path: "counterparty/:CounterpartyId/show",
    name: "CounterpartyShow",
    component: CounterpartiesEditPageDynamic,
  },
  {
    path: "counterparty/create",
    name: "CounterpartyCreate",
    component: CounterpartiesEditPageDynamic
  },
  {
    path: "counterparty/:CounterpartyId/agreement/:CompanyId/show",
    name: "CounterpartiesAgreementsShow",
    component: CounterpartiesAgreementsEditPage,
  },
  ]
}
