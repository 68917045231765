<template>
  <div :class="generateClass" v-tooltip.top-center="'Начни поиск'">
      <label>{{config.label}}</label>
      <div class="form-group has-label">
        <div class="el-input">
          <v-select
            :name="config.name"
            :label="config.selectLabel"
            :placeholder="config.placeholder"
            :data-cy="config.cyData"
            :value="formatData(form[config.fieldName])"
            @input="form[config.fieldName] = parseData($event)"
            :disabled="readonly"
            :readonly="readonly"
            @search="onSearchCity"
            :options="option">
            <span slot="no-options">Результатов не найдено</span>
          </v-select>
        </div>
      </div>
  </div>
</template>
<script>
  /**
   * depricated
   */
  import {generateClass} from  '../../../../mixins/baseComponets/generateClass'
  import  {ENTITY_NAME} from '../../entity/store/type'
  import  {SEARCH, READ} from '../../../../store/type'
  export default {
    name: 'BaseVSelect',
    props: {
      config: {type: Object, required: true},
      form: {type: Object}
    },
    mixins: [generateClass],
    data() {
      return {
        option: []
      }
    },
    created() {
      let config = this.config
      if(config.SearchType == 'RefEntity') {
          this.option = []
          if(this.form[config.fieldName]){
            this.getInitData(this.form[config.fieldName])
          }
          this.loudStartData();
      }

    },
    methods:{
      formatData(val) {
        let find = this.option.find(el => el.refEntityId == val);
        return find;
      },
      parseData(val) {
        return val?val.refEntityId:null;
      },


      onSearchCity(search, loading, event) {
        if (search.length < 3)
          return false

        loading(true);
        this.searchCity(loading, search, this);
      },

      searchCity(loading, search, vm){
        let params ={
          search: search,
          entity: this.config.relative,
          self: vm
        }
        this.$store.dispatch(ENTITY_NAME + SEARCH, params)
          .then(res => {
            let dataNow = res.object
            if (dataNow) {
              try {
                dataNow = dataNow.map(row => {
                  return this.extractLang(row)
                })

              } catch (e) {
                console.log(e)
              }
            }
            vm.option = dataNow;
            loading(false)
        });
      },

      extractLang(row){
        let langNow = this.$store.state.common.lang
        let findNowLang = {}
        if (row.refEntityLangDTOList && row.refEntityLangDTOList[0]) {
          findNowLang = row.refEntityLangDTOList.find(item => item.languageCode == langNow)
            || row.refEntityLangDTOList.find(item => item.languageCode == 'en')
            || row.refEntityLangDTOList[0]
        }
        return {...row, ...findNowLang, label: findNowLang.name }
      },

      getInitData(id){
        let params ={
          id: id,
          entity: this.config.relative,
          self: this,
          noPagination: false
        }
        this.$store.dispatch(ENTITY_NAME + READ, params).then(res => {
         let result = this.extractLang(res.object)
         let find = this.option.find(el => el.refEntityId == result.refEntityId)
         if (!find) {
           this.option.push(result)
         }
        })
      },
      loudStartData() {
        let params ={
          entity: this.config.relative,
          self: this,
          noPagination: false
        }
        this.$store.dispatch(ENTITY_NAME + READ, params).then(res => {
          let result = res.object
          result = result.map(item=> {
            return this.extractLang(item)
          })
          result.forEach( item=> {
            let find =  this.option.find(el => el.refEntityId == item.refEntityId)
            if (!find) {
              this.option.push(item)
            }
          })
        })
      }
    },

  }
</script>
