<template>
  <card>
    <el-tabs :tab-position="tabPosition" v-model="nowTab" @before-leave="beforeLeave" @tab-click="beforeLeave">
      <el-tab-pane :label="$t('Настройки таблицы')" name="settings">
        <SheduleSettings
          v-if="nowTab == 'settings'"
        />
      </el-tab-pane>
    </el-tabs>
  </card>
</template>
<script>
import { Tabs, TabPane } from "element-ui";
import SheduleSettings from './SheduleTabs/SheduleSettings.vue'
import clearReactive from "../../../../helpers/clearReactive"
//import { NAME_OBJECT, NAME_ACTION } from "../../../../RoleConstanans";
export default {
  name: "SheduleData",
  created() {
    // this.loadData = clearReactive(this.loadDataIn);
  },
  components: {
    ElTabs: Tabs,
    ElTabPane: TabPane,
    SheduleSettings
  },
  data() {
    return {
      loadData: null,
      tabPosition: "top",
      //nameObject: NAME_OBJECT,
      nowTab: ''
    };
  },
  methods: {
    beforeLeave() {

    }
  },
};
</script>
