import HttpClient from "./HttpClient";
import { API_METHODS, CATEGORY_MENU, API_URLS } from "../constant";


/**
 * API
 * @param method
 * @param paramsIn
 * @param rootState
 * @returns {*}
 */

export const api = (method, paramsIn, rootState = null) => {
  let HttpClientLocal = new HttpClient(paramsIn.self);
  let urlReq = "";

  /**
   * метод получение всех записей
   * @param urlReq
   * @returns {*}
   * @private
   */
  const _getAll = (urlReq, dopParams = {}) => {
    // console.log(rootState.pagination.pagination.currentPage)
    let params = {
      page: rootState.pagination.pagination.currentPage,
      size: rootState.pagination.pagination.perPage,
      lang: rootState.common.lang,
      search: rootState.pagination.search ? rootState.pagination.search : null,
      orderBy: rootState.pagination.sortData.prop,
      orderDir: rootState.pagination.sortData.order == "ascending",
      ...dopParams
    };
    return HttpClientLocal.getRequest(urlReq, params);
  };
  /**
   * метод получения конкретной записи
   * @param urlReq
   * @returns {*}
   * @private
   */
  const _get = (urlReq, paramsDop = null) => {
    let paramsSend = {
      lang: rootState.common.lang
    };
    if (paramsDop) {
      paramsSend = { ...paramsSend, ...paramsDop };
    }
    return HttpClientLocal.getRequest(urlReq, paramsSend);
  };
  /**
   * обновление записи
   * @param urlReq
   * @returns {*}
   * @private
   */
  const _updata = urlReq => {
    let urlSend = urlReq;
    try {
      if (rootState && rootState.common.lang) {
        urlSend += "?lang=" + rootState.common.lang;
      }
    } catch (e) {
      console.error(e);
      urlSend = urlReq;
    }
    return HttpClientLocal.putRequest(urlSend, paramsIn.saveData);
  };
  /**
   * создании записи
   * @param urlReq
   * @returns {*}
   * @private
   */
  const _create = urlReq => {
    let urlSend = urlReq;
    try {
      if (rootState && rootState.common.lang) {
        urlSend += "?lang=" + rootState.common.lang;
      }
    } catch (e) {
      console.error(e);
      urlSend = urlReq;
    }
    return HttpClientLocal.postRequest(urlSend, paramsIn.saveData);
  };
  /**
   * удаление записи
   * @param urlReq
   * @returns {*}
   * @private
   */
  const _delete = urlReq => {
    return HttpClientLocal.putRequest(urlReq);
  };

  /**
   * метод получения листа сущностей
   * @param urlReq
   * @returns {*}
   * @private
   */
  const _list = urlReq => {
    let paramsSend = {
      lang: rootState.common.lang
    };
    return HttpClientLocal.getRequest(urlReq, paramsSend);
  };

  /**
   * метод получения данных для поиска
   * @param urlReq
   * @param query
   * @returns {*}
   * @private
   */
  const _loadData = (urlReq, query) => {
    return HttpClientLocal.getRequest(urlReq, query);
  };

  const _getHistory = urlReq => {
    const pagination = rootState.history.pagination;
    let params = {
      page: pagination.currentPage,
      size: pagination.perPage,
      lang: rootState.common.lang,
      search: rootState.history.search ? rootState.history.search : null
      // sort: rootState.sortData.prop,
      // direction: rootState.sortData.order
    };
    return HttpClientLocal.getRequest(urlReq, params);
  };

  /**
   * метод для востановления данных
   * @param urlReq
   * @returns {*}
   * @private
   */
  const _recoverData = urlReq => {
    let sendDate = {
      id: paramsIn.id,
      dateCreated: paramsIn.dateCreated
    };
    return HttpClientLocal.putRequest(urlReq, sendDate);
  };

  switch (method) {
    case API_METHODS.AUTH_LOGIN: {
      let urlReq = "/auth/signin";
      let lang = rootState.common.lang;
      urlReq += `?lang=${lang}`;
      const sendDate = {
        email: paramsIn.email,
        password: paramsIn.password
      };
      return HttpClientLocal.postRequest(urlReq, sendDate);

    }
    case API_METHODS.AUTH_ME: {
      let urlReq = "/auth/me";
      let lang = rootState.common.lang;
      return HttpClientLocal.getRequest(urlReq, { lang: lang });

    }
    case API_METHODS.AUTH_REFRESH: {
      let urlReq = "/auth/refresh";
      return HttpClientLocal.getRequest(urlReq);

    }
    case API_METHODS.AUTH_CHECH: {
      let urlReq = "/auth/health";
      return HttpClientLocal.getRequest(urlReq);

    }
    // TODO доделать это потом
    case API_METHODS.AUTH_LOGOUT: {
      let urlReq = "/auth/logout";
      return true;
      // return HttpClientLocal.getRequest(urlReq);

    }

    //code
    case API_METHODS.GET_CODE: {  //@dep
      urlReq = `/Code/${paramsIn.id}`;
      return _get(urlReq);

    }
    case API_METHODS.GET_ALL_CODE: { //@dep
      urlReq = `/Code/Agency/${paramsIn.AgencyId}`;
      return _getAll(urlReq);

    }
    /**
     * апи на получение кодов для договоров
    */
    case API_METHODS.GET_CONTRACT_CODE: { //@dep
      urlReq = `Code/Contract/${paramsIn.ContractId}/${paramsIn.CodeType}`;
      return _getAll(urlReq);

    }

    case API_METHODS.UPDATE_CODE:
    case API_METHODS.CREATE_CODE: { //@dep
      urlReq = `/Code`;
      return _updata(urlReq);

    }
    case API_METHODS.DELETE_CODE: { //@dep
      let urlReq = `/Code/${paramsIn.CodeId}`;
      return _delete(urlReq);

    }

    case API_METHODS.RETURN_CODE: {
      urlReq = `/Code/Return`;
      return _recoverData(urlReq);

    }
    /**
     * @depricate
     */
    case API_METHODS.CODE_VALUE: {
      urlReq = `/Company/${paramsIn.CompanyId}/CodeValue/${paramsIn.CodeId}`;
      return _loadData(urlReq);

    }

    // получение кодов которые непривязаны к команиии
    /**
     * @depricate
     */
    case API_METHODS.GET_CODE_NOT_LINKED_TO_COMPANY: {
      let urlReq = `/Code/Client/${paramsIn.CompanyId}/Unlinked/${paramsIn.CodeType}`;
      return _loadData(urlReq, paramsIn.query);

    }
    case API_METHODS.GET_CODE_ENUM_VALUES: {
      let urlReq = `/Code/${paramsIn.CodeId}/CodeEnum`;
      return _loadData(urlReq, paramsIn.query);

    }

    //code enum
    case API_METHODS.GET_CODE_ENUM: {
      urlReq = `/Code/${paramsIn.CodeId}/CodeEnum/${paramsIn.id}`;
      return _get(urlReq);

    }
    case API_METHODS.GET_ALL_CODE_ENUM: {
      urlReq = `/Code/${paramsIn.CodeId}/CodeEnum`;
      return _getAll(urlReq);

    }
    case API_METHODS.UPDATE_CODE_ENUM:
    case API_METHODS.CREATE_CODE_ENUM: {
      urlReq = `/Code/${paramsIn.CodeId}/CodeEnum`;
      return _updata(urlReq);

    }
    case API_METHODS.DELETE_CODE_ENUM: {
      urlReq = `/Code/${paramsIn.CodeId}/CodeEnum/${paramsIn.CodeEnumId}`;
      return _delete(urlReq);

    }
    case API_METHODS.RETURN_CODE_ENUM: {
      urlReq = `/Code/${paramsIn.CodeId}/CodeEnum/Return`;
      return _recoverData(urlReq);

    }

    //code history
    case API_METHODS.GET_CODE_HISTORY: {
      urlReq = `/Code/History/${paramsIn.CodeId}`;
      return _getHistory(urlReq);

    }

    //code enum history
    case API_METHODS.GET_CODE_ENUM_HISTORY: {
      urlReq = `/Code/${paramsIn.CodeId}/CodeEnum/History/${paramsIn.CodeEnumId}`;
      return _getHistory(urlReq);

    }
    // person history
    case API_METHODS.PERSON_GET_HISTORY: {
      urlReq = `/Contract/${paramsIn.ContractId}/Person/History/${paramsIn.PersonId}`;
      return _getHistory(urlReq);

    }
    // Document history
    case API_METHODS.DOCUMENT_GET_HISTORY: {
      urlReq = `/Person/${paramsIn.PersonId}/Document/History/${paramsIn.DocumentId}`;
      return _getHistory(urlReq);

    }
    // Bonus Card history
    case API_METHODS.BONUS_CARD_GET_HISTORY: {
      urlReq = `/Person/${paramsIn.PersonId}/BonusCard/History/${paramsIn.BonusCardId}`;
      return _getHistory(urlReq);

    }
    // Return Document
    case API_METHODS.RETURN_DOCUMENT: {
      urlReq = `/Person/${paramsIn.PersonId}/Document/Return`;
      return _recoverData(urlReq);

    }
    // Return Bonus Card
    case API_METHODS.RETURN_BONUS_CARD: {
      urlReq = `/Person/${paramsIn.PersonId}/BonusCard/Return`;
      return _recoverData(urlReq);

    }

    //company client suplier
    case API_METHODS.GET_COMPANY: {
      urlReq =
        `/Company/${paramsIn.typeCompany ? paramsIn.typeCompany : "Client"}` +
        "/" +
        paramsIn.id;
      return _get(urlReq);

    }
    case API_METHODS.GET_ALL_COMPANY: {
      urlReq = `/Company/${paramsIn.typeCompany ? paramsIn.typeCompany : "Client"
        }`;
      return _getAll(urlReq);

    }
    case API_METHODS.UPDATE_COMPANY: {
      urlReq = `/Company`;
      return _updata(urlReq);

    }
    case API_METHODS.CREATE_COMPANY: {
      urlReq = `/Company`;
      return _create(urlReq);

    }
    case API_METHODS.DELETE_COMPANY: {
      urlReq = `/Company/${paramsIn.id}`;
      return _delete(urlReq);

    }
    /**
     * соединяем код и компанию
     */
    case API_METHODS.COMPANY_CONNECT_CODE: {
      // urlReq = "/Company/CodeCompany" + paramsIn.CodeType;
      if (paramsIn.CodeType == "Budget") {
        urlReq = "/Company/CodeCompanyValue";
      }
      if (paramsIn.CodeType == "Structure") {
        urlReq = "/Company/CodeCompany";
      }

      const sendData = {
        companyId: paramsIn.companyId,
        codeId: paramsIn.codeId,
        value: paramsIn.value,
        codeEnumId: paramsIn.codeEnumId
        // "corteosId": "string"
      };
      return HttpClientLocal.postRequest(urlReq, sendData);

    }

    /**
     * отсоединяем код и компанию
     */
    case API_METHODS.COMPANY_UNCONNECT_CODE: {
      urlReq = "/Company/CodeCompany/Delete";
      const sendData = {
        companyId: paramsIn.companyId,
        codeId: paramsIn.codeId
      };
      return HttpClientLocal.putRequest(urlReq, sendData);

    }

    // contract
    case API_METHODS.GET_CONTRACT: {
      urlReq = `/Contract/${paramsIn.id}`;
      return _get(urlReq);

    }

    case API_METHODS.GET_ALL_CONTRACT: {
      let dopParams = {}
      if (paramsIn.CompanyId) {
        urlReq = "/Contract/Company/" + paramsIn.CompanyId;
        if(paramsIn.isClient) {
          dopParams = {
            fromClient: true
          }
        }
      } else {
        urlReq = `/Contract`;
      }
      return _getAll(urlReq, dopParams);

    }
    case API_METHODS.UPDATE_CONTRACT: {
      urlReq = `/Contract`;
      return _updata(urlReq);

    }
    case API_METHODS.CREATE_CONTRACT: {
      urlReq = `/Contract`;
      return _create(urlReq);

    }
    case API_METHODS.DELETE_CONTRACT: {
      let urlReq = `/Contract/${paramsIn.ContractId}`;
      return _delete(urlReq);

    }
    case API_METHODS.GET_CONTACT_HISTORY: {

      // urlReq = `/Person/${paramsIn.PersonId}/Contact/${paramsIn.ContactId}/History`;
      urlReq = `/${paramsIn.PersonId}/Contact/History`
      return _getHistory(urlReq);

    }
    case API_METHODS.RETURN_CONTACT: {
      urlReq = `/Person/${paramsIn.PersonId}/Contact/Return`;
      return _recoverData(urlReq);

    }

    // person
    case API_METHODS.GET_PERSON: {   //@dep
      let urlReq = `/Contract/${paramsIn.ContractId}/Person/${paramsIn.id}`;
      return _get(urlReq);

    }
    case API_METHODS.GET_ALL_PERSON: {  //@dep
      let urlReq = `/Contract/${paramsIn.ContractId}/Person`;
      return _getAll(urlReq);

    }
    case API_METHODS.UPDATE_PERSON: {  //@dep
      let urlReq = `/Contract/${paramsIn.ContractId}/Person`;
      return _updata(urlReq);

    }
    case API_METHODS.CREATE_PERSON: {  //@dep
      let urlReq = `/Contract/${paramsIn.ContractId}/Person`;
      return _create(urlReq);

    }
    case API_METHODS.DELETE_PERSON: {  //@dep
      let urlReq = `/Contract/${paramsIn.ContractId}/Person/${paramsIn.PersonId}`;
      return _delete(urlReq);

    }
    case API_METHODS.RETURN_PERSON: {
      let urlReq = `/Contract/${paramsIn.ContractId}/Person/Return`;
      return _recoverData(urlReq);

    }

    // document
    case API_METHODS.GET_DOCUMENT: {  //@dep
      let urlReq = `/Person/${paramsIn.PersonId}/Document/${paramsIn.id}`;
      return _get(urlReq);

    }
    case API_METHODS.GET_ALL_DOCUMENT: {  //@dep
      let urlReq = `/Person/${paramsIn.PersonId}/Document`;
      return _getAll(urlReq);

    }
    case API_METHODS.UPDATE_DOCUMENT: {  //@dep
      let urlReq = `/Person/${paramsIn.PersonId}/Document`;
      return _updata(urlReq);

    }
    case API_METHODS.CREATE_DOCUMENT: {  //@dep
      let urlReq = `/Person/${paramsIn.PersonId}/Document`;
      return _create(urlReq);

    }
    case API_METHODS.DELETE_DOCUMENT: {  //@dep
      let urlReq = `/Person/${paramsIn.PersonId}/Document/${paramsIn.DocumentId}`;
      return _delete(urlReq);

    }

    //bonus card
    case API_METHODS.GET_BONUS_CARD: {  //@dep
      let urlReq = `/Person/${paramsIn.PersonId}/BonusCard/${paramsIn.id}`;
      return _get(urlReq);

    }
    case API_METHODS.GET_ALL_BONUS_CARD: {  //@dep
      let urlReq = `/Person/${paramsIn.PersonId}/BonusCard`;
      return _getAll(urlReq);

    }
    case API_METHODS.UPDATE_BONUS_CARD: {  //@dep
      let urlReq = `/Person/${paramsIn.PersonId}/BonusCard`;
      return _updata(urlReq);

    }
    case API_METHODS.CREATE_BONUS_CARD: {  //@dep
      let urlReq = `/Person/${paramsIn.PersonId}/BonusCard`;
      return _create(urlReq);

    }
    case API_METHODS.DELETE_BONUS_CARD: {  //@dep
      let urlReq = `/Person/${paramsIn.PersonId}/BonusCard/${paramsIn.BonusCardId}`;
      return _delete(urlReq);

    }

    //contact
    case API_METHODS.GET_CONTACT: {  //@dep
      let urlReq = `/${paramsIn.SubjectId}/Contact/${paramsIn.id}`;
      return _get(urlReq);

    }
    case API_METHODS.GET_ALL_CONTACT: { //@dep
      let urlReq = `/${paramsIn.SubjectId}/Contact`;
      return _getAll(urlReq);

    }
    case API_METHODS.UPDATE_CONTACT: { //@dep
      let urlReq = `/${paramsIn.SubjectId}/Contact`;
      return _updata(urlReq);

    }
    case API_METHODS.CREATE_CONTACT: {//@dep
      let urlReq = `/${paramsIn.SubjectId}/Contact`;
      return _create(urlReq);

    }
    case API_METHODS.DELETE_CONTACT: {//@dep
      let urlReq = `/${paramsIn.SubjectId}/Contact/${paramsIn.id}`;
      return _delete(urlReq);

    }

    //entity  depricate
    case API_METHODS.GET_ENTITY: {
      let urlReq = "/RefEntity/" + paramsIn.entity + "/" + paramsIn.id;
      return _get(urlReq);

    }
    case API_METHODS.GET_ALL_ENTITY: {
      let urlReq = "/RefEntity/" + paramsIn.entity;
      return _getAll(urlReq);
    }
    case API_METHODS.UPDATE_ENTITY: {
      let urlReq = "/RefEntity";
      switch (paramsIn.entity) {
        case "City": {
          urlReq += "/City";
          break;
        }
        case "Rule": {
          urlReq += "/Rule";
          break;
        }
        case "Port": {
          urlReq += "/Port";
          break;
        }
        case "Currency": {
          urlReq += "/Currency";
          break;
        }
        case "AirPlane": {
          urlReq += "/AirPlane";
          break;
        }
      }

      return _updata(urlReq);

    }
    case API_METHODS.CREATE_ENTITY: {
      let urlReq = "/RefEntity";
      switch (paramsIn.entity) {
        case "City": {
          urlReq += "/City";
          break;
        }
        case "Rule": {
          urlReq += "/Rule";
          break;
        }
        case "Port": {
          urlReq += "/Port";
          break;
        }
        case "Currency": {
          urlReq += "/Currency";
          break;
        }
        case "AirPlane": {
          urlReq += "/AirPlane";
          break;
        }
      }
      return _create(urlReq);

    }
    case API_METHODS.DELETE_ENTITY: {
      let urlReq = "/RefEntity/" + paramsIn.id;
      return _delete(urlReq);

    }
    case API_METHODS.LIST_ENTITY: {
      let urlReq = `/RefEntity/RefEntityType/${paramsIn.category ? paramsIn.category : CATEGORY_MENU.COMMON
        }`;
      return _list(urlReq);

    }

    //BaseVSelect
    case API_METHODS.BASE_V_SELECT_LOAD_DATA: {
      let urlReq = `/RefEntity/DropDownList/${paramsIn.relative}${paramsIn.id ? "/" + paramsIn.id : ""
        }`;
      return _loadData(urlReq, paramsIn.query);

    }

    //user
    case API_METHODS.GET_USER: {
      urlReq = `/Admin/User/${paramsIn.id}`;
      return _get(urlReq);

    }
    case API_METHODS.GET_ALL_USER: {
      urlReq = "/Admin/User";
      return _getAll(urlReq);

    }
    case API_METHODS.UPDATE_USER: {
      urlReq = "/Admin/User";
      return _updata(urlReq);

    }
    case API_METHODS.CREATE_USER: {
      urlReq = "/Admin/User";
      return _create(urlReq);

    }
    case API_METHODS.DELETE_USER: {
      let urlReq = `/Admin/User/${paramsIn.id}`;
      return _delete(urlReq);

    }
    case API_METHODS.GET_USER_SUBJECTLINK: {
      urlReq = `/Admin/User/${paramsIn.id}/SubjectLink`;
      return _getAll(urlReq);

    }
    case API_METHODS.GET_USER_SUBLINKS: {
      urlReq = `/RefEntity/DropDownList/${paramsIn.type}`;
      return _loadData(urlReq, paramsIn.query);
    }
    case API_METHODS.GET_ROLE_USER: {
      urlReq = `/Admin/RoleAccess/${paramsIn.RoleId}`;
      return _get(urlReq);
    }
    case API_METHODS.USER_UPDATE_ROLE: {
      urlReq = `/Admin/RoleAccess`;
      return _updata(urlReq);
    }
    //policy
    // case API_METHODS.GET_POLICY: {
    //   let urlReq = `/Policy/${paramsIn.id}`;
    //   return _get(urlReq);

    // }
    // case API_METHODS.GET_ALL_POLICY: {
    //   let urlReq = `/Policy`;
    //   return _getAll(urlReq);

    // }
    // case API_METHODS.UPDATE_POLICY: {
    //   let urlReq = `/Policy`;
    //   return _updata(urlReq);

    // }
    // case API_METHODS.CREATE_POLICY: {
    //   let urlReq = `/Policy`;
    //   return _create(urlReq);

    // }
    // case API_METHODS.DELETE_POLICY: {
    //   let urlReq = `/Policy/${paramsIn.id}`;
    //   return _delete(urlReq);

    // }

    // //FINANCIAL_CONDITION
    // case API_METHODS.GET_FINANCIAL_CONDITION: {
    //   let urlReq = `/FinancialCondition/${paramsIn.id}`;
    //   return _get(urlReq);

    // }
    // case API_METHODS.GET_ALL_FINANCIAL_CONDITION: {
    //   let urlReq = `/FinancialCondition`;
    //   return _getAll(urlReq);

    // }
    // case API_METHODS.UPDATE_FINANCIAL_CONDITION: {
    //   let urlReq = `/FinancialCondition`;
    //   return _updata(urlReq);

    // }
    // case API_METHODS.CREATE_FINANCIAL_CONDITION: {
    //   let urlReq = `/FinancialCondition`;
    //   return _create(urlReq);

    // }
    // case API_METHODS.DELETE_FINANCIAL_CONDITION: {
    //   let urlReq = `/FinancialCondition/${paramsIn.id}`;
    //   return _delete(urlReq);

    // }

    //POLICY_RULE
    case API_METHODS.GET_POLICY_RULE: {

      let urlReq = `/Policy/${paramsIn.PolicyId}/Rule/${paramsIn.id}`;
      return _get(urlReq, {ruleId:paramsIn.ruleId });

    }
    case API_METHODS.GET_ALL_POLICY_RULE: {
      let urlReq = `/Policy/${paramsIn.PolicyId}/Rule`;
      return _getAll(urlReq);

    }
    case API_METHODS.UPDATE_POLICY_RULE: {
      let urlReq = `/Policy/${paramsIn.PolicyId}/Rule`;
      return _updata(urlReq);

    }
    case API_METHODS.CREATE_POLICY_RULE: {
      let urlReq = `/Policy/${paramsIn.PolicyId}/Rule`;
      return _create(urlReq);

    }
    case API_METHODS.DELETE_POLICY_RULE: {
      let urlReq = `/Policy/${paramsIn.PolicyId}/Rule/${paramsIn.id}`;
      return _delete(urlReq);

    }

    //POLICY_RULE
    case API_METHODS.GET_FINANCIAL_CONDITION_RULE: {
      let urlReq = `/FinancialCondition/${paramsIn.FinancialConditionId}/Rule/${paramsIn.id}`;
      return _get(urlReq);

    }
    case API_METHODS.GET_ALL_FINANCIAL_CONDITION_RULE: {
      let urlReq = `/FinancialCondition/${paramsIn.FinancialConditionId}/Rule`;
      return _getAll(urlReq);

    }
    case API_METHODS.UPDATE_FINANCIAL_CONDITION_RULE: {
      let urlReq = `/FinancialCondition/${paramsIn.FinancialConditionId}/Rule`;
      return _updata(urlReq);

    }
    case API_METHODS.CREATE_FINANCIAL_CONDITION_RULE: {
      let urlReq = `/FinancialCondition/${paramsIn.FinancialConditionId}/Rule`;
      return _create(urlReq);

    }
    case API_METHODS.DELETE_FINANCIAL_CONDITION_RULE: {
      let urlReq = `/FinancialCondition/${paramsIn.FinancialConditionId}/Rule/${paramsIn.id}`;
      return _delete(urlReq);

    }

    case API_METHODS.GET_ALL_CHILD_TYPE_CONTACT: {
      let urlReq = `/Contract/${paramsIn.ContractId}/${paramsIn.ChildType}`;
      return _getAll(urlReq);

    }
    case API_METHODS.DELETE_CHILD_TYPE_CONTACT: {
      let sendData = {
        ParentId: paramsIn.ContractId,
        ChildId: paramsIn.id
      };
      let urlReq = "/Contract/DeleteChild";
      return HttpClientLocal.postRequest(urlReq, sendData);

    }
    case API_METHODS.CREATE_CHILD_TYPE_CONTACT: {
      let sendData = {
        ParentId: paramsIn.ContractId,
        ChildId: paramsIn.id
      };
      let urlReq = "/Contract/CreateChild";
      return HttpClientLocal.postRequest(urlReq, sendData);

    }
    case API_METHODS.GET_CHILD_TYPE_UNLINKED_CONTACT: {
      let sendData = {
        ParentId: paramsIn.ContractId,
        ChildId: paramsIn.id
      };
      let urlReq = `/Contract/${paramsIn.ContractId}/Unlinked/${paramsIn.ChildType}`;
      return _loadData(urlReq, paramsIn.query);

    }
    //address
    case API_METHODS.GET_ADDRES: { //@dep
      let urlReq = `/${paramsIn.SubjectId}/Address/${paramsIn.id}`;
      return _get(urlReq);

    }
    case API_METHODS.GET_ALL_ADDRES: { //@dep
      let urlReq = `/${paramsIn.SubjectId}/Address`;
      return _getAll(urlReq);

    }
    case API_METHODS.UPDATE_ADDRES: { //@dep
      let urlReq = `/${paramsIn.SubjectId}/Address`;
      return _updata(urlReq);

    }
    case API_METHODS.CREATE_ADDRES: { //@dep
      let urlReq = `/${paramsIn.SubjectId}/Address`;
      return _create(urlReq);

    }
    case API_METHODS.DELETE_ADDRES: { //@dep
      let urlReq = `/${paramsIn.SubjectId}/Address/${paramsIn.id}`;
      return _delete(urlReq);

    }

    //ATTR_ENTITY
    case API_METHODS.GET_ATTR_ENTITY: {
      let urlReq = `/AttrEntity/${paramsIn.id}`;
      return _get(urlReq);

    }
    case API_METHODS.GET_ALL_ATTR_ENTITY: {
      let urlReq = `/AttrEntity`;
      return _getAll(urlReq);

    }
    case API_METHODS.UPDATE_ATTR_ENTITY: {
      let urlReq = `/AttrEntity`;
      return _updata(urlReq);

    }
    case API_METHODS.CREATE_ATTR_ENTITY: {
      let urlReq = `/AttrEntity`;
      return _create(urlReq);

    }
    case API_METHODS.DELETE_ATTR_ENTITY: {
      let urlReq = `/AttrEntity/${paramsIn.id}`;
      return _delete(urlReq);

    }

    //_ATTR_VALUE
    case API_METHODS.GET_ATTR_VALUE: {
      let urlReq = `/${paramsIn.SubjectId}/AttrValue/${paramsIn.id}`;
      return _get(urlReq);

    }
    case API_METHODS.GET_ALL_ATTR_VALUE: {
      let urlReq = `/${paramsIn.SubjectId}/AttrValue`;
      return _getAll(urlReq);

    }
    case API_METHODS.UPDATE_ATTR_VALUE: {
      let urlReq = `/${paramsIn.SubjectId}/AttrValue`;
      return _updata(urlReq);

    }
    case API_METHODS.CREATE_ATTR_VALUE: {
      let urlReq = `/${paramsIn.SubjectId}/AttrValue`;
      return _create(urlReq);

    }
    case API_METHODS.DELETE_ATTR_VALUE: {
      let urlReq = `/${paramsIn.SubjectId}/AttrValue/${paramsIn.id}`;
      return _delete(urlReq);

    }

    case API_METHODS.GET_TYPE_ATTR_VALUE: {
      let urlReq = `/${paramsIn.SubjectId}/AttrValue/Attr/${paramsIn.id}`;
      return _get(urlReq);

    }

    //_SUPPLIER_SERVICE

    case API_METHODS.GET_ALL_SUPPLIER_SERVICE: { //@dep
      let urlReq = `/Company/SupplierService/${paramsIn.CompanyId}`;
      return _getAll(urlReq);

    }
    case API_METHODS.CREATE_SUPPLIER_SERVICE: { //@dep
      let urlReq = `/Company/SupplierService/CreateChild`;
      const sendData = {
        childId: paramsIn.childId,
        parentId: paramsIn.parentId
      };
      return HttpClientLocal.postRequest(urlReq, sendData);

    } //@dep
    case API_METHODS.DELETE_SUPPLIER_SERVICE: {
      let urlReq = `/Company/SupplierService/DeleteChild`;
      const sendData = {
        childId: paramsIn.childId,
        parentId: paramsIn.parentId
      };
      return HttpClientLocal.postRequest(urlReq, sendData);

    }
    case API_METHODS.GET_ALL_SUPPLIER_SERVICE_UNLINKED: {
      let urlReq = `/Company/SupplierService/${paramsIn.CompanyId}/Unlinked`;
      return _loadData(urlReq, paramsIn.query);

    }

    //PERSONE_CODE_VALUE
    case API_METHODS.GET_PERSONE_CODE_VALUE: {
      let urlReq = `/Person/${paramsIn.PersonId}/CodeValue/${paramsIn.id}`;
      return _get(urlReq);

    }
    case API_METHODS.GET_ALL_PERSONE_CODE_VALUE: {
      let urlReq = `/Person/${paramsIn.PersonId}/CodeValue`;
      return _getAll(urlReq);

    }
    case API_METHODS.UPDATE_PERSONE_CODE_VALUE: {
      let urlReq = `/Person/${paramsIn.PersonId}/CodeValue`;
      return _updata(urlReq);

    }
    case API_METHODS.CREATE_PERSONE_CODE_VALUE: {
      let urlReq = `/Person/${paramsIn.PersonId}/CodeValue`;
      return _create(urlReq);

    }
    case API_METHODS.DELETE_PERSONE_CODE_VALUE: {
      let urlReq = `/Person/${paramsIn.PersonId}/CodeValue/${paramsIn.id}`;
      return _delete(urlReq);

    }

    case API_METHODS.IMPORT_FILE: {
      let urlReq = `/Import/${paramsIn.SubjectType}`;
      let formdata = new FormData();
      formdata.append("uploadedFile", paramsIn.uploadedFile);
      return HttpClientLocal.postRequestFile(urlReq, formdata);

    }

    // get history company
    case API_METHODS.COMPANY_GET_HISTORY: {
      urlReq = `/Company/History/${paramsIn.CompanyId}`;
      return _getHistory(urlReq);

    }
    case API_METHODS.RETURN_COMPANY: {
      let urlReq = `/Company/Return`;
      return _recoverData(urlReq);

    }

    case API_METHODS.JURNAL_GET: {
      let urlReq = `/ESBJournal/Import`;
      console.log(paramsIn);
      const params = {
        externalSystemId: paramsIn.externalSystemId,
        dateFrom: paramsIn.dateFrom,
        dateTo: paramsIn.dateTo,
        statusId: paramsIn.statusId
      };
      return _getAll(urlReq, params);
    }
    case API_METHODS.JURNAL_CHANGE_GET: {
      let urlReq = `/ESBJournal/Changed/${paramsIn.DataImportId}`;
      const params = {
        page: paramsIn.allPaginator.pagination.currentPage,
        size: paramsIn.allPaginator.pagination.perPage,
        search: paramsIn.allPaginator.search
          ? paramsIn.allPaginator.search
          : null
      };
      return _getAll(urlReq, params);
    }
    case API_METHODS.JURNAL_OUT_MES_GET: {
      let urlReq = `/ESBJournal/Export/${paramsIn.DataChangedId}`;
      const params = {
        page: paramsIn.allPaginator.pagination.currentPage,
        size: paramsIn.allPaginator.pagination.perPage,
        search: paramsIn.allPaginator.search
          ? paramsIn.allPaginator.search
          : null
      };
      return _getAll(urlReq, params);
    }
    //admin route
    case API_METHODS.LOAD_LIST_TABS: {
      urlReq = `/EsbAdmin/SystemAgent`;
      return _get(urlReq);
    }
    case API_METHODS.LOAD_TABS_DATA: {
      urlReq = `/EsbAdmin/Route/list/${paramsIn.systemId}`;
      return _getAll(urlReq);
    }

    case API_METHODS.GET_ADMIN_ROUTE_MANAGER: {
      let urlReq = `/EsbAdmin/Route/${paramsIn.id}`;
      return _get(urlReq, { systemId: paramsIn.systemId });
    }
    case API_METHODS.UPDATE_ADMIN_ROUTE_MANAGER: {
      let urlReq = `/EsbAdmin/Route`;
      return _updata(urlReq);
    }
    case API_METHODS.CREATE_ADMIN_ROUTE_MANAGER: {
      let urlReq = `/EsbAdmin/Route`;
      return _create(urlReq);
    }
    case API_METHODS.DELETE_ADMIN_ROUTE_MANAGER: {
      let urlReq = `/EsbAdmin/Route/${paramsIn.id}`;
      return HttpClientLocal.deleteRequest(urlReq);
    }

    //CREDITCARD
    case API_METHODS.GET_CREDITCARD: {
      let urlReq = `/${paramsIn.SubjectId}/CreditCard/${paramsIn.id}`;
      return _get(urlReq);
    }
    case API_METHODS.GET_ALL_CREDITCARD: {
      let urlReq = `/${paramsIn.SubjectId}/CreditCard`;
      return _getAll(urlReq);
    }
    case API_METHODS.UPDATE_CREDITCARD: {
      let urlReq = `/${paramsIn.SubjectId}/CreditCard`;
      return _updata(urlReq);
    }
    case API_METHODS.CREATE_CREDITCARD: {
      let urlReq = `/${paramsIn.SubjectId}/CreditCard`;
      return _create(urlReq);
    }
    case API_METHODS.DELETE_CREDITCARD: {
      let urlReq = `/${paramsIn.SubjectId}/CreditCard/${paramsIn.id}`;
      return _delete(urlReq);
    }
    case API_METHODS.EVENTLOG_GET: {
      let urlReq = `/EventLog`;
      const params = {
        statusId: paramsIn.eventLogStatusId,
        typeId: paramsIn.eventLogTypeId,
        dateFrom: paramsIn.dateFrom,
        dateTo: paramsIn.dateTo
      };
      return _getAll(urlReq, params);
    }
    case API_METHODS.GET_ALL_REF_ENTITY: {
      let urlReq = `/RefEntity/Category`;
      return _get(urlReq);
    }
    //----------------------------------------------------------
    //persone perf data
    case API_METHODS.GET_PREF_DATA: { //@dep
      let urlReq = `/Subject/${paramsIn.PersonId}/Pref/${paramsIn.id}`;
      return _get(urlReq);

    }
    case API_METHODS.GET_ALL_PREF_DATA: { //@dep
      let urlReq = `/Subject/${paramsIn.PersonId}/Pref`;
      return _getAll(urlReq);

    }
    case API_METHODS.UPDATE_PREF_DATA: {//@dep
      let urlReq = `/Subject/${paramsIn.PersonId}/Pref`;
      return _updata(urlReq);

    }
    case API_METHODS.CREATE_PREF_DATA: {//@dep
      let urlReq = `/Subject/${paramsIn.PersonId}/Pref`;
      return _create(urlReq);

    }
    case API_METHODS.DELETE_PREF_DATA: {//@dep
      let urlReq = `/Subject/${paramsIn.PersonId}/Pref/${paramsIn.PrefId}`;
      return _delete(urlReq);

    }
    //----------------------------------------------------
    //TREVELRS
    case API_METHODS.GET_TREVELRS: {
      let urlReq = `/Person/${paramsIn.id}`;
      return _get(urlReq);

    }
    case API_METHODS.GET_ALL_TREVELRS: {
      let urlReq = `/Person`;
      return _getAll(urlReq);

    }
    // case API_METHODS.UPDATE_TREVELRS:
    //     {
    //         let urlReq = `/Person`;
    //         return _updata(urlReq);
    //         break;
    //     }
    // case API_METHODS.CREATE_TREVELRS:
    //     {
    //         let urlReq = `/Person`;
    //         return _create(urlReq);
    //         break;
    //     }
    // case API_METHODS.DELETE_TREVELRS:
    //     {
    //         let urlReq = `/Person/${paramsIn.id}`;
    //         return _delete(urlReq);
    //         break;
    //     }
    //----------------------------------------------------

    //----------------------------------------------------------
    //persone visa
    case API_METHODS.GET_VISA: {  //@dep
      let urlReq = `/Person/${paramsIn.PersonId}/Document/Visa/${paramsIn.id}`;
      return _get(urlReq);

    }
    case API_METHODS.GET_ALL_VISA: { //@dep
      let urlReq = `/Person/${paramsIn.PersonId}/Document/Visa`;
      return _getAll(urlReq);

    }
    case API_METHODS.UPDATE_VISA: { //@dep
      let urlReq = `/Person/${paramsIn.PersonId}/Document`;
      return _updata(urlReq);

    }
    case API_METHODS.CREATE_VISA: { //@dep
      let urlReq = `/Person/${paramsIn.PersonId}/Document`;
      return _create(urlReq);

    }
    case API_METHODS.DELETE_VISA: { //@dep
      let urlReq = `/Person/${paramsIn.PersonId}/Document/${paramsIn.id}`;
      return _delete(urlReq);

    }
    //----------------------------------------------------

    //получение кодов не привязых к контракту
    case API_METHODS.GET_CODE_NOT_LINKED_TO_CONTRACT: {
      let urlReq = `/Code/Contract/${paramsIn.ContractId}/Unlinked/${paramsIn.CodeType}`;
      return _loadData(urlReq, paramsIn.query);
    }

    /**
     * соединяем код и контракт
     */
    case API_METHODS.CONTRACT_CONNECT_CODE: {
      urlReq = "/Code";
      const sendData =   paramsIn.saveData
      return HttpClientLocal.postRequest(urlReq, sendData);
    }

    /**
     * отсоединяем код и контракт
     */
    case API_METHODS.CONTRACT_UNCONNECT_CODE: {
      urlReq = "/Contract/CodeContract/Delete";
      const sendData = {
        contractId: paramsIn.contractId,
        codeId: paramsIn.codeId
      };
      return HttpClientLocal.putRequest(urlReq, sendData);
    }

    /**
     * Получаем значение кода
     */
    case API_METHODS.CONTRACT_CODE_VALUE: {

      urlReq = `/Code/Contract/${paramsIn.contractId}/Budget/${paramsIn.CodeId}`;
      return _loadData(urlReq);
    }

    /**
     * Получаем значение структурного кода договора
     */
    case API_METHODS.GET_STRUCTORE_CODE_CONTRACT: {
      urlReq = `/Code/Contract/${paramsIn.contractId}/Structure/${paramsIn.CodeId}`;
      return _loadData(urlReq);
    }

    // CREATE_STRUCTORE_CODE_CONTRACT:"CREATE_STRUCTORE_CODE_CONTRACT",
    // UPDATE_STRUCTORE_CODE_CONTRACT:"UPDATE_STRUCTORE_CODE_CONTRACT",
    // DELETE_STRUCTORE_CODE_CONTRACT:"DELETE_STRUCTORE_CODE_CONTRACT",
    // GET_ALL_STRUCTORE_CODE_CONTRACT: "GET_ALL_STRUCTORE_CODE_CONTRACT",






    //SABRE REMARK
    case API_METHODS.GET_SABRE_REMARK: {
      let urlReq = `/${paramsIn.SubjectId}/SubjectParam/Code/${paramsIn.ParamCode}/${paramsIn.SubjectParamId}`;
      return _get(urlReq);

    }
    case API_METHODS.GET_ALL_SABRE_REMARK: {
      let urlReq = `/${paramsIn.SubjectId}/SubjectParam/Code/${paramsIn.ParamCode}`;
      return _getAll(urlReq);
    }
    case API_METHODS.UPDATE_SABRE_REMARK: {
      let urlReq = `/${paramsIn.SubjectId}/SubjectParam`;
      return _updata(urlReq);
    }
    case API_METHODS.CREATE_SABRE_REMARK: {
      let urlReq = `/${paramsIn.SubjectId}/SubjectParam`;
      return _create(urlReq);
    }
    case API_METHODS.DELETE_SABRE_REMARK: {
      let urlReq = `/${paramsIn.SubjectId}/SubjectParam/${paramsIn.SubjectParamId}`;
      return _delete(urlReq);
    }

    // RULE_ITEM_TABLE
    case API_METHODS.CREATE_RULE_ITEM_TABLE: {
      let urlReq = `/Policy/${paramsIn.PolicyId}/Rule/Item`;
      return HttpClientLocal.postRequest(urlReq, paramsIn.datasend);
    }
    case API_METHODS.UPDATE_RULE_ITEM_TABLE: {
      let urlReq = `/Policy/${paramsIn.PolicyId}/Rule/Item`;
      return HttpClientLocal.putRequest(urlReq, paramsIn.datasend);
    }
    case API_METHODS.GET_NEWRULE_ITEM_TABLE: {
      let urlReq = `/Policy/${paramsIn.PolicyId}/Rule/${paramsIn.PolicyRuleId}/Item`;
      return _get(urlReq, {
        groupId:"null", isCreate:true
      });
    }
    case API_METHODS.DELETE_RULE_ITEM_TABLE: {
      let urlReq = `/Policy/${paramsIn.PolicyId}/Rule/Item/${paramsIn.GroupId}`;
      return HttpClientLocal.putRequest(urlReq, paramsIn.datasend);
    }
    // CONTRACT_BUDGET_CODE_VALUE


    case API_METHODS.GET_CONTRACT_BUDGET_CODE_VALUE: {
      let urlReq = `/Contract/${paramsIn.ContractId}/CodeValue/${paramsIn.CodeId}`;
      return _get(urlReq);
    }
    case API_METHODS.UPDATE_CREATE_CONTRACT_BUDGET_CODE_VALUE: {
      let urlReq = `/Contract/${paramsIn.ContractId}/CodeValue`;
      return _updata(urlReq);
    }
    case API_METHODS.DELETE_CONTRACT_BUDGET_CODE_VALUE: {
      let urlReq = `/Contract/${paramsIn.ContractId}/CodeValue/${paramsIn.ContractCodeValueId}`;
      return _delete(urlReq);
    }

    // DELETE_BUDGET_CODE_CONTRACT


    case API_METHODS.DELETE_CODE_CONTRACT: {
      let urlReq = `/Code/${paramsIn.CodeId}`;
      return _delete(urlReq);
    }

    //----------------------------------------------------
    default: {
      console.error(`ошибка: api ${method} - не реализовано!`)
      return false;
    }
  }
};
