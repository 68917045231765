import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue'
const Access = () =>
    import ('./PageAccess.vue')
export default {
    path: '/setting',
    component: DashboardLayout,
    children: [{
        path: 'access',
        name: 'Access',
        component: Access
    }, ]
}