import {NAME_OBJECT} from "../../../../RoleConstanans"
const META_SUPPLIER_CONTACT = {
    typeCompany: "supplier",
    fieldRoute: "CompanyId",
    configName: "clientContact",
    roleName: NAME_OBJECT.clientContact,
    routeNameShow: "SupplierShowPageContact",
    routeNameCreate: "SupplierCreatePageContact",
    routeNameEdit: "SupplierEditPageContact"
};
const META_AGENT_CONTACT = {
    typeCompany: "agent",
    fieldRoute: "CompanyId",
    configName: "clientContact",
    roleName: NAME_OBJECT.clientContact,
    routeNameShow: "AgentShowPageContact",
    routeNameCreate: "AgentCreatePageContact",
    routeNameEdit: "AgentEditPageContact"
};
const META_CLIENT_CONTACT = {
    typeCompany: "client",
    fieldRoute: "CompanyId",
    configName: "clientContact",
    roleName: NAME_OBJECT.clientContact,
    routeNameShow: "ClientShowPageContact",
    routeNameCreate: "ClientCreatePageContact",
    routeNameEdit: "ClientEditPageContact"
};
import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue";
import EditPageContactMain from "./EditPageContactMain.vue"
export default {
    path: "/company",
    component: DashboardLayout,
    children: [

    {
        path: "supplier/:CompanyId/contact/:ContactId/edit",
        name: "SupplierEditPageContactMain",
        component: EditPageContactMain,
        meta: META_SUPPLIER_CONTACT
    },
    {
        path: "supplier/:CompanyId/contact/:ContactId/show",
        name: "SupplierShowPageContact",
        component: EditPageContactMain,
        meta: META_SUPPLIER_CONTACT
    },
    {
        path: "supplier/:CompanyId/contact/create",
        name: "SupplierCreatePageContact",
        component: EditPageContactMain,
        meta: META_SUPPLIER_CONTACT
    },
    

    {
        path: "agent/:CompanyId/contact/:ContactId/edit",
        name: "AgentEditPageContactMain",
        component: EditPageContactMain,
        meta: META_AGENT_CONTACT
    },
    {
        path: "agent/:CompanyId/contact/:ContactId/show",
        name: "AgentShowPageContact",
        component: EditPageContactMain,
        meta: META_AGENT_CONTACT
    },
    {
        path: "agent/:CompanyId/contact/create",
        name: "AgentCreatePageContact",
        component: EditPageContactMain,
        meta: META_AGENT_CONTACT
    },

    
       {
            path: "client/:CompanyId/contact/:ContactId/edit",
            name: "ClientEditPageContactMain",
            component: EditPageContactMain,
            meta: META_CLIENT_CONTACT
        },
        {
            path: "client/:CompanyId/contact/:ContactId/show",
            name: "ClientShowPageContact",
            component: EditPageContactMain,
            meta: META_CLIENT_CONTACT
        },
        {
            path: "client/:CompanyId/contact/create",
            name: "ClientCreatePageContact",
            component: EditPageContactMain,
            meta: META_CLIENT_CONTACT
        },



] }