import { mutations } from "./mutations";
import { DEFAULT_PER_PAGE } from "../../../constant";
import { SET_OLD_PAGINATION, SET_ALL_PAGINATION } from "../../type";
import { clearRouteName } from "../../../helpers/clearRouteName";
const state = {
  pagination: {
    perPage: DEFAULT_PER_PAGE,
    currentPage: 1,
    perPageOptions: [5, 10, 25, 50],
    total: 0
  },
  sortData: {
    prop: "refEntityId",
    order: "descending"
  },
  search: "",
  cachePaginator: {}
};
const actions = {
  [SET_OLD_PAGINATION]: async ({ commit, state }, name) => {
    const clearUrl = clearRouteName(name);
    if (clearUrl && state.cachePaginator[clearUrl]) {
      commit(SET_ALL_PAGINATION, state.cachePaginator[clearUrl]);
      return await true;
    }
    return await false;
  }
};
export default {
  actions,
  state,
  mutations
};
