<template>
  <div :class="generateClass">
    <fg-input :label="config.label" :data-cy="config.cyData">
      <el-date-picker
        v-model="form[config.fieldName]"
        type="date"
        :format="formatData"
        value-format="yyyy-MM-dd"
        :placeholder="config.placeholder"
        :readonly="readonly"
        :picker-options="pickerOptions1"
        v-validate.initial="config.validationRule"
        :name="config.fieldName"
        :error="getError(config.fieldName)"
        :data-vv-as="config.label"
      >
      </el-date-picker>

      <div
        class="text-danger invalid-feedback"
        style="display: block"
        v-if="getError(config.fieldName)"
      >
        {{ $t("Обязательное") }}
      </div>
    </fg-input>
  </div>
</template>
<script>
import { DatePicker } from "element-ui";
import { generateClass } from "../../../../mixins/baseComponets/generateClass";
import { validateExt } from "../../../../mixins/baseComponets/validateExt";
import { GLOBAL_DATE_FORMAT_FOR_PICKER } from "../../../../constant";
export default {
  name: "BaseDate",
  mixins: [generateClass, validateExt],
  props: {
    config: { type: Object, required: true },
    form: { type: Object },
  },
  components: {
    [DatePicker.name]: DatePicker,
  },
  created() {
    if (this.form[this.config.fieldName] == "0001-01-01T00:00:00") {
      this.form[this.config.fieldName] = "1920-01-01T00:00:00";
    }
    if (this.form[this.config.fieldName] == "0001-01-01") {
      this.form[this.config.fieldName] = "1920-01-01T00:00:00";
    }
  },
  data() {
    return {
      formatData: GLOBAL_DATE_FORMAT_FOR_PICKER,
      pickerOptions1: {
        shortcuts: [
          {
            text: this.$t("Сегодня"),
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: this.$t("Вчера"),
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: this.$t("Неделю назад"),
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
    };
  },
};
</script>
