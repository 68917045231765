<template>
  <div class="row">
    <load-block :load="load"></load-block>
    <div class="col-12">
      <card :title="titleTable">
        <div>
          <!-- <filter-block :searchData="searchData" :setPerPage="setPerPage" :is-create="false"
                        :isHistoryMode="true"></filter-block> -->

          <!-- <show-code :code="convertData"></show-code> -->


          <div class="col-sm-12">
            <el-table stripe @sort-change="setSort" style="width: 100%;"
                      :data="convertData" border v-if="tableColumns"
                      :empty-text="$t('Нет данных!')"
                      row-key="historyId"
                      :tree-props="{children: fieldChildren, hasChildren: 'hasChildren'}">
              >
              <el-table-column v-for="column in tableColumns"
                               :key="column.label" :min-width="column.minWidth" sortable="custom" :prop="column.prop"
                               :label="column.label">


              </el-table-column>
              <!-- <el-table-column
                :min-width="200"
                width="200"
                fixed="right"
                align="center"
                :label="$t('Действия')" class="noHiddenText">
                <template slot-scope="props">
                  <l-button type="success" v-if="props.row.showBtn" wide block @click="resetData(props.row)">
                    {{$t('Вернуть')}}
                  </l-button>
                </template>
              </el-table-column> -->
            </el-table>
          </div>
        </div>
        <div slot="footer" class="col-12">
          
          <!-- <pagination-block :historyMode="true" :updateData="updateData"></pagination-block> -->
        </div>
      </card>
    </div>
  </div>
</template>
<script>
  import {Table, TableColumn} from 'element-ui';
  import {Loading} from 'element-ui';
  //  import {SET_MAIN_PAGINATION, SET_MAIN_PAGINATION_PAGE} from "../../src/store/type"
  import clearReactive from '../../../helpers/clearReactive'
  import LoadBlock from "../../shared/LoadBlock.vue";
  //  import FilterBlock from "./shared/theTablePage/FilterBlock.vue";
  //  import PaginationBlock from "./shared/theTablePage/PaginationBlock.vue";
  import swal from 'sweetalert2'
  import PaginationBlock from "../../shared/theTablePage/PaginationBlock.vue";
  // import FilterBlock from "../../shared/theTablePage/FilterBlock.vue";

  import {SET_HISTORY_PAGE} from './store/type'
  import formatData from '../../../helpers/converters/formatData'
  import ShowCode from "../../shared/ShowCode.vue";

  export default {
    name: "TableHistory",
    components: {
//      PaginationBlock,
//      FilterBlock,
      ShowCode,
      // FilterBlock,
      PaginationBlock,
      LoadBlock,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    props: {
      titleTable: {type: String, default: ''},

      tableData: {required: true},

      fieldId: {type: String, default: 'refEntityId'},

      updateData: {type: Function, required: true},

      searchStart: {type: Function, required: true},
      sortData: {type: Object, required: true},
      setSort: {type: Function, required: true},

      fieldInfoList: {type: Array, required: true},

      load: {type: Boolean, default: false},
      backData: {type: Function},
      fieldChildren: {type: String, default: 'historyAttrDTOList'},
      configName: {type: String, required: true},
    },
    computed: {
      convertData() {
        let tableData = clearReactive(this.tableData)
        return tableData ? tableData.map(el => {
          const convData = formatData(el.dateCreated, 'DD.MM.YYYY HH:mm:ss')
          return {...el, showBtn: true, dateCreated: convData};
        }) : []
      },
      showBtnReturn(){ 
        //устарело 
        let config = this.$store.state.common.config
        let configName = this.configName
        return (config && config[configName]) ? config[configName].historyReturn : false 
      },
      tableColumns() {
        let fieldInfoList = this.fieldInfoList
        let tableColumns = fieldInfoList ? fieldInfoList.map(el => {
          return {
            prop: el.code,
            label: el.name,
            minWidth: el.sizePercent + '%',
          }
        }) : []
        return tableColumns
      },
    },
    methods: {
      setPerPage() {
        this.$store.commit(SET_HISTORY_PAGE, 1);
        this.updateData()
      },
      searchData(val) {
        this.$store.commit(SET_HISTORY_PAGE, 1);
        this.searchStart(val)
      },
      resetData(val) {
        swal({
          title: this.$t('Вы уверены?'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: this.$t('Да удалить!'),
          cancelButtonText: this.$t('Закрыть'),
          buttonsStyling: false
        }).then((res) => {
          if (res.value && res.value == true) {
            this.backData(val)
          }
        })
      }
    },
  }
</script>
