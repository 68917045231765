<template>
  <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap  filterblock">
    <slot></slot>
    <template >
      <el-input
        v-if="forceIsSearch"
        type="search"
        class="mb-3 col-6 rounded-10 p-0"
        size="large"
        v-model.lazy="search"
        style="max-width: 950px; min-height: 42px"
        :placeholder="$t('Поиск записей')"
      />
      <div>
         <router-link
          v-if="forceIsCreate && isCreate"
          class="btn-warning btn-simple btn-link"
          :to="linkCreate ? linkCreate : `${$route.path}/create`"
        >
          <l-button type="success" wide>
            <span class="btn-label"
              >Добавить<i class="el-icon-plus"></i
            ></span>
          </l-button>
        </router-link>
      </div>
    </template>
  </div>
</template>
<script>
import { Select, Option } from "element-ui";

export default {
  name: "TheSearchElement",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    linkCreate: { type: String, default: "" },
    isCreate: { type: Boolean, default: true },

    forceIsCreate: { type: Boolean, default: true },
    forsePerpage: { type: Boolean, default: true },
    paramsPaginator:{},
    forceIsSearch: { type: Boolean, default: true }
  },
  methods: {
    searchData(val) {
      this.$emit("set-search", val.target.value);
    }
  },
  computed: {
    paginator() {
      return this.paramsPaginator
        ? this.paramsPaginator
        : {
            size: 10,
            search: ""
          };
    },
    search: {
      get() {
        return this.paginator.search;
      },
      set(val) {
        this.$emit("set-search", val);
      }
    }
  }
};
</script>
<style scoped>
  [class^=el-icon-] {
    line-height: auto;
    margin-bottom: -2px;
  }
  [class^=el-icon-]::before {
    position: relative;
    top: 1px;
    padding-left: 6px;
  }
  .btn-label {
    padding: 1px 20px;
  }
</style>
