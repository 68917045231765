/**
 * Notify
 * @param self
 * @param message
 * @param type
 */
import NotificationSample from 'src/pages/Dashboard/Components/NotificationSample';
import {TYPE_ERRORS} from '../../constant'
export default (self, message, type=TYPE_ERRORS.DEF_TYPE) => {
  self.$notify(
    {
      component: NotificationSample,
      icon: 'custom_tick',
      horizontalAlign:  'right',
      verticalAlign: 'top',
      type: type,
      message: message
    })
}
