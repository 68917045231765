<template>
  <sidebar-item :link="{name: $t('Выход'), icon: 'vip_icon icon_exit', path: `../../../login`}" :linkCl="logOut">
  </sidebar-item>
</template>
<script>
    export default {
        name: "UserMenu",
        methods: {
            logOut () {
                this.$store.dispatch('AUTH_LOGOUT')
            }
        }
    }
</script>



