<template>
  <the-table-page
    :titleTable="$t('Атрибуты')"
    :tableData="tableData"
    :load="load"
    fieldId="id"
    :updateData="updateData"
    :fieldInfoList="fieldInfoList"
    :searchStart="searchStart"
    :sortData="sortData"
    :setSort="setSort"
    :deleteEl="deleteEl"
    :prefixEdit="prefixEdit"
    :prefixShow="prefixShow"
    :linkCreate="linkCreate"
    :configName="configName"
    :roleName="nameRoleEntity"
    :hasReadOnlyInTable="hasReadOnlyInTable"
    :nameTableSaveInCashe="nameTableSaveInCashe"
  ></the-table-page>
</template>
<script>
import TheTablePage from "../../TheTablePage.vue";
import { ATTR_VALUE } from "./store/type";

import {
  SET_MAIN_PAGINATION,
  SET_MAIN_PAGINATION_PER_PAGE,
  SET_MAIN_PAGINATION_PAGE,
  SET_MAIN_SORT_ENTITY,
  SET_MAIN_SEARCH_ENTITY
} from "../../../../src/store/type";

import { READ, DELETE } from "../../../store/type";
import { mapState } from "vuex";
import NotificationSample from "src/pages/Dashboard/Components/NotificationSample";
import {
  DEFAULT_PER_PAGE,
  TYPE_ERRORS,
  GET_MEMORY_PER_PAGE
} from "../../../constant";
import notify from "../../../helpers/notify/notify";
import convertLangDataToTable from "../../../helpers/converters/convertLangDataToTable";
import { NAME_OBJECT } from "../../../RoleConstanans";
export default {
  name: "AttrValueTable",
  components: {
    TheTablePage
  },
  props: {
    routeField: { type: String, default: "CompanyId" },
    configName: { type: String, default: "attrValue" }, //depricate нигде ни стали использовать
    prefixEditIn: { type: String },
    hasReadOnlyInTable: { type: Boolean, default: true }
  },
  data() {
    return {
      load: false
    };
  },
  computed: {
    ...mapState({
      fieldInfoList: state => state.attrvalue.fieldInfoList,
      lang: state => state.common.lang,
      sortData: state => state.pagination.sortData
    }),
    nameTableSaveInCashe(){
      const nameRoute = this.$route.name;
      switch (nameRoute) {
        case "AgentCreatePageContact": {
          return "AgentCreatePageContactAttr"+"--"+this.$route.path
        }
        case "AgentShow": {
          return "AgentShowAttr"+"--"+this.$route.path
        }
        case "AgentEdit": {
          return "AgentEditAttr"+"--"+this.$route.path
        }
        case "ClientCreatePageContact":  {
          return "ClientCreatePageContactAttr"+"--"+this.$route.path
        }
        case "ClientShow": {
          return "ClientShowAttr"+"--"+this.$route.path
        }
        case "ClientEdit": {
          return "ClientEditAttr"+"--"+this.$route.path
        }
        case "CorpPolityShow":  {
          return "CorpPolityShowAttr" +"--"+this.$route.path
        }
      }
      return this.nameRoleEntity +"--"+this.$route.path
    },
    nameRoleEntity() {
      const nameRoute = this.$route.name;
      let role = NAME_OBJECT.attrValue;
      
      switch (nameRoute) {
        case "AgentCreatePageContact":
        case "AgentShow":
        case "AgentEdit": {
          role = NAME_OBJECT.AttrAgency;
          break;
        }
        case "ClientCreatePageContact":
        case "ClientShow":
        case "ClientEdit": {
          role = NAME_OBJECT.AttrClient;
          break;
        }
      }
      
      return role;
    },

    tableData() {
      let langNow = this.$store.state.common.lang;
      let dataNow = this.$store.state.attrvalue.data || [];
      if (dataNow && dataNow.length > 0) {
        dataNow = convertLangDataToTable(dataNow, langNow, "", false);
        return dataNow;
      }
    },
    prefixEdit() {
      return `${this.prefixEditIn}/attrvalue`;
    },
    prefixShow() {
      return this.prefixEdit;
    },
    linkCreate() {
      return this.prefixEdit + "/create";
    }
  },
  watch: {
    lang() {
      this.initData();
    }
  },
  methods: {
    getData() {
      this.load = true;
      this.$store
        .dispatch(ATTR_VALUE + READ, {
          self: this,
          SubjectId: this.$route.params[this.routeField]
        })
        .then(res => {
          this.load = false;
        })
        .catch(err => {
          this.load = false;
          notify(this, this.$t("Ошибка получения данных"), TYPE_ERRORS.DANGER);
        });
    },
    updateData() {
      this.getData();
    },
    searchStart(search) {
      this.$store.commit(SET_MAIN_SEARCH_ENTITY, search);
      this.getData();
    },
    setSort(val) {
      this.$store.commit(SET_MAIN_SORT_ENTITY, val);
      this.getData();
    },
    deleteEl(id) {
      this.$store
        .dispatch(ATTR_VALUE + DELETE, {
          SubjectId: this.$route.params[this.routeField],
          id: id,
          self: this
        })
        .then(res => {
          this.getData();
          if (res.errors && res.errors.length == 0) {
            notify(this, this.$t("Успешно"), TYPE_ERRORS.SUCCESS);
          }
        });
    },
    initData() {
      this.$store.commit(ATTR_VALUE + READ, { data: [], fieldInfoList: [] });
      this.$store.commit(SET_MAIN_SORT_ENTITY, {});
      this.$store.commit(SET_MAIN_SEARCH_ENTITY, "");
      this.$store.commit(SET_MAIN_PAGINATION_PER_PAGE, GET_MEMORY_PER_PAGE());
      this.$store.commit(SET_MAIN_PAGINATION_PAGE, 1);
      let name = this.nameTableSaveInCashe ? this.nameTableSaveInCashe : null;
      this.$store.dispatch("SET_OLD_PAGINATION", name)
      this.getData();
    }
  },
  created() {
    this.initData();
  }
};
</script>
