<template>
  <common-page-edit
    :title="title"
    :nameRoleEntity="nameRoleEntity"
    :apiNameGet="apiNameGet"
    :apiNameCreate="apiNameCreate"
    :apiNameUpdate="apiNameUpdate"
    :routeNameShow="routeNameShow"
    :routeNameCreate="routeNameCreate"
    :keyRoute="'id'"
    :backUrl="pathBack"
    @set-loadData="setLoadData"
    :convertLoadData="convertLoadData"
    :paramsSave="paramsSave"
    :isDefaultConverter="false"
    :paramsSend="paramsSend"
    :forceNoShowEdit="true"
    :forceNoShowReturn="true"
  >
  </common-page-edit>
</template>
<script>
import { API_METHODS, FIELD_LANG_COMPANY } from "../../../../../constant";
import { NAME_OBJECT } from "../../../../../RoleConstanans";
import CommonPageEdit from "../../../commonElement/CommonPageEdit.vue";

import convertFieldInfoListToScheme from "../../../../../helpers/converters/convetFieldInfoListToScheme";

export default {
  name: "SheduleEditPageDynamic",
  components: {
    CommonPageEdit,
  },
  data() {
    return {
      wait: false,
      scheme: {},
      loadData: null,
      fieldInfoList: null,
      preResult: null,
      load: true,

      routeNameShow: "SheduleShow",
      routeNameCreate: "SheduleCreate",

      pathBack: "/shedule",
      apiNameGet: API_METHODS.GET_SHEDULE_SETTINGS,
      apiNameCreate: API_METHODS.UPDATE_SHEDULE_SETTINGS,
      apiNameUpdate: API_METHODS.UPDATE_SHEDULE_SETTINGS,
    };
  },
  computed: {
    title() {
      return this.$t("Настройки таблицы");
    },
    nameRoleEntity() {
      return NAME_OBJECT.clients;
    },
    paramsSend() {
      return {
        id: 0,
      };
    },
  },

  methods: {
    convertLoadData(res) {
      let loadData = res.object;
      let fieldInfoList = res.fieldInfoList;
      let scheme = { configForm: [] };

      scheme.configForm = convertFieldInfoListToScheme({
        fieldInfoList,
        hasLang: false
      });

      return {
        loadData,
        fieldInfoList,
        scheme,
      };
    },
    setLoadData(loadData) {
      this.loadData = loadData;
    },

    paramsSave() {
      return {
        id: 0,
      };
    },
  },
};
</script>
