import {
  API_METHODS
} from "../../constant";
export const counterpartyAgreementsApi = (
  _getAll,
  _get,
  _update,
  _create,
  _delete,
  paramsIn
) => {
  return {
    [API_METHODS.GET_COUNTERPARTY_ALL_AGREEMENTS]: () => {
      let urlReq = `/Counterparties/${paramsIn.id}/Agreements`;

      return _getAll(urlReq);
    },
    [API_METHODS.GET_COUNTERPARTY_AGREEMENT]: () => {
      let urlReq = `/Counterparties/${paramsIn.id}/Agreements/${paramsIn.agreementId}`;
      // let urlReq = `/Counterparties/12/Agreements/15`;
      return _get(urlReq);
    },
    // [API_METHODS.UPDATE_COUNTERPARTY_AGREEMENT]: () => {
    //   let urlReq = `/Counterparties/${paramsIn.id}/Agreements/${id}`;
    //   return _update(urlReq);
    // },
    // [API_METHODS.CREATE_COUNTERPARTY_AGREEMENT]: () => {
    //   let urlReq = `/Counterparties/${paramsIn.id}/Agreements/${id}`;
    //   return _create(urlReq);
    // },
    // [API_METHODS.DELETE_COUNTERPARTY_AGREEMENT]: () => {
    //   let urlReq = `/Counterparties/${paramsIn.id}/Agreements/${id}`;
    //   return _delete(urlReq);
    // },
  };
};
