<template>
  <nav class="navbar navbar-expand-lg ">
    <div class="container-fluid">
      <div class="collapse navbar-collapse justify-content-end">
        <user-info/>
        <lang-btn></lang-btn>
      </div>
    </div>
  </nav>
</template>
<script>
  import LangBtn from "../../../components/shared/LangBtn.vue";
  import UserInfo from "../../../components/shared/UserInfo.vue"
  export default {
    components: {LangBtn, UserInfo},
    computed: {
      routeName () {
        const {name} = this.$route
        return this.capitalizeFirstLetter(name)
      }
    },
    data () {
      return {
        activeNotifications: false
      }
    },
    methods: {
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      toggleNotificationDropDown () {
        this.activeNotifications = !this.activeNotifications
      },
      closeDropDown () {
        this.activeNotifications = false
      },
      toggleSidebar () {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      hideSidebar () {
        this.$sidebar.displaySidebar(false)
      },
      minimizeSidebar () {
        this.$sidebar.toggleMinimize()
      },
      roleLoud() {
        this.$store.dispatch('LOAD_LOGIN_USER_ROLE', { self: this });
      }
    }
  }

</script>
<style>

</style>
