import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue";
const CorpPolityPage = () =>
    import ("./CorpPolityPage.vue");
const EditPageCorpPolity = () =>
    import ("./EditPageCorpPolity.vue");
const EditPagePolicyRule = () =>
    import ("../../../modules/corpPolicy/policyRule/EditPagePolicyRule.vue");

import EditPageAttrValueMain from "../attrValue/EditPageAttrValueMain.vue"
import {NAME_OBJECT} from "../../../../RoleConstanans"
const META_POLICY_ATTRVALUE = {
    pathBackStart: "corpPolicy",
    fieldRoute: "PolicyId",
    configName: "attrValue",
    roleName: NAME_OBJECT.attrValue,
    routeNameShow: "PolicyAttrValueShow",
    routeNameCreate: "PolicyAttrValueCreate",
    routeNameEdit: "PolicyAttrValueEdit"
};

export default {
    path: "/corpPolicy",
    component: DashboardLayout,
    children: [{
            path: "/",
            name: "CorpPolityPage",
            component: CorpPolityPage
        },
        {
            path: "create",
            name: "CorpPolityCreate",
            component: EditPageCorpPolity
        },
        {
            path: ":PolicyId/show",
            name: "CorpPolityShow",
            component: EditPageCorpPolity
        },
        {
            path: ":PolicyId/edit",
            name: "CorpPolityEdit",
            component: EditPageCorpPolity
        },

        {
            path: ":PolicyId/policyRule/create",
            name: "PolicyRuleCreate",
            component: EditPagePolicyRule
        },
        {
            path: ":PolicyId/policyRule/:PolicyRuleId/show",
            name: "PolicyRuleShow",
            component: EditPagePolicyRule
        },
        {
            path: ":PolicyId/policyRule/:PolicyRuleId/edit",
            name: "PolicyRuleEdit",
            component: EditPagePolicyRule
        },


        //ATTRVALUE
        {
            path: ":PolicyId/attrvalue/:AttrValueId/edit",
            name: META_POLICY_ATTRVALUE.routeNameEdit,
            component: EditPageAttrValueMain,
            meta: META_POLICY_ATTRVALUE
        },
        {
            path: ":PolicyId/attrvalue/create",
            name: META_POLICY_ATTRVALUE.routeNameCreate,
            component: EditPageAttrValueMain,
            meta: META_POLICY_ATTRVALUE
        },
        {
            path: ":PolicyId/attrvalue/:AttrValueId/show",
            name: META_POLICY_ATTRVALUE.routeNameShow,
            component: EditPageAttrValueMain,
            meta: META_POLICY_ATTRVALUE
        }
    ]
};