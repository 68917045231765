<template>
  <sidebar-item :link="{ name: $t('CfoMenu'), icon: 'nc-icon nc-circle-09' }" v-if="showMenu(NAME_OBJECT.cfo)">
    <sidebar-item class="noCapital"
      :link="{ name: $t('Cfo1BusinessLevel_menu'), path: `/cfo/${CfoType.Cfo1BusinessLevel}` }"
    ></sidebar-item>
    <sidebar-item class="noCapital"
      :link="{ name: $t('Cfo2BusinessLevel_menu'), path: `/cfo/${CfoType.Cfo2BusinessLevel}` }"
    ></sidebar-item>
    <sidebar-item class="noCapital"
      :link="{ name: $t('Cfo_menu'), path: `/cfo/${CfoType.Cfo}` }"
    ></sidebar-item>
    <sidebar-item class="noCapital"
      :link="{ name: $t('Cfo4BusinessLevel_menu'), path: `/cfo/${CfoType.Cfo4BusinessLevel}` }"
    ></sidebar-item>
    <sidebar-item class="noCapital"
      :link="{ name: $t('CfoHoldings_menu'), path: `/cfo/${CfoType.CfoHoldings}` }"
    ></sidebar-item>
  </sidebar-item>
</template>

<script>
import { CfoType } from "../../../../constant";
import {NAME_OBJECT, NAME_ACTION} from "../../../../RoleConstanans";
export default {
  name: "CfoMenu",
  data() {
    return {
      CfoType,
      NAME_OBJECT
    };
  },
  methods: {
    showMenu(name) {
          let isActionAccess  = this.$store.getters.isActionAccess
          return isActionAccess(name, NAME_ACTION.isMenu, name, 'showMenu'  )
      },
  }
};
</script>
