/**
 * ConvertLangDataTo Table
 * разварачивает структуру данных с языком без языка
 * @param data
 * @param langNow
 * @param fieldLangName
 * @returns {Array}
 */
export default (data, langNow, fieldLangName, hasLang = true) => {
    let res = []
    try {
        res = data.map(row => {
            let findNowLang = {}
            if (hasLang && row[fieldLangName] && row[fieldLangName][0]) {
                findNowLang = row[fieldLangName].find(item => item.languageCode == langNow) ||
                    row[fieldLangName].find(item => item.languageCode == 'en') ||
                    row[fieldLangName][0]
            }
            return {...row, ...findNowLang }
        })
    } catch (e) {
        console.error('Ошибка конвертации таблицы ', e)
    }
    return res
}