<template>
  <footer class="footer">
    <div class="">
      <the-copyright></the-copyright>
    </div>
  </footer>
</template>
<script>
  import TheCopyright from "../../../components/shared/footer/TheCopyright.vue";

  export default {components: {TheCopyright}}

</script>
<style>

</style>
