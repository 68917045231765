import HTTP from "./http-common";

import checkError from './checkErrors'
class HttpClient {
    constructor(vm = null) {
        this.vm = vm
        this.HTTP = new HTTP().HTTP();
    }

    getRequest(url, params = null) {
        return new Promise((resolve, reject) => {
            this.HTTP.get(url, { params })
                .then(res => {
                    checkError(res, this.vm)
                    resolve((res.data && res.data.response) ? res.data.response : res.data ? res.data : res);
                })
                .catch((err) => {
                    let res = err
                    checkError(res, this.vm)
                    let result = false
                        // if ('data' in res) {
                        //     result = (res.data && res.data.response) ? res.data.response : res.data ? res.data : res
                        // }
                    reject(result);
                });
        });
    }
    postRequest(url, data = null) {
        return new Promise((resolve, reject) => {
            this.HTTP.post(url, data)
                .then(res => {
                    checkError(res, this.vm)
                    resolve((res.data && res.data.response) ? res.data.response : res.data ? res.data : res);
                })
                .catch(res => {
                    checkError(res, this.vm)
                    reject((res.data && res.data.response) ? res.data.response : res.data ? res.data : res);
                });
        });
    }
    putRequest(url, data = null) {
        return new Promise((resolve, reject) => {
            this.HTTP.put(url, data)
                .then(res => {
                    checkError(res, this.vm)
                    resolve((res.data && res.data.response) ? res.data.response : res.data ? res.data : res);
                })
                .catch(res => {
                    checkError(res, this.vm)
                    reject((res.data && res.data.response) ? res.data.response : res.data ? res.data : res);
                });
        });
    }
    postRequestFile(url, formData) {
        return new Promise((resolve, reject) => {
            this.HTTP.post(url, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then(res => {
                    checkError(res, this.vm)
                    resolve((res.data && res.data.response) ? res.data.response : res.data ? res.data : res);
                })
                .catch(res => {
                    checkError(res, this.vm)
                    reject((res.data && res.data.response) ? res.data.response : res.data ? res.data : res);
                });
        });
    }

    putRequestFile(url, formData) {
        return new Promise((resolve, reject) => {
            formData.append("_method", "PUT")
            this.HTTP.post(url, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then(res => {
                    checkError(res, this.vm)
                    resolve(res.data.response);
                })
                .catch(res => {
                    checkError(res, this.vm)
                    reject(res ? res.response : res);
                });
        });
    }
    deleteRequest(url, params = null) {
        return new Promise((resolve, reject) => {
            this.HTTP.delete(url, { params })
                .then(res => {
                    checkError(res, this.vm)
                    resolve((res.data && res.data.response) ? res.data.response : res.data ? res.data : res);
                })
                .catch((err) => {
                    let res = err
                    checkError(res, this.vm)
                    let result = false
                        // if ('data' in res) {
                        //     result = (res.data && res.data.response) ? res.data.response : res.data ? res.data : res
                        // }
                    reject(result);
                });
        });
    }

  getFileRequest(url, params = null) {
    return new Promise((resolve, reject) => {
      this.HTTP.get(url, { params , responseType: "blob" } )
        .then(res => {
          checkError(res, this.vm)
          resolve((res.data && res.data.response) ? res.data.response : res.data ? res.data : res);
        })
        .catch((err) => {
          let res = err
          checkError(res, this.vm)
          let result = false
          // if ('data' in res) {
          //     result = (res.data && res.data.response) ? res.data.response : res.data ? res.data : res
          // }
          reject(result);
        });
    });
  }

  getFileRequestFull(url, params = null) {
    return new Promise((resolve, reject) => {
      this.HTTP.get(url, { params , responseType: "blob" } )
        .then(res => {
          checkError(res, this.vm)
          resolve(res);
        })
        .catch((err) => {
          let res = err
          checkError(res, this.vm)
          let result = false
          // if ('data' in res) {
          //     result = (res.data && res.data.response) ? res.data.response : res.data ? res.data : res
          // }
          reject(result);
        });
    });
  }

}

export default HttpClient;
