import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue'
const ContractPage = () =>
    import ('./ContractPage.vue')
const EditPageContract = () =>
    import ('./EditPageContract.vue')

export const contractNew = [ {  
    path: '/',
    name: 'ContractPage',
    component: ContractPage
},
{
    path: 'create',
    name: 'CreateContract',
    component: EditPageContract
},
{
    path: ':ContractId/show',
    name: 'ContractShow',
    component: EditPageContract
},

{
    path: ':ContractId/edit',
    name: 'ContractEdit',
    component: EditPageContract
},]




export default {
        path: '/contract',
        component: DashboardLayout,
        redirect: "/",
        children: contractNew
}



