import { READ } from '../../../../store/type'
import { ENTITY_NAME, SET_TAB_FIN } from "./type";

export const mutations = {
    [ENTITY_NAME + READ]: (state, { data, fieldInfoList }) => {
        state.data = data
        state.fieldInfoList = fieldInfoList
    },
    [SET_TAB_FIN]: (state, tab) => {
        state.tabnow = tab
    },
};