import {
  API_METHODS
} from "../../constant";
export const userApi = (
  _getAll,
  _get,
  _update,
  _create,
  _delete,
  paramsIn
) => {
  return {
    [API_METHODS.GET_USER]: () => {
      let urlReq = `/Admin/User/${paramsIn.id}`;
      return _get(urlReq);
    },
    [API_METHODS.GET_ALL_USER]: () => {
      let urlReq = "/Admin/User";
      return _getAll(urlReq);
    },
    [API_METHODS.UPDATE_USER]: () => {
      let urlReq = "/Admin/User";
      return _update(urlReq);
    },
    [API_METHODS.CREATE_USER]: () => {
      let urlReq = "/Admin/User";
      return _create(urlReq);
    },
    [API_METHODS.DELETE_USER]: () => {
      let urlReq = `/Admin/User/${paramsIn.id}`;
      return _delete(urlReq);
    },
    [API_METHODS.GET_USER_SUBLINKS]: () => {
      let urlReq = `/Admin/User/${paramsIn.id}/SubjectLink`;
      return _getAll(urlReq);
    }
  };
};
