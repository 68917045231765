var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$route.params.CompanyId)?_c('card',[_c('el-tabs',{attrs:{"tab-position":_vm.tabPosition},on:{"before-leave":_vm.beforeLeave,"tab-click":_vm.beforeLeave},model:{value:(_vm.nowTab),callback:function ($$v) {_vm.nowTab=$$v},expression:"nowTab"}},[(_vm.isContract && _vm.showMenu(_vm.nameObject.contracts))?_c('el-tab-pane',{attrs:{"label":_vm.$t('Договоры'),"name":"contract"}},[(_vm.nowTab == 'contract')?_c('contract-page',{attrs:{"hasReadOnlyInTable":_vm.loadData ? !_vm.loadData.readOnly : true,"forseNoCreateLink":true,"isClient":""}}):_vm._e()],1):_vm._e(),(
        _vm.isContact &&
          _vm.showMenu(
            _vm.isAgent ? _vm.nameObject.ContactAgency : _vm.nameObject.ContactClient
          )
      )?_c('el-tab-pane',{attrs:{"label":_vm.$t('Контакты'),"name":"contact"}},[(_vm.nowTab == 'contact')?_c('ContactTable',{attrs:{"routeField":"CompanyId","hasReadOnlyInTable":_vm.loadData ? !_vm.loadData.readOnly : true}}):_vm._e()],1):_vm._e(),(
        _vm.isAddress &&
          _vm.showMenu(
            _vm.isAgent ? _vm.nameObject.AddressAgency : _vm.nameObject.AddressClient
          )
      )?_c('el-tab-pane',{attrs:{"label":_vm.$t('Адреса'),"name":"address"}},[(_vm.nowTab == 'address')?_c('AddresTable',{attrs:{"routeField":"CompanyId","hasReadOnlyInTable":_vm.loadData ? !_vm.loadData.readOnly : true}}):_vm._e()],1):_vm._e(),(
        _vm.isattrVal &&
          _vm.showMenu(_vm.isAgent ? _vm.nameObject.AttrAgency : _vm.nameObject.AttrClient)
      )?_c('el-tab-pane',{attrs:{"label":_vm.$t('Атрибуты'),"name":"attrVal","hasReadOnlyInTable":_vm.loadData ? !_vm.loadData.readOnly : true}},[(_vm.nowTab == 'attrVal')?_c('AttrValueTable',{attrs:{"hasReadOnlyInTable":_vm.loadData ? !_vm.loadData.readOnly : true,"routeField":"CompanyId","prefixEditIn":`/company/${_vm.$route.meta.typeCompany}/${_vm.$route.params.CompanyId}`}}):_vm._e()],1):_vm._e(),(_vm.isSuppServ)?_c('el-tab-pane',{attrs:{"label":_vm.$t('Услуги поставщика'),"name":"suppServ"}},[(_vm.nowTab == 'suppServ')?_c('TableSupplierService',{attrs:{"hasReadOnlyInTable":_vm.loadData ? !_vm.loadData.readOnly : true}}):_vm._e()],1):_vm._e(),(_vm.isCreditCard && _vm.showMenu(_vm.nameObject.CreditCardClient))?_c('el-tab-pane',{attrs:{"label":_vm.$t('Кредитные карты'),"name":"creditcard"}},[(_vm.nowTab == 'creditcard')?_c('CreditCardTable',{attrs:{"hasReadOnlyInTable":_vm.loadData ? !_vm.loadData.readOnly : true}}):_vm._e()],1):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }