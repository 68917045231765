<template>
  <common-page-table
    :apiName="apiName"
    :nameRole="nameRoleEntity"
    :titleTable="titleName"
    :paramsGetData="paramsGetData"
    :fieldLand="fieldLang"
    :keyTable="'cfoTableId'"
    :deleteParams="deleteParams"
    :apiDeleteName="apiDeleteName"
    :alternativeConverter="convertData"
    :isDefaultConverter="false"
    :forceIsEdit="false"
    :forceIsCreate="false"
    :forceDelete="false"
  />
</template>
<script>
import { NAME_OBJECT } from "../../../../../RoleConstanans";
import CommonPageTable from "../../../commonElement/CommonPageTable.vue";
import { API_METHODS , GLOBAL_DATE_FORMAT} from "../../../../../constant";
import convertLangDataToTable from "../../../../../helpers/converters/convertLangDataToTable";
export default {
  name: "CfoTablePage",
  components: { CommonPageTable },
  data() {
    return {
      fieldLang: "",
      apiName: API_METHODS.GET_ALL_CFO,
      apiDeleteName: API_METHODS.DELETE_CFO
    };
  },
  computed: {
    cfoType() {
      const { CfoType = "Cfo" } = this.$route.params;
      return CfoType;
    },
    titleName() {
      return this.$t(this.cfoType+'_table');
    },
    paramsGetData() {
      return { CfoType: this.cfoType };
    },
    nameRoleEntity() {
      return NAME_OBJECT.cfo;
    }
  },
  methods: {
    convertDataEl(val, def=null) {
      return  val ? this.$moment(val).format(
            GLOBAL_DATE_FORMAT
          ) : def
    },
    convertData(dataNow, langNow) {
      let res = convertLangDataToTable(dataNow, langNow, "", false);    
      const convertDataEl = this.convertDataEl
      res = res.map(item=> {
        return {
          ...item,
          startDate : convertDataEl(item.startDate),
          endDate: convertDataEl(item.endDate),
        }
      })
      return res;
    },
    deleteParams(id) {
      return { id: id,  CfoType: this.cfoType };
    }
  }
};
</script>
