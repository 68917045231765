import {
    AUTH_LOGOUT,
    AUTH_REQUEST,
    AUTH_SUCCESS,
    AUTH_ME,
    AUTH_TOKEN,
    AUTH_REGISTRATION,
    SET_USER,
    AUTH_ONLY_ME,
    RESET_USER_ROLE
} from "../../type";
import { API_URLS, NAME_TOKEN, API_METHODS , TOKEN_TIME_START } from "../../../constant";
import HttpClient from "../../../util/HttpClient";
import { api } from '../../../util/api'
import { GET_LIST_ENTITY } from '../../../components/modules/entity/store/type'

export const actions = {
    [AUTH_REQUEST]: async({ commit, dispatch, rootState }, { email, password, self }) => {
        let paramsIn = {
            self,
            email,
            password
        };
        localStorage.removeItem(NAME_TOKEN);
        try {
            let res = await api(API_METHODS.AUTH_LOGIN, paramsIn, rootState)
            let token = res.object;
            localStorage.setItem(NAME_TOKEN, token);
            localStorage.setItem(TOKEN_TIME_START, Date.now()) 
            commit(AUTH_SUCCESS, { token: token });
            dispatch(GET_LIST_ENTITY, self)
            return res;
        } catch (err) {
            console.error(err)
            return err
        }
    },
    // eslint-disable-next-line no-unused-vars
    [AUTH_LOGOUT]: ({ commit, dispatch }) => {
        // eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => {
            commit(RESET_USER_ROLE);
            commit(AUTH_LOGOUT); 
            localStorage.removeItem(NAME_TOKEN);
            resolve();
        });
    },
    // eslint-disable-next-line no-unused-vars
    [AUTH_REGISTRATION]: ({ commit, dispatch }, { name, email, password }) => {
        return new Promise((resolve, reject) => {
            let data = {
                name,
                email,
                password
            };
            new HttpClient()
                .postRequest(API_URLS.registration, data)
                .then(res => {
                    let token = res.token;
                    let user = res.me;
                    localStorage.setItem(NAME_TOKEN, token);
                    dispatch(SET_USER, user);
                    resolve(res);
                })
                .catch(res => {
                    reject(res);
                });
        });
    },
    [AUTH_ME]: async({ commit, dispatch, rootState }, { self }) => {
        // eslint-disable-next-line no-unused-vars
        let token = localStorage.getItem(NAME_TOKEN);
        if (!token) {
            return false;
        }
        let paramsIn = {
            self
        }
        try {
            let res = await api(API_METHODS.AUTH_ME, paramsIn, rootState);
            localStorage.setItem("userId", res.object.userId);
            commit(AUTH_SUCCESS, { token: token });
            let user = res.object;
            dispatch(SET_USER, user);
            return res;
        } catch (err) {
            console.warn("Токен устарел!")
            localStorage.removeItem(NAME_TOKEN);
            return err
        }
    },
    [AUTH_TOKEN]: ({ commit, dispatch }, { event }) => {

        return new Promise((resolve, reject) => {
            if (localStorage.getItem(NAME_TOKEN) == null) {
                window.location.href = '/#/login'
                reject(false)
            }
            resolve(true)
        })
    }
};