<template>
  <common-page-table
    :titleTable="$t('Договоры')"
    :apiName="apiName"
    :nameRole="nameRoleEntity"
    :paramsGetData="paramsGetData"
    :fieldLand="fieldLang"
    :hasLang="false"
    :prefixEdit="`/contract`"
    :prefixShow="`/contract`"
    :linkCreate="`/contract/create`"
    :keyTable="'contractId'"
    :alternativeConverter="convertData"
    :isDefaultConverter="false"
    :deleteParams="deleteParams"
    :apiDeleteName="apiDeleteName"
    :forceIsCreate="false &&  !forseNoCreateLink"
    
    :hasReadOnlyInTable="hasReadOnlyInTable"

 
    :forceIsEdit="false"
    :forceDelete="false"
  />
</template>
<script>
/**
 * :forceIsCreate="false &&  !forseNoCreateLink" 
 * отключаем данные функционал
 */
import { NAME_OBJECT } from "../../../../RoleConstanans";
import CommonPageTable from "../../commonElement/CommonPageTable.vue";
import { API_METHODS } from "../../../../constant";
import convertLangDataToTable from "../../../../helpers/converters/convertLangDataToTable";

export default {
  name: "ContractPage",

  components: { CommonPageTable },
  props: {
    forseNoCreateLink: { type: Boolean, default: false },
    hasReadOnlyInTable: { type: Boolean, default: true },
    isClient: { type: Boolean, default: false }
  },
  data() {
    return {
      fieldLang: "",
      apiName: API_METHODS.GET_ALL_CONTRACT,
      apiDeleteName: API_METHODS.DELETE_CONTRACT
    };
  },
  computed: {
    paramsGetData() {
      return {
        CompanyId: this.$route.params.CompanyId,
        isClient: this.isClient
      };
    },
    nameRoleEntity() {
      return NAME_OBJECT.contracts;
    }
  },
  methods: {
    convertData(dataNow, langNow) {
      let res = convertLangDataToTable(dataNow, langNow, "", false);
      return res;
    },
    deleteParams(id) {
      return {
        ContractId: id,
        id: id
      };
    }
  }
};
</script>
