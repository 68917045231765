import {
  API_METHODS
} from "../../constant";
export const sheduleApi = (
  _getAll,
  _get,
  _update,
  _create,
  _delete,
  paramsIn
) => {
  return {
    [API_METHODS.GET_SHEDULE]: () => {
      let urlReq = `/odata/flights/${paramsIn.id}`;
      return _get(urlReq);
    },
    [API_METHODS.GET_ALL_SHEDULE]: () => {
      let urlReq = "/odata/flights";
      return _getAll(urlReq);
    },
    [API_METHODS.UPDATE_SHEDULE]: () => {
      let urlReq = "/odata/flights";
      return _update(urlReq);
    },
    [API_METHODS.CREATE_SHEDULE]: () => {
      let urlReq = "/odata/flights";
      return _create(urlReq);
    },
    [API_METHODS.DELETE_SHEDULE]: () => {
      let urlReq = `/odata/flights/${paramsIn.id}`;
      return _delete(urlReq);
    },


    [API_METHODS.GET_SHEDULE_SETTINGS ]: () => {
      let urlReq = `/Flights/FlightsSettings`;
      return _get(urlReq);
    },
    [API_METHODS.UPDATE_SHEDULE_SETTINGS ]: () => {
      let urlReq = `/Flights/FlightsSettings`;
      return _update(urlReq);
    },
    [API_METHODS.CREATE_SHEDULE_SETTINGS ]: () => {
      let urlReq = `/Flights/FlightsSettings`;
      return _update(urlReq);
    },
  };
};
