<template>
    <span>Welcome to <b>Vue LBD PRO</b> - a beautiful freebie for every web developer.  {{message}}</span>
</template>
<script>
  export default {
    name: 'notification-sample',
    props:{
      message : {}
    }
  }
</script>
<style>
</style>
