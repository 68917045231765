
import SupplierTablePage from "./SupplierTablePage"
import SupplierEditPageDynamic from "./SupplierEditPageDynamic.vue"
import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue"


const META_SUPPLIER = {
    typeCompany: 'supplier'
}

export default {
    path: "/company",
    component: DashboardLayout,
    children: [
        {
            path: "supplier",
            name: "SupplierTablePage",
            component: SupplierTablePage
        },
        {
            path: "supplier/:CompanyId/edit",
            name: "SupplierEdit",
            component: SupplierEditPageDynamic,
            meta: META_SUPPLIER
        },
        {
            path: "supplier/:CompanyId/show",
            name: "SupplierShow",
            component: SupplierEditPageDynamic,
            meta: META_SUPPLIER
        },
        {
            path: "supplier/create",
            name: "SupplierCreate",
            component: SupplierEditPageDynamic
        },
    ]
}