import { API_METHODS } from "../../constant";
export const cfoApi = (
  _getAll,
  _get,
  _update,
  _create,
  _delete,
  paramsIn
) => {
  return {
    // api/CfoTables/{CfoType} 
    [API_METHODS.GET_CFO]: () => {
      let urlReq = `/CfoTables/${paramsIn.CfoType}/${paramsIn.id}`;
      return _get(urlReq);
    },
    [API_METHODS.GET_ALL_CFO]: () => {
      let urlReq = `/CfoTables/${paramsIn.CfoType}`;
      return _getAll(urlReq);
    },
    [API_METHODS.UPDATE_CFO]: () => {
      let urlReq = `/CfoTables`;
      return _update(urlReq);
    },
    [API_METHODS.CREATE_CFO]: () => {
      let urlReq = `/CfoTables`;
      return _create(urlReq);
    },
    [API_METHODS.DELETE_CFO]: () => {
      let urlReq = `/CfoTables/${paramsIn.CfoType}/${paramsIn.id}`;
      return _delete(urlReq);
    }
  };
};
