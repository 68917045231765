import {
    SET_USER,
    USER_SUCCESS,
    EDIT_PROFILE,
    ADD_ERROR
} from "../../type";
import { API_URLS } from "../../../constant";
import HttpClient from "../../../util/HttpClient";
export const actions = {

    [SET_USER]: ({ commit }, user) => {
        commit(USER_SUCCESS, user);
    },
    [EDIT_PROFILE]: (
        // eslint-disable-next-line no-unused-vars
        { commit, dispatch }, { name, files }
    ) => {
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            formData.append("name", name);
            if (files) {
                for (var i = 0; i < files.length; i++) {
                    let file = files[i];
                    formData.append("images[" + i + "]", file);
                }
            }

            new HttpClient()
                .putRequestFile(API_URLS.editProfile, formData)
                .then(res => {
                    resolve(res);
                })
                .catch(res => {
                    reject(res);
                });
        });
    },
};
