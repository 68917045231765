import {TYPE_COMPONETS} from '../constants'
import extendComponents from '../extendComponents'
let radio = {
  ...extendComponents(TYPE_COMPONETS.RADIO),
  variant: []
}
delete radio.name
delete radio.placeholder
delete radio.default

export default radio
