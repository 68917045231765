import {
  API_METHODS
} from "../../constant";
export const counterpartyBankDetailsApi = (
  _getAll,
  _get,
  _update,
  _create,
  _delete,
  paramsIn
) => {
  return {
    [API_METHODS.GET_COUNTERPARTY_BANK_DETAILS]: () => {
      let urlReq = `/CounterpartyBankDetails/${paramsIn.id}`;
      return _get(urlReq);
    },
    [API_METHODS.GET_ALL_COUNTERPARTIES_BANK_DETAILS]: () => {
      let urlReq = "/CounterpartyBankDetails";
      return _getAll(urlReq);
    },
    [API_METHODS.UPDATE_COUNTERPARTY_BANK_DETAILS ]: () => {
      let urlReq = "/CounterpartyBankDetails";
      return _update(urlReq);
    },
    [API_METHODS.CREATE_COUNTERPARTY_BANK_DETAILS ]: () => {
      let urlReq = "/CounterpartyBankDetails";
      return _create(urlReq);
    },
    [API_METHODS.DELETE_COUNTERPARTY_BANK_DETAILS]: () => {
      let urlReq = `/CounterpartyBankDetails/${paramsIn.id}`;
      return _delete(urlReq);
    },
  };
};
