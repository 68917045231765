<template>
  <card :title="$t('Привязать услуги поставщика')">
    <load-block :load="load"></load-block>
    <div class="row">
      <div class="col-md-4">
        <label>{{$t('Код')}}</label>
        <div class="form-group has-label">
          <div class="el-input">
            <v-select
              :label="labelSelect"
              v-model="selectCode"
              :options="option"
              @search="onSearch"
            >
              <span slot="no-options">{{$t('Результатов не найдено')}}</span>
            </v-select>

          </div>
        </div>

      </div>
      <div class="col-md-2">
        <label>&nbsp;</label>
        <l-button type="success" v-if="hasSave" :disabled="!selectCode" wide block @click="connectCode">{{$t('Привязать')}}</l-button>
      </div>
    </div>
  </card>
</template>
<script>
  import HttpClient from '../../../../util/HttpClient'
  import {api} from '../../../../util/api'
  import {API_METHODS} from '../../../../constant'
  import {SUPPLIER_SERVICE_CONNECT} from '../store/type'
  import LoadBlock from "../../../shared/LoadBlock.vue";
  import {NAME_OBJECT, NAME_ACTION} from "../../../../RoleConstanans"

  export default {
    components: {LoadBlock},
    name: 'EditCodeClient',
    data() {
      return {
        selectCode: null,
        option: [],
        labelSelect: 'entityName',
        keyId: 'entityId',
        load: false,
        routeAdd : "SupplierServiceCreate",
        routeEdit: "ClientEditCodePersoneEdit",
        nameRule: NAME_OBJECT.supplierService
      }
    },
    created() {
      this.initData()
    },
    computed:{
      hasSave() {
        let nameRule = this.nameRule  
        if(this.userRoleLouded){
          if(this.$route.name == this.routeAdd) {
              return this.isActionAccess(nameRule, NAME_ACTION.isCreate) 
          }
          if(this.$route.name == this.routeEdit) {
              return this.isActionAccess(nameRule, NAME_ACTION.isEdit)
          }
        }
        return true 
      },
      userRoleLouded(){
        return this.$store.getters.userRoleLouded
      }
    },
    methods: {
      isActionAccess(name, actions){
        return this.$store.getters.isActionAccess(name, actions)
      },
      async initData() {
        this.load = true
        try {
          const res = await this.loadData()
          this.load = false
        } catch (e) {
          this.load = false
        }
      },
      async connectCode() {
        const paramsIn = {
          self: this,
          childId:  this.selectCode[this.keyId],
          parentId: this.$route.params.CompanyId
        }
        this.load = true
        try {
          let res = await this.$store.dispatch(SUPPLIER_SERVICE_CONNECT, paramsIn)
          this.load = false
          this.$router.push({path:`/company/supplier/${this.$route.params.CompanyId}/edit`});
        } catch (e) {
          console.error(e)
          this.load = false
        }
      },
      onSearch(search, loading, event) {
        if (search.length < 3)
          return false

        loading(true);
        this.search(loading, search, this);
      },
      search(loading, search, vm) {
        vm.loadData(null, search)
          .then(() => {
            loading(false)
          })
          .catch(() => {
            loading(false)
          })
      },
      async loadData(search = null) {
        try {
          const params = {
            self: this,
            CompanyId: this.$route.params.CompanyId,
            query: {
              search: search,
              lang: this.$store.state.common.lang,
            }
          }
          let res = await api(API_METHODS.GET_ALL_SUPPLIER_SERVICE_UNLINKED, params)
          let responseData = res.object
          if (!('map' in responseData)) {
            responseData = [responseData]
          }
          this.addOptions(responseData);
          return res
        } catch (err) {
          return err
        }

      },
      addOptions(result) {
        result.forEach(item => {
          let find = this.option.find(el => el[this.keyId] == item[this.keyId])
          if (!find) {
            this.option.push(item)
          }
        })
      },
    }
  }
</script>
