import {actions} from "./actions";
import {mutations} from "./mutations";
import {DEFAULT_PER_PAGE} from '../../../../constant'
const state = {
  data: [],
  fieldInfoList: [],
  search: '',
  sortData: {
    prop: 'id',
    order: 'descending'
  },
  pagination: {
    perPage: DEFAULT_PER_PAGE,
    currentPage: 1,
    perPageOptions: [5, 10, 25, 50],
    total: 0
  },
};

export default {
  state,
  actions,
  mutations
};
