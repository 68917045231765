<template>
  <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap filterblock"> 
    <slot> </slot>
    <template v-if="manualMode == false">
      <el-input v-if="forseSearch"
        type="search"
        class="mb-3"
        v-model.lazy="search"
        @input="searchData"
        style="width: 250px; border-radius: 10px"
        :placeholder="$t('Поиск записей')"
      />
      <div>
        <router-link
          v-if="forceIsCreate && isCreate"
          class="btn-warning btn-simple btn-link"
          :to="linkCreate ? linkCreate : `${$route.path}/create`"
        >
          <l-button type="success" wide>
            <span class="btn-label"
              ><i class="el-icon-plus el-icon-right"></i
            ></span>
          </l-button>
        </router-link>
      </div>
    </template>
    <template v-if="manualMode == true">
      <el-input v-if="forseSearch"
        type="search"
        class="mb-3"
        v-model.lazy="search"
        @input="searchData"
        style="width: 250px"
        :placeholder="$t('Поиск записей')"
      />
      <div>
        <router-link
          v-if="forceIsCreate && isCreate"
          class="btn-warning btn-simple btn-link"
          :to="linkCreate ? linkCreate : `${$route.path}/create`"
        >
          <l-button type="success" wide>
            <span class="btn-label"
              ><i class="el-icon-plus el-icon-right"></i
            ></span>
          </l-button>
        </router-link>
      </div>
    </template>
  </div>
</template>
<script>
import { Select, Option } from "element-ui";
import { SET_MAIN_PAGINATION_PER_PAGE } from "src/store/type";
import { SET_HISTORY_PER_PAGE } from "../../modules/history/store/type";
export default {
  name: "FilterBlock",
  components: {
    [Select.name]: Select,
    [Option.name]: Option
  },
  props: {
    searchData: {
      type: Function,
      required: true,
      description: "функция старта поиска"
    },
    setPerPage: {
      type: Function,
      required: true,
      description: "функция измения колчичесва страниц"
    },
    linkCreate: { type: String, default: "" },
    isCreate: { type: Boolean, default: true },
    isHistoryMode: { type: Boolean, default: false },
    forceIsCreate: { type: Boolean, default: true },

    manualMode: { type: Boolean, default: false },
    allPaginator: {type: Object},
    forseSearch: { type: Boolean, default: true },
  },
  data() {
    return {
      search: ""
    };
  },
  mounted() {
    this.search = this.$store.state.pagination.search
  },
  computed: {
    perPage: {
      get() {
        return this.pagination.perPage;
      },
      set(val) {
        const nameAction = this.isHistoryMode
          ? SET_HISTORY_PER_PAGE
          : SET_MAIN_PAGINATION_PER_PAGE;
        this.$store.commit(nameAction, val);
      }
    },
    pagination() {
      if (this.isHistoryMode) {
        return this.$store.state.history.pagination;
      }
      return this.$store.state.pagination.pagination;
    },

     perPageManual: {
      get() {
        return this.allPaginator.pagination.perPage;
      },
      set(val) {
        this.allPaginator.pagination.perPage = val;
      }
    },
    paginationManual() {
      return  this.allPaginator.pagination;
    }
  }
};
</script>
