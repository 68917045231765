import {CREATE, READ, UPDATE, DELETE, SET_MAIN_PAGINATION_TOTAL} from '../../../../store/type'
import {ENTITY_NAME, CODE_ENUM, HISTORY_CODE} from "./type";

import {api} from "../../../../util/api"
import {API_METHODS} from "../../../../constant";

export const actions = {
  [ENTITY_NAME + READ]: async ({state, rootState, commit}, paramsIn = null) => {
    try {
      if (paramsIn.id) {
        return await api(API_METHODS.GET_CODE, paramsIn, rootState)
      } else {
        let res = null
        if(paramsIn.ContractId) {
          res = await api(API_METHODS.GET_CONTRACT_CODE, paramsIn, rootState)
        } else {
          res = await api(API_METHODS.GET_ALL_CODE, paramsIn, rootState)
        }
        commit(ENTITY_NAME + READ, {
          data: res.object,
          fieldInfoList: res.fieldInfoList
        })
        commit(SET_MAIN_PAGINATION_TOTAL, {
          countPage: res.countPage,
        })
        return res
      }
    } catch(err) {
      return err
    }
  },
  [ENTITY_NAME + UPDATE]: async ({commit}, paramsIn = null) => {
    try {
      return await api(API_METHODS.UPDATE_CODE, paramsIn)
    } catch(err) {
      return (err)
    }
  },
  [ENTITY_NAME + DELETE]: async ({}, paramsIn = null) => {
    try {
      return await api(API_METHODS.DELETE_CODE, paramsIn)
    } catch(err) {
      return (err)
    }
  },

  [CODE_ENUM + READ]: async ({state, rootState, commit}, paramsIn = null) => {
    try {
      if (paramsIn.id) {
        return await api(API_METHODS.GET_CODE_ENUM, paramsIn, rootState)
      } else {
        const res = await api(API_METHODS.GET_ALL_CODE_ENUM, paramsIn, rootState)
        commit(CODE_ENUM + READ, {
          data: res.object,
          fieldInfoList: res.fieldInfoList
        })
        commit(SET_MAIN_PAGINATION_TOTAL, {
          countPage: res.countPage,
        })
        return res
      }
    } catch (err) {
      return err
    }
  },

  [CODE_ENUM + UPDATE]: async ({commit}, paramsIn = null) => {
    try {
      return await api(API_METHODS.UPDATE_CODE_ENUM, paramsIn)
    } catch (err) {
      return err
    }
  },
  [CODE_ENUM + DELETE]: async ({}, paramsIn = null) => {
    try {
      return await api(API_METHODS.DELETE_CODE_ENUM, paramsIn)
    } catch (err) {
      return err
    }
  }
};
