// sabreRemark
import EditPageSabreRemarkPerson from "../../../modules/sabreRemark/EditPageSabreRemarkPerson.vue"
const META_CLIENT_CREDITCARD = {
    routeNameShow: "ShowSabreRemark",
    routeNameCreate: "CreateSabreRemark",
    routeNameEdit: "EditSabreRemark"
};
export default [
        {
            path: 'contract/:ContractId/person/:PersonId/sabreRemark/create',
            name: 'CreateSabreRemark',
            component: EditPageSabreRemarkPerson,
            meta: META_CLIENT_CREDITCARD
        },
        {
            path: 'contract/:ContractId/person/:PersonId/sabreRemark/:SubjectParamId/edit',
            name: 'EditSabreRemark',
            component: EditPageSabreRemarkPerson,
            meta: META_CLIENT_CREDITCARD
        },
        {
            path: 'contract/:ContractId/person/:PersonId/sabreRemark/:SubjectParamId/show',
            name: 'ShowSabreRemark',
            component: EditPageSabreRemarkPerson,
            meta: META_CLIENT_CREDITCARD
        }       
    ]
