import { API_METHODS } from "../../constant";
export const codeApi = (_getAll, _get, _create, _update, _delete, paramsIn) => {
  return {
    [API_METHODS.GET_CODE]: () => {
      let urlReq = `/Code/${paramsIn.id}`;
      return _get(urlReq);
    },

    [API_METHODS.GET_ALL_CODE]: () => {
      let urlReq = `/Code/Agency/${paramsIn.AgencyId}`;
      return _getAll(urlReq);
    },

    [API_METHODS.UPDATE_CODE]: () => {
      let urlReq = `/Code`;
      return _update(urlReq);
    },
    [API_METHODS.CREATE_CODE]: () => {
      let urlReq = `/Code`;
      return _update(urlReq);
    },

    [API_METHODS.DELETE_CODE]: () => {
      let urlReq = `/Code/${paramsIn.CodeId}`;
      return _delete(urlReq);
    },


    [API_METHODS.DELETE_CODE_CONTRACT]: () => {
      let urlReq = `/Code/${paramsIn.CodeId}`;
      return _delete(urlReq);
    }


  };
};
