import EditPageCreditCardMain from "../creditCard/EditPageCreditCardMain.vue"
import {NAME_OBJECT} from "../../../RoleConstanans"
const META_CLIENT_CREDITCARD = {
    typeCompany: "client",
    fieldRoute: "CompanyId",
    configName: "creditcard",
    roleName: NAME_OBJECT.creditcard,
    routeNameShow: "ClientShowPageCreditCard",
    routeNameCreate: "ClientCreatePageCreditCard",
    routeNameEdit: "ClientEditPageCreditCard"
};

// const META_CLIENT_ATTRVALUE = {
//     typeCompany: "client",
//     pathBackStart: "company/client",
//     fieldRoute: "CompanyId",
//     configName: "attrValue",
//     routeNameShow: "ClientShowPageattrvalue",
//     routeNameCreate: "ClientCreatePagattrvalue",
//     routeNameEdit: "ClientEditPageattrvalue"
// };

// const META_SUPPLIER_ATTRVALUE = {
//     typeCompany: "supplier",
//     pathBackStart: "company/supplier",
//     fieldRoute: "CompanyId",
//     configName: "attrValue",
//     routeNameShow: "supplierShowPageattrvalue",
//     routeNameCreate: "supplierCreatePagattrvalue",
//     routeNameEdit: "supplierEditPageattrvalue"
// };

// const META_AGENT_ATTRVALUE = {
//     typeCompany: "agent",
//     pathBackStart: "company/agent",
//     fieldRoute: "CompanyId",
//     configName: "attrValue",
//     routeNameShow: "agentShowPageattrvalue",
//     routeNameCreate: "agentCreatePagattrvalue",
//     routeNameEdit: "agentEditPageattrvalue"
// };


export default [{
        path: "client/:CompanyId/creditcard/:CreditCardId/edit",
        name: META_CLIENT_CREDITCARD.routeNameEdit,
        component: EditPageCreditCardMain,
        meta: META_CLIENT_CREDITCARD
    },
    {
        path: "client/:CompanyId/creditcard/:CreditCardId/show",
        name: META_CLIENT_CREDITCARD.routeNameShow,
        component: EditPageCreditCardMain,
        meta: META_CLIENT_CREDITCARD
    },
    {
        path: "client/:CompanyId/creditcard/create",
        name: META_CLIENT_CREDITCARD.routeNameCreate,
        component: EditPageCreditCardMain,
        meta: META_CLIENT_CREDITCARD
    },

    // {
    //     path: "supplier/:CompanyId/attrvalue/:AttrValueId/edit",
    //     name: META_SUPPLIER_ATTRVALUE.routeNameEdit,
    //     component: EditPageAttrValueMain,
    //     meta: META_SUPPLIER_ATTRVALUE
    // },
    // {
    //     path: "supplier/:CompanyId/attrvalue/:AttrValueId/show",
    //     name: META_SUPPLIER_ATTRVALUE.routeNameShow,
    //     component: EditPageAttrValueMain,
    //     meta: META_SUPPLIER_ATTRVALUE
    // },
    // {
    //     path: "supplier/:CompanyId/attrvalue/create",
    //     name: META_SUPPLIER_ATTRVALUE.routeNameCreate,
    //     component: EditPageAttrValueMain,
    //     meta: META_SUPPLIER_ATTRVALUE
    // },
    // {
    //     path: "agent/:CompanyId/attrvalue/:AttrValueId/edit",
    //     name: META_AGENT_ATTRVALUE.routeNameEdit,
    //     component: EditPageAttrValueMain,
    //     meta: META_AGENT_ATTRVALUE
    // },
    // {
    //     path: "agent/:CompanyId/attrvalue/:AttrValueId/show",
    //     name: META_AGENT_ATTRVALUE.routeNameShow,
    //     component: EditPageAttrValueMain,
    //     meta: META_AGENT_ATTRVALUE
    // },
    // {
    //     path: "agent/:CompanyId/attrvalue/create",
    //     name: META_AGENT_ATTRVALUE.routeNameCreate,
    //     component: EditPageAttrValueMain,
    //     meta: META_AGENT_ATTRVALUE
    // },


]