import { API_METHODS } from "../../constant";
export const entitiesApi = (_getAll, _get, _updata, _create, _delete, paramsIn) => {
  return {
    [API_METHODS.GET_ALL_ENTITY]: () => {
      let urlReq = "/RefEntity/" + paramsIn.entity;
      return _getAll(urlReq);
    },
    [API_METHODS.GET_ENTITY]: () => {
      let urlReq = "/RefEntity/" + paramsIn.entity + "/" + paramsIn.id;
      return _get(urlReq);
    },
    [API_METHODS.UPDATE_ENTITY]: () => {
      let urlReq = "/RefEntity";
      switch (paramsIn.entity) {
        case "City": {
          urlReq += "/City";
          break;
        }
        case "Rule": {
          urlReq += "/Rule";
          break;
        }
        case "Port": {
          urlReq += "/Port";
          break;
        }
        case "Currency": {
          urlReq += "/Currency";
          break;
        }
        case "AirPlane": {
          urlReq += "/AirPlane";
          break;
        }
      }
      return _updata(urlReq);
    },
    [API_METHODS.CREATE_ENTITY]: () => {
      let urlReq = "/RefEntity";
      switch (paramsIn.entity) {
        case "City": {
          urlReq += "/City";
          break;
        }
        case "Rule": {
          urlReq += "/Rule";
          break;
        }
        case "Port": {
          urlReq += "/Port";
          break;
        }
        case "Currency": {
          urlReq += "/Currency";
          break;
        }
        case "AirPlane": {
          urlReq += "/AirPlane";
          break;
        }
      }
      return _create(urlReq);
    },
    [API_METHODS.DELETE_ENTITY]: () => {
      let urlReq = "/RefEntity/" + paramsIn.id;
      return _delete(urlReq);
    }
  };
};
