import axios from "axios";
import { MAIN_HOST, NAME_TOKEN } from "../constant";

class HTTP {
    constructor() {
        this.token =  localStorage.getItem(NAME_TOKEN);
    }

    HTTP() {
        let initData = {
            baseURL: window && window.appConfig &&  window.appConfig.MAIN_HOST ? window.appConfig.MAIN_HOST : MAIN_HOST  ,
            headers: this.token ? {
                Authorization: `Bearer ${this.token}`
            } : {}
        }

       return axios.create(initData);
    }
}

export default HTTP;
