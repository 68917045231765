<template>
  <common-page-edit
    :title="title"
    :nameRoleEntity="nameRoleEntity"
    :apiNameGet="apiNameGet"
    :apiNameCreate="apiNameCreate"
    :apiNameUpdate="apiNameUpdate"
    routeNameShow="ReceiverSystemShow"
    routeNameCreate="ReceiverSystemCreate"
    :keyRoute="'id'"
    :backUrl="pathBack"
    @set-loadData="setLoadData"
    :convertLoadData="convertLoadData"
    :paramsSave="paramsSave"
    :isDefaultConverter="false"
    :paramsSend="paramsSend"
    :forceNoShowEdit="true"
  >
    <template v-slot:block2>
      <ConnectDirectoryTablePage  v-if="$route.name !=routeNameCreate"/>
    </template>
  </common-page-edit>
</template>
<script>
import { API_METHODS, FIELD_LANG_COMPANY } from "../../../../../constant";
import { NAME_OBJECT } from "../../../../../RoleConstanans";
import CommonPageEdit from "../../../commonElement/CommonPageEdit.vue";
import CompanyData from "../../company/CompanyData.vue";

import convertFieldInfoListToScheme from "../../../../../helpers/converters/convetFieldInfoListToScheme";
import ConnectDirectoryTablePage from "../connectDirectory/ConnectDirectoryTablePage.vue"
export default {
  name: "ReceiverSystemEditPageDynamic",
  components: {
    CommonPageEdit,
    CompanyData,
    ConnectDirectoryTablePage
  },
  data() {
    return {
      wait: false,
      scheme: {},
      loadData: null,
      fieldInfoList: null,
      preResult: null,
      load: true,

      routeNameShow: "ReceiverSystemShow",
      routeNameCreate: "ReceiverSystemCreate",

      // pathBack: "/crm/CounterpartyBankDetails",
      apiNameGet: API_METHODS.GET_RECEIVER_SYSTEM,
      apiNameCreate: API_METHODS.UPDATE_RECEIVER_SYSTEM,
      apiNameUpdate: API_METHODS.CREATE_RECEIVER_SYSTEM,
      loadData: null
    };
  },
  computed: {
    title() {
      let text1 = this.$t("show_ReceiverSystem");
      let text2 = this.$t("create_ReceiverSystem");
      let text3 = this.$t("edit_ReceiverSystem");

      let findShow = this.$route.path.indexOf("show");
      if (findShow != -1) {
        return text1;
      }
      let findCreate = this.$route.path.indexOf("create");
      if (findCreate != -1) {
        return text2;
      }
      let findEdit = this.$route.path.indexOf("edit");
      if (findEdit != -1) {
        return text3;
      }
      return text1;
    },
    pathBack() {
      return `/receiver-system`
    },
    nameRoleEntity() {
      return NAME_OBJECT.ReceiverSystems;
    },
    paramsSend() {
      return {
        id: this.$route.params.id || "0"
      };
    }
  },

  methods: {
    convertLoadData(res) {
      let loadData = res.object;
      loadData.cfoType = this.cfoType;
      let fieldInfoList = res.fieldInfoList;
      let scheme = { configForm: [] };

      scheme.configForm = convertFieldInfoListToScheme({
            fieldInfoList,
            hasLang: false
      });

      return {
        loadData,
        fieldInfoList,
        scheme
      };
    },
    setLoadData(loadData) {
      this.loadData = loadData;
    },

    paramsSave() {
      return {
        id: this.$route.params.id,
        CfoType: this.cfoType
      };
    }
  }
};
</script>
