<template>
  <div>
    <show-code :code="fieldInfoList"></show-code>
    <load-block :load="!load"></load-block>
    <the-edit-page
      :scheme="scheme"
      :isEntity="true"
      :loadData="loadData"
      v-if="load"
      :title="title"
      :pageMode="pageMode"
    >  
     
      <the-controls-edit-page
        :scheme="scheme"
        :loadData="loadData"
        :saveData="saveData"
        :pathBack="pathBack"
        configName="codes"
        roleName="codes"
        :pageMode="pageMode"
        :wait="wait"
      ></the-controls-edit-page>
    </the-edit-page>
  </div>
</template>
<script>
import TheEditPage from "../../../TheEditPage.vue";
import { CONTRACT_BUDGET_CODE_VALUE, CONTRACT_CONNETCT_CODE } from "../store/type";
import { READ, UPDATE } from "../../../../store/type";
import TheControlsEditPage from "../../../TheControlsEditPage.vue";
import clearReactive from "../../../../helpers/clearReactive";
import ShowCode from "../../../shared/ShowCode.vue";
import convertFieldInfoListToScheme from "../../../../helpers/converters/convetFieldInfoListToScheme";
import clearNull from "../../../../../src/helpers/clearNull";
import { mapState } from "vuex";
import getSchemeComponet from "../../../../helpers/generatorForm/getSchemeComponet";

import { TYPE_ERRORS, FIELD_LANG_CODE } from "../../../../constant";
import notify from "../../../../helpers/notify/notify";
import LoadBlock from "../../../shared/LoadBlock.vue";
import { checkValidate } from "../../../../mixins/validate/checkValidate";
import { pageMode } from "../../../../mixins/editPage/pageMode";

export default {
  name: "EditBudgetCodeValueContract",
  components: {
    LoadBlock,
    ShowCode,
    TheControlsEditPage,
    TheEditPage
  },
  mixins: [checkValidate, pageMode],
  data() {
    return {
      pathBack: `/contract/` + this.$route.params.ContractId + "/edit",
      scheme: {},
      loadData: null,
      fieldInfoList: null,
      preResult: null,
      load: true,
      routeNameShow: "ClientEditCodeShow",
      routeNameCreate: "ClientEditCode",
      wait: false,
      templateValcodeId: null
    };
  },
  computed: {
    ...mapState({
      lang: state => state.common.lang
    }),
    title() {
      return this.pageTitle(
         this.$t('Просмотр бюджетного кода договора'),
         this.$t('Создание бюджетного кода договора'),
         this.$t('Редактирование бюджетного кода договора')
      );
    }
  },
  watch: {
    lang() {
      this.initData();
    },
    loadData: {
      handler: function(val, oldVal) {
        if (this.templateValcodeId !== val.codeId) {
          this.loadNewValue();
        }
        this.templateValcodeId = val.codeId;
      },
      deep: true
    }
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.load = false;
      this.$store
        .dispatch(CONTRACT_BUDGET_CODE_VALUE + READ, {
          CodeId: this.$route.params.CodeId || "0",
          ContractId: this.$route.params.ContractId,
          self: this
        })
        .then(res => {
          this.loadData = res.object;
          let fieldInfoList = res.fieldInfoList;
          this.fieldInfoList = fieldInfoList; 
          this.scheme.configForm = convertFieldInfoListToScheme({
            fieldInfoList,
            hasLang: false
          });
          this.load = true;
        });
    },
    saveData() {
      if (this.validateData() == false) {
        return;
      }
      let saveData = clearReactive(this.loadData);
      saveData = clearNull(saveData);
      this.wait = true;
      this.$store
        .dispatch(CONTRACT_BUDGET_CODE_VALUE + UPDATE , {
          contractId: this.$route.params.ContractId,
          saveData:saveData,
          self: this
        })
        .then(res => {
          this.wait = false;
          if (res.errors.length == 0) {
            notify(this, this.$t("Успешно"), TYPE_ERRORS.SUCCESS);
            this.$router.push({ path: this.pathBack });
          }
        })
        .catch(err => {
          console.error(err);
          this.wait = false;
        });
    },
    loadNewValue() {
      let id = this.loadData.codeId;
      this.$store
        .dispatch(CONTRACT_BUDGET_CODE_VALUE + READ, {
          CodeId: id,
          contractId: this.$route.params.ContractId,
          self: this
        })
        .then(res => {
          let configForm = {
            code: "value",
            type: "String",
            name: "Значение",
            sizePercent: 40,
            refEntityCode: null,
            isLang: false,
            isReadOnlyAdd: false,
            isReadOnlyEdit: false,
            colWidth: 4,
            required: false,
            subListId: null
          };
          let isEnum = res.object.isEnum;
          if (isEnum == true) {
            configForm.subListId = id;
            configForm.refEntityCode = "CodeEnum";
            configForm.type = "refEntity";
            configForm.code = "codeEnumId";
          }
          let fullSchema = getSchemeComponet(configForm.type, configForm);
          this.$delete(this.scheme.configForm, 1);
          this.scheme.configForm.push(fullSchema);
        });
    }
  }
};
</script>
