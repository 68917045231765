import {
  SET_MAIN_PAGINATION,
  SET_MAIN_PAGINATION_PER_PAGE,
  SET_MAIN_SEARCH_ENTITY,
  SET_MAIN_SORT_ENTITY,
  SET_MAIN_PAGINATION_PAGE,
  SET_MAIN_PAGINATION_TOTAL,
  SAVE_TABLE_PAGINATION,
  SET_ALL_PAGINATION,
  CLEAR_TABLE_PAGINATOR
} from "../../type";
import { NAME_LS_DEF_PAGE } from "../../../constant";
import clearReactive from "../../../helpers/clearReactive";
import { clearRouteName } from "../../../helpers/clearRouteName";
export const mutations = {
  [SET_MAIN_SEARCH_ENTITY]: (state, val) => {
    state.search = val;
  },
  [SET_MAIN_SORT_ENTITY]: (state, { prop = null, order = null }) => {
    state.sortData.prop = prop;
    state.sortData.order = order;
  },
  [SET_MAIN_PAGINATION]: (state, val) => {
    state.pagination = val;
  },
  [SET_MAIN_PAGINATION_PER_PAGE]: (state, val) => {
    localStorage.setItem(NAME_LS_DEF_PAGE, val);
    state.pagination.perPage = val;
  },
  [SET_MAIN_PAGINATION_PAGE]: (state, val) => {
    state.pagination.currentPage = val;
  },
  [SET_MAIN_PAGINATION_TOTAL]: (state, { countPage }) => {
    state.pagination.total = countPage * state.pagination.perPage;
  },
  [SAVE_TABLE_PAGINATION]: (state, payload) => {
    const { name, data } = payload;
    if (name && data) {
      const clearName = clearRouteName(name);
      state.cachePaginator[clearName] = payload.data;
    }
  },

  [SET_ALL_PAGINATION]: (state, payload) => {
    const { sortData, search, pagination } = clearReactive(payload);
    try {
      if (pagination) {
        state.pagination.currentPage = pagination.currentPage;
        state.pagination.perPage = pagination.perPage;
      }

      if (sortData) {
        state.sortData = sortData;
      }
      if (search) {
        state.search = search;
      }
    } catch (e) {
      console.error("ошибка кеша пагинаторв", e);
    }
  },

  [CLEAR_TABLE_PAGINATOR]: (state, payload) => {
    // console.log("почистил данные");
    
    const { delUrl = null } = payload;
    if (delUrl) {
      const clearUrl = clearRouteName(delUrl);
      // console.log("почистил по урлу  данные", clearUrl);
      const allKey = Object.keys(state.cachePaginator);
      // console.log("было", allKey);
      const deleteKeys = allKey.filter(item => item.includes(clearUrl));
      if (deleteKeys) {
        let cloneCachePaginator = clearReactive(state.cachePaginator);
        deleteKeys.forEach(delKey => {
          delete cloneCachePaginator[delKey];
        });
        state.cachePaginator = cloneCachePaginator;
        const allKey2 = Object.keys(state.cachePaginator);
        // console.log("стало", allKey2);
      }
    } else {
      state.cachePaginator = {};
    }
  }
};
