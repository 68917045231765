<template>
  <sidebar-item :link="{ name: $t('ReceiverSystem'), icon: 'nc-icon nc-grid-45', path: `/receiver-system` }" v-if="showMenu('ReceiverSystems')">
    
  </sidebar-item>
</template>

<script>
  import {NAME_OBJECT, NAME_ACTION} from "../../../../RoleConstanans";
export default {
  name: "ReceiverSystemMenu",
  methods: {
    showMenu(name) {
          let isActionAccess  = this.$store.getters.isActionAccess
          return isActionAccess(name, NAME_ACTION.isMenu, name, 'showMenu'  )
      },
  }
};
</script>
