import HrFeedLogEditPageDynamic from "./HrFeedLogEditPageDynamic.vue";
import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue";

export default {
  path: "/company/client/",
  component: DashboardLayout,
  children: [
    {
      path: ":CompanyId/hrlog/:id/show",
      name: "HrFeedLogEditPageDynamicShow",
      component: HrFeedLogEditPageDynamic
    }
  ]
};
