<template>
  <common-page-table
    :apiName="apiName"
    :nameRole="nameRoleEntity"
    :titleTable="$t('Соглашения')"
    :fieldLand="fieldLang"
    :keyTable="'agreementId'"
    :deleteParams="deleteParams"
    :apiDeleteName="apiDeleteName"
    :alternativeConverter="convertData"
    :isDefaultConverter="false"
    :forceIsEdit="false"
    :forceIsCreate="false"
    :forceDelete="false"
  />
</template>
<script>
import { NAME_OBJECT } from "../../../../RoleConstanans";
import CommonPageTable from "../../commonElement/CommonPageTable.vue";
import { API_METHODS, FIELD_LANG_COMPANY, GLOBAL_DATE_FORMAT} from "../../../../constant";
import convertLangDataToTable from "../../../../helpers/converters/convertLangDataToTable";
export default {
  name: "AgreementsTablePage2",
  components: { CommonPageTable },
  data() {
    return {
      fieldLang: FIELD_LANG_COMPANY,
      apiName: API_METHODS.GET_AGREEMENTS_FOR_CRM_CORP,
      apiDeleteName: API_METHODS.DELETE_AGREEMENTS
    };
  },
  computed: {
    paramsGetData() {
      return { typeCompany: 'Client' };
    },
    nameRoleEntity() {
      return NAME_OBJECT.clients
    }
  },
  methods: {
    convertData(dataNow, langNow) {
      let res = convertLangDataToTable(dataNow, langNow, FIELD_LANG_COMPANY, false)
//2019-10-01T00:00:00
      res = res.map(el => {
        return {
          ...el,
          dateStart: this.$moment(el.dateStart).format(
            GLOBAL_DATE_FORMAT
          ),
           dateEnd: this.$moment(el.dateEnd).format(
            GLOBAL_DATE_FORMAT
          )
        };
      });
      return res;


      return res;
    },
    deleteParams(id) {
      return { id: id };
    },
  }
};
</script>

