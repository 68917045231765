<template>
  <common-page-table
    :apiName="apiName"
    :nameRole="nameRoleEntity"
    :titleTable="$t('HrFeedFields')"
    :paramsGetData="paramsGetData"
    :fieldLand="fieldLang"
    :keyTable="'id'"
    :deleteParams="deleteParams"
    :apiDeleteName="apiDeleteName"
    :alternativeConverter="convertData"
    :isDefaultConverter="false"
    :prefixEdit="prefixEdit"
    :prefixShow="prefixShow"
    :linkCreate="linkCreate"
    :hasReadOnlyInTable="hasReadOnlyInTable"
    :nameTableSaveInCashe="nameTableSaveInCashe"
  />
</template>
<script>
import { NAME_OBJECT } from "../../../../RoleConstanans";
import CommonPageTable from "../../commonElement/CommonPageTable.vue";
import { API_METHODS } from "../../../../constant";
import formatData from "../../../../helpers/converters/formatData";
import convertLangDataToTable from "../../../../helpers/converters/convertLangDataToTable";
export default {
  name: "HrFeedFieldsTable",
  components: { CommonPageTable },
  props:{
      isCompanyType: {type: Boolean, default: true},
      hasReadOnlyInTable: { type: Boolean, default: true }, 
      routeField: {type: String, default: "CompanyId"},
      prefixEditIn: { type: String },
  },
  data() {
    return {
      fieldLang: "",
      apiName: API_METHODS.GET_ALL_HrFeedFields,
      apiDeleteName: API_METHODS.DELETE_HrFeedFields
    };
  },
  computed: {
    paramsGetData() {
      return {  };
    },
    prefixEdit() {
      return `HrFeedFields`;
    },
    prefixShow() {
      return this.prefixEdit;
    },
    linkCreate() {
      return this.prefixEdit + "/create";
    },
    nameRoleEntity(){
      const nameRoute = this.$route.name;
      let role = NAME_OBJECT.HrFeedFields;
      return role;
    },
    nameTableSaveInCashe(){
      const nameRoute = this.$route.name;
      return this.nameRoleEntity
    },
  },
  methods: {
    convertData(dataNow, langNow) {
      let res = convertLangDataToTable(dataNow, langNow, '', false)
      return res;
    },
    deleteParams(id) {
      return {
        id: id,
      };
    },
  }
};
</script>

