import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from "./locales/en.json"
import enAccess from "./locales/enAccess.json"
import ru from "./locales/ru.json"
import ruAccess from "./locales/ruAccess.json"
const messages = {
  en: {
    ...en,
    ...enAccess,

  },
  ru: {
    ...ru,
    ...ruAccess
  }
}


Vue.use(VueI18n)

// function loadLocaleMessages () {
//   const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
//   const messages = {}
//   locales.keys().forEach(key => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i)
//     if (matched && matched.length > 1) {
//       const locale = matched[1]
//       messages[locale] = locales(key)
//     }
//   })
//   console.log(messages)
//   return messages
// }

const langCode = localStorage.getItem('lang') || process.env.VUE_APP_I18N_LOCALE || 'en'
export default new VueI18n({
  locale: langCode,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: messages
})
