import { actions } from "./actions";
import { mutations } from "./mutations";
import { DEFAULT_PER_PAGE } from '../../../../constant'
const state = {
    form: {},
    data: {},
    fieldInfoList: [],
    filters: null,
    listEntity: [],
    commonList: [],
    SystemList: [],
    GeographyList: [],

    fieldTable: {},

    listLoadEntity: []
};

export default {
    state,
    actions,
    mutations
};