import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue'

const TablePage = () => import ('./TablePageAttrEntity.vue')
const EditPageDynamic = () => import ('./EditPageDynamic.vue')
export default {
    path: '/attrentity',
    component: DashboardLayout,
    children: [{
            path: '/',
            name: 'AttrEntityPage',
            component: TablePage
        },
        {
            path: 'create',
            name: 'CreateAttrEntity',
            component: EditPageDynamic
        },
        {
            path: ':AttrEntityId/show',
            name: 'ShowAttrEntity',
            component: EditPageDynamic
        },
        {
            path: ':AttrEntityId/edit',
            name: 'EditAttrEntity',
            component: EditPageDynamic
        }
    ]
}