import { SET_HISTORY_PAGE, SET_HISTORY_SEARCH, SET_HISTORY_PER_PAGE } from "../../components/modules/history/store/type";
import { DEFAULT_PER_PAGE } from '../../constant'
import { mapState } from 'vuex';

export const mainMethods = {
    props: {
        modals: { type: Object, required: true },
        toggleModal: { type: Function, required: true },
        nameModal: { type: String, default: 'history' },
        closeHistory: { type: Function, required: true },
        configName: { type: String },
    },
    data() {
        return {
            load: false
        }
    },
    computed: {
        ...mapState({
            fieldInfoList: state => state.history.fieldInfoList,
            search: state => state.history.search,
            sortData: state => state.history.sortData,
            loadData: state => state.history.data
        }),

    },
    // created() {
    //   this.initData()
    // },
    methods: {
        demo() {

        },
        initData() {
            //      this.$store.commit( SET_MAIN_SORT_ENTITY,{})
            this.$store.commit(SET_HISTORY_SEARCH, '')
            this.$store.commit(SET_HISTORY_PER_PAGE, DEFAULT_PER_PAGE)
            this.$store.commit(SET_HISTORY_PAGE, 1)
            this.getData()
        },
        updateData() {
            this.getData()
        },
        searchStart(search) {
            this.$store.commit(SET_HISTORY_SEARCH, search)
            this.getData()
        },
    }
}