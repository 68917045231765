import VstContragentTablePage from "./VstContragentTablePage.vue"
import VstContragentEditPage from "./VstContragentEditPage.vue"


export default [{
    path: "contragent",
    name: "VstContragentTablePage",
    component: VstContragentTablePage
  },
  {
    path: "contragent/:CounterpartyId/show",
    name: "VstContragentEditPageShow",
    component: VstContragentEditPage,
  }
  ]

