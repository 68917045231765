import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue'
const TablePage = () => import ('./TablePage.vue')
const EditPageDynamic = () => import ('./EditPageDynamic.vue')
export default {
    path: '/entity',
    component: DashboardLayout,
    children: [{
            path: ':entity',
            component: TablePage
        },
        {
            path: ':entity/create',
            name: 'CreatePageDynamic',
            component: EditPageDynamic
        },
        {
            path: ':entity/:id/edit',
            name: 'EntityDynamicEdit',
            component: EditPageDynamic
        },
        {
            path: ':entity/:id/show',
            name: 'EntityDynamicShow',
            component: EditPageDynamic
        }
    ]
}