    import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue'
const CommercialTermsPage = () =>
    import ('./CommercialTermsPage.vue')
const EditPageCommercialTerms = () =>
    import ('./EditPageCommercialTerms.vue')

const EditPageCommercialTermRule = () =>
    import ('../../../modules/commercialTerms/rule/EditPageCommercialTermRule.vue')
import {NAME_OBJECT} from "../../../../RoleConstanans"
import EditPageAttrValueMain from "../attrValue/EditPageAttrValueMain.vue"

const META_FIN_ATTRVALUE = {
    pathBackStart: "commercialTerms",
    fieldRoute: "FinancialConditionId",
    configName: "attrValue",
    roleName: NAME_OBJECT.attrValue,
    routeNameShow: "FinAttrValueShow",
    routeNameCreate: "FinAttrValueCreate",
    routeNameEdit: "FinAttrValueEdit"
};

export default {
    path: '/commercialTerms',
    component: DashboardLayout,
    children: [{
            path: '/',
            name: 'CommercialTermsPage',
            component: CommercialTermsPage
        },
        {
            path: 'create',
            name: 'CommercialTermsCreate',
            component: EditPageCommercialTerms
        },
        {
            path: ':FinancialConditionId/show',
            name: 'CommercialTermsShow',
            component: EditPageCommercialTerms
        },
        {
            path: ':FinancialConditionId/edit',
            name: 'CommercialTermsEdit',
            component: EditPageCommercialTerms
        },
        {
            path: ':FinancialConditionId/rule/create',
            name: 'CommercialTermRuleCreate',
            component: EditPageCommercialTermRule
        },
        {
            path: ':FinancialConditionId/rule/:FinancialConditionRuleId/show',
            name: 'CommercialTermRuleShow',
            component: EditPageCommercialTermRule
        },
        {
            path: ':FinancialConditionId/rule/:FinancialConditionRuleId/edit',
            name: 'CommercialTermRuleEdit',
            component: EditPageCommercialTermRule
        },

        //ATTRVALUE
        {
            path: ":FinancialConditionId/attrvalue/:AttrValueId/edit",
            name: META_FIN_ATTRVALUE.routeNameEdit,
            component: EditPageAttrValueMain,
            meta: META_FIN_ATTRVALUE
        },
        {
            path: ":FinancialConditionId/attrvalue/create",
            name: META_FIN_ATTRVALUE.routeNameCreate,
            component: EditPageAttrValueMain,
            meta: META_FIN_ATTRVALUE
        },
        {
            path: ":FinancialConditionId/attrvalue/:AttrValueId/show",
            name: META_FIN_ATTRVALUE.routeNameShow,
            component: EditPageAttrValueMain,
            meta: META_FIN_ATTRVALUE
        }
    ]
}