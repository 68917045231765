<template>
  <div class="row">
    <div class="col-md-1">
      <label>&nbsp;</label>
      <l-button type="info" class="smallBtn" wide block @click="reset()">{{
          $t("Сброс")
        }}
      </l-button>
    </div>
    <div class="col-md-9">
      <dynamic-generator-form
        :form="loadData"
        v-if="loadData"
        :configData="configData"
        :isCreateForm="true"
        :pageMode="pageMode"
      ></dynamic-generator-form>
    </div>
    <div class="col-md-2">
      <label>&nbsp;</label>
      <l-button type="success" wide block @click="filterData(loadData)">{{
          $t("Применить")
        }}
      </l-button>
    </div>
  </div>
</template>

<script>
import { PAGE_MODE } from "../../../../constant";
import convertFieldInfoListToScheme from "../../../../helpers/converters/convetFieldInfoListToScheme";
import convertJsonShemeToData from "../../../../helpers/generatorForm/convertJsonShemeToData";
import clearReactive from "../../../../helpers/clearReactive";
import DynamicGeneratorForm from "../../../modules/generator/DynamicGeneratorForm.vue";
export default {
  name: "FilterBlockStatistic",
  components: {
    DynamicGeneratorForm,
  },
  props: {
    filterList: { required: true },
    filterData: { type: Function, required: true },
    defaultDataIn: {type: Object }
  },
  data() {
    return {
      loadData: null,
      pageMode: PAGE_MODE.CREATE,
      configData: [],
      defaultData: null
    };
  },
  created() {
    let fieldInfoList = this.filterList;
    this.configData = convertFieldInfoListToScheme({
      fieldInfoList,
      hasLang: false
    });
    this.loadData = convertJsonShemeToData(this.configData);

    if(this.defaultDataIn) {
      this.loadData.dateFrom = this.defaultDataIn.dateFrom;
      this.loadData.dateTo = this.defaultDataIn.dateTo;
    } else {
      this.loadData.dateFrom = this.$moment().subtract(1, "month").format("YYYY-MM-DD");
      this.loadData.dateTo = this.$moment().format("YYYY-MM-DD");
    }


    this.defaultData = clearReactive(this.loadData);

  },
  methods: {

    reset() {
      this.loadData = clearReactive(this.defaultData);
    }
  }
};
</script>

<style lang="scss" scoped></style>
