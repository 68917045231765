import { API_METHODS } from "../../constant";
export const personApi = (
  _getAll,
  _get,
  _update,
  _create,
  _delete,
  paramsIn
) => {
  return {
    [API_METHODS.GET_PERSON]: () => {
      let urlReq = `/Contract/${paramsIn.ContractId}/Person/${paramsIn.id}`;
      return _get(urlReq);
    },

    [API_METHODS.GET_ALL_PERSON]: () => {
      let urlReq = `/Contract/${paramsIn.ContractId}/Person`;
      return _getAll(urlReq);
    },

    [API_METHODS.UPDATE_PERSON]: () => {
      let urlReq = `/Contract/${paramsIn.ContractId}/Person`;
      return _update(urlReq);
    },

    [API_METHODS.CREATE_PERSON]: () => {
      let urlReq = `/Contract/${paramsIn.ContractId}/Person`;
      return _create(urlReq);
    },

    [API_METHODS.DELETE_PERSON]: () => {
      let urlReq = `/Contract/${paramsIn.ContractId}/Person/${paramsIn.PersonId}`;
      return _delete(urlReq);
    }
  };
};
