import { API_METHODS } from "../../constant";
export const personBonusCardApi = (
  _getAll,
  _get,
  _update,
  _create,
  _delete,
  paramsIn
) => {
  return {
    [API_METHODS.GET_BONUS_CARD]: () => {
      let urlReq = `/Person/${paramsIn.PersonId}/BonusCard/${paramsIn.id}`;
      return _get(urlReq);
    },
    [API_METHODS.GET_ALL_BONUS_CARD]: () => {
      let urlReq = `/Person/${paramsIn.PersonId}/BonusCard`;
      return _getAll(urlReq);
    },
    [API_METHODS.UPDATE_BONUS_CARD]: () => {
      let urlReq = `/Person/${paramsIn.PersonId}/BonusCard`;
      return _update(urlReq);
    },
    [API_METHODS.CREATE_BONUS_CARD]: () => {
      let urlReq = `/Person/${paramsIn.PersonId}/BonusCard`;
      return _create(urlReq);
    },
    [API_METHODS.DELETE_BONUS_CARD]: () => {
      let urlReq = `/Person/${paramsIn.PersonId}/BonusCard/${paramsIn.BonusCardId}`;
      return _delete(urlReq);
    }
  };
};
