<template>
  <the-table
    :load="load"
    :titleTable="titleTable"
    :tableData="tableData"
    :fieldInfoList="fieldInfoList"
    @set-sort="setSort"
    @set-perPage="setPerPage"
    @set-page="setPage"
    @set-search="setSearch"
    :paramsPaginator="paramsPaginator"
    :fieldId="keyTable"
    :linkCreate="linkCreate"
    :prefixEdit="prefixEdit"
    :prefixShow="prefixShow"
    :deleteEl="deleteEl"
    :forceIsCreate="forceIsCreate"
    :forceDelete="forceDelete"
    :forceIsShow="forceIsShow"
    :forceIsEdit="forceIsEdit"
    :isDopLinkPluse="isDopLinkPluse"
    :customDopLink="customDopLink"
    :plusLinkTitle="pluslinktitle"
    :wAction="wAction"
    :hasReadOnlyInTable="hasReadOnlyInTable"
    :roleName="nameRole"
    :forceIsSearch="forceIsSearch"
    :hasAction="hasAction"
  >
    <slot></slot>
  </the-table>
</template>

<script>
import {
  DEFAULT_PER_PAGE,
  TYPE_ERRORS,
  FIELD_LANG_ENTITY
} from "../../../constant";
import notify from "../../../helpers/notify/notify";
import convertLangDataToTable from "../../../helpers/converters/convertLangDataToTable";
import { ApiService } from "../../../util/ApiService";
import TheTable from "./TheTable";

const KEY_SAVE_PERPAGE = "KEY_SAVE_PERPAGE";
export default {
  name: "CommonPageTable",
  components: {
    TheTable
  },
  props: {
    apiName: {
      //имя апи
      type: String,
      required: true
    },
    apiDeleteName: {
      // todo
      type: String
    },
    nameRole: {
      type: String,
      require: true
    },
    titleTable: {
      type: String,
      require: true
    },
    paramsGetData: {}, //параметры для получение
    hasLang: {
      type: Boolean,
      default: true
    },
    fieldLand: {
      //языковое поле на которое надо смотреть
      type: String,
      default: FIELD_LANG_ENTITY
    },
    isDefaultConverter: {
      // дефолтный конвертер
      type: Boolean,
      default: true
    },
    alternativeConverter: {
      // функция альтернативного конвертора
      type: Function
    },

    linkCreate: {},
    prefixEdit: {},
    prefixShow: {},

    keyTable: {
      type: String,
      default: "refEntityId"
    },
    deleteParams: {
      type: Function
    },

    forceIsCreate: {
      // доступна кнопка создать
      type: Boolean,
      default: true
    },
    forceIsEdit: {
      // доступна кнопка редактировать
      type: Boolean,
      default: true
    },
    forceIsShow: {
      // доступна кнопка редактировать
      type: Boolean,
      default: true
    },
    forceDelete: {
      // доступна кнопка удалить
      type: Boolean,
      default: true
    },
    lastUpdate: { type: String, default: "" },

    isDopLinkPluse: { type: Boolean, default: false }, // дополнительная ссылка
    customDopLink: { type: Function },
    pluslinktitle: { type: String, default: "" },
    wAction: { type: Number, default: 170 },
    hasReadOnlyInTable: { type: Boolean, default: true },

    eventGetParamsGetData: { type: Boolean, default: false },
    trigerReinit: { type: Boolean, default: false },
    forceIsSearch: { type: Boolean, default: true },

    hasAction: {type: Boolean, default: true }
  },

  data() {
    return {
      load: false,
      tableDataBase: [],
      fieldInfoList: [],
      paramsPaginator: {
        page: 1, //страница
        size: DEFAULT_PER_PAGE, //perpage
        countPage: 1, //всего страниц
        orderBy: null, //сортировка по
        search: "", //поиск по
        orderDir: "ascending" // направление
      }
    };
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    },
    tableData() {
      const langNow = this.lang;
      let dataNow = this.tableDataBase || [];
      if (dataNow && this.isDefaultConverter) {
        dataNow = convertLangDataToTable(
          dataNow,
          langNow,
          this.fieldLand,
          this.hasLang
        );
      } else if (dataNow) {
        dataNow = this.alternativeConverter(
          dataNow,
          langNow,
          this.fieldLand,
          this.hasLang
        );
      }
      return dataNow;
    },
    paramsPaginatorConvert() {
      const { page, size, orderBy, orderDir, search, lastUpdate } =
        this.paramsPaginator;
      return {
        self: this,
        page,
        size,
        lang: this.lang,
        search,
        lastUpdate: this.lastUpdate,
        orderBy,
        orderDir
      };
    }
  },
  watch: {
    lang() {
      this.initData();
    },
    lastUpdate() {
      this.setSheduleDate(this.lastUpdate);
    },
    paramsGetData() {
      console.log("watch get data");
      if (this.eventGetParamsGetData) {
        this.getDataDebonce(this);
      }
    },
    trigerReinit() {
      if (this.trigerReinit) {
        this.paramsPaginator.page = 1;
        this.getDataDebonce(this);
        this.$emit("setTriger");
      }
    }
  },
  methods: {
    reInit() {
      console.log("reinit");
      this.getDataDebonce(this);
    },
    async getData() {
      this.load = true;
      try {
        this.$emit('setAllParams',{
          ...this.paramsPaginatorConvert,
          ...this.paramsGetData
        })
        const getData = await ApiService(this.apiName, {
          ...this.paramsPaginatorConvert,
          ...this.paramsGetData
        });

        this.fieldInfoList = getData.fieldInfoList;
        this.tableDataBase = getData.object;
        this.paramsPaginator.countPage = getData.countPage;
        this.load = false;
      } catch (e) {
        console.error(e);
        this.load = false;
        notify(this, this.$t("Ошибка получения данных"), TYPE_ERRORS.DANGER);
      }
    },
    getDataDebonce: _.debounce((vm) => {
      vm.getData();
    }, 500),

    setSearch(search) {
      this.paramsPaginator.search = search;
      this.paramsPaginator.page = 1;
      this.getDataDebonce(this);
    },
    setSheduleDate(date) {
      this.paramsPaginator.lastUpdate = date;
      this.paramsPaginator.page = 1;
      this.getDataDebonce(this);
    },
    setSort(val) {
      this.paramsPaginator.orderBy = val.prop;
      this.paramsPaginator.orderDir = val.order;
      this.paramsPaginator.page = 1;
      this.getData();
    },
    setPerPage(perPage) {
      this.paramsPaginator.page = 1;
      this.paramsPaginator.size = perPage;
      this.savePerPageInLocalStorage(perPage, this.apiName);
      this.getData();
    },
    setPage(page) {
      this.paramsPaginator.page = page;
      this.getData();
    },

    async deleteEl(id, row) {
      try {
        let res = null;
        if (this.deleteParams) {
          res = await ApiService(this.apiDeleteName, {
            ...this.deleteParams(id, row),
            ...{
              self: this
            }
          });
        } else {
          res = await ApiService(this.apiDeleteName, {
            id: id,
            self: this
          });
        }

        this.getData();
        if (res.errors && res.errors.length == 0) {
          notify(this, this.$t("Успешно"), TYPE_ERRORS.SUCCESS);
        }
      } catch (e) {
        console.error(e);
      }
    },

    initData() {
      this.getData();
    },
    savePerPageInLocalStorage(perPage, uk = "common") {
      if (uk) {
        let saveData = {};
        const read = localStorage.getItem(KEY_SAVE_PERPAGE);
        if (read) {
          saveData = JSON.parse(read);
        }
        saveData[uk] = perPage;
        localStorage.setItem(KEY_SAVE_PERPAGE, JSON.stringify(saveData));
      }
    },
    getPerPageFromLocalStorage(uk = "common") {
      if (uk) {
        let saveData = {};
        const read = localStorage.getItem(KEY_SAVE_PERPAGE);
        if (read) {
          saveData = JSON.parse(read);
          return saveData[uk] ? saveData[uk] : DEFAULT_PER_PAGE;
        }
      }
      return DEFAULT_PER_PAGE;
    }
  },
  created() {
    this.paramsPaginator.size = this.getPerPageFromLocalStorage(
      this.apiName
    );
    this.lastUpdate && (this.paramsPaginator.lastUpdate = this.lastUpdate);
    this.initData();
  }
};
</script>
