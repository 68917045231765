import EditPageAttrValueMain from "./EditPageAttrValueMain.vue";
import {NAME_OBJECT} from "../../../../RoleConstanans";
import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue";

const META_CLIENT_ATTRVALUE = {
    typeCompany: "client",
    pathBackStart: "company/client",
    fieldRoute: "CompanyId",
    configName: "attrValue",
    roleName: NAME_OBJECT.attrValue,
    routeNameShow: "ClientShowPageattrvalue",
    routeNameCreate: "ClientCreatePagattrvalue",
    routeNameEdit: "ClientEditPageattrvalue"
};

const META_SUPPLIER_ATTRVALUE = {
    typeCompany: "supplier",
    pathBackStart: "company/supplier",
    fieldRoute: "CompanyId",
    configName: "attrValue",
    roleName: NAME_OBJECT.attrValue,
    routeNameShow: "supplierShowPageattrvalue",
    routeNameCreate: "supplierCreatePagattrvalue",
    routeNameEdit: "supplierEditPageattrvalue"
};

const META_AGENT_ATTRVALUE = {
    typeCompany: "agent",
    pathBackStart: "company/agent",
    fieldRoute: "CompanyId",
    configName: "attrValue",
    roleName: NAME_OBJECT.attrValue,
    routeNameShow: "agentShowPageattrvalue",
    routeNameCreate: "agentCreatePagattrvalue",
    routeNameEdit: "agentEditPageattrvalue"
};

export default {
    path: "/company",
    component: DashboardLayout,
    children: [
        {
            path: "client/:CompanyId/attrvalue/:AttrValueId/edit",
            name: META_CLIENT_ATTRVALUE.routeNameEdit,
            component: EditPageAttrValueMain,
            meta: META_CLIENT_ATTRVALUE
        },
        {
            path: "client/:CompanyId/attrvalue/:AttrValueId/show",
            name: META_CLIENT_ATTRVALUE.routeNameShow,
            component: EditPageAttrValueMain,
            meta: META_CLIENT_ATTRVALUE
        },
        {
            path: "client/:CompanyId/attrvalue/create",
            name: META_CLIENT_ATTRVALUE.routeNameCreate,
            component: EditPageAttrValueMain,
            meta: META_CLIENT_ATTRVALUE
        },
    
        {
            path: "supplier/:CompanyId/attrvalue/:AttrValueId/edit",
            name: META_SUPPLIER_ATTRVALUE.routeNameEdit,
            component: EditPageAttrValueMain,
            meta: META_SUPPLIER_ATTRVALUE
        },
        {
            path: "supplier/:CompanyId/attrvalue/:AttrValueId/show",
            name: META_SUPPLIER_ATTRVALUE.routeNameShow,
            component: EditPageAttrValueMain,
            meta: META_SUPPLIER_ATTRVALUE
        },
        {
            path: "supplier/:CompanyId/attrvalue/create",
            name: META_SUPPLIER_ATTRVALUE.routeNameCreate,
            component: EditPageAttrValueMain,
            meta: META_SUPPLIER_ATTRVALUE
        },
        {
            path: "agent/:CompanyId/attrvalue/:AttrValueId/edit",
            name: META_AGENT_ATTRVALUE.routeNameEdit,
            component: EditPageAttrValueMain,
            meta: META_AGENT_ATTRVALUE
        },
        {
            path: "agent/:CompanyId/attrvalue/:AttrValueId/show",
            name: META_AGENT_ATTRVALUE.routeNameShow,
            component: EditPageAttrValueMain,
            meta: META_AGENT_ATTRVALUE
        },
        {
            path: "agent/:CompanyId/attrvalue/create",
            name: META_AGENT_ATTRVALUE.routeNameCreate,
            component: EditPageAttrValueMain,
            meta: META_AGENT_ATTRVALUE
        },
    ]
}