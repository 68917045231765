<template>
    <sidebar-item :link="{name: $t('Статистика')}" :class="'subMenuStatic'" :hasActive="false">
      <sidebar-item :link="{name: $t('HrFeed(входящая)'),  path: `/statisticHrFeed/` }"></sidebar-item>
      <sidebar-item :link="{name: $t('HrFeed(исходящая)'),  path: `/statisticHrFeedExport/` }"></sidebar-item>
      <sidebar-item :link="{name: 'GetCatalogUnit',  path: `/statisticGetCatalogUnit/` }"></sidebar-item>
    </sidebar-item>
</template>

<script>
    export default {
    }
</script>

<style lang="scss" scoped>
  .subMenuStatic {
    padding-left: 13px;
  }
</style>
