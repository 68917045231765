<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-md-12">
        <h1>  </h1>
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">{{$t('VipService')}}</h4>
            <p class="card-category">v {{version}}  {{$t('VipService')}} </p>
            <p class="card-category text-warning">{{$t('Номер сборки')}}: {{buildVer}}  {{$t('Время сборки')}}: {{lastTimeBuild}} </p>
          </div>
          <div class="card-body">
            <div class="typo-line">

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {VERSION} from '../../../constant'
  export default {
    data () {
      return {
        version: VERSION || 1,
        lastTimeBuild: process.env.VUE_APP_BULD_LAST_TIME || 'н/д',
        buildVer: process.env.VUE_APP_BULD_VER || 'н/д',
      }
    }
  }
</script>
