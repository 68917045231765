import { PAGE_MODE } from '../../constant';
export const pageMode = {
    computed: {
        pageMode() {
            if (this.$route.name == this.routeNameShow) {
                return PAGE_MODE.SHOW
            }
            if (this.$route.name == this.routeNameCreate) {
                return PAGE_MODE.CREATE
            } else {
                return PAGE_MODE.EDIT
            }
        }
    },
    methods: {
        pageTitle(titleShow, titleCreate, titleEdit) {
            switch (this.pageMode) {
                case PAGE_MODE.SHOW:
                    {
                        return titleShow;
                    }
                case PAGE_MODE.CREATE:
                    {
                        return titleCreate
                    }
                case PAGE_MODE.EDIT:
                    {
                        return titleEdit
                    }
            }
        }
    }
}