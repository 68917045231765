import Vue from 'vue'
import VueRouter from 'vue-router'
import LightBootstrap from './light-bootstrap-main'

import App from './App.vue'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'

import routes from './routes/routes'

import store from './store/index.js'
import i18n from './i18n'
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(VueRouter)
Vue.use(LightBootstrap)

import './directive/v-price'


const router = new VueRouter({
    routes,
    linkActiveClass: 'active',
})


import moment from 'moment';
import VueMoment from 'vue-moment';

Vue.use(VueMoment, { moment });
Vue.use(VueLodash, { lodash: lodash })

new Vue({
    render: h => h(App),
    store,
    i18n,
    router
}).$mount('#app')
