<template>
  <div :class="generateClass">
    <fg-input
      :label="config.label"
      :data-cy="config.cyData"
      :placeholder="config.mask"
      v-model="form[config.fieldName]"
      :readonly="readonly"
      v-mask="config.mask?config.mask:''"
      :name="config.fieldName"
      v-validate.initial="config.validationRule"
      :error="getError(config.fieldName)"
      :data-vv-as="config.label"
      :errorsMsg=getErrorMas(config.fieldName)
    >
    </fg-input>
  </div>
</template>
<script>
  import {generateClass} from '../../../../mixins/baseComponets/generateClass'
  import {validateExt} from '../../../../mixins/baseComponets/validateExt'
  import Vue from 'vue'
  import VueMask from 'v-mask'
  Vue.use(VueMask)
  export default {
    name: 'BaseInputMask',
    props: {
      config: {type: Object, required: true},
      form: {type: Object}
    },
    mixins: [generateClass, validateExt]
  }
</script>
