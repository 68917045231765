<template>
  <div
    @click="tryClose"
    data-notify="container"
    class="alert open"
    :class="[
      { 'alert-with-icon': icon },
      verticalAlign,
      horizontalAlign,
      alertType
    ]"
    role="alert"
    :style="customPosition"
    data-notify-position="top-center"
  >
    <button
      v-if="showClose"
      type="button"
      aria-hidden="true"
      class="close col-xs-1"
      data-notify="dismiss"
      @click="close"
    >
      <i class="custom_remove"></i>
    </button>

    <span v-if="icon" data-notify="icon" :class="['alert-icon', icon]"></span>
    <span data-notify="message">
      <span v-if="title" class="title"
        ><b>{{ title }}<br /></b
      ></span>
      <span v-if="message" v-html="message"></span>
      <content-render
        v-if="!message && component"
        :component="component"
      ></content-render>
    </span>
  </div>
</template>
<script>
export default {
  name: 'notification',
  components: {
    contentRender: {
      props: ['component'],
      render(h) {
        // register component locally if it's not registered
        let componentsObj = this.$options.components;
        if(!componentsObj[this.component.name]) {
          componentsObj[this.component.name] = this.component;
        }
        return h(this.component)
      }
    }
  },
  props: {
    message: String,
    title: String,
    icon: String,
    verticalAlign: {
      type: String,
      default: 'top',
      validator: value => {
        let acceptedValues = ['top', 'bottom'];
        return acceptedValues.indexOf(value) !== -1;
      }
    },
    horizontalAlign: {
      type: String,
      default: 'right',
      validator: value => {
        let acceptedValues = ['left', 'center', 'right'];
        return acceptedValues.indexOf(value) !== -1;
      }
    },
    type: {
      type: String,
      default: 'info',
      validator: value => {
        let acceptedValues = [
          'info',
          'primary',
          'danger',
          'warning',
          'success'
        ];
        return acceptedValues.indexOf(value) !== -1;
      }
    },
    timeout: {
      type: Number,
      default: 5000,
      validator: value => {
        return value >= 0;
      }
    },
    timestamp: {
      type: Date,
      default: () => new Date()
    },
    component: {
      type: [Object, Function]
    },
    showClose: {
      type: Boolean,
      default: true
    },
    closeOnClick: {
      type: Boolean,
      default: true
    },
    clickHandler: Function
  },
  data() {
    return {
      elmHeight: 0
    };
  },
  computed: {
    hasIcon() {
      return this.icon && this.icon.length > 0;
    },
    alertType() {
      return `alert-${this.type}`;
    },
    customPosition() {
      let initialMargin = 20;
      let alertHeight = this.elmHeight + 10;
      let sameAlertsCount = this.$notifications.state.filter(alert => {
        return (
          alert.horizontalAlign === this.horizontalAlign &&
          alert.verticalAlign === this.verticalAlign &&
          alert.timestamp <= this.timestamp
        );
      }).length;
      if (this.$notifications.settings.overlap) {
        sameAlertsCount = 1;
      }
      let pixels = (sameAlertsCount - 1) * alertHeight + initialMargin;
      let styles = {};
      if (this.verticalAlign === 'top') {
        styles.top = `${pixels}px`;
      } else {
        styles.bottom = `${pixels}px`;
      }
      return styles;
    }
  },
  methods: {
    close() {
      this.$emit('close', this.timestamp);
    },
    tryClose(evt) {
      if (this.clickHandler) {
        this.clickHandler(evt, this);
      }
      if (this.closeOnClick) {
        this.close();
      }
    }
  },
  mounted() {
    this.elmHeight = this.$el.clientHeight;
    if (this.timeout) {
      setTimeout(this.close, this.timeout);
    }
  }
};
</script>
<style lang="scss">
.notifications .alert {
  position: fixed;
  z-index: 10000;

  &[data-notify='container'] {
    width: 246px;
  }

  &.center {
    margin: 0 auto;
  }
  &.left {
    left: 20px;
  }
  &.right {
    right: 20px;
  }
}
.custom_remove {
    width: 15px;
    height: 15px;
}
.custom_remove::before {
  content: '';
  display: inline-block;
  position: absolute;
  box-sizing: border-box;
  top: 1px;
  left: 1px;
  height: 15px;
  width: 15px;
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.3079 7.70548L4.0025 5.40122L1.6921 7.70947C1.30553 8.09684 0.677238 8.09684 0.290673 7.70947C-0.096891 7.3231 -0.096891 6.69612 0.290673 6.30875L2.60008 3.9995L0.290673 1.69125C-0.096891 1.30388 -0.096891 0.6769 0.290673 0.290528C0.677238 -0.0968426 1.30553 -0.0968426 1.6921 0.290528L4.0025 2.59878L6.3079 0.294521C6.69447 -0.0928491 7.32176 -0.0928491 7.70933 0.294521C8.09589 0.681892 8.09589 1.30887 7.70933 1.69525L5.40392 4.0005L7.70933 6.30475C8.09689 6.69113 8.09689 7.31911 7.70933 7.70548C7.32276 8.09185 6.69447 8.09185 6.3079 7.70548Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
}
.custom_tick::before {
  content: '';
  display: inline-block;
  position: absolute;
  box-sizing: border-box;
  top: 8px;
  left: 8px;
  height: 16px;
  width: 16px;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 8C0 3.582 3.581 0 8 0C12.418 0 16 3.582 16 8C16 12.418 12.418 16 8 16C3.581 16 0 12.418 0 8ZM6.0253 11.0103C6.4153 11.4013 7.0483 11.4013 7.4393 11.0103L11.5103 6.9393C11.9013 6.5483 11.9013 5.9153 11.5103 5.5253C11.1193 5.1343 10.4863 5.1343 10.0963 5.5253L6.7323 8.8893L5.9033 8.0603C5.5133 7.6703 4.8803 7.6703 4.4893 8.0603C4.0993 8.4513 4.0993 9.0843 4.4893 9.4753L6.0253 11.0103Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
}

</style>
