import {TYPE_COMPONETS} from '../constants'
import extendComponents from '../extendComponents'
let separator = {
  ...extendComponents(TYPE_COMPONETS.SEPARATOR)
}
delete separator.placeholder
delete separator.name
delete separator.default
delete separator.label
delete separator.validationRule
delete separator.errName
delete separator.fieldName
delete separator.readonly
delete separator.main
export default separator
