import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue'

const TravelersPage = () =>
    import ('./TravelersPage.vue')
export default {
    path: '/travelers',
    component: DashboardLayout,
    children: [{
            path: '/',
            name: 'travelersPage',
            component: TravelersPage
        },
    ]
}