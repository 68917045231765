import { actions } from "./actions";
import { mutations } from "./mutations";
const state = {
  data: {},
  fieldInfoList:[],
  fieldTable: {},

  dataLink:[],
  dataLinkMem:[], 
  fieldInfoListLink:[],
};

export default {
  state,
  actions,
  mutations
};
