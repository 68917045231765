import { actions } from "./actions";
import { mutations } from "./mutations";
const state = {
    data: {},
    fieldInfoList: [],
    fieldTable: {},
    tabnow: 'policy',

};

export default {
    state,
    actions,
    mutations
};