<template>
  <common-page-edit 
    :title="title"
    :nameRoleEntity="nameRoleEntity"
    :apiNameGet="apiNameGet"
    :apiNameCreate="apiNameCreate"
    :apiNameUpdate="apiNameUpdate"
    :routeNameShow="routeNameShow"
    :routeNameCreate="routeNameCreate"
    :keyRoute="'PersonId'"
    :backUrl="pathBack"
    :paramsSend="paramsSend"
  
    :convertLoadData="convertLoadData"
    :paramsSave="paramsSave"
    :isDefaultConverter="false"
  > 
  </common-page-edit>
</template>
<script>

import { API_METHODS  } from "../../../../../constant";
import { NAME_OBJECT } from "../../../../../RoleConstanans";
import CommonPageEdit from '../../../commonElement/CommonPageEdit.vue';
import convertFieldInfoListToScheme from "../../../../../helpers/converters/convetFieldInfoListToScheme";

export default {
  name: "EditPageDynamic",
  components: {
    CommonPageEdit
  },
  data() {
    return {
      wait: false,
    
     
      fieldInfoList: null,
      preResult: null,
      load: true,

      routeNameShow: "ShowBonusCard",
      routeNameCreate: "CreateBonusCard",
      pathBack: `/contract/${this.$route.params.ContractId}/person/${this.$route.params.PersonId}/edit`,
      apiNameGet: API_METHODS.GET_BONUS_CARD,
      apiNameCreate:API_METHODS.CREATE_BONUS_CARD,
      apiNameUpdate:API_METHODS.UPDATE_BONUS_CARD,
     
    };
  },
  computed: {
    title() {
      let text1 =   this.$t("Просмотр бонусной карты");
      let text2 =  this.$t("Создание бонусной карты");
      let text3 =   this.$t("Редактирование бонусной карты");

      let findShow = this.$route.path.indexOf('show')
      if(findShow !=-1) {
        return text1
      }
      let findCreate = this.$route.path.indexOf('create')
      if(findCreate !=-1) {
        return text2
      }
      let findEdit = this.$route.path.indexOf('edit')
      if(findEdit !=-1) {
        return text3
      }
      return text1
    },
   nameRoleEntity(){
         return NAME_OBJECT.clientPersonBonusCard
   },
   paramsSend(){
     return  {
          id: this.$route.params.BonusCardId || "0",
          PersonId: this.$route.params.PersonId
        }
   }
  },

  methods:{
    convertLoadData(res){
          let loadData = res.object;
          let fieldInfoList = res.fieldInfoList;
          let scheme = {configForm: []} 
    
          scheme.configForm = convertFieldInfoListToScheme({
            fieldInfoList,
            hasLang: false
          }); 
          loadData.personId = this.$route.params.PersonId;
          scheme.configForm = scheme.configForm.map(item => {
            if (item.fieldName === "number") {
              return {
                ...item,
                validationRule: {
                  ...item.validationRule,
                   regex: "^[a-zA-Z0-9]+$"
                },
                
              };
            }
            return item;
          });

          return {
            loadData, 
            fieldInfoList,
            scheme
          } 
    },
    paramsSave() {
      return {
          id: this.$route.params.BonusCardId,
          PersonId: this.$route.params.PersonId
        }
    },
  }
};
</script>
