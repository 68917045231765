/**
 * Убирает edit show
 * @param {*} url
 * @returns
 */
export const clearRouteName = url => {
  let clearName = url;
  let urlL = url.length;
  let findEditIndex = url.indexOf("/edit");
  let findShowIndex = url.indexOf("/show");
  if (findEditIndex > -1) {

    clearName = clearName.substr(0, findEditIndex);
  }
  if (findShowIndex > -1) {

    clearName = clearName.substr(0, findShowIndex);
  }
  return clearName;
};
