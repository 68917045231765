<template>
  <common-page-table
    :apiName="apiName"
    :nameRole="nameRoleEntity"
    :titleTable="$t('HrFeed(исходящая) статистика')"
    :paramsGetData="paramsGetData"
    :fieldLand="fieldLang"
    :keyTable="'linkConvert'"
    :deleteParams="()=>{}"
    :apiDeleteName="apiDeleteName"
    :alternativeConverter="convertData"
    :isDefaultConverter="false"
    :force-delete="false"
    :force-is-create="false"
    :force-is-edit="false"
    :prefixShow="prefixShow"
    :hasReadOnlyInTable="hasReadOnlyInTable"
    :eventGetParamsGetData="true"
    :trigerReinit="trigerReinit"
    @setTriger="setTriger"
  >
    <div>
      <div class="col-sm-12">
        <FilterBlockStatistic :filter-list="filterList" :filterData="filterData" />
      </div>
    </div>
  </common-page-table>
</template>
<script>
import { NAME_OBJECT } from "../../../../RoleConstanans";
import CommonPageTable from "../../commonElement/CommonPageTable.vue";
import { API_METHODS,  GLOBAL_DATE_TIME_FORMAT } from "../../../../constant";
import convertLangDataToTable from "../../../../helpers/converters/convertLangDataToTable";
import FilterBlockStatistic from "./FilterBlockStatistic.vue";

export default {
  name: "StatisticHrFeedExportTable",
  components: { CommonPageTable, FilterBlockStatistic },
  props: {
    hasReadOnlyInTable: { type: Boolean, default: true },
    routeField: { type: String, default: "CompanyId" }
  },
  data() {
    return {
      trigerReinit: false,
      dateFrom: "",
      dateTo: "",
      fieldLang: "",
      apiName: API_METHODS.GET_ALL_STATISTIC_HR_FEED_EXPORT,
      apiDeleteName: "",
      filterList: [{
        "code": "dateFrom",
        "name": "Период от",
        "type": "Date",
        "refEntityCode": null,
        "colWidth": 6,
        "subListId": 0,
        "watchAttr": null
      },
        {
          "code": "dateTo",
          "name": "До",
          "type": "Date",
          "refEntityCode": null,
          "colWidth": 6,
          "subListId": 0,
          "watchAttr": null
        }
      ]
    };
  },
  created() {
    this.dateFrom = this.$moment().subtract(1, "month").format("YYYY-MM-DD");
    this.dateTo = this.$moment().format("YYYY-MM-DD");
  },
  computed: {
    paramsGetData() {
      return {
        paramsDop: {
          dateFrom: this.dateFrom,
          dateTo: this.dateTo
        }
      };
    },
    prefixEdit() {
      return "/statisticHrFeedExport";
    },
    prefixShow() {
      return this.prefixEdit;
    },
    linkCreate() {
      return this.prefixEdit + "/create";
    },
    nameRoleEntity() {
      let role = NAME_OBJECT.StatisticHrFeed;
      return role;
    }
  },
  methods: {
    convertData(dataNow, langNow) {
      let res = convertLangDataToTable(dataNow, langNow, "", false);
      res = res.map(el => {
        return {
          ...el,
          linkConvert: `${el.contragentId}/${this.dateFrom}/${this.dateTo}`,     // :contragentId/:dateFrom/:dateTo
        };
      });
      return res;
    },
    filterData(val) {
      if(this.dateFrom == val.dateFrom && this.dateTo == val.dateTo) {
        this.trigerReinit = true;
      } else {
        this.dateFrom = val.dateFrom;
        this.dateTo = val.dateTo;
      }
    } ,
    setTriger(){
      this.trigerReinit = false;
    },
  }
};
</script>
