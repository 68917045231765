import {
  USER_ERROR,
  USER_SUCCESS,
  USER_REQUEST,
  AUTH_LOGOUT
} from "../../type";
import Vue from "vue";

export const mutations = {
  [USER_REQUEST]: state => {
    state.status = "loading";
  },
  [USER_SUCCESS]: (state, resp) => {
    state.status = "success";
    Vue.set(state, "profile", resp);
    localStorage.setItem('userId', state.profile.id)
  },
  [USER_ERROR]: state => {
    state.status = "error";
  },
  [AUTH_LOGOUT]: state => {
    state.profile = {};
  }
};
