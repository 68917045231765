<template>
  <common-page-edit
    :title="title"
    :nameRoleEntity="nameRoleEntity"
    :apiNameGet="apiNameGet"
    :apiNameCreate="apiNameCreate"
    :apiNameUpdate="apiNameUpdate"
    routeNameShow="ConnectDirectoryShow"
    routeNameCreate="ConnectDirectoryCreate"
    :keyRoute="'id'"
    :backUrl="pathBack"
    @set-loadData="setLoadData"
    :convertLoadData="convertLoadData"
    :paramsSave="paramsSave"
    :isDefaultConverter="false"
    :paramsSend="paramsSend"
    :forceNoShowEdit="true"
  >
    
  </common-page-edit>
</template>
<script>
import { API_METHODS, FIELD_LANG_COMPANY } from "../../../../../constant";
import { NAME_OBJECT } from "../../../../../RoleConstanans";
import CommonPageEdit from "../../../commonElement/CommonPageEdit.vue";
import CompanyData from "../../company/CompanyData.vue";

import convertFieldInfoListToScheme from "../../../../../helpers/converters/convetFieldInfoListToScheme";

export default {
  name: "ConnectDirectoryEditPageDynamic",
  components: {
    CommonPageEdit,
    CompanyData
  },
  data() {
    return {
      wait: false,
      scheme: {},
      loadData: null,
      fieldInfoList: null,
      preResult: null,
      load: true,

      routeNameShow: "ConnectDirectoryShow",
      routeNameCreate: "ConnectDirectoryCreate",

      // pathBack: "/crm/CounterpartyBankDetails",
      apiNameGet: API_METHODS.GET_CONNECT_DIRECTORY,
      apiNameCreate: API_METHODS.UPDATE_CONNECT_DIRECTORY,
      apiNameUpdate: API_METHODS.CREATE_CONNECT_DIRECTORY,
      loadData: null
    };
  },
  computed: {
    title() {
      let text1 = this.$t("show_CONNECT_DIRECTORY");
      let text2 = this.$t("create_CONNECT_DIRECTORY");
      let text3 = this.$t("edit_CONNECT_DIRECTORY");

      let findShow = this.$route.path.indexOf("show");
      if (findShow != -1) {
        return text1;
      }
      let findCreate = this.$route.path.indexOf("create");
      if (findCreate != -1) {
        return text2;
      }
      let findEdit = this.$route.path.indexOf("edit");
      if (findEdit != -1) {
        return text3;
      }
      return text1;
    },
    pathBack() {
      return `/receiver-system/${this.$route.params.ReceiverSystemId}/show`
    },
    nameRoleEntity() {
      return NAME_OBJECT.ReceiverSystems;
    },
    paramsSend() {
      return {
        id: this.$route.params.id || "0",
        ReceiverSystemId: this.$route.params.ReceiverSystemId
      };
    }
  },

  methods: {
    convertLoadData(res) {
      let loadData = res.object;
      loadData.cfoType = this.cfoType;
      let fieldInfoList = res.fieldInfoList;
      let scheme = { configForm: [] };

      scheme.configForm = convertFieldInfoListToScheme({
            fieldInfoList,
            hasLang: false
      });

      return {
        loadData,
        fieldInfoList,
        scheme
      };
    },
    setLoadData(loadData) {
      this.loadData = loadData;
    },

    paramsSave() {
      return {
        id: this.$route.params.id,
        CfoType: this.cfoType
      };
    }
  }
};
</script>
