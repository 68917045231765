import ReceiverSystemTablePage from "./ReceiverSystemTablePage.vue"
import ReceiverSystemEditPageDynamic from "./ReceiverSystemEditPageDynamic.vue"
import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue"

export default {
  path: "/receiver-system",
  component: DashboardLayout,
  children: [{
    path: "/",
    name: "ReceiverSystem",
    component: ReceiverSystemTablePage
  },
  {
    path: ":id/edit",
    name: "ReceiverSystemEdit",
    component: ReceiverSystemEditPageDynamic,
  },
  {
    path: ":id/delete",
    name: "ReceiverSystemDelete",
    component: ReceiverSystemEditPageDynamic,
  },
  {
    path: ":id/show",
    name: "ReceiverSystemShow",
    component: ReceiverSystemEditPageDynamic,
  },
  {
    path: "create",
    name: "ReceiverSystemCreate",
    component: ReceiverSystemEditPageDynamic
  },


  


  ]
}
