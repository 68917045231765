import { READ, UPDATE, DELETE, SET_MAIN_PAGINATION_TOTAL } from '../../../../store/type'
import { ENTITY_NAME, DOCUMENT, BONUS_CARD, CONTACT, PREF_DATA, VISA } from "./type";
import {api} from "../../../../util/api";
import {API_METHODS} from "../../../../constant";

export const actions = {
    [ENTITY_NAME + READ]: async ({ state, rootState, commit }, paramsIn = null) => {
     
      try {
        if (paramsIn.id) {
          return await api(API_METHODS.GET_PERSON, paramsIn, rootState)
        } else {
          const res = await api(API_METHODS.GET_ALL_PERSON, paramsIn, rootState)
          commit(ENTITY_NAME + READ, {
            data: res.object,
            fieldInfoList: res.fieldInfoList
          })
          commit(SET_MAIN_PAGINATION_TOTAL, {
            countPage: res.countPage,
          })
          return res
        }
      } catch (err) {
        return (err)
      }
    },
    [ENTITY_NAME + UPDATE]: async ({ commit }, paramsIn = null) => {
      try {
        return await api(API_METHODS.UPDATE_PERSON, paramsIn)
      } catch(err) {
        return (err)
      }
    },
    [ENTITY_NAME + DELETE]: async ({}, paramsIn = null) => {
      try {
        return await api(API_METHODS.DELETE_PERSON, paramsIn)
      } catch(err) {
        return (err)
      }
  },

    [DOCUMENT + READ]: async ({ state, rootState, commit }, paramsIn = null) => {
      try {
        if (paramsIn.id) {
          return await api(API_METHODS.GET_DOCUMENT, paramsIn, rootState)
        } else {
          const res = await api(API_METHODS.GET_ALL_DOCUMENT, paramsIn, rootState)
          commit(DOCUMENT + READ, {
            data: res.object,
            fieldInfoList: res.fieldInfoList
          })
          commit(SET_MAIN_PAGINATION_TOTAL, {
            countPage: res.countPage,
          })
          return res
        }
      } catch (err) {
        return (err)
      }
    },

    [DOCUMENT + UPDATE]: async ({ commit }, paramsIn = null) => {
      try {
        return await api(API_METHODS.UPDATE_DOCUMENT, paramsIn)
      } catch(err) {
        return (err)
      }
    },

   [DOCUMENT + DELETE]: async ({}, paramsIn = null) => {
      try {
       return await api(API_METHODS.DELETE_DOCUMENT, paramsIn)
     } catch(err) {
       return (err)
     }
  },




   [BONUS_CARD + READ]: async ({ state, rootState, commit }, paramsIn = null) => {
      try {
        if (paramsIn.id) {
          return await api(API_METHODS.GET_BONUS_CARD, paramsIn, rootState)
        } else {
          const res = await api(API_METHODS.GET_ALL_BONUS_CARD, paramsIn, rootState)
          commit(BONUS_CARD + READ, {
            data: res.object,
            fieldInfoList: res.fieldInfoList
          })
          commit(SET_MAIN_PAGINATION_TOTAL, {
            countPage: res.countPage,
          })
          return res
        }
      } catch (err) {
        return (err)
      }
    },

   [BONUS_CARD + UPDATE]: async ({ commit }, paramsIn = null) => {
     try {
       return await api(API_METHODS.UPDATE_BONUS_CARD, paramsIn)
     } catch(err) {
       return (err)
     }
    },

  [BONUS_CARD + DELETE]: async ({}, paramsIn = null) => {
    try {
      return await api(API_METHODS.DELETE_BONUS_CARD, paramsIn)
    } catch(err) {
      return (err)
    }
  },

  [CONTACT + READ]: async ({ state, rootState, commit }, paramsIn = null) => {
      try {
        if (paramsIn.id) {
          return await api(API_METHODS.GET_CONTACT, paramsIn, rootState)
        } else {
          const res = await api(API_METHODS.GET_ALL_CONTACT, paramsIn, rootState)
          commit(CONTACT + READ, {
            data: res.object,
            fieldInfoList: res.fieldInfoList
          })
          commit(SET_MAIN_PAGINATION_TOTAL, {
            countPage: res.countPage,
          })
          return res
        }
      } catch (err) {
        return (err)
      }
  },

  [CONTACT + UPDATE]: async ({ commit }, paramsIn = null) => {
    try {
      return await api(API_METHODS.UPDATE_CONTACT, paramsIn)
    } catch(err) {
      return (err)
    }
  },

  [CONTACT + DELETE]: async ({}, paramsIn = null) => {
    try {
      return await api(API_METHODS.DELETE_CONTACT, paramsIn)
    } catch(err) {
      return (err)
    }
  },

  // ------------------------------------------------------------------------------
  // PREF_DATA
  [PREF_DATA + READ]: async ({ state, rootState, commit }, paramsIn = null) => {
    try {
      if (paramsIn.id) {
        return await api(API_METHODS.GET_PREF_DATA, paramsIn, rootState)
      } else {
        const res = await api(API_METHODS.GET_ALL_PREF_DATA, paramsIn, rootState)
        commit(PREF_DATA + READ, {
          data: res.object,
          fieldInfoList: res.fieldInfoList
        })
        commit(SET_MAIN_PAGINATION_TOTAL, {
          countPage: res.countPage,
        })
        return res
      }
    } catch (err) {
      return (err)
    }
  },

 [PREF_DATA + UPDATE]: async ({ commit }, paramsIn = null) => {
   try {
     return await api(API_METHODS.UPDATE_PREF_DATA, paramsIn)
   } catch(err) {
     return (err)
   }
  },

[PREF_DATA + DELETE]: async ({}, paramsIn = null) => {
  try {
    return await api(API_METHODS.DELETE_PREF_DATA, paramsIn)
  } catch(err) {
    return (err)
  }
},
//-----------------------------------------------------------------------------
[VISA + READ]: async ({ state, rootState, commit }, paramsIn = null) => {
  try {
    if (paramsIn.id) {
      return await api(API_METHODS.GET_VISA, paramsIn, rootState)
    } else {
      const res = await api(API_METHODS.GET_ALL_VISA, paramsIn, rootState)
      commit(VISA + READ, {
        data: res.object,
        fieldInfoList: res.fieldInfoList
      })
      commit(SET_MAIN_PAGINATION_TOTAL, {
        countPage: res.countPage,
      })
      return res
    }
  } catch (err) {
    return (err)
  }
},

[VISA + UPDATE]: async ({ commit }, paramsIn = null) => {
  try {
    return await api(API_METHODS.UPDATE_VISA, paramsIn)
  } catch(err) {
    return (err)
  }
},

[VISA + DELETE]: async ({}, paramsIn = null) => {
  try {
   return await api(API_METHODS.DELETE_VISA, paramsIn)
 } catch(err) {
   return (err)
 }
}
};
