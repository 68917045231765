import { SHOW_MAIN_MENU, CHANGE_LOCAL, SET_ROLE_PARAMS, LOAD_LOGIN_USER_ROLE, RESET_USER_ROLE } from "../../type";
import clearReactive from "../../../helpers/clearReactive"
import Vue from "vue";
export const mutations = {
    [SHOW_MAIN_MENU]: (state, data) => {
        state.drawerMenu = data;
    },
    [CHANGE_LOCAL]: (state, data) => {
        localStorage.getItem(data)
        state.lang = data;
    },
    [SET_ROLE_PARAMS]: (state, data) => {
        let name = data.name
        let params = data.params
        state.config[name][params] = !state.config[name][params]
    },
    [LOAD_LOGIN_USER_ROLE]: (state, data) => {
        let nowData  = clearReactive(data)
        let res = {}
        nowData = nowData.forEach( el=> {
            res[el.elementCode]=el
        })
        state.userRoleData = res;
    },
    [RESET_USER_ROLE]: (state, data) => {
        state.userRoleData = null;
    }, 
    
};