<template>
  <card :title="$t('Выберите дату последнего обновления')">
    <TheDatePicker @shedule="setSheduleDate" name="shedule" />
  </card>
</template>
<script>
import TheDatePicker from "../../commonElement/tableElement/TheDatePicker.vue";
import clearReactive from "../../../../helpers/clearReactive";
//import { NAME_OBJECT, NAME_ACTION } from "../../../../RoleConstanans";
export default {
  name: "SheduleDatePicker",
  created() {
    // this.loadData = clearReactive(this.loadDataIn);
  },
  components: {
    TheDatePicker,
  },
  data() {
    return {
      sheduleDate: "",
    };
  },
  methods: {
    beforeLeave() {},
    setSheduleDate(e) {
      this.$emit("set-shedule-date", e);
      this.sheduleDate = e;
    },
  },
};
</script>
