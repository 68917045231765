import { API_METHODS } from "../../constant";
export const contractCodeApi = (
  _getAll,
  _get,
  _create,
  _update,
  _delete,
  paramsIn
) => {
  return {
    [API_METHODS.GET_CONTRACT_CODE]: () => {
      let urlReq = `Code/Contract/${paramsIn.ContractId}/${paramsIn.CodeType}`;
      return _getAll(urlReq);
    }
  };
};

