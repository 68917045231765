<template>
  <common-page-table
    :apiName="apiName"
    :nameRole="nameRoleEntity"
    :titleTable="titleName"
    :paramsGetData="paramsGetData"
    :fieldLand="fieldLang"
    :keyTable="'subjectReceiverSystemId'"
    :deleteParams="deleteParams"
    :apiDeleteName="apiDeleteName"
    :alternativeConverter="convertData"
    :isDefaultConverter="false"
    :forceIsEdit="true"
    :forceIsCreate="true"
    :forceDelete="true"


    :linkCreate = linkCreate
    :prefixEdit = prefixEdit
    :prefixShow = prefixShow
  />
</template>
<script>
import { NAME_OBJECT } from "../../../../../RoleConstanans";
import CommonPageTable from "../../../commonElement/CommonPageTable.vue";
import { API_METHODS , GLOBAL_DATE_FORMAT} from "../../../../../constant";
import convertLangDataToTable from "../../../../../helpers/converters/convertLangDataToTable";

export default {
  name: "ConnectDirectoryTablePage",
  components: { CommonPageTable },
  data() {
    return {
      fieldLang: "",
      apiName: API_METHODS.GET_ALL_CONNECT_DIRECTORY,
      apiDeleteName: API_METHODS.DELETE_CONNECT_DIRECTORY,
    };
  },
  computed: {

      // :prefixEdit="`/corpPolicy`"
      //             :prefixShow="`/corpPolicy`"
      //             :linkCreate="`/corpPolicy/create`"




    prefixEdit() {
      return `/receiver-system/${this.$route.params.id}/ModuleNames`;
    },
    prefixShow() {
      return `/receiver-system/${this.$route.params.id}/ModuleNames`;
    },
    linkCreate() {
      return  `/receiver-system/${this.$route.params.id}/ModuleNames/create`;
    },  

    titleName() {
      return this.$t('ConnectDirectory');
    },
    paramsGetData() {
      return { ReceiverSystemId: this.$route.params.id };
    },
    nameRoleEntity() {
      return NAME_OBJECT.ConnectDirectory;
    }
  },

  methods: {
    convertDataEl(val, def=null) {
      return  val ? this.$moment(val).format(
            GLOBAL_DATE_FORMAT
          ) : def
    },
    convertData(dataNow, langNow) {
      let res = convertLangDataToTable(dataNow, langNow, "", false);    
      const convertDataEl = this.convertDataEl
      // res = res.map(item=> {
      //   return {
      //     ...item,
         
      //   }
      // })
      return res;
    },
    deleteParams(id) {
      return { id: id };
    }
  }
};
</script>
