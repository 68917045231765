<template>
  <common-page-table
    :apiName="apiName"
    :nameRole="nameRoleEntity"
    :titleTable="$t('Контакты')"
    :paramsGetData="paramsGetData"
    :fieldLand="fieldLang"
    :keyTable="'id'"
    :deleteParams="deleteParams"
    :apiDeleteName="apiDeleteName"
    :alternativeConverter="convertData"
    :isDefaultConverter="false"
    :prefixEdit="isCompanyType?prefixEdit:`/company/${$route.params.CompanyId}/person/${$route.params.PersonId}/contact`"
    :prefixShow="isCompanyType?prefixShow:`/company/${$route.params.CompanyId}/person/${$route.params.PersonId}/contact`"
    :linkCreate="isCompanyType?linkCreate:`/company/${$route.params.CompanyId}/person/${$route.params.PersonId}/contact/create`"
    :hasReadOnlyInTable="hasReadOnlyInTable"      
  />
</template>
<script>
import { NAME_OBJECT } from "../../../../RoleConstanans";
import CommonPageTable from "../../commonElement/CommonPageTable.vue";
import { API_METHODS } from "../../../../constant";
import formatData from "../../../../helpers/converters/formatData";
import convertLangDataToTable from "../../../../helpers/converters/convertLangDataToTable";
export default {
  name: "ContactTable",
  components: { CommonPageTable },
  props:{
      isCompanyType: {type: Boolean, default: true},
      hasReadOnlyInTable: { type: Boolean, default: true }, 
      routeField: {type: String, default: "PersonId"},
  },
  data() {
    return {
      fieldLang: "",
      apiName: API_METHODS.GET_ALL_CONTACT,
      apiDeleteName: API_METHODS.DELETE_CONTACT
    };
  },
  computed: {
    paramsGetData() {
      return { SubjectId: this.$route.params[this.routeField] };
    },
     prefixEdit() {
        const route = this.$route
        return `/company/${route.meta.typeCompany}/${route.params.CompanyId}/contact`
      },
      prefixShow() {
        return this.prefixEdit
      },
      linkCreate() {
         return this.prefixEdit+'/create'
      },
    nameRoleEntity(){
        const nameRoute = this.$route.name
        let role = NAME_OBJECT.clientPersonContact
        switch (nameRoute) {
          case 'AgentShow':
          case 'AgentEdit' : {
             role =  NAME_OBJECT.ContactAgency
             break
          }
          case 'ClientShow' : 
          case 'ClientEdit' : {  
            role =  NAME_OBJECT.ContactClient
            break
          }
        }
        
        return role
      },
  },
  methods: {
    convertData(dataNow, langNow) {
      let res = convertLangDataToTable(dataNow, langNow, '', false)
      return res;
    },
    deleteParams(id) {
      return {SubjectId:  this.$route.params[this.routeField],  id: id };
    },
  }
};
</script>

