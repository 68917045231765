<template>
  <sidebar-item :link="{name: $t('Пользователи'), icon: 'nc-icon nc-single-02', path: `/user`}">
  </sidebar-item>
</template>
<script>
    export default {
        name: "UserMenu"
    }
</script>

