<template>
  <div>
    <el-dialog
        :title="$t('Журнал ошибок')"
        :visible="dialogTableVisible"
        @close="$emit('showToggle')"
      >
      <div class="btn_wrapper">
        <l-button class="download_button" v-if="errorsJSON" type="primary" @click="uploadErrorsJSON">
          {{ $t("Выгрузить все ошибки в JSON") }}
        </l-button>
      </div>
      <el-table v-if="errorGrid.length" :data="errorGrid" :header-cell-style="errorHeader">
        <el-table-column type="expand">
          <template slot-scope="props">
            <b>Info:</b>
            <p v-for="value in props.row.value" :key="value">{{ value }}</p>
          </template>
        </el-table-column>
        <el-table-column property="line" label="Errors"></el-table-column>
      </el-table>
      <el-table v-if="warningGrid.length" :data="warningGrid" :header-cell-style="warningHeader">
        <el-table-column type="expand">
          <template slot-scope="props">
            <b>Info:</b>
            <p v-for="value in props.row.value" :key="value">{{ value }}</p>
          </template>
        </el-table-column>
        <el-table-column property="line" label="Warnings"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import { Table, TableColumn, Dialog } from "element-ui";
export default {
  name: "CustomDialog",
  components: {
    elTable: Table,
    elDialog: Dialog,
    elTableColumn: TableColumn,
  },
  props: {
    warningData: {
      type: Array,
      default: ["line: no info"],
    },
    errorData: {
      type: Array,
      default: ["line: no info"],
    },
    fileName: {
      type: String,
      default: '',
    },
    dialogTableVisible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    warningGrid() {
      const reducedData = {};
      this.warningData.forEach((el) => {
        const [line, element] = el.split(",");
        if (reducedData[line]) {
          reducedData[line].push(element);
        } else {
          reducedData[line] = [element];
        }
        return { element, line };
      });
      const dataArray = [];
      for (const [line, value] of Object.entries(reducedData)) {
        dataArray.push({ line, value });
      }
      return dataArray;
    },
    errorGrid() {
      const reducedData = {};
      this.errorData.forEach((el) => {
        const [line, element] = el.split(",");
        if (reducedData[line]) {
          reducedData[line].push(element);
        } else {
          reducedData[line] = [element];
        }
        return { element, line };
      });
      const dataArray = [];
      for (const [line, value] of Object.entries(reducedData)) {
        dataArray.push({ line, value });
      }
      return dataArray;
    },
    errorsJSON() {
      const errors = [];
      this.warningGrid.forEach(el => {
        for (const [key, value] in Object.entries(el)) {
          errors.push(`${key}: ${value}`)
        }
      });
      return errors
    }
  },
  methods: {
    errorHeader({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #cc3300; color: #fff; font-weight: 500;'
      }
    },
    warningHeader({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #ffa434; color: #fff; font-weight: 500;'
      }
    },
    uploadErrorsJSON() {
      const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify({errors: this.errorGrid,warnings: this.warningGrid}));
      const hiddenNode = document.createElement('a');
      hiddenNode.setAttribute("href", dataStr);
      hiddenNode.setAttribute("download", `errors_log_${this.fileName}.json`);
      document.body.appendChild(hiddenNode);
      hiddenNode.click();
      hiddenNode.remove();
    }
  }
};
</script>

<style lang="scss">
.el-table .cell {
  white-space: normal !important;
}
.el-dialog__wrapper .el-dialog {
  background-color: #ffffffa2 !important;
}
.el-dialog__headerbtn .el-dialog__close, .el-dialog__title {
  color: #404040 !important;
}
.download_button {
  min-width: 50%;
}
.btn_wrapper {
  margin-bottom: 2rem;
  text-align: right;
}
</style>
