import CfoTablePage from "./CfoTablePage.vue"
import CfoEditPageDynamic from "./CfoEditPageDynamic.vue"
import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue"

export default {
  path: "/cfo",
  component: DashboardLayout,
  children: [{
    path: ":CfoType",
    name: "CfoTablePage",
    component: CfoTablePage
  },
  {
    path: ":CfoType/:id/edit",
    name: "CfoEdit",
    component: CfoEditPageDynamic,
  },
  {
    path: ":CfoType/:id/delete",
    name: "CfoDelete",
    component: CfoEditPageDynamic,
  },
  {
    path: ":CfoType/:id/show",
    name: "CfoShow",
    component: CfoEditPageDynamic,
  },
  {
    path: ":CfoType/create",
    name: "CfoCreate",
    component: CfoEditPageDynamic
  },
  ]
}
