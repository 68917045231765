<template>
  <div v-if="show" style="display: none;">
    <div @click="showData=!showData" class="showDataLink">{{title ? title : 'showData'}}</div>
    <pre class="code codeExtend" v-if="showData">{{code}}</pre>
  </div>
</template>
<script>
  export default {
    name: 'ShowCode',
    props: {code: {required: true}, title: {type: String}},
    data() {
      return {
        showData: false,
        show: false
      }
    },
    created() {
      if (location.hostname == "localhost") {
        this.show = true
      }
    }
  }
</script>
<style>
  .showDataLink {
    color: #00bbff;
    font-size: 11px;
    cursor: pointer;
  }

  .code {
    background: #303133;
    color: #fff;
    border-radius: 12px;
    padding: 12px;
  }
</style>
