<template>
  <card :title="$t('Лог шины')">
    <FilterBlock
      :filterList="filterList"
      v-if="filterList"
      :filterData="filterData"
    />

    <the-table-page
      :titleTable="$t('Входящие данные')"
      :tableData="tableData"
      :load="load"
      fieldId="dataImportId"
      :updateData="updateData"
      :fieldInfoList="fieldInfoList"
      :searchStart="searchStart"
      :sortData="sortData"
      :setSort="setSort"
      :deleteEl="deleteEl"
      configName="shina"
      :isSelect="true"
      :setSelect="setSelect"
      :selectId="selectData.dataImportId"
      :isDetail="true"
      :toggleModal="toggleModal"
      :textAction="'Детали'"
      :roleName="nameRoleEntity"
      :forceIsShow="false"
      :forceIsEdit="false"
      :forceDelete="false"
      :forceIsCreate="false"
    ></the-table-page>

    <JournalProcess
      :dataImportId="selectData.dataImportId"
      v-if="selectData.dataImportId"
      :selectData="selectData"
      :isDetail="true"
      :toggleModal="toggleModal"
    />
    <JournalOut
      :DataChangedId="selectData.DataChangedId"
      v-if="selectData.DataChangedId"
      :isDetail="true"
      :toggleModal="toggleModal"
    />

    <DetailData
      :modal="modal"
      :dataShow="dataShow"
      :fieldList="fieldList"
      :toggleModal="toggleModal"
    />
  </card>
</template>
<script>
import TheTablePage from "../../TheTablePage.vue";
import { JURNAL } from "./store/type";
import JournalProcess from "./JournalProcess.vue";
import JournalOut from "./JournalOut";
import FilterBlock from "./FilterBlock";
import DetailData from "./DetailData";
import {
  SET_MAIN_PAGINATION,
  SET_MAIN_PAGINATION_PER_PAGE,
  SET_MAIN_PAGINATION_PAGE,
  SET_MAIN_SORT_ENTITY,
  SET_MAIN_SEARCH_ENTITY
} from "../../../../src/store/type";

import { READ, DELETE } from "../../../store/type";
import { mapState } from "vuex";
import NotificationSample from "src/pages/Dashboard/Components/NotificationSample";
import { DEFAULT_PER_PAGE, TYPE_ERRORS, GET_MEMORY_PER_PAGE} from "../../../constant";
import notify from "../../../helpers/notify/notify";
import convertLangDataToTable from "../../../helpers/converters/convertLangDataToTable";
import formatData from "../../../helpers/converters/formatData";
import clearReactive from "../../../helpers/clearReactive";
import {NAME_OBJECT} from "../../../RoleConstanans"
export default {
  components: {
    TheTablePage,
    JournalProcess,
    JournalOut,
    FilterBlock,
    DetailData
  },
  props: {
    routeField: { type: String, default: "PersonId" },
    configName: { type: String, default: "clientPersonContact" },
    isCompanyType: { type: Boolean, default: true }
  },
  data() {
    return {
      firstLoad: true,
      load: false,
      filterform:{
        dateFrom: this.$moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
        dateTo: this.$moment().format("YYYY-MM-DD")
      } ,
      selectData: {
        dataImportId: null,
        DataChangedId: null
      },
      modal: {
        details: false
      },
      dataShow: {},
      fieldList: null
    };
  },
  computed: {
    ...mapState({
      fieldInfoList: state => state.bus.fieldInfoList,
      filterList: state => state.bus.filterList,
      lang: state => state.common.lang,
      sortData: state => state.pagination.sortData
    }),
    nameRoleEntity(){
      return NAME_OBJECT.Esb
    },
    tableData() {
      let langNow = this.$store.state.common.lang;
      let dataNow = this.$store.state.bus.data || [];
      if (dataNow && dataNow.length > 0) {
        dataNow = convertLangDataToTable(dataNow, langNow, "", false);

        dataNow = dataNow.map(item => {
          return {
            ...item,
            dateCreated: formatData(item.dateCreated, "DD.MM.YYYY HH:mm:ss"),
            dataFull: item.data,
            data:  item.data ? item.data.substr(0, 30) + "..." : item.data  ,
            errorFull: item.error,
           error:  (item.error && item.error.substr(0, 30)).length >0 ? item.error.substr(0, 30) + "..." : ''
          }
        });
        return dataNow;
      }
    },
    prefixEdit() {
      const route = this.$route;
      return `/company/${route.meta.typeCompany}/${route.params.CompanyId}/contact`;
    },
    prefixShow() {
      return this.prefixEdit;
    },
    linkCreate() {
      return this.prefixEdit + "/create";
    }
  },
  watch: {
    lang() {
      this.initData();
    }
  },
  methods: {
    toggleModal(row = null, fieldList) {
      if (row !== null) {
        let datashow = clearReactive(row);
        datashow.data = datashow.dataFull;
        datashow.error = datashow.errorFull;
        if (datashow.isCoreChangedFull) {
          datashow.isCoreChanged = datashow.isCoreChangedFull;
          delete datashow.isCoreChangedFull;
        }
        delete datashow.dataFull;
        delete datashow.errorFull;
        this.dataShow = datashow;
        this.fieldList = fieldList;
      }

      this.modal.details = !this.modal.details;
    },
    getData() {
      this.load = true;
      let filterform = this.filterform;

      this.$store
        .dispatch(JURNAL + READ, {
          self: this,
          SubjectId: this.$route.params[this.routeField],
          ...filterform
        })
        .then(res => {
          this.load = false;
          let data = this.tableData;
          if (data && data.length > 0) {
            this.setSelect(data[0].dataImportId);
          }
        })
        .catch(err => {
          this.load = false;
          notify(this, this.$t("Ошибка получения данных"), TYPE_ERRORS.DANGER);
        });
    },
    updateData() {
      this.getData();
    },
    searchStart(search) {
      this.$store.commit(SET_MAIN_SEARCH_ENTITY, search);
      this.getData();
    },
    setSort(val) {
      this.$store.commit(SET_MAIN_SORT_ENTITY, val);
      this.getData();
    },
    deleteEl(id) {},
    initData() {
      this.$store.commit(JURNAL + READ, { data: [], fieldInfoList: [] });
      this.$store.commit(SET_MAIN_SORT_ENTITY, {});
      this.$store.commit(SET_MAIN_SEARCH_ENTITY, "");
      this.$store.commit(SET_MAIN_PAGINATION_PER_PAGE, GET_MEMORY_PER_PAGE());
      this.$store.commit(SET_MAIN_PAGINATION_PAGE, 1);
      this.getData();
    },
    setSelect(id) {
      this.selectData.dataImportId = id;
      this.selectData.DataChangedId = null;
    },
    filterData(val) {
      this.selectData.dataImportId = null;
      this.selectData.DataChangedId = null;
      this.filterform = val;
      this.initData();
    }
  },
  created() {
    this.initData();
  }
};
</script>
