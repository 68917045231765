import { NAME_ACTION, LIST_EQUIVALENT } from "../../../RoleConstanans";

export const getters = {
  // метод дает информацию об возможности действий пользователя
  /**
   *  name  это название сушности
   *  action это действие
   *  oldName страое название скоро уберу
   */
  //TODO убрать oldName
  isActionAccess: state => (
    name,
    action = NAME_ACTION.isCreate,
    oldName = null,
    oldAction = null) => {
    const UserRoleConfig = state.userRoleData;
    name = LIST_EQUIVALENT[name] ? LIST_EQUIVALENT[name] : name;
    if (UserRoleConfig) {
      if (UserRoleConfig[name]) {
        if (action in NAME_ACTION) {
          return UserRoleConfig[name][action];
        }
      }
    }
    if (oldName && oldAction) { 
      const OldConfig = state.config;
      if (OldConfig[oldName] && oldAction in OldConfig[oldName]) {
        return OldConfig[oldName][oldAction];
      }
    }
    return true;
  },
  userRoleLouded: state => state.userRoleData
};
