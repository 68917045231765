<template>
  <div class="copyright">
    &copy; <a href="https://webinnovations.ru/">2021-2022 webinnovations.ru</a>
  </div>
</template>
<script>
    export default {
        name: 'TheCopyright'
    }
</script>
