import { API_METHODS } from "../../constant";
export const commertialPolicyApi = (
  _getAll,
  _get,
  _create,
  _update,
  _delete,
  paramsIn
) => {
  return {
    [API_METHODS.GET_FINANCIAL_CONDITION]: () => {
      let urlReq = `/FinancialCondition/${paramsIn.id}`;
      return _get(urlReq);
      
    },
    [API_METHODS.GET_ALL_FINANCIAL_CONDITION]: () => {
      let urlReq = `/FinancialCondition`;
      return _getAll(urlReq);
      
    },
    [API_METHODS.UPDATE_FINANCIAL_CONDITION]: () => {
      let urlReq = `/FinancialCondition`;
      return _update(urlReq);
      
    },
    [API_METHODS.CREATE_FINANCIAL_CONDITION]: () => {
      let urlReq = `/FinancialCondition`;
      return _create(urlReq);
      
    },
    [API_METHODS.DELETE_FINANCIAL_CONDITION]: () => {
      let urlReq = `/FinancialCondition/${paramsIn.id}`;
      return _delete(urlReq);
      
    }

  }
}
