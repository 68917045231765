
import {NAME_OBJECT} from "../../../../RoleConstanans";
import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue";
import HrFeedFieldsTable from "./HrFeedFieldsTable.vue"
import EditPageHrFeedFields from "./EditPageHrFeedFields.vue"

export default {
    path: "/HrFeedFields",
    component: DashboardLayout,
    children: [
        {
            path: "/",
            name: 'HrFeedFieldsTable',
            component: HrFeedFieldsTable,
          
        },
        {
            path: ":id/edit",
            name: 'EditPageHrFeedFieldsEdit',
            component: EditPageHrFeedFields,
           
        },
        {
            path: ":id/show",
            name: 'EditPageHrFeedFieldsShow',
            component: EditPageHrFeedFields,
           
        },
        {
            path: "create",
            name: 'EditPageHrFeedFieldsCreate',
            component: EditPageHrFeedFields,
        
        },
    
     
    ]
}