<template>
  <drop-down>
   <span class="el-dropdown-link" slot="title" >
     <i class="lang_icon" :class="`${lang}_icon`"></i>
     <b style="text-transform: uppercase">{{lang}}</b>
  </span>
    <span class="dropdown-item langbtn"  @click="setLang(l.code)"
       v-for="(l, ind) in langs" :key="ind">{{$t(l.code)}}</span>
  </drop-down>
</template>
<script>
  import {mapState, mapActions} from "vuex";
  import {CHANGE_LOCAL} from "../../store/type";

  export default {
    name: 'LangBtn',
    computed: {
      ...mapState({
        langs: state => state.common.langs,
        lang: state => state.common.lang
      })
    },
    methods: {
      ...mapActions({
        setLang: CHANGE_LOCAL
      })
    }
  }
</script>
<style>
  .el-dropdown-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .lang_icon {
    margin-right: 20px;
  }
</style>
