import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue";

const AgentTablePage = () =>
    import ("./AgentTablePage.vue");
const AgentEditPageDynamic = () =>
    import ("./AgentEditPageDynamic.vue");

const META_AGENT = {
    typeCompany: 'agent'
}

export default {
    path: "/company",
    component: DashboardLayout,
    children: [
        {
            path: "agent",
            name: "AgentTablePage",
            component: AgentTablePage
        },
        {
            path: "agent/:CompanyId/edit",
            name: "AgentEdit",
            component: AgentEditPageDynamic,
            meta: META_AGENT
        },
        {
            path: "agent/:CompanyId/show",
            name: "AgentShow",
            component: AgentEditPageDynamic,
            meta: META_AGENT
        },
        {
            path: "agent/create",
            name: "AgentCreate",
            component: AgentEditPageDynamic
        }
    ]
};