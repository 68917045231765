//@depricate
import { READ } from "../../../../store/type";
import {
    HISTORY_CODE,
    HISTORY_CODE_ENUM,
    HISTORY_PERSON,
    RETURN_CODE,
    RETURN_CODE_ENUM,
    RETURN_PERSON,
    HISTORY_CONTACT,
    RETURN_CONTACT,
    HISTORY_BONUS_CARD,
    HISTORY_DOCUMENT,
    RETURN_BONUS_CARD,
    RETURN_DOCUMENT,
    HISTORY_COMPANY,
    RETURN_COMPANY
} from "./type";

import { api } from "../../../../util/api";
import { API_METHODS } from "../../../../constant";

export const actions = {
    [HISTORY_CODE + READ]: async({ state, rootState, commit },
        paramsIn = null
    ) => {
        try {
            const res = await api(API_METHODS.GET_CODE_HISTORY, paramsIn, rootState);
            commit(HISTORY_CODE + READ, res);
            return res;
        } catch (err) {
            return err;
        }
    },
    [HISTORY_CODE_ENUM + READ]: async({ state, rootState, commit },
        paramsIn = null
    ) => {
        try {
            const res = await api(
                API_METHODS.GET_CODE_ENUM_HISTORY,
                paramsIn,
                rootState
            );
            commit(HISTORY_CODE + READ, res);
            return res;
        } catch (err) {
            return err;
        }
    },

    [HISTORY_PERSON + READ]: async({ state, rootState, commit },
        paramsIn = null
    ) => {
        try {
            const res = await api(
                API_METHODS.PERSON_GET_HISTORY,
                paramsIn,
                rootState
            );
            commit(HISTORY_CODE + READ, res);
            return res;
        } catch (err) {
            return err;
        }
    },
    [HISTORY_DOCUMENT + READ]: async({ state, rootState, commit },
        paramsIn = null
    ) => {
        try {
            const res = await api(
                API_METHODS.DOCUMENT_GET_HISTORY,
                paramsIn,
                rootState
            );
            commit(HISTORY_CODE + READ, res);
            return res;
        } catch (err) {
            return err;
        }
    },
    [HISTORY_BONUS_CARD + READ]: async({ state, rootState, commit },
        paramsIn = null
    ) => {
        try {
            const res = await api(
                API_METHODS.BONUS_CARD_GET_HISTORY,
                paramsIn,
                rootState
            );
            commit(HISTORY_CODE + READ, res);
            return res;
        } catch (err) {
            return err;
        }
    },

    [RETURN_CODE]: async({ rootState }, paramsIn = null) => {
        try {
            return await api(API_METHODS.RETURN_CODE, paramsIn, rootState);
        } catch (err) {
            return err;
        }
    },

    [RETURN_CODE_ENUM]: async({ rootState }, paramsIn = null) => {
        try {
            return await api(API_METHODS.RETURN_CODE_ENUM, paramsIn, rootState);
        } catch (err) {
            return err;
        }
    },

    [RETURN_PERSON]: async({ rootState }, paramsIn = null) => {
        try {
            return await api(API_METHODS.RETURN_PERSON, paramsIn, rootState);
        } catch (err) {
            return err;
        }
    },
    [HISTORY_CONTACT + READ]: async({ state, rootState, commit },
        paramsIn = null
    ) => {
        try {
            const res = await api(
                API_METHODS.GET_CONTACT_HISTORY,
                paramsIn,
                rootState
            );
            commit(HISTORY_CODE + READ, res);
            return res;
        } catch (err) {
            return err;
        }
    },
    [RETURN_CONTACT]: async({ rootState }, paramsIn = null) => {
        try {
            return await api(API_METHODS.RETURN_CONTACT, paramsIn, rootState);
        } catch (err) {
            return err;
        }
    },
    [RETURN_DOCUMENT]: async({ rootState }, paramsIn = null) => {
        try {
            return await api(API_METHODS.RETURN_DOCUMENT, paramsIn, rootState);
        } catch (err) {
            return err;
        }
    },
    [RETURN_BONUS_CARD]: async({ rootState }, paramsIn = null) => {
        try {
            return await api(API_METHODS.RETURN_BONUS_CARD, paramsIn, rootState);
        } catch (err) {
            return err;
        }
    },

    [HISTORY_COMPANY + READ]: async({ state, rootState, commit },
        paramsIn = null
    ) => {
        try {
            const res = await api(
                API_METHODS.COMPANY_GET_HISTORY,
                paramsIn,
                rootState
            );
            commit(HISTORY_CODE + READ, res);
            return res;
        } catch (err) {
            return err;
        }
    },


    [RETURN_COMPANY]: async({ rootState }, paramsIn = null) => {
        try {
            return await api(API_METHODS.RETURN_COMPANY, paramsIn, rootState);
        } catch (err) {
            return err;
        }
    }
};