import { actions } from "./actions";
import { mutations } from "./mutations";
import { TYPE_IMPORT_FILE } from "../../../../constant"
const state = {
    data: {},
    fieldInfoList: [],
    fieldTable: {},
    tabnow: TYPE_IMPORT_FILE.COMPANY
};

export default {
    state,
    actions,
    mutations
};