import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
const state = {
  status: "",
  profile: {}
};

export default {
  state,
  getters,
  actions,
  mutations
};
