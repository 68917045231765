<template>
  <common-page-table
    :apiName="apiName"
    :nameRole="nameRoleEntity"
    :titleTable="$t('Атрибуты')"
    :paramsGetData="paramsGetData"
    :fieldLand="fieldLang"
    :keyTable="'id'"
    :deleteParams="deleteParams"
    :apiDeleteName="apiDeleteName"
    :alternativeConverter="convertData"
    :isDefaultConverter="false"
    :prefixEdit="prefixEdit"
    :prefixShow="prefixShow"
    :linkCreate="linkCreate"
    :hasReadOnlyInTable="hasReadOnlyInTable"
    :nameTableSaveInCashe="nameTableSaveInCashe"
  />
</template>
<script>
import { NAME_OBJECT } from "../../../../RoleConstanans";
import CommonPageTable from "../../commonElement/CommonPageTable.vue";
import { API_METHODS } from "../../../../constant";
import formatData from "../../../../helpers/converters/formatData";
import convertLangDataToTable from "../../../../helpers/converters/convertLangDataToTable";
export default {
  name: "AttrValueTable",
  components: { CommonPageTable },
  props:{
      isCompanyType: {type: Boolean, default: true},
      hasReadOnlyInTable: { type: Boolean, default: true }, 
      routeField: {type: String, default: "CompanyId"},
      prefixEditIn: { type: String },
  },
  data() {
    return {
      fieldLang: "",
      apiName: API_METHODS.GET_ALL_ATTR_VALUE,
      apiDeleteName: API_METHODS.DELETE_ATTR_VALUE
    };
  },
  computed: {
    paramsGetData() {
      return { SubjectId: this.$route.params[this.routeField] };
    },
    prefixEdit() {
      return `${this.prefixEditIn}/attrvalue`;
    },
    prefixShow() {
      return this.prefixEdit;
    },
    linkCreate() {
      return this.prefixEdit + "/create";
    },
    nameRoleEntity(){
      const nameRoute = this.$route.name;
      let role = NAME_OBJECT.attrValue;
      switch (nameRoute) {
        case "AgentCreatePageContact":
        case "AgentShow":
        case "AgentEdit": {
          role = NAME_OBJECT.AttrAgency;
          break;
        }
        case "ClientCreatePageContact":
        case "ClientShow":
        case "ClientEdit": {
          role = NAME_OBJECT.AttrClient;
          break;
        }
      }
      return role;
    },
        nameTableSaveInCashe(){
      const nameRoute = this.$route.name;
      switch (nameRoute) {
        case "AgentCreatePageContact": {
          return "AgentCreatePageContactAttr"+"--"+this.$route.path
        }
        case "AgentShow": {
          return "AgentShowAttr"+"--"+this.$route.path
        }
        case "AgentEdit": {
          return "AgentEditAttr"+"--"+this.$route.path
        }
        case "ClientCreatePageContact":  {
          return "ClientCreatePageContactAttr"+"--"+this.$route.path
        }
        case "ClientShow": {
          return "ClientShowAttr"+"--"+this.$route.path
        }
        case "ClientEdit": {
          return "ClientEditAttr"+"--"+this.$route.path
        }
        case "CorpPolityShow":  {
          return "CorpPolityShowAttr" +"--"+this.$route.path
        }
      }
      return this.nameRoleEntity +"--"+this.$route.path
    },
  },
  methods: {
    convertData(dataNow, langNow) {
      let res = convertLangDataToTable(dataNow, langNow, '', false)
      return res;
    },
    deleteParams(id) {
      return {
        SubjectId: this.$route.params[this.routeField],  
        id: id,
      };
    },
  }
};
</script>

