import { CREATE, READ, UPDATE, DELETE, SET_MAIN_PAGINATION_TOTAL, SEARCH } from '../../../../store/type'
import { ENTITY_NAME, POLITY_RULE, POLITY_CONTACT } from "./type";
import { api } from "../../../../util/api";
import { API_METHODS } from "../../../../constant";

export const actions = {
    [ENTITY_NAME + READ]: async({ state, rootState, commit }, paramsIn = null) => {
        try {
            if (paramsIn.id) {
                return await api(API_METHODS.GET_POLICY, paramsIn, rootState)
            } else {
                const res = await api(API_METHODS.GET_ALL_POLICY, paramsIn, rootState)
                commit(ENTITY_NAME + READ, {
                    data: res.object,
                    fieldInfoList: res.fieldInfoList
                })
                commit(SET_MAIN_PAGINATION_TOTAL, {
                    countPage: res.countPage,
                })
                return res
            }
        } catch (err) {
            return err
        }
    },

    [POLITY_CONTACT + READ]: async({ state, rootState, commit }, paramsIn = null) => {
        try {
            const res = await api(API_METHODS.GET_ALL_CHILD_TYPE_CONTACT, paramsIn, rootState)
            commit(ENTITY_NAME + READ, {
                data: res.object,
                fieldInfoList: res.fieldInfoList
            })
            commit(SET_MAIN_PAGINATION_TOTAL, {
                countPage: res.countPage,
            })
            return res
        } catch (err) {
            return err
        }
    },
    [POLITY_CONTACT + DELETE]: async({}, paramsIn = null) => {
        try {
            return await api(API_METHODS.DELETE_CHILD_TYPE_CONTACT, paramsIn)
        } catch (err) {
            return err
        }
    },



    [ENTITY_NAME + UPDATE]: async({ commit }, paramsIn = null) => {
        try {
            return await api(API_METHODS.UPDATE_POLICY, paramsIn)
        } catch (err) {
            return err
        }
    },
    [ENTITY_NAME + CREATE]: async({ commit }, paramsIn = null) => {
        try {
            return await api(API_METHODS.CREATE_POLICY, paramsIn)
        } catch (err) {
            return err
        }
    },

    [ENTITY_NAME + DELETE]: async({}, paramsIn = null) => {
        try {
            return await api(API_METHODS.DELETE_POLICY, paramsIn)
        } catch (err) {
            return err
        }
    },


    [POLITY_RULE + READ]: async({ state, rootState, commit }, paramsIn = null) => {
        try {
            if (paramsIn.id) {
                return await api(API_METHODS.GET_POLICY_RULE, paramsIn, rootState)
            } else {
                const res = await api(API_METHODS.GET_ALL_POLICY_RULE, paramsIn, rootState)
                commit(ENTITY_NAME + READ, {
                    data: res.object,
                    fieldInfoList: res.fieldInfoList
                })
                commit(SET_MAIN_PAGINATION_TOTAL, {
                    countPage: res.countPage,
                })
                return res
            }
        } catch (err) {
            return err
        }
    },
    [POLITY_RULE + UPDATE]: async({ commit }, paramsIn = null) => {
        try {
            return await api(API_METHODS.UPDATE_POLICY_RULE, paramsIn)
        } catch (err) {
            return err
        }
    },
    [POLITY_RULE + CREATE]: async({ commit }, paramsIn = null) => {
        try {
            return await api(API_METHODS.CREATE_POLICY_RULE, paramsIn)
        } catch (err) {
            return err
        }
    },

    [POLITY_RULE + DELETE]: async({}, paramsIn = null) => {
        try {
            return await api(API_METHODS.DELETE_POLICY_RULE, paramsIn)
        } catch (err) {
            return err
        }
    }
};