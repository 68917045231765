<template>
  <sidebar-item :link="{ name: $t('CrmCorp'), icon: 'nc-icon nc-istanbul' }" v-if="showMenu(NAME_OBJECT.CrmCorp)">
    <sidebar-item class="noCapital"
      :link="{ name: $t('CrmCorpContragent'), path: `/crm_corp/contragent` }"
    ></sidebar-item>
    <sidebar-item class="noCapital"
      :link="{ name: $t('Соглашения'), path: `/crm_corp/Agreements` }"
    ></sidebar-item>
  </sidebar-item>
</template>
<script>
import {NAME_OBJECT, NAME_ACTION} from "../../../../RoleConstanans";
export default {
  name: "CrmCorpMenu",
  data() {
    return {
      NAME_OBJECT
    };
  },  
  methods: {
    showMenu(name) {
          let isActionAccess  = this.$store.getters.isActionAccess
          return isActionAccess(name, NAME_ACTION.isMenu, name, 'showMenu'  )
      },
  }
};
</script>
