<template>
  <div :class="generateClass">
    <label>&nbsp;</label>
    <div class="form-group has-label">
      <div class="el-input" >
    <checkbox v-model="form[config.fieldName]" :name="config.name" :disabled="readonly" >{{config.label}}</checkbox>
      </div>
    </div>
  </div>
</template>
<script>
  import {Checkbox} from 'src/components/index'
  import {generateClass} from  '../../../../mixins/baseComponets/generateClass'
  export default {
    name: 'BaseCheckbox',
    mixins: [generateClass],
    components: {
      Checkbox
    },
    props: {
      config: {type: Object, required: true},
      form: {type: Object}
    }
  }
</script>
