import {
  CREATE,
  READ,
  UPDATE,
  DELETE,
  SET_MAIN_PAGINATION_TOTAL,
  SEARCH
} from "../../../../store/type";
import {
  ENTITY_NAME,
  CONTRACT_CONNECT,
  BUDGET_CODE,
  CONTRACT_CONNETCT_CODE,
  CONTRACT_UNCONNECT_CODE,
  STRUCTORE_CODE_CONTRACT,
  CONTRACT_BUDGET_CODE_VALUE,
  DELETE_CODE_CONTRACT
} from "./type";
import HttpClient from "../../../../util/HttpClient";
import { api } from "../../../../util/api";
import { API_METHODS } from "../../../../constant";

export const actions = {
  [ENTITY_NAME + READ]: async (
    { state, rootState, commit },
    paramsIn = null
  ) => {
    try {
      if (paramsIn.id) {
        return await api(API_METHODS.GET_CONTRACT, paramsIn, rootState);
      } else {
        const res = await api(
          API_METHODS.GET_ALL_CONTRACT,
          paramsIn,
          rootState
        );
        commit(ENTITY_NAME + READ, {
          data: res.object,
          countPage: res.countPage,
          fieldInfoList: res.fieldInfoList
        });
        commit(SET_MAIN_PAGINATION_TOTAL, {
          countPage: res.countPage
        });
        return res;
      }
    } catch (err) {
      return err;
    }
  },
  [ENTITY_NAME + UPDATE]: async ({ commit }, paramsIn = null) => {
    try {
      return await api(API_METHODS.UPDATE_CONTRACT, paramsIn);
    } catch (err) {
      return err;
    }
  },

  [ENTITY_NAME + DELETE]: async ({}, paramsIn = null) => {
    try {
      return await api(API_METHODS.DELETE_CONTRACT, paramsIn);
    } catch (err) {
      return err;
    }
  },

  [CONTRACT_CONNECT]: async ({}, paramsIn = null) => {
    try {
      return await api(API_METHODS.CREATE_CHILD_TYPE_CONTACT, paramsIn);
    } catch (err) {
      return err;
    }
  },

  [BUDGET_CODE + READ]: async (
    { state, rootState, commit },
    paramsIn = null
  ) => {
    try {
      return await api(API_METHODS.CONTRACT_CODE_VALUE, paramsIn, rootState);
    } catch (err) {
      return err;
    }
  },

  /**
   * @param paramsIn = {self, companyId, codeId}
   * @returns {Promise.<*>}
   *
   */
  [CONTRACT_CONNETCT_CODE]: async ({ rootState }, paramsIn) => {
    try {
      return await api(API_METHODS.CONTRACT_CONNECT_CODE, paramsIn, rootState);
    } catch (err) {
      return err;
    }
  },
  /**
   * @param paramsIn = {self, companyId, codeId}
   * @returns {Promise.<*>}
   */
  [CONTRACT_UNCONNECT_CODE]: async ({ rootState }, paramsIn) => {
    try {
      return await api(
        API_METHODS.CONTRACT_UNCONNECT_CODE,
        paramsIn,
        rootState
      );
    } catch (err) {
      return err;
    }
  },

  //------------

  [STRUCTORE_CODE_CONTRACT + READ]: async (
    { state, rootState, commit },
    paramsIn = null
  ) => {
    try {
      return await api(
        API_METHODS.GET_STRUCTORE_CODE_CONTRACT,
        paramsIn,
        rootState
      );
    } catch (err) {
      return err;
    }
  },
 // --------------- code value ----------------
    [CONTRACT_BUDGET_CODE_VALUE+READ]: async (
      { state, rootState, commit },
      paramsIn = null
    ) => {
      try {
        return await api(
          API_METHODS.GET_CONTRACT_BUDGET_CODE_VALUE,
          paramsIn,
          rootState
        );
      } catch (err) {
        return err;
      }
    },
    [CONTRACT_BUDGET_CODE_VALUE + UPDATE]: async ({ commit ,rootState}, paramsIn = null) => {
      try {
        return await api(API_METHODS.UPDATE_CREATE_CONTRACT_BUDGET_CODE_VALUE, paramsIn, rootState);
      } catch (err) {
        return err;
      }
    },
    [CONTRACT_BUDGET_CODE_VALUE + DELETE]: async ({rootState}, paramsIn = null) => {
      try {
        return await api(API_METHODS.DELETE_CONTRACT_BUDGET_CODE_VALUE, paramsIn, rootState);
      } catch (err) {
        return err;
      }
    },
   // удаление кода 

   [DELETE_CODE_CONTRACT]: async ({}, paramsIn = null) => {
    try {
      return await api(API_METHODS.DELETE_CODE_CONTRACT, paramsIn);
    } catch (err) {
      return err;
    }
  },







};
