<template>
  <div class="www">
    <SheduleDatePicker @set-shedule-date="setSheduleDate" />
    <common-page-table
      v-if="lastUpdate"
      :apiName="apiName"
      :nameRole="nameRoleEntity"
      :titleTable="$t('Расписание')"
      :fieldLand="fieldLang"
      :keyTable="'flightId'"
      :deleteParams="deleteParams"
      :apiDeleteName="apiDeleteName"
      :alternativeConverter="convertData"
      :isDefaultConverter="false"
      :forceDelete="false"
      :forceIsCreate="false"
      :lastUpdate="lastUpdate"
    />
    <SheduleData />
  </div>
</template>
<script>
import { NAME_OBJECT } from "../../../../RoleConstanans";
import CommonPageTable from "../../commonElement/CommonPageTable.vue";
import { API_METHODS, FIELD_LANG_COMPANY } from "../../../../constant";
import convertLangDataToTable from "../../../../helpers/converters/convertLangDataToTable";
import SheduleData from "./SheduleData.vue";
import SheduleDatePicker from "./SheduleDatePicker.vue";
export default {
  name: "SheduleTablePage",
  components: { CommonPageTable, SheduleData, SheduleDatePicker },
  data() {
    return {
      fieldLang: FIELD_LANG_COMPANY,
      apiName: API_METHODS.GET_ALL_SHEDULE,
      apiDeleteName: API_METHODS.DELETE_SHEDULE,
      lastUpdate: null,
    };
  },
  computed: {
    paramsGetData() {
      return { typeCompany: "Client" };
    },
    nameRoleEntity() {
      return NAME_OBJECT.clients;
    },
  },
  methods: {
    convertData(dataNow, langNow) {
      let res = convertLangDataToTable(dataNow, langNow, FIELD_LANG_COMPANY);
      return res;
    },
    deleteParams(id) {
      return { id: id };
    },
    setSheduleDate(date) {
      this.lastUpdate = date
    },
  },
};
</script>

