<template>
  <el-dialog
    center
    :title="$t('Детальная информация')"
    :visible.sync="modal[nameModal]"
  >
  
    <el-table
      stripe
      style="width: 100%;"
      :data="tableData"
      border
      :empty-text="$t('Нет данных!')"
    >
      <el-table-column prop="fieldname" :label="$t('Поле')" width="180">
      </el-table-column>
      <el-table-column
        class-name="infoSize"
        prop="data"
        :label="$t('Информация')"
      >
       <template slot-scope="props">
<pre v-if="(props.row.fieldname == 'Данные' ||  props.row.fieldname == 'Data')">{{props.row.data}}</pre>
<span v-else>{{props.row.data}}</span>
    
                </template>
      </el-table-column>
 

    </el-table>

    <span slot="footer" class="dialog-footer">
      
        <button class="btn btn-info" @click="copy()">
      {{ $t("Скопировать") }}
    </button>
      <button class="btn btn-success ml-1" @click="toggleModal()">
        {{ $t("Закрыть!") }}
      </button>
    </span>
  </el-dialog>
</template>
<script>
import { HISTORY_CODE, RETURN_CODE } from "./store/type";
import { Dialog } from "element-ui";
import { Table, TableColumn } from "element-ui";
import clearReactive from "../../../helpers/clearReactive";
export default {
  components: {
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  props: {
    modal: { type: Object, required: true },
    nameModal: { default: "details" },
    dataShow: { type: Object, required: true },
    fieldList: { required: true },
    toggleModal: { type: Function }
  },
  computed: {
    tableData() {
      let copy = clearReactive(this.dataShow);
      copy = Object.entries(copy);
      let fieldList = this.fieldList  
      copy = copy.map(item => {
        let findLabel = fieldList? fieldList.find(el=>  el.code == item[0] ) : null 
  
        return { fieldname: findLabel?findLabel.name:[item[0]], data: item[1] };
      });
      return copy;
    }
  },
  methods: {
    copy() {
          let copy = clearReactive(this.dataShow);
          
      navigator.clipboard
        .writeText( copy)
        .then(() => {
          // Получилось!
        })
        .catch(err => {
          console.log("Something went wrong", err);
        });
    }
  }
};
</script>

