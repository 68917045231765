import { render, staticRenderFns } from "./CrmCorpContragentEditPageDynamic.vue?vue&type=template&id=2882a626"
import script from "./CrmCorpContragentEditPageDynamic.vue?vue&type=script&lang=js"
export * from "./CrmCorpContragentEditPageDynamic.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports