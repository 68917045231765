<template>
  <div class="row" v-if="configData">
    <component 
      :form=form 
      :is="cd.type" 
      :config="cd" 
      v-for="(cd, index) in configData" 
      :key="fieldNameAsKey?cd.fieldName:index" :isCreateForm="isCreateForm" 
      :pageMode="pageMode"  
      :readonly="readolyMethod(cd)" 
      :languageCode="languageCode">
    </component>
  </div>
</template>
<script>
  import BaseInput from "./BaseComponets/BaseInput.vue";
  import BaseTextArea from "./BaseComponets/BaseTextArea.vue";
  import BaseInfo from "./BaseComponets/BaseInfo.vue";
  import {TYPE_COMPONETS} from '@/helpers/generatorForm/constants';
  import BaseInputPrice from "./BaseComponets/BaseInputPrice.vue";
  import BaseInputNumber from "./BaseComponets/BaseInputNumber.vue";
  import BaseSimpleSelect from "./BaseComponets/BaseSimpleSelect.vue";
  import BaseCheckbox from "./BaseComponets/BaseCheckbox.vue";
  import BaseSpace from "./BaseComponets/BaseSpace.vue";
  import BaseSeparator from "./BaseComponets/BaseSeparator.vue";
  import BaseRadio from "./BaseComponets/BaseRadio.vue";
  import BaseDate from "./BaseComponets/BaseDate.vue";
  import BaseLangEntityBlock from "./BaseComponets/BaseLangEntityBlock"
  import BaseVSelect from './BaseComponets/BaseVSelect.vue'
  import BaseVSelectComplex from './BaseComponets/BaseVSelectComplex.vue'
  import BaseVSelectComplexMulti from "./BaseComponets/BaseVSelectComplexMulti"
  import BaseInputMask from "./BaseComponets/BaseInputMask.vue"
  import { pageMode } from '../../../mixins/editPage/pageMode';
  import { PAGE_MODE } from '../../../constant';
  export default {
    name: 'DynamicGeneratorForm',
    components: {
      [TYPE_COMPONETS.INPUT.nameC]:BaseInput,
      [TYPE_COMPONETS.TEXTAREA.nameC]:BaseTextArea,
      [TYPE_COMPONETS.INFO.nameC]:BaseInfo,
      [TYPE_COMPONETS.INPUT_PRICE.nameC]:BaseInputPrice,
      [TYPE_COMPONETS.INPUT_NUMBER.nameC]:BaseInputNumber,
      [TYPE_COMPONETS.SELECT.nameC]:BaseSimpleSelect,
      [TYPE_COMPONETS.CHECKBOX.nameC]:BaseCheckbox,
      [TYPE_COMPONETS.SPACE.nameC]:BaseSpace,
      [TYPE_COMPONETS.SEPARATOR.nameC]:BaseSeparator,
      [TYPE_COMPONETS.RADIO.nameC]:BaseRadio,
      [TYPE_COMPONETS.INPUT_DATE.nameC]:BaseDate,
      [TYPE_COMPONETS.ENTITY_LANG_BLOCK.nameC]:BaseLangEntityBlock,
      [TYPE_COMPONETS.VSELECT.nameC]:BaseVSelect,
      [TYPE_COMPONETS.V_SELECT_COMPLEX.nameC]:BaseVSelectComplex,
      [TYPE_COMPONETS.INPUT_MASK.nameC]: BaseInputMask,
      [TYPE_COMPONETS.MULTI_SELECT.nameC] : BaseVSelectComplexMulti
    },
    props: {
      configData: {type: Array, required: true},
      form: {type: Object, required: true},
      languageCode: {type: String},
      isCreateForm:{type: Boolean, default: false},
      pageMode:  {type: String, required: true},
      fieldNameAsKey: {type: Boolean, default: false}
    },
    methods: {
      readolyMethod (component) {
         if (this.pageMode == PAGE_MODE.CREATE && component.isReadOnlyAdd ) {
           return component.isReadOnlyAdd
         }
         if (this.pageMode == PAGE_MODE.EDIT && component.isReadOnlyEdit ) {
           return component.isReadOnlyEdit
         }
          if (this.pageMode == PAGE_MODE.SHOW  ) {
           return true
         }
          return false
      }
    }

  }
</script>