import ConnectDirectoryEditPageDynamic from "./ConnectDirectoryEditPageDynamic.vue"
import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue"

export default {
  path: "/receiver-system",
  component: DashboardLayout,
  children: [
  {
    path: ":ReceiverSystemId/ModuleNames/:id/edit",
    name: "ConnectDirectoryEdit",
    component: ConnectDirectoryEditPageDynamic,
  },
  {
    path: ":ReceiverSystemId/ModuleNames/:id/delete",
    name: "ConnectDirectoryDelete",
    component: ConnectDirectoryEditPageDynamic,
  },
  {
    path: ":ReceiverSystemId/ModuleNames/:id/show",
    name: "ConnectDirectoryShow",
    component: ConnectDirectoryEditPageDynamic,
  },
  {   
    path: ":ReceiverSystemId/ModuleNames/create",
    name: "ConnectDirectoryCreate",
    component: ConnectDirectoryEditPageDynamic
  },
  ]
}



