import { API_METHODS } from "../../constant";
export const crmCorpApi = (
  _getAll,
  _get,
  _update,
  _create,
  _delete,
  paramsIn
) => {
  return {
    // [API_METHODS.GET_ADDRES]: () => {
    //   let urlReq = `/${paramsIn.SubjectId}/Address/${paramsIn.id}`;
    //   return _get(urlReq);
    // },
    [API_METHODS.GET_ALL_CRM_CORP_CONTRAGENT]: () => {
      let urlReq = `/Counterparties/Corp`;
      return _getAll(urlReq);
    },
    // [API_METHODS.UPDATE_ADDRES]: () => {
    //   let urlReq = `/${paramsIn.SubjectId}/Address`;
    //   return _update(urlReq);
    // },
    // [API_METHODS.CREATE_ADDRES]: () => {
    //   let urlReq = `/${paramsIn.SubjectId}/Address`;
    //   return _create(urlReq);
    // },
    // [API_METHODS.DELETE_ADDRES]: () => {
    //   let urlReq = `/${paramsIn.SubjectId}/Address/${paramsIn.id}`;
    //   return _delete(urlReq);
    // }


  };
};
