<template>
  <div>
    <sidebar-item :link="{name: $t('Агентства'),  path: `/company/agent`, icon: 'vip_icon icon_agency'}">
    </sidebar-item>
  </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>