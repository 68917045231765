<template>
  <div>
    <date-picker
      v-model="date"
      type="datetime"
      format="dd-MM-yyyy HH:mm"
      :picker-options="pickerOptions"
      value-format="yyyy-MM-dd HH:mm"
      :placeholder="$t('Выберите дату последнего обновления')"
      name="shedule"
      class="shedule__date"
    >
    </date-picker>
  </div>
</template>

<script>
import { DatePicker } from "element-ui";

export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: this.$t("Сегодня"),
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: this.$t("Вчера"),
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: this.$t("Неделю назад"),
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      date: "",
    };
  },
  mounted() {
    if (localStorage.sheduleDate) {
      if (localStorage.sheduleDate === 'null') {
        localStorage.removeItem("sheduleDate");
        return
      }
      this.date = localStorage.sheduleDate;
    }
  },
  watch: {
    date(newValue) {
      if (newValue == null) {
        localStorage.removeItem("sheduleDate");
      }
      localStorage.sheduleDate = newValue;
      this.$emit("shedule", newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.shedule__date {
  max-width: 400px;
}
</style>
