<template>
  <div class="row">
    <load-block :load="load"></load-block>
    <div class="col-12">
      <card :title="titleTable">
        <div>
          <div class="col-sm-12">
            <el-table 
                      stripe 
                      @sort-change="setSort" 
                      style="width: 100%;"
                      :data="convertData" 
                      border 
                      v-if="tableColumns"
                      :empty-text="$t('Нет данных!')"
                      row-key="historyId"
                      :tree-props="{children: fieldChildren, hasChildren: 'hasChildren'}">
              >
              <el-table-column v-for="column in tableColumns"
                               :key="column.label" :min-width="column.minWidth"  :prop="column.prop"
                               :label="column.label">


              </el-table-column>
            </el-table>
          </div>
        </div>
        <div slot="footer" class="col-12">
          <the-paginator
           :paramsPaginator="paramsPaginator"
            @set-perpage="$emit('set-perPage', $event)"
            @set-page="$emit('set-page', $event)"
           />
        
        </div>
      </card>
    </div>
  </div>
</template>
<script>
  import {Table, TableColumn} from 'element-ui';
  import {Loading} from 'element-ui';

  import clearReactive from '../../../helpers/clearReactive'
  import LoadBlock from "../../shared/LoadBlock.vue";
  import swal from 'sweetalert2'
  import PaginationBlock from "../../shared/theTablePage/PaginationBlock.vue";

  import formatData from '../../../helpers/converters/formatData'
import ThePaginator from './tableElement/ThePaginator.vue';

  export default {
    name: "TableHistoryNew",
    components: {
      PaginationBlock,
      LoadBlock,
        ThePaginator,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    props: {
      titleTable: {type: String, default: ''},

      tableData: {required: true},

      fieldId: {type: String, default: 'refEntityId'},

      updateData: {type: Function, required: true},

      searchStart: {type: Function, required: true},
      sortData: {type: Object},
      setSort: {type: Function},

      fieldInfoList: {type: Array, required: true},

      load: {type: Boolean, default: false},
      backData: {type: Function},
      fieldChildren: {type: String, default: 'historyAttrDTOList'},
      configName: {type: String, required: true},
      paramsPaginator: { type: Object, required: true },
    },
    data() {
    return {
      perPageOptions: [1, 5, 10, 20, 50],
      ribbon: false,
    };
  },
    computed: {
      convertData() {
        let tableData = clearReactive(this.tableData)
        return tableData ? tableData.map(el => {
          const convData = formatData(el.dateCreated, 'DD.MM.YYYY HH:mm:ss')
          return {...el, showBtn: true, dateCreated: convData};
        }) : []
      },
      tableColumns() {
        let fieldInfoList = this.fieldInfoList
        let tableColumns = fieldInfoList ? fieldInfoList.map(el => {
          return {
            prop: el.code,
            label: el.name,
            minWidth: el.sizePercent + '%',
          }
        }) : []
        return tableColumns
      },
    },
    methods: {
      resetData(val) {
        swal({
          title: this.$t('Вы уверены?'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: this.$t('Да удалить!'),
          cancelButtonText: this.$t('Закрыть'),
          buttonsStyling: false
        }).then((res) => {
          if (res.value && res.value == true) {
            this.backData(val)
          }
        })
      }
    },
  }
</script>
