// VISA PERSON
import EditPageVisa from "./personVisa/EditPageVisa"
export default [
        {
            path: 'contract/:ContractId/person/:PersonId/visa/create',
            name: 'CreateVisa',
            component: EditPageVisa
        },
        {
            path: 'contract/:ContractId/person/:PersonId/visa/:DocumentId/edit',
            name: 'EditVisa',
            component: EditPageVisa
        },
        {
            path: 'contract/:ContractId/person/:PersonId/visa/:DocumentId/show',
            name: 'ShowVisa',
            component: EditPageVisa
        }       
    ]
