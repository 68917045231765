<template>
  <div class="row">
    <div class="col-6">
      <label class="label_main">
        <div class="form-group has-label">
          <label>{{ $t("Выберите файл (*.xls ,*.json):") }}</label>
          <div class="el-input">
            <input
              v-if="!fileName"
              type="file"
              :id="name"
              :name="name"
              class="inpL"
              @change="previewFiles"
            />
            <div class="form-control def_file">
              {{ fileName }}
              <span class="close clearFile" @click="clearFile">
                <i class="fa fa-times"></i>
              </span>
            </div>
          </div>
        </div>
      </label>
    </div>
    <div class="col-6 buttons">
      <l-button
        type="success"
        class="send_button"
        :disabled="!file"
        @click="loadFile('check')"
      >
        {{ $t("Проверить") }}
      </l-button>
      &nbsp;&nbsp;или&nbsp;&nbsp;
      <l-button
        type="success"
        class="send_button"
        :disabled="!file"
        @click="loadFile('process')"
      >
        {{ $t("Загрузить") }}
      </l-button>
    </div>
  </div>
</template>
<script>
import { TYPE_ERRORS, API_METHODS } from "../../../../constant";
import notify from "../../../../helpers/notify/notify";
import { ApiService } from "../../../../util/ApiService";
export default {
  name: "InputAttachFile",
  props: {
    name: { type: String },
  },
  data() {
    return {
      file: null,
      fileName: null,
      method: null,
    };
  },
  computed: {
    apiMethod() {
    
      if (this.method === "check") {
        return API_METHODS.HR_CHECK_FILE;
      }
      if (this.method === "process") {
        return API_METHODS.HR_LOAD_FILE;
      }
    },
  },
  methods: {
    previewFiles(event) {
      this.file = event.target.files[0];
      this.fileName = this.file.name;
    },
    clearFile(event) {
      event.preventDefault();
      this.file = null;
      this.fileName = "";
    },
    async loadFile(method) {
      this.load = true;
      this.method = method
      const apiMethod =  this.apiMethod
      const paramsIn = {
        self: this,
        uploadedFile: this.file,
        SubjectId: this.$route.params.CompanyId,
      };
      try {
        let res = await ApiService(apiMethod, {
          ...paramsIn,
        });

        if (res && res.warnings && res.warnings.length > 0) {
          this.$emit("treatWarnings", res.warnings, this.fileName);
        }
        if (res && res.errors && res.errors.length > 0) {
          this.$emit("treatErrors", res.errors, this.fileName);
        }
        if (
          res &&
          res.errors &&
          res.errors.length === 0 &&
          res.warnings &&
          res.warnings.length === 0
        ) {
          notify(this, this.$t("Успешно"), TYPE_ERRORS.SUCCESS);
        }
        this.file = null;
        this.fileName = null;
      } catch (err) {
        console.error(err.name);
        console.error(err.message);
      } finally {
        this.load = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.send_button {
  height: 40px;
  width: 120px;
  margin: 0;
}
.buttons {
  display: flex;
  align-items: center;
}
.clearFile {
  right: 10px;
    top: 6px;
}
</style>
