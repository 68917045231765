import { actions } from "./actions";
import { mutations } from "./mutations";
const state = {
    data: {},
    fieldInfoList: [],
};

export default {
    state,
    actions,
    mutations
};