import AgreementsTable from "./AgreementsTablePage"
import AgreementsEditPageDynamic from "./AgreementsEditPageDynamic.vue"
import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue"

export default {
  path: "/crm_corp",
  component: DashboardLayout,
  children: [{
    path: "Agreements",
    name: "AgreementsTable2",
    component: AgreementsTable
  },
  {
    path: "Agreements/:CompanyId/edit",
    name: "AgreementsEdit2",
    component: AgreementsEditPageDynamic,
  },
  {
    path: "Agreements/:CompanyId/delete",
    name: "AgreementsDelete2",
    component: AgreementsEditPageDynamic,
  },
  {
    path: "Agreements/:CompanyId/show",
    name: "AgreementsShow2",
    component: AgreementsEditPageDynamic,
  },
  {
    path: "Agreements/create",
    name: "AgreementsCreate2",
    component: AgreementsEditPageDynamic
  },
  ]
}
