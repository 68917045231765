var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[(_vm.ribbon)?_c('div',{staticClass:"cover-ribbon"},[_c('div',{staticClass:"cover-ribbon-inside",attrs:{"title":"новый код"}},[_vm._v("new")])]):_vm._e(),_c('load-block',{attrs:{"load":_vm.load}}),_c('div',{staticClass:"col-12 mintableH"},[_c('card',{class:{ noTitle: _vm.noTitle },attrs:{"title":_vm.titleTable}},[_vm._t("default"),_c('the-search-element',{attrs:{"paramsPaginator":_vm.paramsPaginator,"linkCreate":_vm.linkCreate,"forceIsSearch":_vm.forceIsSearch,"forceIsCreate":_vm.forceIsCreate &&
          (_vm.readOnlyIsActive ? _vm.hasReadOnlyInTable : true) &&
          (_vm.configRole ? _vm.configRole.create : _vm.isCreate)},on:{"set-search":function($event){return _vm.$emit('set-search', $event)}}}),_c('div',[_c('div',{staticClass:"col-sm-12"},[(_vm.tableColumns)?_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"row-class-name":() => 'padding',"empty-text":_vm.$t('Нет данных!')},on:{"sort-change":_vm.beforeSetSort}},[_vm._l((_vm.tableColumns),function(column){return _c('el-table-column',{key:column.label,attrs:{"min-width":column.minWidth,"sortable":_vm.sortable,"prop":column.prop,"label":column.label,"class-name":_vm.hasFieldInData(column.prop) ? '' : 'errortable'},scopedSlots:_vm._u([{key:"default",fn:function(props){return [[_vm._v(" "+_vm._s(column.prop in props.row ? props.row[column.prop] : _vm.$t("ОШИБКА! Данные для поля не найдены"))+" ")]]}}],null,true)})}),(_vm.hasAction)?_c('el-table-column',{staticClass:"noHiddenText",attrs:{"min-width":180,"width":_vm.wAction,"lineHeight":_vm.lineHeight,"fixed":"right","align":"right","label":_vm.$t(_vm.textAction),"class-name":"noHidden"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(_vm.isDopLinkPluse)?_c('router-link',{staticClass:"btn-success btn-simple btn-link tableBtn mr-2",attrs:{"to":_vm.customDopLink(props.row)}},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t(_vm.plusLinkTitle)),expression:"$t(plusLinkTitle)"}],staticClass:"table_icon icon-plus"})]):_vm._e(),(
                    _vm.forceIsShow && (_vm.configRole ? _vm.configRole.show : _vm.isShow)
                  )?_c('router-link',{staticClass:"btn-info btn-simple btn-link tableBtn mr-2",attrs:{"to":`${_vm.prefixShow ? _vm.prefixShow : _vm.$route.path}/${
                    props.row[_vm.fieldId]
                  }/show`}},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('Посмотреть')),expression:"$t('Посмотреть')"}],staticClass:"table_icon icon_eye"})]):_vm._e(),(
                    _vm.forceIsEdit &&
                    _vm.hasReadOnlyInTableRow(props.row) &&
                    (_vm.configRole ? _vm.configRole.edit : _vm.isEdit)
                  )?_c('router-link',{staticClass:"btn-warning btn-simple btn-link tableBtn mr-2",attrs:{"to":`${_vm.prefixEdit ? _vm.prefixEdit : _vm.$route.path}/${
                    props.row[_vm.fieldId]
                  }/edit`}},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('Редактировать')),expression:"$t('Редактировать')"}],staticClass:"table_icon icon_edit"})]):_vm._e(),(
                    _vm.forceDelete &&
                    _vm.hasReadOnlyInTableRow(props.row) &&
                    (_vm.configRole ? _vm.configRole.delete : _vm.isDelete)
                  )?_c('a',{staticClass:"btn-danger btn-simple btn-link tableBtn",on:{"click":function($event){return _vm.handleDelete(props.row[_vm.fieldId], props.row)}}},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('Удалить')),expression:"$t('Удалить')"}],staticClass:"table_icon icon_delete"})]):_vm._e()]}}],null,false,2710310194)}):_vm._e()],2):_vm._e()],1)]),_c('div',{staticClass:"col-12",attrs:{"slot":"footer"},slot:"footer"},[_c('the-paginator',{attrs:{"paramsPaginator":_vm.paramsPaginator},on:{"set-perpage":function($event){return _vm.$emit('set-perPage', $event)},"set-page":function($event){return _vm.$emit('set-page', $event)}}})],1)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }