import { NAME_OBJECT } from "../../../../RoleConstanans";
import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue";
import EditPageAddresMain from "./EditPageAddresMain.vue";
const META_AGENT_ADDRESS = {
    typeCompany: "agent",
    fieldRoute: "CompanyId",
    configName: "CompanyAddress",
    roleName: NAME_OBJECT.CompanyAddress,
    routeNameShow: "AgentShowPageAddress",
    routeNameCreate: "AgentCreatePageAddress",
    routeNameEdit: "AgentEditPageAddress"
};
const META_SUPPLIER_ADDRESS = {
    typeCompany: "supplier",
    fieldRoute: "CompanyId",
    configName: "CompanyAddress",
    roleName: NAME_OBJECT.CompanyAddress,
    routeNameShow: "SupplierShowPageAddress",
    routeNameCreate: "SupplierCreatePageAddress",
    routeNameEdit: "SupplierEditPageAddress"
};
const META_CLIENT_ADDRESS = {
    typeCompany: "client",
    fieldRoute: "CompanyId",
    configName: "CompanyAddress",
    roleName: NAME_OBJECT.CompanyAddress,
    routeNameShow: "ClientShowPageAddress",
    routeNameCreate: "ClientCreatePageAddress",
    routeNameEdit: "ClientEditPageAddress"
};

export const addressRoute2 = [
    {
        path: "client/:CompanyId/address/:ContactId/edit",
        name: META_CLIENT_ADDRESS.routeNameEdit,
        component: EditPageAddresMain,
        meta: META_CLIENT_ADDRESS
    },
    {
        path: "client/:CompanyId/address/:ContactId/show",
        name: META_CLIENT_ADDRESS.routeNameShow,
        component: EditPageAddresMain,
        meta: META_CLIENT_ADDRESS
    },
    {
        path: "client/:CompanyId/address/create",
        name: META_CLIENT_ADDRESS.routeNameCreate,
        component: EditPageAddresMain,
        meta: META_CLIENT_ADDRESS
    },

    {
        path: "supplier/:CompanyId/address/:ContactId/edit",
        name: META_SUPPLIER_ADDRESS.routeNameEdit,
        component: EditPageAddresMain,
        meta: META_SUPPLIER_ADDRESS
    },
    {
        path: "supplier/:CompanyId/address/:ContactId/show",
        name: META_SUPPLIER_ADDRESS.routeNameShow,
        component: EditPageAddresMain,
        meta: META_SUPPLIER_ADDRESS
    },
    {
        path: "supplier/:CompanyId/address/create",
        name: META_SUPPLIER_ADDRESS.routeNameCreate,
        component: EditPageAddresMain,
        meta: META_SUPPLIER_ADDRESS
    },

    {
        path: "agent/:CompanyId/address/:ContactId/edit",
        name: META_AGENT_ADDRESS.routeNameEdit,
        component: EditPageAddresMain,
        meta: META_AGENT_ADDRESS
    },
    {
        path: "agent/:CompanyId/address/:ContactId/show",
        name: META_AGENT_ADDRESS.routeNameShow,
        component: EditPageAddresMain,
        meta: META_AGENT_ADDRESS
    },
    {
        path: "agent/:CompanyId/address/create",
        name: META_AGENT_ADDRESS.routeNameCreate,
        component: EditPageAddresMain,
        meta: META_AGENT_ADDRESS
    },
]


export default {
    path: "/company",
    component: DashboardLayout,
    
    children: addressRoute2 
    }