import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue'
const AdminPage = () =>
    import ('./AdminPage.vue')
import EditPageAdminManager from "./EditPageAdminManager.vue"
export default {
    path: '/adminmanager',
    component: DashboardLayout,
    children: [{
            path: '/',
            name: 'AdminPage',
            component: AdminPage
        },
        {
            path: ':systemId/create',
            name: 'CreateRouteAdmin',
            component: EditPageAdminManager
        },
        {
            path: ':systemId/:esbRouteId/show',
            name: 'ShowRouteAdmin',
            component: EditPageAdminManager
        },
        {
            path: ':systemId/:esbRouteId/edit',
            name: 'EditRouteAdmin',
            component: EditPageAdminManager
        }
    ]
}