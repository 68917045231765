import { actions } from "./actions";
import { mutations } from "./mutations";
const state = {
    data: {},
    fieldInfoList: [],
    filterList: null,

    dataProcess: [],
    fieldInfoListProcess: [],

    dataOut: [],
    fieldInfoListOut: []

};

export default {
    state,
    actions,
    mutations
};