<template>
  <auth-layout pageClass="login-page">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-5 col-md-6 col-sm-8">
        <form @submit.prevent="login">
          <load-block :load="load"></load-block>
          <fade-render-transition>
            <card>
              <template slot="header">
                <img class="login-page_logo" src="/static/img/logo-eng_letters.svg" alt="">
              </template>
              <div>
                <h3 class="login-text">{{ $t("Логин")}}</h3>
                <fg-input
                  :placeholder="$t('Введите почту')"
                  type="email"
                  v-model="model.email"
                ></fg-input>
                <fg-input
                  type="password"
                  :placeholder="$t('Введите пароль')"
                  v-model="model.password"
                ></fg-input>
              </div>
              <div class="text-center">
                <button
                  type="submit"
                  class="btn btn-block btn-login"
                >
                  {{ $t("Войти") }}
                </button>
              </div>
            </card>
          </fade-render-transition>
        </form>
      </div>
    </div>
  </auth-layout>
</template>
<script>
import {
  Checkbox as LCheckbox,
  FadeRenderTransition
} from "src/components/index";
import AuthLayout from "./AuthLayout.vue";
import * as type from "../../../store/type";
import LoadBlock from "../../../components/shared/LoadBlock";
import Radio from '../../../components/shared/Inputs/Radio.vue';
export default {
  components: {
    LCheckbox,
    FadeRenderTransition,
    AuthLayout,
    LoadBlock,
    Radio
  },
  data() {
    return {
      model: {
        email: "",
        password: "",
        subscribe: true
      },
      load: false
    };
  },
  methods: {
    login() {
      this.load = true;
      this.$store
        .dispatch("AUTH_REQUEST", {
          self: this,
          email: this.model.email,
          password: this.model.password
        })
        .then(async res => {
          if (res.object !== null) {
            try {
              await this.$store.dispatch(type.AUTH_ME, { self: this });
              await this.$store.dispatch(type.LOAD_LOGIN_USER_ROLE, {
                self: this
              });
              this.load = false;
              this.$router.push({ path: "/" });
            } catch (e) {
              this.load = false;
              console.error(e);
            }
          }
        })
        .catch(() => {
          this.load = false;
        });
    },
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      try {
        document.body.classList.remove("nav-open");
        document.body.classList.remove("off-canvas-sidebar");
      } catch (e) {
        console.error("ошибка визуальная 2!! ");
      }
    }
  },
  beforeDestroy() {
    this.closeMenu();
  }
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
.login-text {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 30px;
}
.form-check{
  margin-bottom: 20px;
}
</style>
