import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user/user";
import auth from "./modules/auth/auth";
import common from "./modules/common/common"
import pagination from "./modules/pagination/pagination"
import entity from '../components/modules/entity/store/store'
import company from '../components/modules/company/store/store'
import person from '../components/modules/person/store/store'
import code from '../components/modules/code/store/store'
import contract from '../components/modules/contract/store/store'
import history from '../components/modules/history/store/store'
import users from '../components/modules/user/store/store'
import corpPolity from '../components/modules/corpPolicy/store/store'
import commercialTerms from '../components/modules/commercialTerms/store/store'
import contact from "../components/modules/contact/store/store"
import address from "../components/modules/address/store/store"
// import attrentity from "../components/modules/attrEntity/store/store"
import attrvalue from "../components/modules/attrValue/store/store"
import personCodeValue from "../components/modules/personCodeValue/store/store"
import importFile from "../components/modules/importData/store/store"
import bus from "../components/modules/bus/store/store"
import adminmanager from "../components/modules/adminManager/store/store"
import creditcard from "../components/modules/creditCard/store/store"
import eventlog from "../components/modules/eventLog/store/store"
import setting from "../components/modules/setting/store/store"
import travelers from "../components/modules/travelers/store/store"
import sabreRemark from "../components/modules/sabreRemark/store/store"
Vue.use(Vuex);
const debug = process.env.NODE_ENV !== "production";
export default new Vuex.Store({
    modules: {
        user,
        auth,
        common,
        entity,
        pagination,
        company,
        person,
        code,
        contract,
        history,
        users,
        corpPolity,
        commercialTerms,
        contact,
        address,
        // attrentity,
        attrvalue,
        personCodeValue,
        importFile,
        bus,
        adminmanager,
        creditcard,
        eventlog,
        setting,
        travelers,
        sabreRemark
    },
    strict: debug
});