<template>
  <card v-if="$route.params.CounterpartyId">
    <el-tabs :tab-position="tabPosition" v-model="nowTab" @before-leave="beforeLeave" @tab-click="beforeLeave">
      <el-tab-pane
        :label="$t('Соглашения')"
        name="agreementsVal"
      >
        <AgreementsCounterpartyPage
          v-if="nowTab == 'agreementsVal'"
          routeField="CounterpartyId"
        />
      </el-tab-pane>
    </el-tabs>
  </card>
</template>
<script>
import { Tabs, TabPane } from "element-ui";

const SET_TAB_CONTRACT = "SET_TAB_CONTRACT";

import AgreementsCounterpartyPage from "../agreements/AgreementsCounterpartyPage";

import clearReactive from "../../../../helpers/clearReactive"
import { NAME_OBJECT, NAME_ACTION } from "../../../../RoleConstanans";

import { API_METHODS } from '../../../../constant';
export default {
  name: "CounterpartyData",
  components: {
    ElTabs: Tabs,
    ElTabPane: TabPane,
    AgreementsCounterpartyPage
  },
  props: {
    loadDataIn: { type: Object },
    activeShowMenu: { type: Boolean, default: false }
  },
  created(){
    this.loadData = clearReactive(this.loadDataIn)
  },
  data() {
    return {
      tabPosition: "top",
      loadData: null,
      nameObject: NAME_OBJECT,
      apiMethodDeleteInStructurCode:  API_METHODS.DELETE_CODE_CONTRACT
    };
  },
  computed: {
    nowTab: {
      set(tab) {
        this.$store.commit(SET_TAB_CONTRACT, tab);
      },
      get() {
        return this.$store.state.contract.tabnow;
      },
    },
  },
  methods: {
    beforeLeave(){
      this.$store.commit("CLEAR_TABLE_PAGINATOR", { delUrl: this.$route.path });
    },
    async deleteElCustom(codeId) {
      const paramsIn = {
        self: this,
        CodeId: codeId,
      };
      try {
        let res = await this.$store.dispatch(DELETE_CODE_CONTRACT, paramsIn);
        return res;
      } catch (err) {
        console.error(err);
        return false;
      }
    },
    showMenu(name) {
      if (this.activeShowMenu) {
        let isActionAccess = this.$store.getters.isActionAccess;
        return isActionAccess(name, NAME_ACTION.isMenu, name, "showMenu");
      }
      return true;
    },
    deleteParamsInStuctureCode(codeId){
      return { CodeId: codeId }
    },
  },
};
</script>
