export const generateClass = {
    props: {
        readonly: { type: Boolean, required: true, default: false },
    },
    computed: {
        generateClass() {
            let res = ''
            const { colsXS, colsSM, colsMD, colsLG, OffsetXS, OffsetSM, OffsetMD, OffsetLG, addClass } = this.config
            res = `col-xs-${colsXS} col-sm-${colsSM} col-md-${colsMD} col-lg-${colsLG}`
            res += ` offset-xs-${OffsetXS} offset-sm-${OffsetSM} offset-md-${OffsetMD} offset-ls-${OffsetLG}`
            res += ` ${addClass}`
            return res
        }
    }
}