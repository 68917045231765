<template>
  <div> 
  <common-page-table
    :apiName="apiName"
    :nameRole="nameRoleEntity"
    :titleTable="'HR'"
    :fieldLand="fieldLang"
    :keyTable="'id'"
    :deleteParams="deleteParams"
    :apiDeleteName="apiDeleteName"
    :alternativeConverter="convertData"
    :isDefaultConverter="false"
   
    :forceIsCreate="false"
    :forceDelete="false"
    :paramsGetData="paramsGetData"
   
    :prefixEdit="`/company/client/${$route.params.CompanyId}/hr`"
    :prefixShow="`/company/client/${$route.params.CompanyId}/hr`"
  />
   <SettingHrFeed />
</div>


</template>
<script>





import { NAME_OBJECT } from "../../../../RoleConstanans";
import CommonPageTable from "../../commonElement/CommonPageTable.vue";
import { API_METHODS, FIELD_LANG_COMPANY, GLOBAL_DATE_FORMAT} from "../../../../constant";
import convertLangDataToTable from "../../../../helpers/converters/convertLangDataToTable";
import SettingHrFeed from "./SettingHrFeed.vue"
export default {
  name: "HrFeedLogTablePage",
  components: { CommonPageTable, SettingHrFeed },
  props:{
      routeField: {type: String, default: "CompanyId"},
     
  },
  data() {
    return {
      fieldLang: "",
      apiName: API_METHODS.GET_ALL_HR,
      apiDeleteName: ""
    };
  },
  computed: {
    paramsGetData() {
      return { SubjectId: this.$route.params[this.routeField] };
    },
    nameRoleEntity() {
      return NAME_OBJECT.hrFeed
    }
  },
  methods: {
    convertData(dataNow, langNow) {
      let res = convertLangDataToTable(dataNow, langNow, "", false)
//2019-10-01T00:00:00
      res = res.map(el => {
        return {
          ...el,
          // dateStart: this.$moment(el.dateStart).format(
          //   GLOBAL_DATE_FORMAT
          // ),
          //  dateEnd: this.$moment(el.dateEnd).format(
          //   GLOBAL_DATE_FORMAT
          // )
        };
      });
      return res;


      return res;
    },
    deleteParams(id) {
      return { id: id };
    },
  }
};
</script>

