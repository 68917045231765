import {
  API_METHODS
} from "../../constant";
export const counterpartyApi = (
  _getAll,
  _get,
  _update,
  _create,
  _delete,
  paramsIn
) => {
  return {
    [API_METHODS.GET_COUNTERPARTY]: () => {
      let urlReq = `/Counterparties/${paramsIn.id}`;
      return _get(urlReq);
    },
    [API_METHODS.GET_ALL_COUNTERPARTIES]: () => {
      let urlReq = "/Counterparties";
      return _getAll(urlReq);
    },
    [API_METHODS.UPDATE_COUNTERPARTY]: () => {
      let urlReq = "/Counterparties";
      return _update(urlReq);
    },
    [API_METHODS.CREATE_COUNTERPARTY]: () => {
      let urlReq = "/Counterparties";
      return _create(urlReq);
    },
    [API_METHODS.DELETE_COUNTERPARTY]: () => {
      let urlReq = `/Counterparties/${paramsIn.id}`;
      return _delete(urlReq);
    },

    [API_METHODS.GET_ALL_COUNTERPARTY_COMMON] : ()=> {
      let urlReq = '/Counterparties'
      return _getAll(urlReq, paramsIn.dopParams?paramsIn.dopParams:{});
    }


  };
};
