export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_ERROR = 'AUTH_ERROR'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_REGISTRATION = 'AUTH_REGISTRATION'
export const AUTH_ME = 'AUTH_ME'
export const AUTH_TOKEN = 'AUTH_TOKEN'
export const AUTH_ONLY_ME = 'AUTH_ONLY_ME'

export const USER_REQUEST = 'USER_REQUEST'
export const USER_SUCCESS = 'USER_SUCCESS'
export const USER_ERROR = 'USER_ERROR'
export const SET_USER = 'SET_USER'
export const EDIT_PROFILE = "EDIT_PROFILE"

export const SHOW_MAIN_MENU = 'SHOW_MAIN_MENU'
export const CHANGE_LOCAL = 'CHANGE_LOCAL'

export const AUTH_SET = 'AUTH_SET'

//CRUD

export const CREATE = 'CREATE'
export const READ = 'READ'
export const UPDATE = 'UPDATE'
export const DELETE = 'DELETE'
export const SEARCH = 'SEARCH'

//FORM_UPDATE

export const FORM_UPDATE = 'FORM_UPDATE'

//PAGINATION
export const SET_MAIN_PAGINATION = 'SET_MAIN_PAGINATION'
export const SET_MAIN_PAGINATION_PER_PAGE = 'SET_MAIN_PAGINATION_PER_PAGE'
export const SET_MAIN_PAGINATION_PAGE = 'SET_MAIN_PAGINATION_PAGE'
export const SET_MAIN_SEARCH_ENTITY = 'SET_MAIN_SEARCH_ENTITY'
export const SET_MAIN_SORT_ENTITY = 'SET_MAIN_SORT_ENTITY'
export const SET_MAIN_PAGINATION_TOTAL = 'SET_MAIN_PAGINATION_TOTAL'

//LANG
export const LOAD_LANG = 'LOAD_LANG'

export const SET_ROLE_PARAMS = 'SET_ROLE_PARAMS'
export const CHANGE_DATA_ACCESS = "CHANGE_DATA_ACCESS"


//LOGIN_USER_ROLE

export const LOAD_LOGIN_USER_ROLE = "LOAD_LOGIN_USER_ROLE"
export const RESET_USER_ROLE = "RESET_USER_ROLE"

export const SAVE_TABLE_PAGINATION = "SAVE_TABLE_PAGINATION"
export const SET_ALL_PAGINATION = "SET_ALL_PAGINATION"
export const SET_OLD_PAGINATION = "SET_OLD_PAGINATION"

export const CLEAR_TABLE_PAGINATOR = "CLEAR_TABLE_PAGINATOR"