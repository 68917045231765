import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue'
const UserPage = () =>
    import ('./UserPage.vue')
    const EditPageUser = () =>
    import ('./EditPageUser.vue')
export default {
    path: '/',
    component: DashboardLayout,
    children: [{
            path: 'user',
            name: 'UserPage',
            component: UserPage
        },
        {
            path: 'user/create',
            name: 'CreateUser',
            component: EditPageUser
        },
        {
            path: 'user/:UserId/edit',
            name: 'UserEdit',
            component: EditPageUser
        },
        {
            path: 'user/:UserId/show',
            name: 'UserShow',
            component: EditPageUser
        }
    ]
}