import {TYPE_COMPONETS} from '../constants'
import extendComponents from '../extendComponents'

let select = {
...extendComponents(TYPE_COMPONETS.SELECT),
  relative: null,
  search: false,
  selectLabel: 'label',
  enable: false
}

export default select
