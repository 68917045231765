<template>
  <sidebar-item
    :link="{ name: $t('Справочники'), icon: 'vip_icon icon_faq' }"
    :hasActive="false"
  >
    <sidebar-item
      :link="{ name: list.parent.name }"
      :hasActive="false"
      v-for="(list, index) in listLoadEntity"
      :key="index"
    >
      <sidebar-item
        :link="{ name:  entity.hasTranslate?  $t(entity.nameDisplay): entity.nameDisplay, path: `/entity/${entity.code}`, entity }"
        v-for="(entity, index) in list.list"
        :key="index"
      ></sidebar-item>
    </sidebar-item>
  </sidebar-item>
</template>
<script>
import { mapState } from "vuex";
import { GET_LIST_ENTITY } from "./store/type";
import {NAME_ACTION} from "../../../RoleConstanans"
import clearReactive from "../../../helpers/clearReactive"
export default {
  name: "EntityMenu",
  computed: {
    ...mapState({
      lang: state => state.common.lang,
      listLoadEntityState: state => state.entity.listLoadEntity
    }),
    listLoadEntity() {
      let res  = clearReactive(this.listLoadEntityState)
      res = (res && res.length > 0) ? res.filter(el=>{
        return this.showMenu(el.parent.code)
      }):[]
      let list = res.map(el => {
        if (el.parent && el.parent.code === 'Avia') {
          el.list.push({
            code: "Shedule",
            name: "Расписание",
            nameDisplay: "Расписание",
            customLink: '/shedule'
          })
          return el
        }
        if (el.parent && el.parent.code === 'System') {
          el.list.push({
            code: "HrFeedFields",
            name: "HrFeedFields",
            hasTranslate: true,
            nameDisplay: "HrFeedFields",
            customLink: '/HrFeedFields'
          })
          return el
        }
        return el
      })
      return list
    }
  },
  methods:{
    showMenu(name) {
          let isActionAccess  = this.$store.getters.isActionAccess
          return isActionAccess(name, NAME_ACTION.isMenu)
    },
  },
  watch: {
    async lang() {
      await this.$store.dispatch(GET_LIST_ENTITY, this);
    }
  }
};
</script>
