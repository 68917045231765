import { actions } from "./actions";
import { mutations } from "./mutations";
const state = {
    form: {},
    data: {},
    fieldInfoList: [],
    filters: null,
    listEntity: [],
    fieldTable: {},
    documents: [],
    fieldDocumentList: [],
    contacts: [],
    fieldContactList: [],
    bonusCards: [],
    fieldBonusCardList: []
};

export default {
    state,
    actions,
    mutations
};