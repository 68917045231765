import { READ, FORM_UPDATE } from '../../../../store/type'
import { ENTITY_NAME, DOCUMENT, BONUS_CARD, CONTACT, SET_TAB_PERSON, PREF_DATA, VISA } from "./type";
// import Vue from "vue";

export const mutations = {
    [ENTITY_NAME + READ]: (state, { data, fieldInfoList }) => {
        state.data = data
        state.fieldInfoList = fieldInfoList
    },
    [DOCUMENT + READ]: (state, { data, fieldInfoList }) => {
        state.documents = data
        state.fieldDocumentList = fieldInfoList
    },
    [BONUS_CARD + READ]: (state, { data, fieldInfoList }) => {
        state.bonusCards = data
        state.fieldBonusCardList = fieldInfoList
    },
    [CONTACT + READ]: (state, { data, fieldInfoList }) => {
        state.contacts = data
        state.fieldContactList = fieldInfoList
    },
    [ENTITY_NAME + FORM_UPDATE]: (state, form) => {
        state.form = form
    },
    [SET_TAB_PERSON]: (state, tab) => {
        state.tabnow = tab
    },

    [VISA + READ]: (state, { data, fieldInfoList }) => {
        state.visa = data
        state.fieldVisaList = fieldInfoList
    },
   

    // ------------------------------
    //PREF_DATA
    [PREF_DATA + READ]: (state, { data, fieldInfoList }) => {
        state.prefData = data
        state.fieldprefDataList = fieldInfoList
    },
    //-------------------------------
};