<template>
    <div :class="generateClass" >
      <fg-input
        :label="config.label"
        :data-cy="config.cyData"
        :placeholder="config.placeholder"
        v-model="form[config.fieldName]"
        :readonly="readonly"
        :price="true"
        :name="config.fieldName"
        v-validate.initial="config.validationRule"
        :error="getError(config.fieldName)"
        :data-vv-as="config.label"
      >
      </fg-input>
    </div>
</template>
<script>
  import {generateClass} from  '../../../../mixins/baseComponets/generateClass'
  import {validateExt} from '../../../../mixins/baseComponets/validateExt'
    export default {
        name: 'BaseInputPrice',
        props: {
          config: { type: Object, required: true},
          form: { type: Object }
        },
      mixins: [generateClass, validateExt]
    }
//
</script>
