/**
 * очищает данные от null данных
 * @param val
 * @returns {*}
 */
export default (val) => {
  let res = val
  try {

    Object.keys(res).forEach(key => {
      if (res[key] == null) {
        delete res[key]
      } else {
        if (Array.isArray(res[key])) {
          res[key].forEach(el => {
              Object.keys(el).forEach(_key => {
                if (el[_key] == null) {
                  delete el[_key]
                }
              })
            }
          )
        }
      }
    })
  }
  catch (e){
    console.error('ошибка очистки данных от null ')
  }
  return res
}
