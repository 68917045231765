import { TYPE_ERRORS } from '../../constant'
import notify from '../../helpers/notify/notify'

/**
 * checkValidate
 * @type {{methods: {validateData: (function())}}}
 */
export const checkValidate = {
    methods: {
        validateData() {
            if (this.errors.items.length > 0) {
                notify(this, this.$t('Ошибки валидации'), TYPE_ERRORS.WARNING)
                return false
            }
            return true
        }
    }
}