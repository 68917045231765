<template>
  <div :class="generateClass">
    <p :data-cy="config.cyData"> {{config.placeholder}} </p>
  </div>
</template>
<script>
  import {generateClass} from  '../../../../mixins/baseComponets/generateClass'
  export default {
    name: 'BaseInfo',
    mixins: [generateClass],
    props: {
      config: {type: Object, required: true},
    }
  }
</script>
