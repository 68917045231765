import HrFeedEditPageDynamic from "./HrFeedEditPageDynamic.vue";
import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue";

export default {
  path: "/company/client/",
  component: DashboardLayout,
  children: [
    {
      path: ":CompanyId/hr/:id/show",
      name: "HrFeedEditPageDynamicShow",
      component: HrFeedEditPageDynamic
    },
    {
      path: ":CompanyId/hr/:id/edit",
      name: "HrFeedEditPageDynamicEdit",
      component: HrFeedEditPageDynamic
    },
    {
      path: ":CompanyId/hr/create",
      name: "HrFeedEditPageDynamicCreate",
      component: HrFeedEditPageDynamic
    }
  ]
};
