import { API_METHODS } from "../../constant";
export const personDocApi = (
  _getAll,
  _get,
  _update,
  _create,
  _delete,
  paramsIn
) => {
  return {
    [API_METHODS.GET_DOCUMENT]: () => {
      let urlReq = `/Person/${paramsIn.PersonId}/Document/${paramsIn.id}`;
      return _get(urlReq);
    },
    [API_METHODS.GET_ALL_DOCUMENT]: () => {
      let urlReq = `/Person/${paramsIn.PersonId}/Document`;
      return _getAll(urlReq);
    },
    [API_METHODS.UPDATE_DOCUMENT]: () => {
      let urlReq = `/Person/${paramsIn.PersonId}/Document`;
      return _update(urlReq);
    },
    [API_METHODS.CREATE_DOCUMENT]: () => {
      let urlReq = `/Person/${paramsIn.PersonId}/Document`;
      return _create(urlReq);
    },
    [API_METHODS.DELETE_DOCUMENT]: () => {
      let urlReq = `/Person/${paramsIn.PersonId}/Document/${paramsIn.DocumentId}`;
      return _delete(urlReq);
    }
  };
};
