
<template>
    <div class="container border rounded p-4 mt-4">
    <form @submit.prevent="sendNewValues">
      <h2> {{$t('Настройки полей HR')}} </h2>
    
      <div v-for="field in hrSettingsInfoList" :key="field.code" class="mb-3">
        <div v-if="field.code === 'fileType'" class="form-group">
          <label for="fileType">{{labels.fileType}}</label>
          <v-select
            :options="options"
            :reduce="value => value.code"
            :value="newValues.fileType"
            @input="changeFileType"
            label="value"
          ></v-select>
        </div>
        <div v-else-if="field.code === 'firstLineToParse'"  class="form-group" >
          <label :for="field.code" class="form-label">{{labels[field.code]}}</label>
          <input
            v-model="newValues[field.code]"
            type="text"
            class="form-control"
            :id="field.code"
            :maxlength="field.code === 'splitterChar' && 1"
          >
        </div>
        <div v-else-if="field.code === 'splitterChar'" v-show="newValues.fileType" class="form-group" >
          <label :for="field.code" class="form-label">{{labels[field.code]}} </label>
          <input
            v-model="newValues[field.code]"
            type="text"
            class="form-control"
            :id="field.code"
            :maxlength="field.code === 'splitterChar' && 1"
          >
        </div>
        <div v-else class="form-group" >
          <label :for="field.code" class="form-label">{{labels[field.code]}} </label>
          <input
            v-model="newValues[field.code]"
            type="text"
            class="form-control"
            :id="field.code"
            :maxlength="field.code === 'splitterChar' && 1"
          >
        </div>
      </div>
      <button type="submit" class="btn btn-primary" >{{$t('Применить')}}</button>
    </form>
    </div>
</template>
<script>
import clearReactive from '../../../../helpers/clearReactive'
export default {
  name: "SettingsForm",
  props: {
    hrSettingsInfoList: {},
    hrFieldSettings: {}
  },
  data() {
    return {
      options: [{code: 0, value: 'Excel'}, {code: 1, value: 'Text / CSV'}],
      labels: {
        sourcePath: this.$t('Путь к источнику файлов'), // source Path
        fileType: this.$t('Тип файла'),
        firstLineToParse: this.$t('Номер первой строки данных'),
        splitterChar: this.$t('Символ-разделитель'),
        parseInterval: this.$t('Интервал в сутках между проверками'),
      },
      newValues: {
        sourcePath: null,
        fileType: null,
        firstLineToParse: null,
        splitterChar: null,
        parseInterval: null,
      }
    }
  },
  mounted () {
    this.newValues = clearReactive(this.hrFieldSettings);
  },

  methods: {
    changeFileType(value) {
      console.log(value);
      this.newValues.fileType = value
    },
    sendNewValues() {
      this.$emit('new-settings', {...this.hrFieldSettings, ...this.newValues})
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
