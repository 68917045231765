import {TYPE_COMPONETS} from '../constants'
import extendComponents from '../extendComponents'
let entityLangBlock = {
    ...extendComponents(TYPE_COMPONETS.ENTITY_LANG_BLOCK),
    children: [],
    label: '',
    fieldName: 'refEntityLangDTOList',
    landComponent: true,
    setIn: false,
    showTable: false
}
delete entityLangBlock.validationRule
delete entityLangBlock.errName
delete entityLangBlock.placeholder
delete entityLangBlock.default
export default entityLangBlock
