<template>
  <div :class="generateClass">
    <div class="mb-4"></div>
  </div>
</template>
<script>
  import {generateClass} from  '../../../../mixins/baseComponets/generateClass'
  export default {
    name: 'BaseSpace',
    props: {
      config: {type: Object, required: true},
    },
    mixins: [generateClass]
  }
</script>
