<template>
  <div class="d-flex justify-content-end">
    <div class="perPageText ml-4" v-if="forsePerpage">
        {{ $t("Отображать") }}
        <el-select
        class="select-default selectPerPage"
        v-model="perPage"
        placeholder="Per page"
        >
        <el-option
            class="select-default"
            v-for="item in perPageOptions"
            :key="item"
            :label="item"
            :value="item"
        >
        </el-option>
        </el-select>
        {{ $t("записей") }}
        </div>
  </div>
</template>
<script>
import { Select, Option } from "element-ui";

export default {
  name: "ThePerPage",
  components: {
    [Select.name]: Select,
    [Option.name]: Option
  },
  props: {
    paramsPaginator: {
      type: Object,
      required: true,
      description: "функция измения колчичесва страниц"
    },
    forsePerpage: { type: Boolean, default: true }
  },
  data() {
    return {
      perPageOptions: [1, 5, 10, 25, 50]
    };
  },
  computed: {
    paginator() {
      return this.paramsPaginator
        ? this.paramsPaginator
        : {
            size: 10,
          };
    },
    perPage: {
      get() {
        return this.paginator.size;
      },
      set(val) {
        this.$emit("set-perp", val);
      }
    }
  }
};
</script>
<style scoped>
  [class^=el-icon-] {
    line-height: auto;
    margin-bottom: -2px;
  }
  [class^=el-icon-]::before {
    position: relative;
    top: 1px;
    padding-left: 6px;
  }
  .btn-label {
    padding: 1px 20px;
  }
</style>