import {READ} from '../../../../store/type'
import {ENTITY_NAME, GET_SUBJECT_LINK_USER, DEL_SUBJECT_LINK_EL, ADD_SUBJECT_LINK_EL, CLEAR_DATA_SUBJECT_LINK} from "./type";
import clearReactive from "../../../../helpers/clearReactive"
export const mutations = {
  [ENTITY_NAME + READ]: (state, { data, fieldInfoList}) => {
    state.data = data
    state.fieldInfoList = fieldInfoList
  },
  [GET_SUBJECT_LINK_USER]: (state, { data, fieldInfoList}) => {
    state.dataLink = data
    state.dataLinkMem = data
    state.fieldInfoListLink = fieldInfoList
  },
  [DEL_SUBJECT_LINK_EL]: (state, id) => {
    let nowData =  clearReactive(state.dataLink)
    nowData = nowData.filter(el=>el.entityId !== id)
    state.dataLink = nowData
  },
  [CLEAR_DATA_SUBJECT_LINK]: (state) => { 
    state.dataLink = []
  }
};
