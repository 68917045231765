import { Dialog, MessageBox } from 'element-ui'
import { HISTORY_BONUS_CARD, RETURN_BONUS_CARD } from "../../components/modules/history/store/type";
import TableHistory from "../../components/modules/history/TableHistory.vue";
import { mainMethods } from '../../mixins/history/mainMethods'
import { READ } from "../../store/type";

export const historyModal = {
    components: {
        TableHistory,
        [Dialog.name]: Dialog,
    },
    mixins: [mainMethods],
    data: function() {
        return {
            history: HISTORY_BONUS_CARD,
            return: RETURN_BONUS_CARD,
            paramsGet: {},
            paramsReturn: {}
        }
    },
    methods: {
        getData() {
            this.load = true
            this.$store.dispatch(this.$data.history + READ,
                this.$data.paramsGet).then(res => {
                this.load = false
            })
        }
    }
}