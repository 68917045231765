<template>
  <sidebar-item :link="{name: $t('Шина'),  icon: 'vip_icon icon_agency'}">
     <sidebar-item :link="{name: $t('Лог шины'),  path: `/journal`,  icon: 'nc-icon nc-chart-pie-36'}">
    </sidebar-item>
 <sidebar-item :link="{name: $t('Администрирование'),  path: `/adminmanager`,  icon: 'nc-icon nc-chart-pie-36'}">
</sidebar-item>
   
  </sidebar-item>
</template>
<script>
    export default {
        name: 'CorpPolityMenu',
    }
</script>
