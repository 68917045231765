<template>
  <div>
    <sidebar-item :link="{name: $t('Клиенты/Бренды') , icon: 'vip_icon icon_brand'}">
      <sidebar-item :link="{name: $t('Клиенты'),  path: `/company/client`}" v-if="showMenu(nameObject.clients)"></sidebar-item>
        <sidebar-item :link="{name: $t('Путешественники'),  path: `/travelers`}" v-if="showMenu(nameObject.travelers)"></sidebar-item>
      <sidebar-item :link="{name: $t('Бренды'),  path: `/company/brand`}"  v-if="showMenu(nameObject.brand)"  ></sidebar-item>
    </sidebar-item>
    <slot/>
    <sidebar-item
      :link="{name: $t('Поставщики'),  path: `/company/Supplier`, icon: 'vip_icon icon_suppliers'}"  v-if="showMenu(nameObject.supplier)" 
    ></sidebar-item>
  </div>
</template>
<script>
export default {
  name: "CompanyMenu",
  props: {
    showMenu: {
      type: Function
    },
    nameObject: {
      type: Object
    }
  }
};
</script>
