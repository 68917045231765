<template>
  <div style="position: relative" class="loader" >
    <transition name="fade2">
      <div class="loadWrap" v-if="load || demo">
          <div id="main">
            <span class="spinner"></span>
          </div>
      </div>
    </transition>
  </div>
</template>
<script>
  export default {
    name: 'LoadBlock',
    props: {
      load: {type: Boolean}
    },
    data() {
      return {
        demo: false
      }
    }
  }
</script>
