<template>
  <div :class="generateClass">
    <label>{{ config.label }}</label>
    <div class="form-group has-label">
      <!-- <div @click="validData()"> validData </div> -->
      <div class="el-input">
        <v-select
          v-if="draw"
          v-tooltip.top-center="
            readonly || manualBlock ? '' : $t('Начните поиск')
          "
          :label="config.selectLabel"
          :placeholder="config.placeholder"
          :data-cy="config.cyData"
          :value="formatData(form[config.fieldName])"
          @input="$set(form, config.fieldName, parseData($event))"
          :disabled="readonly || manualBlock"
          :readonly="readonly || manualBlock"
          @search="onSearch"
          :options="option"
          :filterable="false"
          :name="config.fieldName"
          v-validate="config.validationRule"
          :data-vv-as="config.label"
        >
          <span slot="no-options">{{ $t("Результатов не найдено") }}</span>
        </v-select>

        <div
          class="text-danger invalid-feedback"
          style="display: block;"
          v-if="getError(config.fieldName)"
        >
          {{ $t("Обязательное") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Select, Option } from "element-ui";

import { SEARCH, READ } from "../../../../store/type";
import HttpClient from "../../../../util/HttpClient";
import { generateClass } from "../../../../mixins/baseComponets/generateClass";
import { validateExt } from "../../../../mixins/baseComponets/validateExt";
import BaseSimpleSelect from "./BaseSimpleSelect.vue";
import { api } from "../../../../util/api";
import { API_METHODS } from "../../../../constant";
import clearReactive from "../../../../helpers/clearReactive";
export default {
  components: { BaseSimpleSelect },
  name: "BaseVSelectComplex",
  mixins: [generateClass, validateExt],
  props: {
    config: { type: Object, required: true },
    form: { type: Object }
  },
  data() {
    return {
      option: [],
      optionSelect: [],
      keyId: "entityId",
      tempOldDataWathc: null,
      draw: true,
      manualBlock: false
    };
  },
  watch: {
    config() {
      this.init();
    },
    form: {
      deep: true,
      handler: function(val) {
        if ("watchAttr" in this.config && this.config.watchAttr) {
          let newVal = clearReactive(val[this.config.watchAttr]);
          let oldVal = clearReactive(this.tempOldDataWathc);
          if (newVal !== oldVal) {
            this.option = [];
            this.draw = false;
            this.$set(this.form, this.config.fieldName, null);
            // this.form[this.config.fieldName] = null
            this.$nextTick(() => {
              this.draw = true;
              this.validData();
              this.init();
            });
          }
          this.updatedWatchProp();
        }
        setTimeout(() => {
          this.validData();
        }, 100);
      }
    }
  },
  created() {
    this.init();
  },
  methods: {
    updatedWatchProp() {
      if (this.config.watchAttr) {
        this.tempOldDataWathc = clearReactive(this.form[this.config.watchAttr]);
      }
    },
    init() {
      let config = this.config;
      this.updatedWatchProp();
      this.option = [];
      if ("watchAttr" in config && config.watchAttr) {
        if (
          this.form[config.watchAttr] == null ||
          this.form[config.watchAttr] == 0
        ) {
          this.manualBlock = true;
          return;
        }
      }
      this.manualBlock = false;
      if (this.form[config.fieldName]) {
        this.loadData(this.form[config.fieldName]).then(() => {
          setTimeout(() => {
            this.validData();
          }, 100);
        });
      }
      this.loadData();
    },
    formatData(val) {
      let find = this.option.find(el => el[this.keyId] == val);
      return find;
    },
    parseData(val) {
      return val ? val[this.keyId] : null;
    },

    onSearch(search, loading, event) {
      if (search.length < 2) return false;

      loading(true);
      this.search(loading, search, this);
    },
    search(loading, search, vm) {
      vm.loadData(null, search, true)
        .then(() => {
          loading(false);
        })
        .catch(() => {
          loading(false);
        });
    },
    async loadData(id = null, search, isSearch = false) {
      try {
        const config = this.config;
        const params = {
          self: this,
          relative: config.relative,
          id: id,
          query: {
            subListId: this.config.watchAttr
              ? this.form[this.config.watchAttr]
              : config.subListId,
            search: search,
            lang: this.$store.state.common.lang
          }
        };
        const res = await api(API_METHODS.BASE_V_SELECT_LOAD_DATA, params);
        let responseData = res.object;
        if (!("map" in responseData)) {
          responseData = [responseData];
        }
        this.addOptions(responseData, isSearch);
        this.validData();
        return res;
      } catch (err) {
        return err;
      }
    },
    addOptions(result, isSearch) {
      const config = this.config;
      if (isSearch) {
        const findNowSelect = this.form[config.fieldName]
          ? this.option.find(el => {
              return el[this.keyId] == this.form[config.fieldName];
            })
          : null;
        this.option = [];
        if (findNowSelect) {
          this.option.push(findNowSelect);
        }
      }
      result.forEach(item => {
        let find = this.option.find(el => el[this.keyId] == item[this.keyId]);
        if (!find) {
          this.option.push(item);
        }
      });
    },
    validData() {
      this.$validator.validateAll();
    }
  }
};
</script>
