<template>
  <card v-if="$route.params.CompanyId">
    <el-tabs :tab-position="tabPosition" v-model="nowTab" @before-leave="beforeLeave" @tab-click="beforeLeave">
      <el-tab-pane
        :label="$t('Договоры')"
        name="contract"
        v-if="isContract && showMenu(nameObject.contracts)"
      >
        <contract-page
          :hasReadOnlyInTable="loadData ? !loadData.readOnly : true"
          v-if="nowTab == 'contract'"
          :forseNoCreateLink="true"
          isClient
        ></contract-page>
      </el-tab-pane>
      <el-tab-pane
        :label="$t('Контакты')"
        name="contact"
        v-if="
          isContact &&
            showMenu(
              isAgent ? nameObject.ContactAgency : nameObject.ContactClient
            )
        "
      >
        <ContactTable
          v-if="nowTab == 'contact'"
          routeField="CompanyId"
          :hasReadOnlyInTable="loadData ? !loadData.readOnly : true"
        />
      </el-tab-pane>
      <el-tab-pane
        :label="$t('Адреса')"
        name="address"
        v-if="
          isAddress &&
            showMenu(
              isAgent ? nameObject.AddressAgency : nameObject.AddressClient
            )
        "
      >
        <AddresTable
          v-if="nowTab == 'address'"
          routeField="CompanyId"
          :hasReadOnlyInTable="loadData ? !loadData.readOnly : true"
        />
      </el-tab-pane>
      <el-tab-pane
        :label="$t('Атрибуты')"
        name="attrVal"
        v-if="
          isattrVal &&
            showMenu(isAgent ? nameObject.AttrAgency : nameObject.AttrClient)
        "
        :hasReadOnlyInTable="loadData ? !loadData.readOnly : true"
      >
        <AttrValueTable
          :hasReadOnlyInTable="loadData ? !loadData.readOnly : true"
          v-if="nowTab == 'attrVal'"
          routeField="CompanyId"
          :prefixEditIn="
            `/company/${$route.meta.typeCompany}/${$route.params.CompanyId}`
          "
        />
      </el-tab-pane>

      <el-tab-pane
        :label="$t('Услуги поставщика')"
        name="suppServ"
        v-if="isSuppServ"
      >
        <TableSupplierService
          v-if="nowTab == 'suppServ'"
          :hasReadOnlyInTable="loadData ? !loadData.readOnly : true"
        />
      </el-tab-pane>

      <el-tab-pane
        :label="$t('Кредитные карты')"
        name="creditcard"
        v-if="isCreditCard && showMenu(nameObject.CreditCardClient)"
      >
        <CreditCardTable
          v-if="nowTab == 'creditcard'"
          :hasReadOnlyInTable="loadData ? !loadData.readOnly : true"
        />
      </el-tab-pane>
    </el-tabs>
  </card>
</template>
<script>
import ContractPage from "../contract/ContractPage.vue";

import TablePageBudget from "../code/TablePageBudget.vue";
import TablePagePersone from "../code/TablePagePersone.vue";

import { Tabs, TabPane } from "element-ui";
import { COMPANY_UNCONNECT_CODE, SET_TAB_CLIENT } from "./store/type";
import ContactTable from "../contact/ContactTable";
import AddresTable from "../address/AddresTable";
import AttrValueTable from "../attrValue/AttrValueTable";

import TableSupplierService from "./supplierService/TableSupplierService";

import CreditCardTable from "../creditCard/CreditCardTable";
import clearReactive from "../../../helpers/clearReactive";
import { NAME_OBJECT, NAME_ACTION } from "../../../RoleConstanans";
export default {
  name: "CompanyData",
  components: {
    ElTabs: Tabs,
    ElTabPane: TabPane,

    ContractPage,
    TablePageBudget,
    TablePagePersone,
    ContactTable,
    AddresTable,
    AttrValueTable,
    TableSupplierService,
    CreditCardTable
  },
  props: {
    isContract: { type: Boolean, default: true },

    isContact: { type: Boolean, default: true },
    isAddress: { type: Boolean, default: true },
    isSuppServ: { type: Boolean, default: false },
    isattrVal: { type: Boolean, default: true },
    isCreditCard: { type: Boolean, default: false },
    loadDataIn: { type: Object },

    isAgent: { type: Boolean, default: false },
    activeShowMenu: { type: Boolean, default: false }
  },
  data() {
    return {
      loadData: null,
      tabPosition: "top",
      nameObject: NAME_OBJECT
    };
  },
  created() {
    let flag = true;
    this.loadData = clearReactive(this.loadDataIn);
    switch (this.nowTab) {
      case "suppServ": {
        flag = this.isSuppServ;
        break;
      }
      case "attrVal": {
        flag = this.isattrVal;
        break;
      }
      case "address": {
        flag = this.isAddress;
        break;
      }
      case "contact": {
        flag = this.isContact;
        break;
      }
      case "contract": {
        flag = this.isContract;
        break;
      }
      case "creditcard": {
        flag = this.isCreditCard;
        break;
      }
    }
    this.nowTab = flag ? this.nowTab : "contract";
  },
  computed: {
    nowTab: {
      set(tab) {
        this.$store.commit(SET_TAB_CLIENT, tab);
      },
      get() {
        return this.$store.state.company.tabnow;
      }
    }
  },
  methods: {
    beforeLeave(){
      this.$store.commit("CLEAR_TABLE_PAGINATOR", { delUrl: this.$route.path });
    },
    showMenu(name) {
      if (this.activeShowMenu) {
        let isActionAccess = this.$store.getters.isActionAccess;
        return isActionAccess(name, NAME_ACTION.isMenu, name, "showMenu");
      }
      return true;
    }
  }
};
</script>
