<template>
  <the-table-page
    :titleTable="$t('Кредитные карты')"
    :tableData="tableData"
    :load="load"
    fieldId="creditCardId"
    :updateData="updateData"
    :fieldInfoList="fieldInfoList"
    :searchStart="searchStart"
    :sortData="sortData"
    :setSort="setSort"
    :deleteEl="deleteEl"
    :prefixEdit="prefixEdit"
    :prefixShow="prefixShow"
    :linkCreate="linkCreate"
    :configName="configName"
    :roleName="nameRoleEntity"
    :hasReadOnlyInTable="hasReadOnlyInTable" 
  ></the-table-page>
</template>
<script>
import TheTablePage from "../../TheTablePage.vue";
import { CREDIT_CARD } from "./store/type";

import {
  SET_MAIN_PAGINATION,
  SET_MAIN_PAGINATION_PER_PAGE,
  SET_MAIN_PAGINATION_PAGE,
  SET_MAIN_SORT_ENTITY,
  SET_MAIN_SEARCH_ENTITY
} from "../../../../src/store/type";

import { READ, DELETE } from "../../../store/type";
import { mapState } from "vuex";
import NotificationSample from "src/pages/Dashboard/Components/NotificationSample";
import { DEFAULT_PER_PAGE, TYPE_ERRORS , GET_MEMORY_PER_PAGE} from "../../../constant";
import notify from "../../../helpers/notify/notify";
import convertLangDataToTable from "../../../helpers/converters/convertLangDataToTable";
import {NAME_OBJECT} from "../../../RoleConstanans"
export default {
  components: {
    TheTablePage
  },
  props: {
    routeField: { type: String, default: "CompanyId" },
    configName: { type: String, default: "creditcard" }, // скорее всего устарело 
    isPersonMode:{ type: Boolean, default: false },
    hasReadOnlyInTable: { type: Boolean, default: true }, 
  },
  data() {
    return {
      load: false
    };
  },
  computed: {
    ...mapState({
      fieldInfoList: state => state.creditcard.fieldInfoList,
      lang: state => state.common.lang,
      sortData: state => state.pagination.sortData
    }),
     nameRoleEntity(){
        const nameRoute = this.$route.name
        let role = NAME_OBJECT.creditcard
       
        switch (nameRoute) {
          case "ClientCreatePageContact":
          case "ClientShow":
          case "ClientEdit": {
            role =  NAME_OBJECT.CreditCardClient
            break
          }
        }
        
        return role
    },
    tableData() {
      let langNow = this.$store.state.common.lang;
      let dataNow = this.$store.state.creditcard.data || [];
      if (dataNow && dataNow.length > 0) {
        dataNow = convertLangDataToTable(dataNow, langNow, "", false);
         dataNow = dataNow.map(item=>{
              return { ...item, isActive: item.isActive? this.$t('Да'):this.$t('Нет')   }
            })
        return dataNow;
      }
    },
    prefixEdit() {
      const route = this.$route;
      if(this.isPersonMode){
       return  `/contract/${route.params.ContractId}/person/${route.params.PersonId}/creditcard`
      }
      return `/company/${route.meta.typeCompany}/${route.params.CompanyId}/creditcard`;
    },
    prefixShow() {
      return this.prefixEdit;
    },
    linkCreate() {
      return this.prefixEdit + "/create";
    }
  },
  watch: {
    lang() {
      this.initData();
    }
  },
  methods: {
    getData() {
      this.load = true;
      this.$store
        .dispatch(CREDIT_CARD + READ, {
          self: this,
          SubjectId: this.isPersonMode? this.$route.params.PersonId : this.$route.params[this.routeField]
        })
        .then(res => {
          this.load = false;
        })
        .catch(err => {
          this.load = false;
          notify(this, this.$t("Ошибка получения данных"), TYPE_ERRORS.DANGER);
        });
    },
    updateData() {
      this.getData();
    },
    searchStart(search) {
      this.$store.commit(SET_MAIN_SEARCH_ENTITY, search);
      this.getData();
    },
    setSort(val) {
      this.$store.commit(SET_MAIN_SORT_ENTITY, val);
      this.getData();
    },
    deleteEl(id) {
      this.$store
        .dispatch(CREDIT_CARD + DELETE, {
          SubjectId: this.isPersonMode? this.$route.params.PersonId : this.$route.params[this.routeField],
          id: id,
          self: this
        })
        .then(res => {
          this.getData();
           if(res.errors && res.errors.length  == 0) {
              notify(this, this.$t("Успешно"), TYPE_ERRORS.SUCCESS); 
          }
        });
    },
    initData() {
      this.$store.commit(CREDIT_CARD + READ, { data: [], fieldInfoList: [] });
      this.$store.commit(SET_MAIN_SORT_ENTITY, {});
      this.$store.commit(SET_MAIN_SEARCH_ENTITY, "");
      this.$store.commit(SET_MAIN_PAGINATION_PER_PAGE, GET_MEMORY_PER_PAGE());
      this.$store.commit(SET_MAIN_PAGINATION_PAGE, 1);
      this.getData();
    }
  },
  created() {
    this.initData();
  }
};
</script>
