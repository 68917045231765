import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue";

const EditPagePlug = () => import("./plugPage/EditPagePlug.vue");
import EditPageAttrValueMain from "../attrValue/EditPageAttrValueMain.vue";
import { NAME_OBJECT } from "../../../RoleConstanans";
const META_CONTRACT_ATTRVALUE = {
  pathBackStart: "contract",
  fieldRoute: "ContractId",
  configName: "attrValue",
  roleName: NAME_OBJECT.attrValue,
  routeNameShow: "ContractAttrValueShow",
  routeNameCreate: "ContractAttrValueCreate",
  routeNameEdit: "ContractAttrValueEdit"
};

import routeSabreRemark from "./routeSabreRemark";
import EditCodeContractStructure from "./codes/EditCodeContractStructure.vue";
import EditCodeContractBudget from "./codes/EditCodeContractBudget.vue";
import EditBudgetCodeValueContract from "./codes/EditBudgetCodeValueContract.vue";

import EditPageCodeEnum from "../code/codeEnum/EditPageCodeEnum.vue";
import { contractNew } from "../../v2/module/contract/route";
export default {
  path: "/contract",
  component: DashboardLayout,
  children: [
    ...contractNew,
    {
      path: ":ContractId/addPolicy",
      name: "addPolicy",
      component: EditPagePlug
    },
    {
      path: ":ContractId/addFinancialCondition",
      name: "addFinancialCondition",
      component: EditPagePlug
    },
    //ATTRVALUE
    {
      path: ":ContractId/attrvalue/:AttrValueId/edit",
      name: META_CONTRACT_ATTRVALUE.routeNameEdit,
      component: EditPageAttrValueMain,
      meta: META_CONTRACT_ATTRVALUE
    },
    {
      path: ":ContractId/attrvalue/create",
      name: META_CONTRACT_ATTRVALUE.routeNameCreate,
      component: EditPageAttrValueMain,
      meta: META_CONTRACT_ATTRVALUE
    },
    {
      path: ":ContractId/attrvalue/:AttrValueId/show",
      name: META_CONTRACT_ATTRVALUE.routeNameShow,
      component: EditPageAttrValueMain,
      meta: META_CONTRACT_ATTRVALUE
    },

    //  бюджетные коды договора
    {
      path: ":ContractId/ContractCodeBudget/create",
      name: "ClientEditCode",
      component: EditCodeContractBudget
    },
    {
      path: ":ContractId/ContractCodeBudget/:CodeId/edit",
      name: "ClientEditCodeEdit",
      component: EditCodeContractBudget
    },
    {
      path: ":ContractId/ContractCodeBudget/:CodeId/show",
      name: "ClientEditCodeShow",
      component: EditCodeContractBudget
    },

    // создание и редактирование бюджетных кодов договора
    {
      path: ":ContractId/ContractCodeStructor/create",
      name: "ContractStructureCodeCreate",
      component: EditCodeContractStructure
    },
    {
      path: ":ContractId/ContractCodeStructor/:CodeId/edit",
      name: "ContractStructureCodeEdit",
      component: EditCodeContractStructure
    },
    {
      path: ":ContractId/ContractCodeStructor/:CodeId/show",
      name: "ContractStructureCodeShow",
      component: EditCodeContractStructure
    },

    //значение бюджетных кодов
    {
      path:
        ":ContractId/ContractCodeBudget/:CodeId/ContractCodeBudgetValue/update",
      name: "ContractCodeBudgetValueCreate",
      component: EditBudgetCodeValueContract
    },

    // варианты значение бюджетных кодов если перечисление
    {
      path: ":ContractId/ContractCodeBudget/:CodeId/CodeEnum/create",
      name: "ContractCodeBudgetCodeEnumCreate",
      component: EditPageCodeEnum
    },
    {
      path: ":ContractId/ContractCodeBudget/:CodeId/CodeEnum/:CodeEnumId/edit",
      name: "ContractCodeBudgetCodeEnumEdit",
      component: EditPageCodeEnum
    },
    {
      path: ":ContractId/ContractCodeBudget/:CodeId/CodeEnum/:CodeEnumId/show",
      name: "ContractCodeBudgetCodeEnumShow",
      component: EditPageCodeEnum
    },

    // варианты значение структурных кодов если перечисление
    {
      path: ":ContractId/ContractCodeStructor/:CodeId/CodeEnum/create",
      name: "ContractCodeStructorEnumCreate",
      component: EditPageCodeEnum
    },
    {
      path:
        ":ContractId/ContractCodeStructor/:CodeId/CodeEnum/:CodeEnumId/edit",
      name: "ContractCodeStructorEnumEdit",
      component: EditPageCodeEnum
    },
    {
      path:
        ":ContractId/ContractCodeStructor/:CodeId/CodeEnum/:CodeEnumId/show",
      name: "ContractCodeStructorEnumShow",
      component: EditPageCodeEnum
    },

    ...routeSabreRemark
  ]
};
