/**
 * getSchemeComponent
 * на вход принмет краткое описание компонентов
 * и отдает схему компонета для его генерации
 * @param type
 * @param params
 * @returns {{}}
 */
import { TYPE_COMPONETS } from './constants'
import COMPONENT_LIST from './componentsList'
import clearReactive from '../clearReactive'

export default (type, params = null, langFieldName) => {
    let componentsList = clearReactive(COMPONENT_LIST)
    if (params && (params.refEntityCode) && type !== 'MultiSelect') {
        type = 'SelectComplex'
    }
    let typeConvert = {
        'String': TYPE_COMPONETS.INPUT,
        'Int': TYPE_COMPONETS.INPUT_NUMBER,
        'lang': TYPE_COMPONETS.ENTITY_LANG_BLOCK,
        'Date': TYPE_COMPONETS.INPUT_DATE,
        'Boolean': TYPE_COMPONETS.CHECKBOX,
        'SelectComplex': TYPE_COMPONETS.V_SELECT_COMPLEX,
        "Space": TYPE_COMPONETS.SPACE,
        "TextArea": TYPE_COMPONETS.TEXTAREA,
        "MultiSelect":TYPE_COMPONETS.MULTI_SELECT
    }

    let componentType = ((type in typeConvert) && typeConvert[type].nameC) ? typeConvert[type].nameC : typeConvert["Space"].nameC
    let componentScheme = componentsList.find(c => c.type == componentType) || componentsList[0]

    if (type !== 'lang') {
        componentScheme.name = params.name
        componentScheme.fieldName = params.code
        componentScheme.label = params.name
        componentScheme.relative = params.refEntityCode
        componentScheme.SearchType = params.type
        componentScheme.colsXS = params.colWidth
        componentScheme.colsSM = params.colWidth
        componentScheme.colsMD = params.colWidth
        componentScheme.colsLG = params.colWidth
        if ('subListId' in params) {
            componentScheme.subListId = params.subListId
        }

        if (('required' in params) && params.required == true) {
            componentScheme.validationRule = { required: true }
        }
        if (('isReadOnlyAdd' in params)) {
            componentScheme.isReadOnlyAdd = params.isReadOnlyAdd
        }
        if (('isReadOnlyEdit' in params)) {
            componentScheme.isReadOnlyEdit = params.isReadOnlyEdit
        }
        if (('watchAttr' in params)) {
            componentScheme.watchAttr = params.watchAttr
        }
    }

    if (type == 'lang') {
        componentScheme.fieldName = langFieldName
    }

    if (type == 'SelectComplex') {
        componentScheme.selectLabel = 'entityName'
    }


    return componentScheme
}