// PREF_DATA 
import EditPagePrefData from "./prefData/EditPagePrefData"
export default [
        {
            path: 'contract/:ContractId/person/:PersonId/pref/create',
            name: 'CreatePrefData',
            component: EditPagePrefData
        },
        {
            path: 'contract/:ContractId/person/:PersonId/pref/:PrefId/edit',
            name: 'EditPrefData',
            component: EditPagePrefData
        },
        {
            path: 'contract/:ContractId/person/:PersonId/pref/:PrefId/show',
            name: 'ShowPrefData',
            component: EditPagePrefData
        }       
    ]
