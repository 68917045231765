import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue'

import EventLogPage from "./EventLogPage.vue"
export default {
    path: '/',
    component: DashboardLayout,
    children: [{
        path: '/eventlog',
        name: 'eventlog',
        component: EventLogPage
    }, ]
}