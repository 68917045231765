/**
 *
 * @type {{INPUT: {nameC: string, description: string},
 * INPUT_NUMBER: {nameC: string, description: string},
 * INPUT_PRICE: {nameC: string, description: string},
 * INPUT_DATE: {nameC: string, description: string},
 * TEXTAREA: {nameC: string, description: string},
 * REACH_EDITOR: {nameC: string, description: string},
 * INFO: {nameC: string, description: string},
 * SELECT: {nameC: string, description: string},
 * VSELECT: {nameC: string, description: string},
 * MULTI_SELECT: {nameC: string, description: string},
 * CHECKBOX: {nameC: string, description: string},
 * RADIO: {nameC: string, description: string},
 * SEPARATOR: {nameC: string, description: string},
 * SPACE: {nameC: string, description: string},
 * GROUP: {nameC: string, description: string},
 * TAB: {nameC: string, description: string},
 * FILE: {nameC: string, description: string},
 * IMG_FILE: {nameC: string, description: string},
 * SLOT: {nameC: string, description: string},
 * ENTITY_LANG_BLOCK: {nameC: string, description: string}}},
 * INPUT_MASK: {nameC: string, description: string}}}
 */

export const TYPE_COMPONETS = {
        INPUT: { nameC: 'INPUT', description: 'Текстовое поле' },
        INPUT_NUMBER: { nameC: 'INPUT_NUMBER', description: 'Числовое поле' },
        INPUT_PRICE: { nameC: 'INPUT_PRICE', description: 'Цена поле' },
        INPUT_MASK: { nameC: 'INPUT_MASK', description: 'Текстовое поле c маской' },

        INPUT_DATE: { nameC: 'INPUT_DATE', description: 'Дата' },

        TEXTAREA: { nameC: 'TEXTAREA', description: 'Большое текстовое поле' },
        REACH_EDITOR: { nameC: 'REACH_EDITOR', description: 'Редактор' },

        INFO: { nameC: 'INFO', description: 'Информация' },

        SELECT: { nameC: 'SELECT', description: 'Селектор' },
        VSELECT: { nameC: 'VSELECT', description: 'V-select' },
        V_SELECT_COMPLEX: { nameC: 'V_SELECT_COMPLEX', description: 'V_SELECT_COMPLEX' },
        MULTI_SELECT: { nameC: 'MULTI_SELECT', description: 'MULTI_SELECT' },

        CHECKBOX: { nameC: 'CHECKBOX', description: 'Чекбокс' },
        RADIO: { nameC: 'RADIO', description: 'Радио поле' },

        SEPARATOR: { nameC: 'SEPARATOR', description: 'Разделяющая линия' },
        SPACE: { nameC: 'SPACE', description: 'Пустое пространсво' },


        GROUP: { nameC: 'GROUP', description: 'Текстовое поле' },
        TAB: { nameC: 'TAB', description: 'Текстовое поле' },

        FILE: { nameC: 'FILE', description: 'Текстовое поле' },
        IMG_FILE: { nameC: 'IMG_FILE', description: 'Текстовое поле' },

        SLOT: { nameC: 'SLOT', description: 'Дает slot' },

        ENTITY_LANG_BLOCK: { nameC: 'ENTITY_LANG_BLOCK', description: 'Языковой блок для справочника один на странице' }

    }
    /**
     *
     * @type {{main: boolean, default: null, colsXS: number, colsSM: number, colsMD: number, colsLG: number, OffsetXS: number, OffsetSM: number, OffsetMD: number, OffsetLG: number, addClass: null, name: null, fieldName: null, label: null, placeholder: null, validationRule: null, errName: null, cyData: null, enable: boolean, readonly: boolean, showTable: boolean, disableInCreate: boolean}}
     */
export const DEF_FIELD = {
    main: false, // явлется ли поле главым
    default: null, // значение по умолчанию
    // поля отвечают за отображение
    colsXS: 12,
    colsSM: 12,
    colsMD: 12,
    colsLG: 12,
    OffsetXS: 0,
    OffsetSM: 0,
    OffsetMD: 0,
    OffsetLG: 0,
    addClass: '', // дополнительные классы
    name: null, // name field
    fieldName: null, // v-model
    label: null, // label
    placeholder: null, //placeholder
    validationRule: null, // правила валидации
    errName: null,
    cyData: null, // уникальное имя для тестирования,
    enable: true,
    readonly: false,
    showTable: true,
    disableInCreate: false // отключить при создании
}