import fgInput from './components/shared/Inputs/formGroupInput.vue'
import DropDown from './components/shared/Dropdown.vue'
import Card from './components/shared/Cards/Card.vue'
import Button from './components/shared/Button.vue';
import {Input, InputNumber} from 'element-ui'
import DynamicGeneratorForm from './components/modules/generator/DynamicGeneratorForm.vue'
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select'

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install (Vue) {
    Vue.component('fg-input', fgInput)
    Vue.component('drop-down', DropDown)
    Vue.component('card', Card)
    Vue.component(Button.name, Button)
    Vue.component(Input.name, Input)
    Vue.component(InputNumber.name, InputNumber)
    Vue.component('dynamic-generator-form',DynamicGeneratorForm)
    Vue.component('v-select',vSelect)
  }
}

export default GlobalComponents
