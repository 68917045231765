import {READ} from '../../../../store/type'
import {ENTITY_NAME, CODE_ENUM} from "./type";

export const mutations = {
  [ENTITY_NAME + READ]: (state, { data, fieldInfoList}) => {
    state.data = data
    state.fieldInfoList = fieldInfoList
  },
  [CODE_ENUM + READ]: (state, { data,  fieldInfoList}) => {
    state.codeEnum = data
    state.fieldCodeEnumList = fieldInfoList
  }
};
