<template>
  <the-table-page :titleTable="$t('Коды бюджетные')"
                  :tableData="tableData"
                  :load="load"
                  fieldId="codeId"
                  :updateData="updateData"
                  :fieldInfoList="fieldInfoList"
                  :searchStart="searchStart"
                  :sortData="sortData"
                  :setSort="setSort"
                  :deleteEl="deleteEl"
                  :prefixEdit="`/contract/${$route.params.ContractId}/ContractCodeBudget`"
                  :prefixShow="`/contract/${$route.params.ContractId}/ContractCodeBudget`"
                  :linkCreate="linkCreate"
                  configName = "clientCodesBudget"
                  :roleName="nameRoleEntity"
                
                  :hasReadOnlyInTable="hasReadOnlyInTable" 
  ></the-table-page>
</template>
<script>

  import TheTablePage from "../../TheTablePage.vue";
  import {ENTITY_NAME} from "./store/type";
  import {COMPANY_UNCONNECT_CODE} from "../company/store/type";

  import {
    SET_MAIN_PAGINATION,
    SET_MAIN_PAGINATION_PER_PAGE,
    SET_MAIN_PAGINATION_PAGE,
    SET_MAIN_SORT_ENTITY,
    SET_MAIN_SEARCH_ENTITY
  } from "../../../../src/store/type"

  import {READ, DELETE} from '../../../store/type'
  import {mapState} from 'vuex';
  import NotificationSample from 'src/pages/Dashboard/Components/NotificationSample';
  import {DEFAULT_PER_PAGE, TYPE_ERRORS, FIELD_LANG_CODE,  GET_MEMORY_PER_PAGE} from '../../../constant'
  import notify from '../../../helpers/notify/notify'
  import convertLangDataToTable from '../../../helpers/converters/convertLangDataToTable'
import {NAME_OBJECT} from "../../../RoleConstanans"
  export default {
    components: {
      TheTablePage
    },
    props: {
      isClientCode: {type: Boolean, default: false},
      linkCreate: { type: String,  default: `/code/create` },
      customDelete: {type: Boolean, default: false},
      deleteElCustom: {type: Function},
      hasReadOnlyInTable: { type: Boolean, default: true }, 
    },
    data() {
      return {
        scheme: {},
        load: false
      }
    },
    computed: {
      ...mapState({
        fieldInfoList: state => state.code.fieldInfoList,
        lang: state => state.common.lang,
        sortData: state => state.pagination.sortData,
      }),
      nameRoleEntity(){
        return NAME_OBJECT.clientCodesBudget
      },
      tableData() {
          let langNow = this.$store.state.common.lang
          let dataNow = this.$store.state.code.data || []
          if (dataNow && dataNow.length > 0) {
            dataNow = convertLangDataToTable(dataNow, langNow, FIELD_LANG_CODE)
            return dataNow
          }
      }
    },
    watch: {
      lang() {
        this.initData()
      }
    },
    methods: {
      getData() {
        this.load= true
        this.$store.dispatch(ENTITY_NAME + READ, {
            self:this,
            AgencyId: 0,
            ContractId: this.$route.params.ContractId,
            CodeType: 'Budget'
        }).then(res=>{
          this.load= false
        })
        .catch(err=>{
          this.load= false
          notify(this, this.$t('Ошибка получения данных'), TYPE_ERRORS.DANGER)
        })
      },
      updateData() {
        this.getData()
      },
      searchStart(search){
        this.$store.commit(SET_MAIN_SEARCH_ENTITY, search)
        this.getData()
      },
      setSort(val){
        this.$store.commit(SET_MAIN_SORT_ENTITY, val)
        this.getData()
      },
      deleteEl(id){
        if (!this.customDelete) {
          this.$store.dispatch(COMPANY_UNCONNECT_CODE, {
            CompanyId: this.$route.params.CompanyId,
            CodeId: id,
            self: this
          }).then(res => {
            this.getData()
               if(res.errors && res.errors.length  == 0){notify(this, this.$t("Успешно"),TYPE_ERRORS.SUCCESS); }
          })
        } else {
          this.deleteElCustom(id).then(res => {
            this.getData()
               if(res.errors && res.errors.length  == 0){notify(this, this.$t("Успешно"),TYPE_ERRORS.SUCCESS); }
          })
        }
      },
      initData() {
        this.$store.commit( ENTITY_NAME + READ ,{ data: [], fieldInfoList: []})
        this.$store.commit( SET_MAIN_SORT_ENTITY,{})
        this.$store.commit( SET_MAIN_SEARCH_ENTITY, '')
        this.$store.commit( SET_MAIN_PAGINATION_PER_PAGE, GET_MEMORY_PER_PAGE())
        this.$store.commit( SET_MAIN_PAGINATION_PAGE, 1)
        this.getData()
      }
    },
    created() {
      this.initData()
    }
  }
</script>
