import {TYPE_COMPONETS} from '../constants'
import extendComponents from '../extendComponents'

let vselectComplexMulti = {
...extendComponents(TYPE_COMPONETS.MULTI_SELECT),
  relative: null,
  search: false,
  selectLabel: 'label',
  SearchType: null
}

export default vselectComplexMulti
