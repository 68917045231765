<template>
  <div :class="generateClass">
    <label>{{ config.label }}</label>
    <div class="form-group has-label">
      <!-- <div @click="validData()"> validData </div> -->
      <div class="el-input muliSelectBlock">
        <v-select
          v-if="draw"
          v-tooltip.top-center="
            readonly || manualBlock ? '' : $t('Начните поиск')
          "
          :label="config.selectLabel"
          :placeholder="config.placeholder"
          :data-cy="config.cyData"
          :disabled="readonly || manualBlock"
          :readonly="readonly || manualBlock"
          @search="onSearch"
          :options="option"
          multiple
          :name="config.fieldName"
          v-validate="config.validationRule"
          :data-vv-as="config.label"
          v-model="selectData"
        >
          <span slot="no-options">{{ $t("Результатов не найдено") }}</span>
        </v-select>

        <div
          class="text-danger invalid-feedback"
          style="display: block;"
          v-if="getError(config.fieldName)"
        >
          {{ $t("Обязательное") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Select, Option } from "element-ui";
import { SEARCH, READ } from "../../../../store/type";

import { generateClass } from "../../../../mixins/baseComponets/generateClass";
import { validateExt } from "../../../../mixins/baseComponets/validateExt";
import BaseSimpleSelect from "./BaseSimpleSelect.vue";
import { api } from "../../../../util/api";
import { API_METHODS } from "../../../../constant";
import clearReactive from "../../../../helpers/clearReactive";

import { ApiService } from "../../../../util/ApiService";
import { mapState } from "vuex";

export default {
  components: { BaseSimpleSelect },
  name: "BaseVSelectComplexMulti",
  mixins: [generateClass, validateExt],
  props: {
    config: { type: Object, required: true },
    form: { type: Object }
  },
  data() {
    return {
      option: [],
      keyId: "entityId",
      tempOldDataWathc: null,
      draw: false,
      manualBlock: false,
      selectNow: [],
      selectData: []
    };
  },
  watch: {
    selectNow() {
      if (this.draw) {
        this.form[this.config.fieldName] = this.parseData(this.selectNow);
      }
    },
    config() {
      this.init();
    },
    form: {
      deep: true,
      handler: function(val) {
        if ("watchAttr" in this.config && this.config.watchAttr) {
          let newVal = clearReactive(val[this.config.watchAttr]);
          let oldVal = clearReactive(this.tempOldDataWathc);
          if (newVal !== oldVal) {
            this.option = [];
            this.draw = false;
            this.$set(this.form, this.config.fieldName, null);
            // this.form[this.config.fieldName] = null
            this.$nextTick(() => {
              this.draw = true;
              this.validData();
              this.init();
            });
          }
          this.updatedWatchProp();
        }
        setTimeout(() => {
          this.validData();
        }, 100);
      }
    }
  },
    computed: {
    ...mapState({
      lang: (state) => state.common.lang,
    })},
  created() {
    this.init();
  },
  methods: {
    updatedWatchProp() {
      if (this.config.watchAttr) {
        this.tempOldDataWathc = clearReactive(this.form[this.config.watchAttr]);
      }
    },
    async init() {
      // debugger
      let config = this.config;
      this.updatedWatchProp();
      this.option = [];
      if ("watchAttr" in config && config.watchAttr) {
        if (
          this.form[config.watchAttr] == null ||
          this.form[config.watchAttr] == 0
        ) {
          this.manualBlock = true;
          return;
        }
      }
      this.manualBlock = false;


      // if (this.form[config.fieldName]) {
      //   const ids = this.form[config.fieldName];
      //   const splitData = ids.split(",");
      //   for (let index = 0; index < splitData.length; index++) {
      //     const id = splitData[index];
      //     await this.loadData(id);
      //   }
      //   this.selectNow = this.formatData(ids);
      // }


      await this.loadSelecData();
      this.$nextTick(() => {
        this.draw = true;
      });
    },
    formatData(val) {
      let keys = val ? clearReactive(val).split(",") : [];
      if (keys && keys.length > 0) {
        let filters = this.option.filter(el => {
          let res = keys.find(key => key == el[this.keyId]) ? true : false;
          return res;
        });
        return filters ? filters : [];
      }
      return [];
    },
    parseData(val) {
      if (val) {
        let conver = val.map(item => item[this.keyId]);
        return val ? conver.join(",") : "";
      }
      return "";
    },

    onSearch(search, loading, event) {
      if (search.length < 3) return false;

      loading(true);
      this.search(loading, search, this);
    },
    search(loading, search, vm) {
      vm.loadData(null, search)
        .then(() => {
          loading(false);
        })
        .catch(() => {
          loading(false);
        });
    },
    async loadData(id = null, search) {
      try {
        const config = this.config;
        const params = {
          self: this,
          relative: config.relative,
          id: id,
          query: {
            subListId: this.config.watchAttr
              ? this.form[this.config.watchAttr]
              : config.subListId,
            search: search,
            lang: this.$store.state.common.lang
          }
        };



        const res = await api(API_METHODS.BASE_V_SELECT_LOAD_DATA, params);
        let responseData = res.object;
        if (!("map" in responseData)) {
          responseData = [responseData];
        }
        this.addOptions(responseData);
        this.validData();
        return res;
      } catch (err) {
        return err;
      }
    },
    addOptions(result) {
      result.forEach(item => {
        let find = this.option.find(el => el[this.keyId] == item[this.keyId]);
        if (!find) {
          this.option.push(item);
        }
      });
    },
    validData() {
      this.$validator.validateAll();
    },

    //TEMP
    async loadSelecData() {
      try{
         // todo
        // console.log(this.config, this.$route.params)

        let temp = this.config.relative.split("/")
        // debugger
        let type = temp[1]
        let mainType =  temp[0]
        let id = temp[2] && this.$route.params[temp[2]] ?this.$route.params[temp[2]]:0;

        // console.log(temp, type, mainType, id);
        const paramsIn = {
          self: this,
          mainType,
          id,
          type,
          lang: this.lang,
        }
        // console.log(paramsIn)
        // debugger
        let res = await ApiService(API_METHODS.GET_DATA_MULTISELEC, paramsIn)
        // debugger
        // console.log(res)
        this.option = res.object? res.object: []
        this.selectData =  res.object? res.object: []
      } catch (e) {
        // debugger
        console.log(e)
      }
    }
  }
};
</script>
