<template>
  <common-page-table
    :apiName="apiName"
    :nameRole="nameRoleEntity"
    :titleTable="$t('Услуги поставщика')"
    :paramsGetData="paramsGetData"
    :fieldLand="fieldLang"
    :keyTable="'childId'"
    :deleteParams="deleteParams"
    :apiDeleteName="apiDeleteName"
    :alternativeConverter="convertData"
    :isDefaultConverter="false"
    :link-create="`/company/supplier/${$route.params.CompanyId}/supplierService/create`"
    :forceIsEdit="false"
    :forceIsShow="false"
  />
</template>
<script>
import { NAME_OBJECT } from "../../../../RoleConstanans";
import CommonPageTable from "../../commonElement/CommonPageTable.vue";
import { API_METHODS, FIELD_LANG_COMPANY} from "../../../../constant";
import formatData from "../../../../helpers/converters/formatData";
import convertLangDataToTable from "../../../../helpers/converters/convertLangDataToTable";
export default {
  name: "TableSupplierService",
  components: { CommonPageTable },
  data() {
    return {
      fieldLang: FIELD_LANG_COMPANY,
      apiName: API_METHODS.GET_ALL_SUPPLIER_SERVICE,
      apiDeleteName: API_METHODS.DELETE_SUPPLIER_SERVICE,
    };
  },
  computed: {
    paramsGetData() {
      return { CompanyId: this.$route.params.CompanyId };
    },
    nameRoleEntity() {
      return NAME_OBJECT.supplierService
    }
  },
  methods: {
    convertData(dataNow, langNow) {
      let res = convertLangDataToTable(dataNow, langNow, FIELD_LANG_COMPANY)
      return res;
    },
    deleteParams(id) {
      return { childId:  id, parentId: this.$route.params.CompanyId };
    },
  }
};
</script>


