import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue";
import StatisticHrFeedTable from "./StatisticHrFeedTable.vue";
// import EditPageStatisticHrFeed from "./EditPageStatisticHrFeed.vue";
import StatisticHrFeedTableDetail from "./StatisticHrFeedTableDetail.vue";
export default {
  path: "/statisticHrFeed",
  component: DashboardLayout,
  children: [
    {
      path: "/",
      component: StatisticHrFeedTable
    },
    {
      path: ":contragentId/:dateFrom/:dateTo/show",
      name: "StatisticHrFeedTableDetail",
      component: StatisticHrFeedTableDetail
    }
  ]
};
