<template>
  <div>
    <show-code :code="fieldInfoList"></show-code>
    <show-code :code="preResult"></show-code>
    <load-block :load="!load"></load-block>
    <the-edit-page
      :scheme="scheme"
      :isEntity="true"
      :loadData="loadData"
      v-if="load"
      :title="title"
      :pageMode="pageMode"
    >
      <the-controls-edit-page
        :scheme="scheme"
        :loadData="loadData"
        :saveData="saveData"
        :pathBack="pathBack"
        :show-history="!($route.name == routeNameCreate)"
        :open-history="openHistory"
        :configName="nameRoleEntity"
        :pageMode="pageMode"
        :hasHistory="false"
        :wait="wait"
        :roleName="nameRoleEntity"
      ></the-controls-edit-page>
    </the-edit-page>
    <!-- <history-person-contact
      v-if="!($route.name == 'CreateContact')"
      :modals="modals"
      :toggleModal="toggleModal"
      :closeHistory="closeHistory"
    ></history-person-contact> -->
  </div>
</template>
<script>
import TheEditPage from "../../TheEditPage.vue";
import { MAIN_ADDRESS } from "./store/type";
import { READ, UPDATE } from "../../../store/type";
import TheControlsEditPage from "../../TheControlsEditPage.vue";
import clearReactive from "../../../helpers/clearReactive";
import ShowCode from "../../shared/ShowCode.vue";
import convertFieldInfoListToScheme from "../../../helpers/converters/convetFieldInfoListToScheme";
import clearNull from "../../../../src/helpers/clearNull";
import { mapState } from "vuex";

import { TYPE_ERRORS } from "../../../constant";
import notify from "../../../helpers/notify/notify";
import LoadBlock from "../../shared/LoadBlock.vue";
import { checkValidate } from "../../../mixins/validate/checkValidate";
import HistoryPersonContact from "../history/HistoryPersonContact.vue";
import { pageMode } from "../../../mixins/editPage/pageMode";
import {NAME_OBJECT} from  "../../../RoleConstanans"
export default {
  name: "EditPageAddresMain",
  components: {
    HistoryPersonContact,
    LoadBlock,
    ShowCode,
    TheControlsEditPage,
    TheEditPage
  },
  mixins: [checkValidate, pageMode],
  data() {
    return {
      wait: false,
      scheme: {},
      loadData: null,
      fieldInfoList: null,
      preResult: null,
      load: true,
      modals: {
        history: false
      },
      routeNameShow: null,
      routeNameCreate: null
    };
  },
  computed: {
     nameRoleEntity(){
        const nameRoute = this.$route.name
        let role = 'CompanyAddress'
       
        switch (nameRoute) {
           case "AgentCreatePageAddress":
           case "AgentShowPageAddress":
           case "AgentEditPageAddress": {
             role =  NAME_OBJECT.AddressAgency
             break
          }
          case "ClientEditPageAddress":
          case "ClientShowPageAddress":
          case "ClientCreatePageAddress": {
            role =  NAME_OBJECT.AddressClient
            break
          }
        }
        
        return role
    },


    ...mapState({
      lang: state => state.common.lang
    }),
    title() {
      return this.pageTitle(
        this.$t("Просмотр адреса"),
        this.$t("Создание адреса"),
        this.$t("Редактирование адреса")
      );
    },
    pathBack() {
          return `/company/${this.meta.typeCompany}/${this.$route.params.CompanyId}/edit`
    },
    meta(){
     return this.$route.meta
    }
  },
  watch: {
    lang() {
      this.initData();
    }
  },
  created() {
    this.initData();
    this.routeNameShow = this.meta.routeNameShow
    this.routeNameCreate = this.meta.routeNameCreate
  },
  methods: {
    toggleModal(name) {
      this.modals[name] = !this.modals[name];
    },
    openHistory() {
      this.toggleModal("history");
    },
    closeHistory() {
      this.toggleModal("history");
      this.initData();
    },

    initData() {
      this.load = false;
      this.$store
        .dispatch(MAIN_ADDRESS + READ, {
          id: this.$route.params.ContactId || "0",
          self: this,
          SubjectId: this.$route.params[this.meta.fieldRoute]
        })
        .then(res => {
          this.loadData = res.object;
          let fieldInfoList = res.fieldInfoList;
        
          this.fieldInfoList = fieldInfoList; //del in future
          this.scheme.configForm = convertFieldInfoListToScheme({
            fieldInfoList,
            hasLang: false
          });
          this.preResult = convertFieldInfoListToScheme({
            fieldInfoList,
            hasLang: false
          }); //del in future
          this.loadData.subjectId = this.$route.params[this.meta.fieldRoute];
          this.load = true;
        });
    },
    saveData() {
      if (this.validateData() == false) {
        return;
      }
      let saveData = clearReactive(this.loadData);
      saveData = clearNull(saveData);
      this.wait = true;
      this.$store
        .dispatch(MAIN_ADDRESS + UPDATE, {
          id: this.$route.params.ContactId,
          saveData: saveData,
          self: this,
          SubjectId: this.$route.params[this.meta.fieldRoute]
        })
        .then(res => {
          this.wait = false;
          if (res.errors.length == 0) {
            notify(this, this.$t("Успешно"), TYPE_ERRORS.SUCCESS);
            this.$router.push({ path: this.pathBack });
          }
        })
        .catch(err => {
          console.error(err);
          this.wait = false;
        });
    }
  }
};
</script>
