<template>
  <div class="row">
    <load-block :load="load"></load-block>
   
   
    <div class="col-12 mintableH pl-0 pr-0">
      <card :title="titleTable" :class="{ noTitle: noTitle }">
        <p class="text-warning" v-if="demotext">{{ demotext }}</p>
         <!-- {{nameTableSaveInCashe}}
        <div v-if="!nameTableSaveInCashe"> забыли   </div> -->
        <div>
          <filter-block
             v-if="!isFirsLoad"
            :searchData="searchData"
            :setPerPage="setPerPage"
            :link-create="linkCreate"
            :forceIsCreate="
              (readOnlyIsActive ? hasReadOnlyInTable : true) && forceIsCreate
            "
            :is-create="configRole ? configRole.create : isCreate"
            :forsePerpage="forsePerpage"
            :forseSearch="forseSearch"
          >
          </filter-block>
          <div class="col-sm-12">
            <el-table
              stripe
              :defaultSort="defaultSort"
              @sort-change="beforeSetSort"
              style="width: 100%;"
              :data="tableData"
              
              v-if="tableColumns && !isFirsLoad"
              :empty-text="$t('Нет данных!')"
              :row-class-name="tableRowClassName"
              @cell-click="cellClick"
            >
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :sortable="sortable"
                :prop="column.prop"
                :label="column.label"
                :class-name="hasFieldInData(column.prop) ? '' : 'errortable'"
              >
                <template slot-scope="props">
                  <template v-if="customTemplate">
                    <template
                      v-if="
                        column.prop == 'elementCode' ||
                          column.prop == 'elementName'
                      "
                    >
                      {{
                        column.prop in props.row
                          ? props.row[column.prop]
                          : $t("ОШИБКА! Данные для поля не найдены")
                      }}
                    </template>
                    <template v-else>
                      <input
                        type="checkbox"
                        :checked="props.row[column.prop]"
                        @change="setChange(props.row, column.prop)"
                      />
                    </template>
                  </template>

                  <template v-if="!customTemplate">
                    <template
                      v-if="!ischechbox(props.row[column.prop], column.prop)"
                    >
                      {{
                        column.prop in props.row
                          ? props.row[column.prop]
                          : $t("ОШИБКА! Данные для поля не найдены")
                      }}
                    </template>
                    <template
                      v-if="ischechbox(props.row[column.prop], column.prop)"
                    >
                      <template v-if="column.prop in props.row">
                        <input
                          type="checkbox"
                          :checked="props.row[column.prop]"
                          disabled
                        />
                      </template>
                      <template v-else>
                        {{ $t("ОШИБКА! Данные для поля не найдены") }}
                      </template>
                    </template>
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                :min-width="80"
                :width="wAction"
                fixed="right"
                align="right"
                :label="$t(textAction)"
                class="noHiddenText"
                class-name="noHidden"
                v-if="hasAction"
              >
                <template slot-scope="props">
                  <router-link
                    class="btn-info btn-simple btn-link tableBtn mr-2 plusLink"
                    v-if="isDopLinkPluse"
                    :to="customDopLink(props.row)"
                    ><i
                      class="fa fa-plus-square-o"
                      v-tooltip="$t(pluslinktitle)"
                    ></i>
                  </router-link>

                  <router-link
                    class="btn-info btn-simple btn-link tableBtn mr-2"
                    v-if="
                      forceIsShow && (configRole ? configRole.show : isShow)
                    "
                    :to="
                      customLinkEditShow
                        ? customLinkEditShow(
                            `${prefixShow ? prefixShow : $route.path}/${
                              props.row[fieldId]
                            }/show`,
                            props.row
                          )
                        : `${prefixShow ? prefixShow : $route.path}/${
                            props.row[fieldId]
                          }/show`
                    "
                    ><i class="table_icon icon_eye" v-tooltip="$t('Посмотреть')"></i>
                  </router-link>
                  <router-link
                    class="btn-warning btn-simple btn-link tableBtn mr-2"
                    v-if="
                      hasReadOnlyInTableRow(props.row) &&
                        forceIsEdit &&
                        (configRole ? configRole.edit : isEdit)
                    "
                    :to="
                      customLinkEditShow
                        ? customLinkEditShow(
                            `${prefixShow ? prefixShow : $route.path}/${
                              props.row[fieldId]
                            }/edit`,
                            props.row
                          )
                        : `${prefixEdit ? prefixEdit : $route.path}/${
                            props.row[fieldId]
                          }/edit`
                    "
                    ><i class="table_icon icon_edit" v-tooltip="$t('Редактировать')"></i>
                  </router-link>
                  <a
                    v-if="
                      hasReadOnlyInTableRow(props.row) &&
                        forceDelete &&
                        (configRole ? configRole.delete : isDelete)
                    "
                    class="btn-danger btn-simple btn-link tableBtn"
                    @click="handleDelete(props.row[fieldId], props.row)"
                    ><i class="table_icon icon_delete" v-tooltip="$t('Удалить')"></i
                  ></a>

                  <a
                    v-if="isDetail"
                    class="btn-info btn-simple btn-link tableBtn"
                    @click="detalShow(props.row)"
                    ><i class="table_icon icon_eye" v-tooltip="$t('Посмотреть')"></i
                  ></a>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div slot="footer" class="col-12" v-if="forsePaginate && !isFirsLoad">
          <pagination-block
            :updateData="beforeUpdateData"
            name="2"
            :setPerPage="setPerPage"
            :forceIsCreate="forceIsCreate"
            :is-create="configRole ? configRole.create : isCreate"
            :manualMode="manualMode"
            :allPaginator="allPaginator"
            :currentPage="currentPage"
          ></pagination-block>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn } from "element-ui";
import { Loading } from "element-ui";
import {
  SET_MAIN_PAGINATION,
  SET_MAIN_PAGINATION_PAGE,
  SAVE_TABLE_PAGINATION
} from "../../src/store/type";
import clearReactive from "../helpers/clearReactive";
import LoadBlock from "./shared/LoadBlock.vue";
import FilterBlock from "./shared/theTablePage/FilterBlock.vue";
import PaginationBlock from "./shared/theTablePage/PaginationBlock.vue";
import swal from "sweetalert2";
import { NAME_ACTION } from "../RoleConstanans";
export default {
  components: {
    PaginationBlock,
    FilterBlock,
    LoadBlock,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  props: {
    titleTable: { type: String },
    tableData: { required: true },
    fieldId: { type: String, default: "refEntityId" },
    updateData: { type: Function, required: true },
    searchStart: { type: Function, required: true },
    sortData: { type: Object, required: true },
    setSort: { type: Function, required: true },
    fieldInfoList: { type: Array },
    load: { type: Boolean, default: false },
    deleteEl: { type: Function, required: true },
    prefixEdit: { type: String, default: "" },
    prefixShow: { type: String, default: "" },
    linkCreate: { type: String, default: "" },
    isShow: { type: Boolean, default: false },
    isEdit: { type: Boolean, default: true },
    isCreate: { type: Boolean, default: true },
    isDelete: { type: Boolean, default: true },
    configName: { type: String, default: null },
    demotext: { type: String, default: null },
    forceIsCreate: { type: Boolean, default: true },
    isSelect: { type: Boolean, default: false },
    setSelect: { type: Function },
    selectId: { type: Number },

    isDetail: { type: Boolean, default: false },
    toggleModal: { type: Function },
    textAction: { type: String, default: "Действия" },

    checkboxfields: { type: Array },
    noTitle: { type: Boolean, default: false },

    noInfoDel: { type: Boolean, default: false }, // если true то не будет информации об удалении
    forceDelete: { type: Boolean, default: true }, // если false то не будет кнопки удлаить

    forsePaginate: { type: Boolean, default: true }, // если false то не будет пагинации
    forsePerpage: { type: Boolean, default: true }, // если false то не будет perpage
    forseSearch: { type: Boolean, default: true }, // если false то не будет search
    hasAction: { type: Boolean, default: true }, // если false то не будет блока действий
    customTemplate: { type: Boolean, default: false }, // если true то приоритет на кастом блок будет
    setChange: { type: Function },
    sortable: { default: "custom" },

    roleName: { type: String },

    forceIsShow: { type: Boolean, default: true },
    forceIsEdit: { type: Boolean, default: true },

    readOnlyIsActive: { type: Boolean, default: true },
    hasReadOnlyInTable: { type: Boolean, default: true }, // если false то не должно быть кнопки создать по признаку ReadOnly
    customLinkEditShow: {
      type: Function
    },

    isDopLinkPluse: { type: Boolean, default: false }, // дополнительная ссылка
    customDopLink: { type: Function },
    pluslinktitle: { type: String, default: "" },

    wAction: { type: Number, default: 230 },
    nameTableSaveInCashe: { type: String },
    manualMode: { type: Boolean, default: false },
    allPaginator: {
      type: Object,
      default: () => {
        return {
          pagination: {
            perPage: 10,
            currentPage: 1,
            perPageOptions: [5, 10, 25, 50],
            total: 0
          },
          sortData: {
            prop: "refEntityId",
            order: "descending"
          },
          search: ""
        };
      }
    },
  },
  computed: {
    defaultSort() {
      return this.$store.state.pagination.sortData;
    },
    pagination() {
      return this.$store.state.pagination.pagination;
    },
    isAdminRole() {
      return this.$store.getters.isAdminRole;
    },
    configRole() {
      const userRoleLouded = this.userRoleLouded;
      const isActionAccess = this.isActionAccess;
      const roleName = this.roleName;
      if (userRoleLouded) {
        return {
          create: isActionAccess(roleName, NAME_ACTION.isCreate),
          show: isActionAccess(roleName, NAME_ACTION.isRead),
          edit: isActionAccess(roleName, NAME_ACTION.isEdit),
          delete: isActionAccess(roleName, NAME_ACTION.isDelete)
        };
      }
      return null;
    },
    configRoleold() {
      let data = this.configName
        ? this.$store.state.common.config[this.configName]
        : null;

      if (data) {
        const { create, show, edit } = data;
        return {
          create,
          show,
          edit,
          delete: data.delete
        };
      }
      return null;
    },
    userRoleLouded() {
      return this.$store.getters.userRoleLouded;
    },
    tableColumns() {
      let fieldInfoList = this.fieldInfoList;
      let tableColumns = fieldInfoList
        ? fieldInfoList.map(el => {
            return {
              prop: el.code,
              label: el.name,
              minWidth: el.sizePercent + "%"
            };
          })
        : [];
      return tableColumns;
    },
    currentPage: {
      get() {
        return this.pagination.currentPage;
      },
      set(val) {
        this.$store.commit(SET_MAIN_PAGINATION_PAGE, val);
      }
    }
  },
  data() {
    return {
      isFirsLoad: true,
      sort: null
    };
  },
  watch: {
    load() {
      if (this.load == false) {
        this.isFirsLoad = false;
      }
    }
  },
  methods: {
    hasReadOnlyInTableRow(row) {
      if (this.isAdminRole) {
        return true;
      }

      if (this.readOnlyIsActive && "readOnly" in row) {
        return !row.readOnly;
      }
      return true;
    },
    isActionAccess(name, action) {
      let isActionAccess = this.$store.getters.isActionAccess;
      return isActionAccess(name, action);
    },
    detalShow(row) {
      if (this.isDetail) {
        this.toggleModal(row, this.fieldInfoList);
      }
    },
    cellClick(row) {
      if (this.isSelect) {
        this.setSelect(row[this.fieldId], row);
      }
    },

    hasFieldInData(field) {
      if (this.tableData && this.tableData.length > 0) {
        return this.tableData[0] && field in this.tableData[0] ? true : false;
      }
      return true;
    },
    setPerPage() {
      this.currentPage = 1;
      this.savePaginator();
      this.updateData();
    },
    searchData(val) {
      this.currentPage = 1;
      this.savePaginator(null, val);
      this.searchStart(val);
    },
    tableRowClassName({ row, rowIndex }) {
      if (this.selectId) {
        if (row && row[this.fieldId] && row[this.fieldId] == this.selectId) {
          return "selectRow";
        }
      }
      return false;
    },
    handleDelete(id, row) {
      if (this.noInfoDel) {
        this.deleteEl(id, row);
      } else {
        swal({
          title: this.$t("Вы уверены?"),
          text: this.$t("Вы не сможете вернуть это!"),
          type: "warning",
          showCancelButton: true,
          confirmButtonClass: "btn btn-success btn-fill",
          cancelButtonClass: "btn btn-danger btn-fill",
          confirmButtonText: this.$t("Да удалить!"),
          cancelButtonText: this.$t("Закрыть"),
          buttonsStyling: false
        }).then(res => {
          if (res.value && res.value == true) {
            this.deleteEl(id, row);
          }
        });
      }
    },
    ischechbox(val, fieldname) {
      if (
        this.checkboxfields &&
        this.checkboxfields.find(el => el == fieldname)
      ) {
        return true;
      }
      return false;
    },
    beforeUpdateData(val) {
      this.savePaginator();
      this.updateData(val);
    },
    savePaginator(sortDataIn = null, searchIn = null) {
      let { sortData, search, pagination } = clearReactive(
        this.$store.state.pagination
      );
      if (sortDataIn) {
        sortData = sortDataIn;
      }
      if (searchIn) {
        search = searchIn
      }
      const data = {
        sortData,
        search,
        pagination
      };
      if (this.nameTableSaveInCashe) {
        this.$store.commit(SAVE_TABLE_PAGINATION, {
          name: this.nameTableSaveInCashe,
          data
        });
      }
    },
    beforeSetSort(val) {
      const sortData = {
        prop: val.prop,
        order: val.order
      };
      this.currentPage = 1;
      this.savePaginator(sortData);
      this.setSort(val);
    }
  }
};
</script>
