export const HISTORY_CODE = 'HISTORY_CODE'
export const HISTORY_CODE_ENUM = 'HISTORY_CODE_ENUM'
export const HISTORY_PERSON = 'HISTORY_PERSON'
export const HISTORY_DOCUMENT = 'HISTORY_DOCUMENT'
export const HISTORY_BONUS_CARD = 'HISTORY_CARD'
export const HISTORY_CONTACT = 'HISTORY_CONTACT'
export const HISTORY_COMPANY = 'HISTORY_COMPANY'

export const SET_HISTORY_SEARCH = 'SET_HISTORY_SEARCH'
export const SET_HISTORY_PAGE = 'SET_HISTORY_PAGE'
export const SET_HISTORY_PER_PAGE = 'SET_HISTORY_PER_PAGE'

export const RETURN_CODE_ENUM = 'RETURN_CODE_ENUM'
export const RETURN_CODE = 'RETURN_CODE'
export const RETURN_PERSON = 'RETURN_PERSON'
export const RETURN_DOCUMENT = 'RETURN_DOCUMENT'
export const RETURN_BONUS_CARD = 'RETURN_BONUS_CARD'
export const RETURN_CONTACT = 'RETURN_CONTACT'
export const RETURN_COMPANY = 'RETURN_COMPANY'