<template>
  <div :class="generateClass">
    <label>{{config.label}}</label>
    <radio :label="variant.value" v-model="form[config.fieldName]" v-for="(variant, index) in config.variant" :readonly="readonly" :key="index">{{variant.label}}</radio>
  </div>
</template>
<script>
  import {Radio} from 'src/components/index'
  import {generateClass} from  '../../../../mixins/baseComponets/generateClass'
  export default {
    name: 'BaseRadio',
    components: {
      Radio
    },

    props: {
      config: {type: Object, required: true},
      form: {type: Object}
    },
    mixins: [generateClass]
  }
  //
</script>
