import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue'
const ImportPage = () =>
    import ('./ImportPage.vue')
    // const EditPageContract = () =>
    //     import ('./EditPageContract.vue')
    // const EditPagePlug = () =>
    //     import ('./plugPage/EditPagePlug.vue')
    // import EditPageAttrValueMain from "../attrValue/EditPageAttrValueMain.vue"

// const META_CONTRACT_ATTRVALUE = {
//     pathBackStart: "contract",
//     fieldRoute: "ContractId",
//     configName: "attrValue",
//     routeNameShow: "ContractAttrValueShow",
//     routeNameCreate: "ContractAttrValueCreate",
//     routeNameEdit: "ContractAttrValueEdit"
// };
export default {
    path: '/import',
    component: DashboardLayout,
    children: [{
            path: '/',
            name: 'ImportPage',
            component: ImportPage
        },
        // {
        //     path: 'create',
        //     name: 'CreateContract',
        //     component: EditPageContract
        // },
        // {
        //     path: ':ContractId/show',
        //     name: 'ContractShow',
        //     component: EditPageContract
        // },
        // {
        //     path: ':ContractId/edit',
        //     name: 'ContractEdit',
        //     component: EditPageContract
        // },
        // {
        //     path: ':ContractId/addPolicy',
        //     name: 'addPolicy',
        //     component: EditPagePlug
        // },
        // {
        //     path: ':ContractId/addFinancialCondition',
        //     name: 'addFinancialCondition',
        //     component: EditPagePlug
        // },



    ]
}