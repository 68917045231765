<template>
  <el-dialog
    center
    @open="initData"
    :title="$t('История изменений')"
    :visible.sync="isVisibleSync"
  >
    <table-history-new
      :tableData="loadData"
      :fieldInfoList="fieldInfoList"
      :load="load"
      :updateData="updateData"
      :searchStart="searchStart"
      :sortData="{}"
      :setSort="demo"
      :backData="demo"
      configName="clientPersonBonusCard"
      :paramsPaginator="paramsPaginator"
      @set-perPage="setPerPage"
      @set-page="setPage"
    >
    </table-history-new>
    <span slot="footer" class="dialog-footer">
      <button class="btn btn-danger" @click="toggleModal(false)">
        Закрыть
      </button>
    </span>
  </el-dialog>
</template>
<script>
import { historyModal } from "../../../mixins/history/HistoryModal";
import TableHistoryNew from "./TableHistoryNew.vue";
import { Dialog, MessageBox } from "element-ui";
import { ApiService } from "../../../util/ApiService";
import { DEFAULT_PER_PAGE } from "../../../constant";
export default {
  name: "HistoryModal",

  components: {
    [Dialog.name]: Dialog,
    TableHistoryNew
  },
  props: {
    isVisible: { type: Boolean, default: false },
    toggleModal: { type: Function },
    apiNameGetHistory: { type: String },
    keyRoute: { type: String }
  },
  data: function() {
    return {
      loadData: [],
      fieldInfoList: [],
      load: true,
      paramsPaginator: {
        page: 1, //страница
        size: DEFAULT_PER_PAGE, //perpage
        countPage: 1, //всего страниц
        orderBy: null, //сортировка по
        search: "", //поиск по
        orderDir: "ascending" // направление
      }
    };
  },
  computed: {
    paramsPaginatorConvert() {
      const {
        page,
        size,
        orderBy,
        orderDir,
        search,
        lastUpdate
      } = this.paramsPaginator;
      return {
        self: this,
        page,
        size,
        lang: this.lang,
        search,
        lastUpdate: this.lastUpdate,
        orderBy,
        orderDir
      };
    },
    isVisibleSync: {
      get() {
        return this.isVisible;
      },
      set(val) {
        this.toggleModal(val);
      }
    }
  },
  methods: {
    setPerPage(perPage) {
      this.paramsPaginator.page = 1;
      this.paramsPaginator.size = perPage;
      // this.savePerPageInLocalStorage(perPage, this.apiName);
      this.getData();
    },
    setPage(page) {
      this.paramsPaginator.page = page;
      this.getData();
    },



    updateData() {
      // this.getData();
    },
    searchStart(search) {
      // this.$store.commit(SET_HISTORY_SEARCH, search);
      // this.getData();
    },

    demo() {},
    initData() {
      this.getData();
    },
    async getData() {
      this.load = true;
      try {
        const res = await ApiService(this.apiNameGetHistory, {
          self: this,
          id: this.$route.params[this.keyRoute],
          lang: this.lang,

          ...this.paramsPaginatorConvert
        });
        const { fieldInfoList, object, countPage } = res;
        this.fieldInfoList = fieldInfoList;
        this.loadData = object;
        this.paramsPaginator.countPage = countPage;
        console.log(res);
      } catch (e) {
        console.error("Ошибка получние истории");
      } finally {
        this.load = false;
      }
    }
  }
};
</script>
