import { actions } from "./actions";
import { mutations } from "./mutations";
import {getters} from "./getters"

const state = {
    drawerMenu: false,
    lang: localStorage.getItem('lang') || process.env.VUE_APP_I18N_LOCALE || 'en',
    langs: [{
            name: "English",
            code: "en"
        },
        {
            name: "Русский",
            code: "ru"
        }
    ],
    userRoleData:{},
    config: {
        entitis: {
            showMenu: true,
            show: true,
            create: true,
            edit: true,
            delete: true,
            history: false,
            historyReturn: false
        },
        clients: {
            showMenu: true,
            show: true,
            create: true,
            edit: true,
            delete: true,
            history: true,
            historyReturn: true
        },
        contracts: {
            showMenu: true,
            show: true,
            create: true,
            edit: true,
            delete: true,
            history: false,
            historyReturn: false
        },
        codesAgent: {
            showMenu: true,
            show: true,
            create: true,
            edit: true,
            delete: true,
            history: true,
            historyReturn: true
        },
        codeEnum: {
            showMenu: true,
            show: true,
            create: true,
            edit: true,
            delete: true,
            history: true,
            historyReturn: true
        },
        clientCodesPerson: {
            showMenu: true,
            show: false,
            create: true,
            edit: false,
            delete: true,
            history: true,
            historyReturn: true
        },
        clientCodesBudget: {
            showMenu: true,
            show: false,
            create: true,
            edit: true,
            delete: true,
            history: true,
            historyReturn: true
        },
        users: {
            showMenu: true,
            show: true,
            create: true,
            edit: true,
            delete: true,
            history: false,
            historyReturn: false
        },
        clientPerson: {
            showMenu: true,
            show: true,
            create: true,
            edit: true,
            delete: true,
            history: true,
            historyReturn: true
        },
        clientPersonDocument: {
            showMenu: true,
            show: true,
            create: true,
            edit: true,
            delete: true,
            history: true,
            historyReturn: true
        },
        clientPersonBonusCard: {
            showMenu: true,
            show: true,
            create: true,
            edit: true,
            delete: true,
            history: true,
            historyReturn: true
        },
        clientPersonContact: {
            showMenu: true,
            show: true,
            create: true,
            edit: true,
            delete: true,
            history: true,
            historyReturn: true
        },
        corpPolity: {
            showMenu: true,
            show: true,
            create: true,
            edit: true,
            delete: true,
            history: true,
            historyReturn: true
        },
        commercialTerms: {
            showMenu: true,
            show: true,
            create: true,
            edit: true,
            delete: true,
            history: false,
            historyReturn: false
        },
        setting: {
            showMenu: true,
            show: true,
            create: true,
            edit: true,
            delete: true,
            history: true,
            historyReturn: true
        },
        policyRule: {
            showMenu: true,
            show: true,
            create: true,
            edit: true,
            delete: true,
            history: false,
            historyReturn: false
        },
        financialConditionRule: {
            showMenu: true,
            show: true,
            create: true,
            edit: true,
            delete: true,
            history: false,
            historyReturn: false
        },
        supplier: {
            showMenu: true,
            show: true,
            create: true,
            edit: true,
            delete: true,
            history: true,
            historyReturn: true
        },
        agent: {
            showMenu: true,
            show: true,
            create: true,
            edit: true,
            delete: true,
            history: true,
            historyReturn: true
        },
        policyContract: {
            showMenu: true,
            show: false,
            create: true,
            edit: true,
            delete: true,
            history: false,
            historyReturn: false
        },
        commercialTermsContract: {
            showMenu: true,
            show: false,
            create: true,
            edit: true,
            delete: true,
            history: false,
            historyReturn: false
        },
        clientContact: {
            showMenu: true,
            show: true,
            create: true,
            edit: true,
            delete: true,
            history: false,
            historyReturn: false
        },
        CompanyAddress: {
            showMenu: true,
            show: true,
            create: true,
            edit: true,
            delete: true,
            history: false,
            historyReturn: false
        },
        attrEntity: {
            showMenu: true,
            show: true,
            create: true,
            edit: true,
            delete: true,
            history: false,
            historyReturn: false
        },
        attrValue: {
            showMenu: true,
            show: true,
            create: true,
            edit: true,
            delete: true,
            history: false,
            historyReturn: false
        },
        supplierService: {
            showMenu: true,
            show: false,
            create: true,
            edit: false,
            delete: true,
            history: false,
            historyReturn: false
        },
        personCodeValue: {
            showMenu: true,
            show: true,
            create: true,
            edit: true,
            delete: true,
            history: false,
            historyReturn: false
        },
        importFile: {
            showMenu: true,
            show: false,
            create: false,
            edit: false,
            delete: false,
            history: false,
            historyReturn: false
        },
        shina: { //bus
            showMenu: true,
            show: false,
            create: false,
            edit: false,
            delete: false,
            history: false,
            historyReturn: false
        },
        adminmanger: {
            showMenu: true,
            show: true,
            create: true,
            edit: true,
            delete: true,
            history: false,
            historyReturn: false
        },
        creditcard: {
            showMenu: true,
            show: true,
            create: true,
            edit: true,
            delete: true,
            history: false,
            historyReturn: false
        },
        eventlog: {
            showMenu: true,
            show: false,
            create: false,
            edit: false,
            delete: false,
            history: false,
            historyReturn: false
        },
        sublinksuser : {
            showMenu: true,
            show: false,
            create: false,
            edit: false,
            delete: true,
            history: false,
            historyReturn: false
        },
        userRole: {
            showMenu: true,
            show: false,
            create: false,
            edit: false,
            delete: true,
            history: false,
            historyReturn: false
        },
        travelers: {
            showMenu: true,
            show: true,
            create: true,
            edit: true,
            delete: false,
            history: false,
            historyReturn: false
        },

    }
};

export default {
    state,
    actions,
    mutations,
    getters
};