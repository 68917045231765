import SheduleTable from "./SheduleTablePage"
import SheduleEditPageDynamic from "./SheduleEditPageDynamic.vue"
import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue"

export default {
  path: "/",
  component: DashboardLayout,
  children: [{
    path: "Shedule",
    name: "SheduleTable",
    component: SheduleTable
  },
  {
    path: "Shedule/:flightId/edit",
    name: "SheduleEdit",
    component: SheduleEditPageDynamic,
  },
  {
    path: "Shedule/:flightId/delete",
    name: "SheduleDelete",
    component: SheduleEditPageDynamic,
  },
  {
    path: "Shedule/:flightId/show",
    name: "SheduleShow",
    component: SheduleEditPageDynamic,
  },
  {
    path: "Shedule/create",
    name: "SheduleCreate",
    component: SheduleEditPageDynamic
  },
  ]
}
