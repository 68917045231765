<template>
  <div>
    <load-block :load="!load"></load-block>
    <card :title="title">
      <div class="row" v-if="drafdata">
        <SelectorBlock
          v-if="loadData"
          labelText="Тип предпочтения"
          url="/RefEntity/DropDownList/EntityType/Category/Preference"
          @selectCode="setTypePrefab"
          :form="loadData"
          nameFiels="prefTypeId"
          :forseDis="isShowPage"
        />
        <SelectorBlock
          labelText="Предпочтения"
          :url="urlSearchPref"
          @selectCode="setPrefab"
          :isBlock="blockSelectType"
          :forseDis="isShowPage"
          :dopParams="{ subListId: $route.params.PersonId }"
          :form="loadData"
          nameFiels="prefId"
          v-if="showSelect"
          
        />

        <BaseInput
          :config="configVal"
          v-if="configVal && showComment"
          :form="loadData"
          :readonly="isShowPage"

        />
      </div>
      <the-controls-edit-page
        v-if="loadData"
        :loadData="loadData"
        :saveData="saveData"
        :pathBack="pathBack"
        :hasHistory="false"
        :roleName="nameRoleEntity"
        :pageMode="pageMode"
        :wait="wait"
      ></the-controls-edit-page>
    </card>
  </div>
</template>
<script>
import TheEditPage from "../../../../TheEditPage.vue";
const  PREF_DATA  = "PREF_DATA_"
import { READ, UPDATE } from "../../../../../store/type";
import TheControlsEditPage from "../../../../TheControlsEditPage.vue";
import clearReactive from "../../../../../helpers/clearReactive";
import convertFieldInfoListToScheme from "../../../../../helpers/converters/convetFieldInfoListToScheme";
import clearNull from "../../../../../../src/helpers/clearNull";
import { mapState } from "vuex";

import {
  TYPE_ERRORS,
  CODE_NO_CHOUSE_PREF,
  API_METHODS
} from "../../../../../constant";
import notify from "../../../../../helpers/notify/notify";
import LoadBlock from "../../../../shared/LoadBlock.vue";
import { checkValidate } from "../../../../../mixins/validate/checkValidate";

import { pageMode } from "../../../../../mixins/editPage/pageMode";
import { NAME_OBJECT } from "../../../../../RoleConstanans";
import SelectorBlock from "./SelectorBlock";
import BaseInput from "../../../../modules/generator/BaseComponets/BaseInput.vue"

import { api } from "../../../../../util/api";

export default {
  name: "EditPageDocument",
  components: {
    LoadBlock,
    
    TheControlsEditPage,
    TheEditPage,
    SelectorBlock,
    BaseInput
  },
  mixins: [checkValidate, pageMode],
  data() {
    return {
      wait: false,
      pathBack: `/contract/${this.$route.params.ContractId}/person/${this.$route.params.PersonId}/edit`,
      loadData: null,
      load: true,
      routeNameShow: "ShowPrefData",
      routeNameCreate: "CreatePrefData",
      typePrefab: null,
      blockSelectType: true,
      prefab: null,
      drafdata: true,
      showComment: false,
      configVal: null,
      oldLoadData: null,
      showSelect: true,
      baseList: [],
      list: [],
    };
  },
  computed: {
    ...mapState({
      lang: state => state.common.lang
    }),
    nameRoleEntity() {
      return NAME_OBJECT.personePref;
    },
    title() {
      return this.pageTitle(
        this.$t("Просмотр предпочтений"),
        this.$t("Создание предпочтений"),
        this.$t("Редактирование предпочтений")
      );
    },
    urlSearchPref() {
      if (this.typePrefab) {
        return `/RefEntity/DropDownList/${this.typePrefab.entityCode}`;
      }
      return null;
    },
    isShowPage() {
      if(this.$route.name === this.routeNameShow) {
        return true
      }
       return false
    }
  },
  watch: {
    lang() {
      this.drafdata = false;
      this.$nextTick(() => {
        this.drafdata = true;
      });
    },
    loadData: {
      deep: true,
      handler() {
        let oldVal = this.oldLoadData;
        let newVal = this.loadData;
        if (oldVal && newVal && newVal.prefTypeId !== oldVal.prefTypeId) {
          this.loadData.prefId = null;
          this.showSelect = false;
          this.$nextTick(() => {
            this.showSelect = true;
          });
        }
        if (oldVal && newVal && newVal.prefId !== oldVal.prefId) {
          this.configForm(newVal.prefId, newVal.prefTypeId);
        }
        this.oldLoadData = clearReactive(this.loadData);
      }
    }
  },
  async created() {
    this.baseList = await this.loadDropDownListPefab(
      "EntityType/Category/Preference"
    );
    this.initData();
  },
  methods: {
    setTypePrefab(val, isModeInit = false) {
      this.blockSelectType = true;
      this.prefab = null;
      this.loadData.prefTypeId = val.entityId;
      if (!isModeInit) {
        this.loadData.prefId = 0;
      }
      this.typePrefab = val;
      this.$nextTick(() => {
        this.blockSelectType = false;
      });
    },
    setPrefab(val, isModeInit = false) {
      
      this.prefab = val;
      this.loadData.prefId = val.entityId;
      this.loadData.prefCode = val.entityCode
      if (isModeInit) {
        this.configForm(this.loadData.prefId,this.loadData.prefTypeId, false);
      }
    },
    initData() {
      this.load = false;
      this.$store
        .dispatch(PREF_DATA + READ, {
          id: this.$route.params.PrefId || "0",
          self: this,
          PersonId: this.$route.params.PersonId
        })
        .then(res => {
          this.loadData = res.object;
          this.oldLoadData = clearReactive(this.loadData);
          let fieldInfoList = res.fieldInfoList;
          let configForm = convertFieldInfoListToScheme({
            fieldInfoList,
            hasLang: false
          });
          this.configVal = configForm[2];
          this.load = true;
        });
    },
    saveData() {
      if (this.validateData() == false) {
        return;
      }
      let saveData = clearReactive(this.loadData);
      saveData = clearNull(saveData);
      this.wait = true;
      this.$store
        .dispatch(PREF_DATA + UPDATE, {
          id: this.$route.params.PrefId,
          saveData: saveData,
          self: this,
          PersonId: this.$route.params.PersonId
        })
        .then(res => {
          this.wait = false;
          if (res.errors.length == 0) {
            notify(this, this.$t("Успешно"), TYPE_ERRORS.SUCCESS);
            this.$router.push({ path: this.pathBack });
          }
        })
        .catch(err => {
          console.error(err);
          this.wait = false;
        });
    },
    async configForm(prefId, prefTypeId, resetData = true) {
      const code = this.getTypeEntityCode(prefTypeId, this.baseList);
      this.list = await this.loadDropDownListPefab(code);
      this.showComment = false;
      if (resetData) this.loadData.comment = "";
      let codeIDNoSelect = this.getTypeEntityId(CODE_NO_CHOUSE_PREF, this.list);
      if (codeIDNoSelect === prefId) {
        this.showComment = true;
        if (resetData) this.loadData.comment = "";
      }
    },
    getTypeEntityId(entityCode, list) {
      const find = list && list.find(item => item.entityCode === entityCode);
      return find ? find.entityId : 0;
    },
    getTypeEntityCode(entityId, list, fieldReturn = "entityCode") {
      const find = list && list.find(item => item.entityId === entityId);
      return find ? find[fieldReturn] : "";
    },

    async loadDropDownListPefab(relative = "PrefTrain") {
      try {
        const paramIn = {
          self: this,
          relative: relative
        };
        const res = await api(API_METHODS.BASE_V_SELECT_LOAD_DATA, paramIn);
        return res.object;
      } catch (e) {
        console.error("неудалось получить список", e);
        return [];
      }
    }
  }
};
</script>
