// sabreRemark
import EditPageSabreRemarkContract from "../sabreRemark/EditPageSabreRemarkContract.vue"
const META_CLIENT_CREDITCARD = {
    routeNameShow: "ShowSabreRemarkContract",
    routeNameCreate: "CreateSabreRemarkContract",
    routeNameEdit: "EditSabreRemarkContract"
};
export default [
        {
            path: '/contract/:ContractId/sabreRemark/create',
            name: 'CreateSabreRemarkContract',
            component: EditPageSabreRemarkContract,
            meta: META_CLIENT_CREDITCARD
        },
        {
            path: '/contract/:ContractId/sabreRemark/:SubjectParamId/edit',
            name: 'EditSabreRemarkContract',
            component: EditPageSabreRemarkContract,
            meta: META_CLIENT_CREDITCARD
        },
        {
            path: '/contract/:ContractId/sabreRemark/:SubjectParamId/show',
            name: 'ShowSabreRemarkContract',
            component: EditPageSabreRemarkContract,
            meta: META_CLIENT_CREDITCARD
        }       
    ]
