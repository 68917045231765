<template>
  <common-page-edit
    :title="title"
    :nameRoleEntity="nameRoleEntity"
    :apiNameGet="apiNameGet"
    :apiNameCreate="apiNameCreate"
    :apiNameUpdate="apiNameUpdate"
    routeNameShow="ShowVisa"
    routeNameCreate="CreateVisa"
    :keyRoute="'PersonId'"
    :backUrl="pathBack"
    :convertLoadData="convertLoadData"
    :paramsSave="paramsSave"
    :isDefaultConverter="false"
    :paramsSend="paramsSend"
  >
  </common-page-edit>
</template>
<script>
import { NAME_OBJECT } from "../../../../../RoleConstanans";
import CommonPageEdit from "../../../commonElement/CommonPageEdit.vue";
import convertFieldInfoListToScheme from "../../../../../helpers/converters/convetFieldInfoListToScheme";

import { API_METHODS } from "../../../../../constant";

export default {
  name: "EditPageVisa",
  components: {
    CommonPageEdit
  },
  data() {
    return {
      wait: false,

      fieldInfoList: null,
      preResult: null,

      routeNameShow: "ShowVisa",
      routeNameCreate: "CreateVisa",
      pathBack: `/contract/${this.$route.params.ContractId}/person/${this.$route.params.PersonId}/edit`,

      apiNameGet: API_METHODS.GET_VISA,
      apiNameCreate: API_METHODS.CREATE_VISA,
      apiNameUpdate: API_METHODS.UPDATE_VISA,

      contactList: [],
      controlLoad: false
    };
  },
  computed: {
    nameEnity() {
      return this.$route.params.visa;
    },
    title() {
      let text1 = this.$t("Просмотр визы");
      let text2 = this.$t("Создание визы");
      let text3 = this.$t("Редактирование визы");

      let findShow = this.$route.path.indexOf("show");
      if (findShow != -1) {
        return text1;
      }
      let findCreate = this.$route.path.indexOf("create");
      if (findCreate != -1) {
        return text2;
      }
      let findEdit = this.$route.path.indexOf("edit");
      if (findEdit != -1) {
        return text3;
      }
      return text1;
    },
    nameRoleEntity() {
      return NAME_OBJECT.visa;
    },
    paramsSend() {
      return {
        id: this.$route.params.DocumentId || "0",
        PersonId: this.$route.params.PersonId
      };
    }
  },

  methods: {
    convertLoadData(res) {
      let loadData = res.object;
      let fieldInfoList = res.fieldInfoList;
      let scheme = { configForm: [] };

      scheme.configForm = convertFieldInfoListToScheme({
        fieldInfoList,
        hasLang: false
      });

      loadData.personId = this.$route.params.PersonId;
      scheme.configForm = scheme.configForm.map(item => {
        if (item.fieldName === "number") {
          return {
            ...item,
            validationRule: {
              required: true,
              regex: "^[a-zA-Z0-9]+$"
            }
          };
        }
        return item;
      });

      return {
        loadData,
        fieldInfoList,
        scheme
      };
    },

    paramsSave() {
      return {
        id: this.$route.params.DocumentId,
        PersonId: this.$route.params.PersonId
      };
    }
  }
};
</script>
