import { API_METHODS } from "../../constant";
export const contractApi = (
  _getAll,
  _get,
  _update,
  _create,
  _delete,
  paramsIn,
  _recoverData
) => {
  return {
    [API_METHODS.GET_CONTRACT]: () => {
      let urlReq = `/Contract/${paramsIn.id}`;
      return _get(urlReq);
    },

    [API_METHODS.GET_ALL_CONTRACT]: () => {
      let dopParams = {};
      let urlReq = "";
      if (paramsIn.CompanyId) {
        urlReq = "/Contract/Company/" + paramsIn.CompanyId;
        if (paramsIn.isClient) {
          dopParams = {
            fromClient: true
          };
        }
      } else {
        urlReq = `/Contract`;
      }
      return _getAll(urlReq, dopParams);
    },

    [API_METHODS.UPDATE_CONTRACT]: () => {
      let urlReq = `/Contract`;
      return _update(urlReq);
    },

    [API_METHODS.CREATE_CONTRACT]: () => {
     let urlReq = `/Contract`;
      return _create(urlReq);
    },

    [API_METHODS.DELETE_CONTRACT]: () => {
      let urlReq = `/Contract/${paramsIn.ContractId}`;
      return _delete(urlReq);
    },

    [API_METHODS.GET_CONTACT_HISTORY]: () => {
      let urlReq = `/${paramsIn.PersonId}/Contact/History`;
      return _getHistory(urlReq);
    },

    [API_METHODS.RETURN_CONTACT]: () => {
      let urlReq = `/Person/${paramsIn.PersonId}/Contact/Return`;
      return _recoverData(urlReq);
    }
  };
};
