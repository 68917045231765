import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue";

// const ClientTablePage = () =>
//     import ("./client/ClientTablePage.vue");
// const ClientEditPageDynamic = () =>
//     import ("./client/ClientEditPageDynamic.vue");

// const SupplierTablePage = () =>
//     import ("./supplier/SupplierTablePage.vue");
// const SupplierEditPageDynamic = () =>
//     import ("./supplier/SupplierEditPageDynamic.vue");



const AgentTablePage = () =>
    import ("./agent/AgentTablePage.vue");
const AgentEditPageDynamic = () =>
    import ("./agent/AgentEditPageDynamic.vue");

import EditPageContactMain from "../contact/EditPageContactMain.vue";
import EditPageAddresMain from "../address/EditPageAddresMain"

// import routeAttrVal from "./routeAttrValue"
import routeCreditCard from "./routeCreditCard"
import routeBrand from "./routeBrand"
import {NAME_OBJECT} from "../../../RoleConstanans"
const META_CLIENT_CONTACT = {
    typeCompany: "client",
    fieldRoute: "CompanyId",
    configName: "clientContact",
    roleName: NAME_OBJECT.clientContact,
    routeNameShow: "ClientShowPageContact",
    routeNameCreate: "ClientCreatePageContact",
    routeNameEdit: "ClientEditPageContact"
};
const META_CLIENT_ADDRESS = {
    typeCompany: "client",
    fieldRoute: "CompanyId",
    configName: "CompanyAddress",
    roleName: NAME_OBJECT.CompanyAddress,
    routeNameShow: "ClientShowPageAddress",
    routeNameCreate: "ClientCreatePageAddress",
    routeNameEdit: "ClientEditPageAddress"
};

const META_CLIENT = {
    typeCompany: 'client'
}

const META_AGENT_CONTACT = {
    typeCompany: "agent",
    fieldRoute: "CompanyId",
    configName: "clientContact",
    roleName: NAME_OBJECT.clientContact,
    routeNameShow: "AgentShowPageContact",
    routeNameCreate: "AgentCreatePageContact",
    routeNameEdit: "AgentEditPageContact"
};
const META_AGENT_ADDRESS = {
    typeCompany: "agent",
    fieldRoute: "CompanyId",
    configName: "CompanyAddress",
    roleName: NAME_OBJECT.CompanyAddress,
    routeNameShow: "AgentShowPageAddress",
    routeNameCreate: "AgentCreatePageAddress",
    routeNameEdit: "AgentEditPageAddress"
};

const META_AGENT = {
    typeCompany: 'agent'
}
const META_SUPPLIER_CONTACT = {
    typeCompany: "supplier",
    fieldRoute: "CompanyId",
    configName: "clientContact",
    roleName: NAME_OBJECT.clientContact,
    routeNameShow: "SupplierShowPageContact",
    routeNameCreate: "SupplierCreatePageContact",
    routeNameEdit: "SupplierEditPageContact"
};
const META_SUPPLIER_ADDRESS = {
    typeCompany: "supplier",
    fieldRoute: "CompanyId",
    configName: "CompanyAddress",
    roleName: NAME_OBJECT.CompanyAddress,
    routeNameShow: "SupplierShowPageAddress",
    routeNameCreate: "SupplierCreatePageAddress",
    routeNameEdit: "SupplierEditPageAddress"
};
const META_SUPPLIER = {
    typeCompany: 'supplier'
}



import EditSupplierService from "./supplierService/EditSupplierService.vue"

export default {
    path: "/company",
    component: DashboardLayout,
    redirect: "/company/client",
    children: [

        // { перенесено в v2
        //     path: "client",
        //     name: "ClientTable",
        //     component: ClientTablePage
        // },
        // {    перенесено в v2
        //     path: "supplier",
        //     name: "SupplierTablePage",
        //     component: SupplierTablePage
        // },


        // {    перенесено в v2
        //     path: "client/:CompanyId/edit",
        //     name: "ClientEdit",
        //     component: ClientEditPageDynamic,
        //     meta: META_CLIENT
        // },
        // {
        //     path: "client/:CompanyId/show",
        //     name: "ClientShow",
        //     component: ClientEditPageDynamic,
        //     meta: META_CLIENT
        // },



     
        // {
        //     path: "client/create",
        //     name: "ClientCreate",
        //     component: ClientEditPageDynamic
        // },

        // {    перенесено в v2
        //     path: "supplier/:CompanyId/edit",
        //     name: "SupplierEdit",
        //     component: SupplierEditPageDynamic,
        //     meta: META_SUPPLIER
        // },
        {
            path: "supplier/:CompanyId/supplierService/create",
            name: "SupplierServiceCreate",
            component: EditSupplierService
        },



        // {    перенесено в v2
        //     path: "supplier/:CompanyId/show",
        //     name: "SupplierShow",
        //     component: SupplierEditPageDynamic,
        //     meta: META_SUPPLIER
        // },
        // {
        //     path: "supplier/create",
        //     name: "SupplierCreate",
        //     component: SupplierEditPageDynamic
        // },

        // {  переписал в v2
        //     path: "agent", 
        //     name: "AgentTablePage",
        //     component: AgentTablePage
        // },
        // {
        //     path: "agent/:CompanyId/edit",
        //     name: "AgentEdit",
        //     component: AgentEditPageDynamic,
        //     meta: META_AGENT
        // },
        // {
        //     path: "agent/:CompanyId/show",
        //     name: "AgentShow",
        //     component: AgentEditPageDynamic,
        //     meta: META_AGENT
        // },
        // {
        //     path: "agent/create",
        //     name: "AgentCreate",
        //     component: AgentEditPageDynamic
        // },
        //contacts
        // {
        //     path: "client/:CompanyId/contact/:ContactId/edit",
        //     name: "ClientEditPageContactMain",
        //     component: EditPageContactMain,
        //     meta: META_CLIENT_CONTACT
        // },
        // {
        //     path: "client/:CompanyId/contact/:ContactId/show",
        //     name: "ClientShowPageContact",
        //     component: EditPageContactMain,
        //     meta: META_CLIENT_CONTACT
        // },
        // {
        //     path: "client/:CompanyId/contact/create",
        //     name: "ClientCreatePageContact",
        //     component: EditPageContactMain,
        //     meta: META_CLIENT_CONTACT
        // },

      
      
        // {  переписал в v2
        //     path: "supplier/:CompanyId/contact/:ContactId/edit",
        //     name: "SupplierEditPageContactMain",
        //     component: EditPageContactMain,
        //     meta: META_SUPPLIER_CONTACT
        // },
        // {
        //     path: "supplier/:CompanyId/contact/:ContactId/show",
        //     name: "SupplierShowPageContact",
        //     component: EditPageContactMain,
        //     meta: META_SUPPLIER_CONTACT
        // },
        // {
        //     path: "supplier/:CompanyId/contact/create",
        //     name: "SupplierCreatePageContact",
        //     component: EditPageContactMain,
        //     meta: META_SUPPLIER_CONTACT
        // },





        // {переписал в v2
        //     path: "agent/:CompanyId/contact/:ContactId/edit",
        //     name: "AgentEditPageContactMain",
        //     component: EditPageContactMain,
        //     meta: META_AGENT_CONTACT
        // },
        // {
        //     path: "agent/:CompanyId/contact/:ContactId/show",
        //     name: "AgentShowPageContact",
        //     component: EditPageContactMain,
        //     meta: META_AGENT_CONTACT
        // },
        // {
        //     path: "agent/:CompanyId/contact/create",
        //     name: "AgentCreatePageContact",
        //     component: EditPageContactMain,
        //     meta: META_AGENT_CONTACT
        // },
        // end contacts
        // address  переписал в v2
        // {
        //     path: "client/:CompanyId/address/:ContactId/edit",
        //     name: META_CLIENT_ADDRESS.routeNameEdit,
        //     component: EditPageAddresMain,
        //     meta: META_CLIENT_ADDRESS
        // },
        // {
        //     path: "client/:CompanyId/address/:ContactId/show",
        //     name: META_CLIENT_ADDRESS.routeNameShow,
        //     component: EditPageAddresMain,
        //     meta: META_CLIENT_ADDRESS
        // },
        // {
        //     path: "client/:CompanyId/address/create",
        //     name: META_CLIENT_ADDRESS.routeNameCreate,
        //     component: EditPageAddresMain,
        //     meta: META_CLIENT_ADDRESS
        // },

        // {
        //     path: "supplier/:CompanyId/address/:ContactId/edit",
        //     name: META_SUPPLIER_ADDRESS.routeNameEdit,
        //     component: EditPageAddresMain,
        //     meta: META_SUPPLIER_ADDRESS
        // },
        // {
        //     path: "supplier/:CompanyId/address/:ContactId/show",
        //     name: META_SUPPLIER_ADDRESS.routeNameShow,
        //     component: EditPageAddresMain,
        //     meta: META_SUPPLIER_ADDRESS
        // },
        // {
        //     path: "supplier/:CompanyId/address/create",
        //     name: META_SUPPLIER_ADDRESS.routeNameCreate,
        //     component: EditPageAddresMain,
        //     meta: META_SUPPLIER_ADDRESS
        // },

        // {
        //     path: "agent/:CompanyId/address/:ContactId/edit",
        //     name: META_AGENT_ADDRESS.routeNameEdit,
        //     component: EditPageAddresMain,
        //     meta: META_AGENT_ADDRESS
        // },
        // {
        //     path: "agent/:CompanyId/address/:ContactId/show",
        //     name: META_AGENT_ADDRESS.routeNameShow,
        //     component: EditPageAddresMain,
        //     meta: META_AGENT_ADDRESS
        // },
        // {
        //     path: "agent/:CompanyId/address/create",
        //     name: META_AGENT_ADDRESS.routeNameCreate,
        //     component: EditPageAddresMain,
        //     meta: META_AGENT_ADDRESS
        // },



        // ...routeAttrVal,
        ...routeCreditCard,
        //...routeBrand
    ]
};