<template>
  <div :class="generateClass" class="m-0 p-0">
    <card :title="config.label">
      <l-button class="mr-1"  wide outline @click="addLang(noLang)" v-for="(noLang, index) in noLangs" :key="index+99" >
        <span class="btn-label"><i class="el-icon-plus el-icon-right"></i></span>{{noLang.title}}
      </l-button>
      <vue-tabs  type="pills" :class="alertShow">
        <v-tab :title="langList[formData.languageCode].title" v-for="(formData, index) in form[config.fieldName]" :key="index" >
          <dynamic-generator-form
            :isCreateForm="isCreateForm"
            :configData="config.children"
            :form="formData"
            :languageCode="formData.languageCode"
            :pageMode="pageMode"
            >
          </dynamic-generator-form>
        </v-tab>
      </vue-tabs>
    </card>
  </div>
</template>
<script>
  import Vue from 'vue'
  import VueTabs from 'vue-nav-tabs'
  import {LANGS} from '../../../../constant'
  import clearReactive from '../../../../helpers/clearReactive'
  Vue.use(VueTabs)
  import {generateClass} from  '../../../../mixins/baseComponets/generateClass'
  export default {
    name: 'BaseLangEntityBlock',
    mixins: [generateClass],
    props: {
      config: {type: Object, required: true},
      form: {type: Object},
      isCreateForm:{type: Boolean, default: false},
      pageMode:  {type: String, required: true},
    },
    created(){
        let _temp = {}
        LANGS.forEach( lang =>{_temp[lang.languageCode] = lang})
        this.langList = _temp
    },
    data() {
      return {
        langList: {},
      }
    },
    methods:{
      addLang(code){
        let nowdata = clearReactive(this.form[this.config.fieldName][0])
        nowdata.languageCode = code.languageCode
        nowdata.languageId = code.languageId
        nowdata.refEntityLangDTOId = null
        this.form[this.config.fieldName].push(nowdata)
      }
    },

    computed: {
      noLangs(){
        let allLang = LANGS
        allLang = allLang.filter( lang => {
         let find =  this.form[this.config.fieldName].find(el=> el.languageCode == lang.languageCode)
         return !find
        })
        return allLang
      },
      alertShow(){
        let haserrror = false;
        try {
          if (this.form[this.config.fieldName].length > 1){
            this.config.children.forEach(child => {
              const {fieldName, validationRule} = child;
              if(validationRule?.required) {
                const findErr = this.form[this.config.fieldName].find(item => !item[fieldName])
                  if(findErr){
                    haserrror = true;
                  }
              }
            })
          }
        } catch (error) {
          console.log('language validation ', error);
        }
        return haserrror ? 'error': '';
      }
    }
  }
</script>
<style lang="scss">
  // css transition for tabs
  .vue-tabs .tab-content {
    padding-top: 10px;
    min-height: 100px;
    display: flex; // to avoid horizontal scroll when animating
    .tab-container {
      animation: fadeIn 0.5s;
    }
  }
  .vue-tabs .tab-container{
    width: 100%;
  }
  .vue-tabs ul.nav-pills {
    border-radius: 10px;
    position: relative;
    display: inline-flex;
    border: 1px solid rgba(223, 227, 233, 0.503047);
    padding: 8px;
    border-radius: 8px; // support: IE9+ ;)
    background-clip: border-box; // support: IE9+
    background-color: #fff;
  }
  .vue-tabs.error ul.nav-pills {
    border: 1px solid #FB404B;
    &::after {
      content: 'Заполните на всех языках';
      padding-left: 10px;
      position: absolute;
      right: -180px;
      top: 12px;
      width: 180px;
      color: #FB404B;
      font-size: 13px;
    }
  }
  .vue-tabs .nav-pills>li.tab.active>a,
  .vue-tabs .nav-pills>li.tab.active>a:hover,
  .vue-tabs .nav-pills>li.tab.active>a:focus {
    background: #637EAF;
    border: none;
    span {
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      font-family: 'proxima-nova';
    }
  }
  .vue-tabs .nav-pills>li.tab>a,
  .vue-tabs .nav-pills>li.tab>a:hover,
  .vue-tabs .nav-pills>li.tab>a:focus {
    span {
      color: #90AAB7;
      font-size: 14px;
      font-weight: 600;
      font-family: 'proxima-nova';
     }
    padding: 3px 13px;
    background: rgba(11, 45, 116, 0.103584);
    border: none;
  }
  .vue-tabs ul.nav-pills>li+li {
    margin-left: 0px;
  }
</style>
