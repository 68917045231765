import { actions } from "./actions";
import { mutations } from "./mutations";
const state = {
  data: {},
  fieldInfoList:[],
  fieldTable: {},

  dataRole : [],
  fieldInfoListRole : []

};

export default {
  state,
  actions,
  mutations
};
