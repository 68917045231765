export const getters = {
  getProfile: state => state.profile,
  isProfileLoaded: state => !!state.profile.name,
  isAdminRole:state => {
    if(state.profile) {
      if (state.profile.roleCode == "Admin") {
        return true
      }
    }
    return false
  }
};
