import { READ } from '../../../../store/type'
import { JURNAL, JURNAL_PROCES, JURNAL_OUT } from "./type";
export const mutations = {
    [JURNAL + READ]: (state, { data, fieldInfoList, filterList }) => {
        state.data = data
        state.fieldInfoList = fieldInfoList
        if (filterList) {
            state.filterList = filterList
        }

    },
    [JURNAL_PROCES + READ]: (state, { data, fieldInfoList }) => {
        state.dataProcess = data
        state.fieldInfoListProcess = fieldInfoList
    },
    [JURNAL_OUT + READ]: (state, { data, fieldInfoList }) => {
        state.dataOut = data
        state.fieldInfoListOut = fieldInfoList
    },
};