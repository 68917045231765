import { READ, FORM_UPDATE } from '../../../../store/type'
import { ENTITY_NAME, GET_LIST_ENTITY, SET_PAGINATION, SET_PAGINATION_PER_PAGE, SET_PAGINATION_PAGE, SET_SEARCH_ENTITY, SET_SORT_ENTITY } from "./type";
import Vue from "vue";

export const mutations = {
    [ENTITY_NAME + READ]: (state, { entity, data, fieldInfoList }) => {
        Vue.set(state.data, entity, data)
        state.fieldInfoList = fieldInfoList
    },
    [GET_LIST_ENTITY]: (state, list) => {
        state.listLoadEntity = list
    },
    [ENTITY_NAME + FORM_UPDATE]: (state, form) => {
        state.form = form
    }
};