<template>
  <div>
    <show-code :code="fieldInfoList"></show-code>
    <show-code :code="preResult"></show-code>
    <load-block :load="!load"></load-block>
    <the-edit-page :scheme="scheme" :isEntity="true" :loadData="loadData" v-if="load" :title="title" :pageMode="pageMode">
      <the-controls-edit-page :scheme="scheme"
                              :loadData="loadData"
                              :saveData="saveData"
                              :pathBack="pathBack"
                               configName="adminmanger"
                              :pageMode="pageMode"
                              :wait="wait"
                              :roleName="nameRoleEntity"
      ></the-controls-edit-page>
    </the-edit-page>
  </div>
</template>
<script>
 
  import TheEditPage from "../../TheEditPage.vue";
  import {ENTITY_NAME, SET_TAB_ADMIN_MANAGER} from "./store/type";
  import {READ, UPDATE, CREATE} from "../../../store/type";
  import TheControlsEditPage from "../../TheControlsEditPage.vue";
  import clearReactive from "../../../helpers/clearReactive";
  import ShowCode from "../../shared/ShowCode.vue";
  import convertFieldInfoListToScheme from "../../../helpers/converters/convetFieldInfoListToScheme";
  import clearNull from "../../../../src/helpers/clearNull";
  import {mapState} from 'vuex';

  import {TYPE_ERRORS, FIELD_LANG_PERSON, PAGE_MODE} from '../../../constant'
  import notify from '../../../helpers/notify/notify'
  import LoadBlock from "../../shared/LoadBlock.vue";
  import {checkValidate} from '../../../mixins/validate/checkValidate'
  import {pageMode} from '../../../mixins/editPage/pageMode'
  import {NAME_OBJECT} from "../../../RoleConstanans"
  export default {
    name: "EditPageContract",
    components: {
      LoadBlock,
      ShowCode,
      TheControlsEditPage,
      TheEditPage,
    },
    mixins: [checkValidate, pageMode],
    data() {
      return {
        pathBack:`/adminmanager`,
        scheme: {},
        loadData: null,
        fieldInfoList: null,
        preResult: null,
        load: true,
        routeNameShow: 'ShowRouteAdmin',
        routeNameCreate:  'CreateRouteAdmin',
        wait: false
      };
    },
    computed: {
      ...mapState({
        lang: state => state.common.lang
      }),
       title() {
        return this.pageTitle(
          this.$t('Просмотр маршрута'),
          this.$t('Создание маршрута'),
          this.$t('Редактирование маршрута'))
      },
       nameRoleEntity(){
          return NAME_OBJECT.Esb
        },
    },
    watch: {
      lang() {
        this.initData();
      }
    },
    created() {
      this.$store.commit(SET_TAB_ADMIN_MANAGER, this.$route.params.systemId+'_tab')
      this.initData();
    },
    methods: {

      initData() {
        this.load = false;
        this.$store
          .dispatch(ENTITY_NAME + READ, {
            id: this.$route.params.esbRouteId || '0',
            systemId: this.$route.params.systemId,
            self: this
          })
          .then(res => {
          
            this.loadData = res.object;
            let fieldInfoList = res.fieldInfoList;
            this.fieldInfoList = fieldInfoList; //del in future
            this.scheme.configForm = convertFieldInfoListToScheme({
              fieldInfoList, hasLang:false
            });
            this.preResult = convertFieldInfoListToScheme({fieldInfoList, hasLang:false}); //del in future

            this.load = true;
          });
      },
      saveData() {
        if(this.validateData() == false) {
          return ;
        }
        let saveData = clearReactive(this.loadData);
        saveData = clearNull(saveData);
        this.wait = true;
        let action = (this.routeNameCreate ==  this.$route.name)? CREATE : UPDATE
        this.$store
          .dispatch(ENTITY_NAME + action, {
            id: this.$route.params.esbRouteId,
            systemId: this.$route.params.systemId,
            saveData: saveData,
            self: this
          })
          .then(res => {
            this.wait = false;
            if (res.errors.length == 0) {
              notify(this, this.$t('Успешно'), TYPE_ERRORS.SUCCESS)

              // if(this.pageMode == PAGE_MODE.CREATE) {
              //   this.$router.push({path: `/adminmanager/${this.$route.params.systemId}/${res.object.esbRouteId}/edit`})
              // }  else {
                this.$router.push({path: this.pathBack})
              // }  

                
              
            }
          }).catch((err)=>{
           console.error(err)
           this.wait = false;
          });;
      }
    }
  };
</script>
