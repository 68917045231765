import { LOAD_ROLE_USER, CHANGE_DATA_ROLE } from "./type";
import clearReactive from "../../../../helpers/clearReactive"
export const mutations = {
  /**
   * updateData показывает нужно ли обновлять данные 
   * если false то изменить только название
   */
  [LOAD_ROLE_USER]: (state, { data, fieldInfoList, updateData = true }) => {
    if (updateData == true) {
      state.dataRole = data
    } else {
      let newData = data
      let oldData = clearReactive(state.dataRole)
      try {
        oldData = oldData.map(el => {
          let findNewData = newData.find(_el => _el.elementCode == el.elementCode)
          el.elementName = findNewData.elementName
          return el
        })
      } catch (e) {
        console.error("ошибка сопостовления данных при сменне языка")
      }
      state.dataRole = oldData
    }
    state.fieldInfoListRole = fieldInfoList
  },
  [CHANGE_DATA_ROLE]: (state, { elementCode, params }) => {
    let nowData = clearReactive(state.dataRole)
    nowData = nowData.map(el => {
      let res = el
      if (el.elementCode == elementCode) {
        res[params] = !res[params]
      }
      return res
    })
    state.dataRole = nowData
  },
};
