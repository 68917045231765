import { API_METHODS } from "../../constant";
export const supplierServicesApi = (
  _getAll,
  _update,
  _create,
  _delete,
  paramsIn,
  _loadData,
  HttpClientLocal
) => {
  return {
    [API_METHODS.GET_ALL_SUPPLIER_SERVICE]: () => {
      let urlReq = `/Company/SupplierService/${paramsIn.CompanyId}`;
      return _getAll(urlReq);
    },
    [API_METHODS.CREATE_SUPPLIER_SERVICE]: () => {
      let urlReq = `/Company/SupplierService/CreateChild`;
      return _create(urlReq);
    },
    [API_METHODS.DELETE_SUPPLIER_SERVICE]: () => {
      let urlReq = `/Company/SupplierService/DeleteChild`;
      return _delete(urlReq);
    },
    [API_METHODS.GET_ALL_SUPPLIER_SERVICE_UNLINKED]: () => {
      let urlReq = `/Company/SupplierService/${paramsIn.CompanyId}/Unlinked`;
      return _loadData(urlReq, paramsIn.query);
    },
    [API_METHODS.DELETE_SUPPLIER_SERVICE]: () => {
      let urlReq = `/Company/SupplierService/DeleteChild`;
      const sendData = {
        childId: paramsIn.childId,
        parentId: paramsIn.parentId
      };
      return HttpClientLocal.postRequest(urlReq, sendData);
    }
  };
};
