<template>
  <common-page-table
    :apiName="apiName"
    :nameRole="nameRoleEntity"
    :titleTable="$t('Поставщики')"
    :paramsGetData="paramsGetData"
    :fieldLand="fieldLang"
    :keyTable="'id'"
    :deleteParams="deleteParams"
    :apiDeleteName="apiDeleteName"
    :alternativeConverter="convertData"
    :isDefaultConverter="false"
  />
</template>
<script>
import { NAME_OBJECT } from "../../../../RoleConstanans";
import CommonPageTable from "../../commonElement/CommonPageTable.vue";
import { API_METHODS, FIELD_LANG_COMPANY} from "../../../../constant";
import formatData from "../../../../helpers/converters/formatData";
import convertLangDataToTable from "../../../../helpers/converters/convertLangDataToTable";
export default {
  name: "SupplierTablePage",
  components: { CommonPageTable },
  data() {
    return {
      fieldLang: FIELD_LANG_COMPANY,
      apiName: API_METHODS.GET_ALL_COMPANY,
      apiDeleteName: API_METHODS.DELETE_COMPANY
    };
  },
  computed: {
    paramsGetData() {
      return { typeCompany: 'Supplier' };
    },
    nameRoleEntity() {
      return NAME_OBJECT.supplier
    }
  },
  methods: {
    convertData(dataNow, langNow) {
      let res = convertLangDataToTable(dataNow, langNow, FIELD_LANG_COMPANY)
      return res;
    },
    deleteParams(id) {
      return { id: id };
    },
  }
};
</script>

