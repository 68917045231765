<template>
  <div class="sidebar"
       :class="$sidebar.isMinimized ? 'minimized' : 'expanded'"
       :style="sidebarStyle"
       :data-color="backgroundColor"
       :data-image="backgroundImage"
       :data-active-color="activeColor">
    <div class="navbar-minimize" :class="$sidebar.isMinimized ? 'minimized' : 'expanded'">
      <button class="btn btn-link" @click="minimizeSidebar">
        <i :class="$sidebar.isMinimized ? 'table_icon icon_showMenu' : 'table_icon icon_showMenu'"></i>
      </button>
    </div>
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <div class="logo">
        <a class="logo-mini"
           href="/">
            <img :src="logo" alt="logo">
        </a>
        <a href="/" class="logo-normal">
          <img :src="logotext" alt="vipservice">
        </a>
      </div>
      <ul class="nav">
        <slot name="links">
          <sidebar-item v-for="(link, index) in sidebarLinks"
                        :key="link.name?link.name:0 + index"
                        :link="link">
            <sidebar-item v-for="(subLink, index) in link.children"
                          :key="subLink.name?subLink.name:99 + index"
                          :link="subLink">
            </sidebar-item>
          </sidebar-item>
        </slot>

      </ul>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'sidebar',
    props: {
      title: {
        type: String,
        default: 'Vue LBD PRO'
      },
      backgroundColor: {
        type: String,
        default: 'pink',
        validator: (value) => {
          let acceptedValues = ['', 'blue', 'azure', 'green', 'orange', 'red', 'purple', 'black', 'pink']
          return acceptedValues.indexOf(value) !== -1
        }
      },
      backgroundImage: {
        type: String,
        default: ''
      },
      activeColor: {
        type: String,
        default: 'success',
        validator: (value) => {
          let acceptedValues = ['primary', 'info', 'success', 'warning', 'danger']
          return acceptedValues.indexOf(value) !== -1
        }
      },
      logo: {
        type: String,
        default: '/static/img/logo-eng_mini.svg'
      },
      logotext: {
        type: String,
        default: '/static/img/logo-eng_letters.svg'
      },
      sidebarLinks: {
        type: Array,
        default: () => []
      },
      autoClose: {
        type: Boolean,
        default: true
      }
    },
    provide () {
      return {
        autoClose: this.autoClose
      }
    },
    computed: {
      sidebarStyle () {
        return {
          backgroundImage: `url(${this.backgroundImage})`
        }
      }
    },
    methods: {
      async initScrollBarAsync () {
        try{
        await import('perfect-scrollbar/dist/css/perfect-scrollbar.css')
        const PerfectScroll = await import('perfect-scrollbar')
        PerfectScroll.initialize(this.$refs.sidebarScrollArea)
        } catch(e) {
          console.warn('ошибка подгрузки скрола', e)
        }
      },
      toggleSidebar () {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      hideSidebar () {
        this.$sidebar.displaySidebar(false)
      },
      minimizeSidebar () {
        this.$sidebar.toggleMinimize()
      },
    },
    mounted () {
      this.initScrollBarAsync()
    },
    beforeDestroy () {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.showSidebar = false
      }
    },
  }

</script>
<style>
  .navbar-minimize {
    position: absolute;
    top: 164px;
    width: 58px;
    height: 58px;
  }
  .navbar-minimize.minimized {
    z-index: 0;
    right: 15px;
  }
  .sidebar-mini .sidebar:hover .navbar-minimize.minimized {
    right: -48px;
    z-index: 6;
  }
  .navbar-minimize.expanded {
    right: -48px;
    z-index: 6;
  }
  @media (min-width: 992px) {
    .navbar-search-form-mobile,
    .nav-mobile-menu{
      display: none;
    }
  }
</style>
