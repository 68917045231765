import {LANGS} from '../../constant'
import clearReactive from '../clearReactive'

export default (jsonSheme) =>{
  let res = {}
  try  {
    clearReactive(jsonSheme).forEach(el=>{
      let data = null
      if(el.children){
        if(el.landComponent){
          data = []
          LANGS.forEach(lang=>{
            let childrenEl = {}
            el.children.forEach(ch=>{
              if(ch.fieldName){
                childrenEl[ch.fieldName] = null
              }
            })
            childrenEl ={...childrenEl, ...clearReactive(lang)}
            data.push(childrenEl)
          })
        }
      }
      res[el.fieldName] = data
    })
  } catch (e){
    console.error('ошибка ', e)
  }
  return res
}
