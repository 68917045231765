import {TYPE_COMPONETS} from './constants'
import extendComponents from './extendComponents'
import checkbox from './componets/checkbox'
import info from './componets/info'
import space from './componets/space'
import separator from './componets/separator'
import radio from './componets/radio'
import select from './componets/select'
import entityLangBlock from './componets/entityLangBlock'
import vselect from './componets/vselect'
import vselectComplex from './componets/vselectComplex'
import vselectComplexMulti from "./componets/vselectComplexMulti"

export default [
  {...extendComponents(TYPE_COMPONETS.INPUT)},
  {...extendComponents(TYPE_COMPONETS.INPUT_MASK), mask: "" },
  {...extendComponents(TYPE_COMPONETS.INPUT_NUMBER)},
  {...extendComponents(TYPE_COMPONETS.INPUT_PRICE)},
  {...extendComponents(TYPE_COMPONETS.TEXTAREA)},
  {...extendComponents(TYPE_COMPONETS.INPUT_DATE)},
  info,
  select,
  vselect,
  vselectComplex,
  vselectComplexMulti,
  checkbox,
  radio,
  separator,
  space,
  entityLangBlock,
  {
    ...extendComponents(TYPE_COMPONETS.GROUP),
    enable: false,
    children: []  // заготовка для блоковой формы
  },
  {
    ...extendComponents(TYPE_COMPONETS.TAB),
    enable: false,
    children: []  // заготовка для блоковой формы
  },
  {
    ...extendComponents(TYPE_COMPONETS.FILE),
    typeFiles: null,
    enable: false,
    multi: false,
    initData: null
  },
  {
    ...extendComponents(TYPE_COMPONETS.SLOT),
    enable: false
  },

]
