<template>
  <div>
    <div v-if="ribbon" class="cover-ribbon">
      <div class="cover-ribbon-inside" title="новый код">new</div>
    </div>

    <load-block :load="!load"></load-block>
    <the-edit-page
      :scheme="scheme"
      :isEntity="true"
      :loadData="loadData"
      v-if="load && scheme"
      :title="title"
      :pageMode="pageMode"
    >
      <the-controls-edit-page
        :loadData="loadData"
        :saveData="saveData"
        :pathBack="backUrl"
        configName="entitis"
        :roleName="nameRoleEntity"
        :pageMode="pageMode"
        :wait="wait"
        :forceNoShowEdit="forceNoShowEdit"
        :forceNoShowReturn="forceNoShowReturn"
        :hasHistory="showHistory"
        :openHistory="showHistoryFunction"
      >
      </the-controls-edit-page>
      <slot />
      <slot name="block2"></slot>
    </the-edit-page>
   <history-modal :isVisible="isVisibleHistory" :toggleModal="showHistoryFunction" :apiNameGetHistory="apiNameGetHistory"
    :keyRoute="keyRouteHistory" />
  </div>
</template>
<script>
import TheEditPage from "../../TheEditPage.vue";
import TheControlsEditPage from "../../TheControlsEditPage.vue";
import clearReactive from "../../../helpers/clearReactive";
import ShowCode from "../../shared/ShowCode.vue";
import convertFieldInfoListToScheme from "../../../helpers/converters/convetFieldInfoListToScheme";
import clearNull from "../../../../src/helpers/clearNull";
import { mapState } from "vuex";
import { TYPE_ERRORS, PAGE_MODE } from "../../../constant";
import notify from "../../../helpers/notify/notify";
import LoadBlock from "../../shared/LoadBlock.vue";
import { checkValidate } from "../../../mixins/validate/checkValidate";
import { pageMode } from "../../../mixins/editPage/pageMode";
import { ApiService } from "../../../util/ApiService";
import HistoryModal from './HistoryModal.vue';
export default {
  name: "CommonPageEdit",
  props: {
    title: {
      type: String,
      required: true,
    },
    nameRoleEntity: {
      type: String,
    },
    apiNameGet: {
      type: String,
      required: true,
    },
    apiNameCreate: {
      type: String,
      required: true,
    },
    apiNameUpdate: {
      type: String,
      required: true,
    },
    paramsSend: {
      type: Object,
    },
    hasLang: {
      type: Boolean,
      default: true,
    },
    langFieldName: {
      type: String,
      default: "refEntityLangDTOList",
    },
    backUrl: {
      type: String,
      required: true,
    },
    routeNameShow: {
      type: String,
      required: true,
      default: "EntityDynamicShow",
    },
    routeNameCreate: {
      type: String,
      required: true,
      default: "CreatePageDynamic",
    },
    keyRoute: {
      type: String,
      default: "id",
    },
    convertLoadData: {
      type: Function,
    },
    readDataFromParent: {
      type: Boolean,
      default: false,
    },
    loadDataIn: {},
    schemeIn: {},

    loadIn: {},
    controlLoad: {
      type: Boolean,
      default: false,
    },
    isDefaultConverter: {
      type: Boolean,
      default: true,
    },

    isDefaultConverterSaveData: {
      type: Boolean,
      default: true,
    },
    convertorSaveData: {
      type: Function
    },

    forceNoShowEdit: { type: Boolean, default: false },
    forceNoShowReturn: { type: Boolean, default: false },
    showHistory: {type: Boolean, default: false },

    apiNameGetHistory: {type: String},
    keyRouteHistory: {type: String},

  },
  mixins: [checkValidate, pageMode],
  components: {
    LoadBlock,
    ShowCode,
    TheControlsEditPage,
    TheEditPage,
    HistoryModal,
  },
  data() {
    return {
      wait: false,
      schemeLocal: {},
      loadDataLocal: null,
      fieldInfoList: null,
      loadLocal: true,
      ribbon: false,
      isVisibleHistory: false
    };
  },
  computed: {
    ...mapState({
      lang: (state) => state.common.lang,
    }),
    scheme: {
      get() {
        return this.readDataFromParent ? this.schemeIn : this.schemeLocal;
      },
      set(val) {
        if (this.readDataFromParent) {
          this.$emit("set-sheme", val);
        } else {
          this.schemeLocal = val;
        }
      },
    },
    loadData: {
      get() {
        return this.readDataFromParent ? this.loadDataIn : this.loadDataLocal;
      },
      set(val) {
        if (this.readDataFromParent) {
          this.$emit("set-loadData", val);
        } else {
          this.loadDataLocal = val;
        }
      },
    },
    load: {
      get() {
        if (this.controlLoad) {
          return this.loadIn;
        } else {
          return this.loadLocal;
        }
      },
      set(val) {
        if (this.controlLoad) {
          this.$emit("set-load", val);
        } else {
          this.loadLocal = val;
        }
      },
    },
  },
  watch: {
    lang() {
      this.initData();
    },
  },
  created() {
    this.initData();
    if (location.hostname == "localhost") {
      this.ribbon = true;
    }
  },

  methods: {
    showHistoryFunction(val=true){
      this.isVisibleHistory = val;
    },
    async initData() {
      this.load = false;
      try {
        const res = await ApiService(this.apiNameGet, {
          self: this,
          id: this.$route.params[this.keyRoute],
          lang: this.lang,
          ...this.paramsSend,
        });

        if (!this.isDefaultConverter) {
          const convert = this.convertLoadData(res);
          const { loadData, fieldInfoList, scheme } = convert;

          this.loadData = loadData;
          this.scheme = scheme;
          this.load = true;
        } else {
          this.loadData = res.object;
          this.scheme.configForm = convertFieldInfoListToScheme({
            fieldInfoList: res.fieldInfoList,
            langFieldName: this.langFieldName ? this.langFieldName : null,
            hasLang: this.hasLang,
          });

          this.load = true;
        }
      } catch (e) {
        console.error("Ошибка получение данных", e);
      }
      if (!this.readDataFromParent) {
        this.$emit("set-loadData", this.loadData);
      }
      this.load = true;
    },
    async saveData() {
      if (this.validateData() == false) {
        return;
      }
      let saveData = clearReactive(this.loadData);
      saveData = clearNull(saveData);
      if (!this.isDefaultConverterSaveData) {
        saveData = this.convertorSaveData(saveData);
      }
      this.wait = true;
      try {
        let nameApi = this.apiNameCreate;
        if (this.pageMode == PAGE_MODE.CREATE) {
          nameApi = this.apiNameUpdate;
        }
        const res = await ApiService(nameApi, {
          ...this.paramsSend,
          self: this,
          id: this.$route.params[this.keyRoute],
          lang: this.lang,
          saveData: saveData,
          ...(this.paramsSave ? this.paramsSave : {}),
        });

        this.wait = false;
        if (res && res.errors && res.errors.length == 0) {
          notify(this, this.$t("Успешно"), TYPE_ERRORS.SUCCESS);
          if (this.backUrl) {
            this.$router.push({ path: this.backUrl });
          }
        }
      } catch (err) {
        console.error(err);
        this.wait = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@keyframes scroll {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.marquee {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.marquee span {
  display: inline-block;
  padding-left: 100%;
  -webkit-animation: scroll 5s infinite linear;
  -moz-animation: scroll 5s infinite linear;
  animation: scroll 5s infinite linear;
}
</style>></style>
