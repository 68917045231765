<template>
  <span class="navbar-text" v-if="profile">
    <b>{{ profile.name }} </b> <span class="d-sm-none d-xl-inline"> ({{ profile.roleName }})</span> <br />
    {{ profile.companyName }}
  </span>
</template>

<script>
import { mapState } from "vuex";
import { AUTH_ME } from "../../store/type";
export default {
  name: "UserInfo",
  computed: {
    profile() {
      return this.$store.state.user.profile;
    },
    ...mapState({
      lang: state => state.common.lang
    })
  },
  watch: {
    lang() {
      this.$store.dispatch(AUTH_ME, { self: this });
    }
  }
};
</script>

<style lang="scss" scoped>
.navbar-text {
  color: #597888;
}
</style>
