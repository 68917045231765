import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue'

import JournalPage from "../bus/JournalPage.vue"
export default {
    path: '/',
    component: DashboardLayout,
    children: [{
        path: '/journal',
        name: 'JournalPage',
        component: JournalPage
    }, ]
}