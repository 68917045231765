<template>
  <TheСustomTablePage
    :titleTable="$t('Исходящие данные')"
    :tableData="tableData"
    :load="load"
    fieldId="id"
    :updateData="updateData"
    :fieldInfoList="fieldInfoList"
    :searchStart="searchStart"
    :sortData="allPaginator.sortData"
    :setSort="setSort"
    :deleteEl="deleteEl"
    configName="shina"
    :manualMode="true"
    :allPaginator="allPaginator"
    :isDetail="isDetail"
    :toggleModal="toggleModal"
    :textAction="'Детали'"
    :roleName="nameRoleEntity"
    :forceIsShow="false"
    :forceIsEdit="false"
    :forceDelete="false"
    :forceIsCreate="false"
  ></TheСustomTablePage>
</template>
<script>
import TheСustomTablePage from "../../TheСustomTablePage.vue";
import { JURNAL_OUT } from "./store/type";

import {
  SET_MAIN_PAGINATION,
  SET_MAIN_PAGINATION_PER_PAGE,
  SET_MAIN_PAGINATION_PAGE,
  SET_MAIN_SORT_ENTITY,
  SET_MAIN_SEARCH_ENTITY
} from "../../../../src/store/type";

import { READ, DELETE } from "../../../store/type";
import { mapState } from "vuex";
import NotificationSample from "src/pages/Dashboard/Components/NotificationSample";
import { DEFAULT_PER_PAGE, TYPE_ERRORS } from "../../../constant";
import notify from "../../../helpers/notify/notify";
import convertLangDataToTable from "../../../helpers/converters/convertLangDataToTable";
import formatData from "../../../helpers/converters/formatData";
import {NAME_OBJECT} from "../../../RoleConstanans"
export default {
  components: {
    TheСustomTablePage
  },
  props: {
    routeField: { type: String, default: "PersonId" },
    configName: { type: String, default: "clientPersonContact" },
    isCompanyType: { type: Boolean, default: true },
    DataChangedId: { type: Number, default: null },
    isDetail: { type: Boolean, default: false },
    toggleModal: { type: Function },
  },
  data() {
    return {
      load: false,
      allPaginator: {
        pagination: {
          perPage: 5,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        sortData: {
          prop: "refEntityId",
          order: "descending"
        },
        search: ""
      }
    };
  },
  computed: {
    ...mapState({
      fieldInfoList: state => state.bus.fieldInfoListOut,
      lang: state => state.common.lang,
      sortData: state => state.pagination.sortData
    }),
    nameRoleEntity(){
      return NAME_OBJECT.Esb
    },
    tableData() {
      let langNow = this.$store.state.common.lang;
      let dataNow = this.$store.state.bus.dataOut || [];
      if (dataNow && dataNow.length > 0) {
        dataNow = convertLangDataToTable(dataNow, langNow, "", false);
        dataNow = dataNow.map(item => {
          return {
            ...item,
            dateCreated: formatData(item.dateCreated, "DD.MM.YYYY HH:mm:ss"),
            dataFull: item.data,
            data:  item.data ? item.data.substr(0, 30) + "..." : item.data  ,
            errorFull: item.error,
           error:  (item.error && item.error.substr(0, 30)).length >0 ? item.error.substr(0, 30) + "..." : '' }
        });
        return dataNow;
      }
    }

    // prefixEdit() {
    //   const route = this.$route;
    //   return `/company/${route.meta.typeCompany}/${route.params.CompanyId}/contact`;
    // },
    // prefixShow() {
    //   return this.prefixEdit;
    // },
    // linkCreate() {
    //   return this.prefixEdit + "/create";
    // }
  },
  watch: {
    lang() {
      this.initData();
    },
    DataChangedId() {
      this.initData();
    }
  },
  methods: {
    getData() {
      this.load = true;
      this.$store
        .dispatch(JURNAL_OUT + READ, {
          self: this,
          DataChangedId: this.DataChangedId,
          allPaginator: this.allPaginator
        })
        .then(res => {
          // todo total
          this.allPaginator.pagination.total = res.countPage;
          this.load = false;
        })
        .catch(err => {
          this.load = false;
          notify(this, this.$t("Ошибка получения данных"), TYPE_ERRORS.DANGER);
        });
    },
    updateData() {
      this.getData();
    },
    searchStart(search) {
      this.allPaginator.search = search;
      this.getData();
    },
    setSort(val) {
      this.$store.commit(SET_MAIN_SORT_ENTITY, val);
      this.getData();
    },
    deleteEl(id) {},
    initData() {
      this.$store.commit(JURNAL_OUT + READ, { data: [], fieldInfoList: [] });
      this.allPaginator.search = "";
      // this.allPaginator.pagination.perPage =5;
      this.allPaginator.pagination.currentPage = 1;
      this.getData();
    }
  },
  created() {
    this.initData();
  }
};
</script>
