/**
 * ConvertFieldInfoToScheme
 * конвертирует приходяший данные для формы в схему для генерации
 * @param fieldInfoList
 * @param langFieldName
 * @returns {*}
 */

import getSchemeComponet from '../generatorForm/getSchemeComponet'
import clearReactive from '../clearReactive'
export default ({fieldInfoList, langFieldName = 'refEntityLangDTOList', hasLang = true, hasReadonly = true}) => {
  fieldInfoList = clearReactive(fieldInfoList)
  let res = fieldInfoList
  try {
    let allScheme = []
    let childLang = []
    fieldInfoList.forEach(shortConfig => {
      let scheme = getSchemeComponet(shortConfig.type, shortConfig, langFieldName)
      if(hasReadonly == false && ('readonly' in scheme)) {
        scheme.readonly = false
      }
      if (shortConfig.isLang == true && hasLang) {
        childLang.push(scheme)
      } else {
        allScheme.push(scheme)
      }
    })
    if(hasLang) {
      let langComponet = getSchemeComponet('lang', null, langFieldName)
      langComponet.children = childLang
      allScheme.unshift(langComponet)
    }
    res = allScheme
  }
  catch (e) {
    console.error('ошибка конвертации', e);
  }
  return res
}
