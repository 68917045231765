import { READ, SET_MAIN_PAGINATION_TOTAL } from '../../../../store/type'
import { JURNAL, JURNAL_PROCES, JURNAL_OUT } from "./type";
import { api } from "../../../../util/api";
import { API_METHODS } from "../../../../constant";

export const actions = {

    [JURNAL + READ]: async({ state, rootState, commit }, paramsIn = null) => {
        try {
            const res = await api(API_METHODS.JURNAL_GET, paramsIn, rootState)
            commit(JURNAL + READ, {
                data: res.object,
                fieldInfoList: res.fieldInfoList,
                filterList: res.filterList
            })
            commit(SET_MAIN_PAGINATION_TOTAL, {
                countPage: res.countPage,
            })
            return res
        } catch (err) {
            return (err)
        }
    },

    [JURNAL_PROCES + READ]: async({ state, rootState, commit }, paramsIn = null) => {
        try {
            const res = await api(API_METHODS.JURNAL_CHANGE_GET, paramsIn, rootState)
            commit(JURNAL_PROCES + READ, {
                data: res.object,
                fieldInfoList: res.fieldInfoList,

            })
            return res
        } catch (err) {
            return (err)
        }
    },
    [JURNAL_OUT + READ]: async({ state, rootState, commit }, paramsIn = null) => {
        try {
            const res = await api(API_METHODS.JURNAL_OUT_MES_GET, paramsIn, rootState)
            commit(JURNAL_OUT + READ, {
                data: res.object,
                fieldInfoList: res.fieldInfoList
            })
            return res
        } catch (err) {
            return (err)
        }
    },

};