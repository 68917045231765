import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue'
const EditPageCode = () =>
    import ('./EditPageCode.vue')
const EditPageCodeEnum = () =>
    import ('./codeEnum/EditPageCodeEnum.vue')
const TablePage = () =>
    import ('./TablePage.vue');

export default {
    path: '/',
    component: DashboardLayout,
    children: [{
            path: 'code',
            name: 'ShowCode',
            component: TablePage
        },
        {
            path: 'code/create',
            name: 'CreateCode',
            component: EditPageCode
        },
        {
            path: 'code/:CodeId/edit',
            name: 'CodeEdit',
            component: EditPageCode
        },
        {
            path: 'code/:CodeId/show',
            name: 'CodeShow',
            component: EditPageCode
        },
        {
            path: 'code/:CodeId/CodeEnum/create',
            name: 'CreateCodeEnum',
            component: EditPageCodeEnum
        },
        {
            path: 'code/:CodeId/CodeEnum/:CodeEnumId/edit',
            name: 'CodeEnumEdit',
            component: EditPageCodeEnum
        },
        {
            path: 'code/:CodeId/CodeEnum/:CodeEnumId/show',
            name: 'CodeEnumShow',
            component: EditPageCodeEnum
        }
    ]
}