<template>
  <the-table-page :titleTable = "$t('Контакты')"
                  :tableData="tableData"
                  :load="load"
                  fieldId="id"
                  :updateData="updateData"
                  :fieldInfoList="fieldInfoList"
                  :searchStart="searchStart"
                  :sortData="sortData"
                  :setSort="setSort"
                  :deleteEl="deleteEl"
                  :prefixEdit="isCompanyType?prefixEdit:`/company/${$route.params.CompanyId}/person/${$route.params.PersonId}/contact`"
                  :prefixShow="isCompanyType?prefixShow:`/company/${$route.params.CompanyId}/person/${$route.params.PersonId}/contact`"
                  :linkCreate="isCompanyType?linkCreate:`/company/${$route.params.CompanyId}/person/${$route.params.PersonId}/contact/create`"
                  :configName = "configName"
                  :roleName="nameRoleEntity"
                  :hasReadOnlyInTable="hasReadOnlyInTable" 
                  :nameTableSaveInCashe="nameTableSaveInCashe"
  ></the-table-page>
</template>
<script>
  import TheTablePage from "../../TheTablePage.vue";
  import {MAIN_CONTACT} from "./store/type";

  import {
    SET_MAIN_PAGINATION,
    SET_MAIN_PAGINATION_PER_PAGE,
    SET_MAIN_PAGINATION_PAGE,
    SET_MAIN_SORT_ENTITY,
    SET_MAIN_SEARCH_ENTITY
  } from "../../../../src/store/type"

  import {READ, DELETE} from '../../../store/type'
  import {mapState} from 'vuex';
  import NotificationSample from 'src/pages/Dashboard/Components/NotificationSample';
  import {DEFAULT_PER_PAGE, TYPE_ERRORS, GET_MEMORY_PER_PAGE} from '../../../constant'
  import notify from '../../../helpers/notify/notify'
  import convertLangDataToTable from '../../../helpers/converters/convertLangDataToTable'
import {NAME_OBJECT} from "../../../RoleConstanans"
  export default {
    components: {
      TheTablePage
    },
    props: {
      routeField: {type: String, default: "PersonId"},
      configName: {type: String, default: "clientPersonContact" }, // устарело неиспользую 
      isCompanyType: {type: Boolean, default: true},
      hasReadOnlyInTable: { type: Boolean, default: true }, 
    },
    data() {
      return {
        load: false
      }
    },
    computed: {
      ...mapState({
        fieldInfoList: state => state.contact.fieldInfoList,
        lang: state => state.common.lang,
        sortData: state => state.pagination.sortData,
      }),
      nameRoleEntity(){
        const nameRoute = this.$route.name
        let role = NAME_OBJECT.clientPersonContact
        switch (nameRoute) {
          case 'AgentShow':
          case 'AgentEdit' : {
             role =  NAME_OBJECT.ContactAgency
             break
          }
          case 'ClientShow' : 
          case 'ClientEdit' : {  
            role =  NAME_OBJECT.ContactClient
            break
          }
        }
        
        return role
      },
      nameTableSaveInCashe(){
        return this.nameRoleEntity+"--"+this.$route.path
      },
      tableData() {
          let langNow = this.$store.state.common.lang
          let dataNow = this.$store.state.contact.data || []
          if (dataNow && dataNow.length > 0) {
            dataNow = convertLangDataToTable(dataNow, langNow, '', false)
            return dataNow
          }
      },
      prefixEdit() {
        const route = this.$route
        return `/company/${route.meta.typeCompany}/${route.params.CompanyId}/contact`
      },
      prefixShow() {
        return this.prefixEdit
      },
      linkCreate() {
         return this.prefixEdit+'/create'
      }
    },
    watch: {
      lang() {
        this.initData()
      }
    },
    methods: {
      getData() {
        this.load= true
        this.$store.dispatch(MAIN_CONTACT + READ, {self:this, SubjectId: this.$route.params[this.routeField] }).then(res=>{
          this.load= false
        })
        .catch(err=>{
          this.load= false
          notify(this, this.$t('Ошибка получения данных'), TYPE_ERRORS.DANGER)
        })
      },
      updateData() {
        this.getData()
      },
      searchStart(search){
        this.$store.commit(SET_MAIN_SEARCH_ENTITY, search)
        this.getData()
      },
      setSort(val){
        this.$store.commit(SET_MAIN_SORT_ENTITY, val)
        this.getData()
      },
      deleteEl(id){
        this.$store.dispatch(MAIN_CONTACT + DELETE, {SubjectId:  this.$route.params[this.routeField],  id: id, self:this} ).then(res=>{
          this.getData()
             if(res.errors && res.errors.length  == 0){notify(this, this.$t("Успешно"),TYPE_ERRORS.SUCCESS); }
        })
      },
      initData() {
        this.$store.commit(MAIN_CONTACT + READ ,{ data: [], fieldInfoList: []})
        this.$store.commit( SET_MAIN_SORT_ENTITY,{})
        this.$store.commit( SET_MAIN_SEARCH_ENTITY, '')
        this.$store.commit( SET_MAIN_PAGINATION_PER_PAGE, GET_MEMORY_PER_PAGE())
        this.$store.commit( SET_MAIN_PAGINATION_PAGE, 1)
        const name = this.nameTableSaveInCashe ? this.nameTableSaveInCashe : null;
        this.$store.dispatch("SET_OLD_PAGINATION", name)
        this.getData()
      }
    },
    created() {
      this.initData()
    }
  }
</script>
