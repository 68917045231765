import AgreementsVstEditPageDynamic from "./AgreementsVstEditPageDynamic.vue";
import AgreementsVstTablePage from "./AgreementsVstTablePage.vue";
import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue";
import  routeVstContagent from "../vstContragent/routeVstContagent";

export default {
  path: "/vst/",
  component: DashboardLayout,
  children: [
    {
      path: "agreements",
      name: "AgreementsVstTablePage",
      component: AgreementsVstTablePage
    },
    {
      path: "agreements/:CompanyId/show",
      name: "AgreementsVstEditPageDynamic",
      component: AgreementsVstEditPageDynamic
    },
    ...routeVstContagent
  ]
};
