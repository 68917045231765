import { CREATE, READ, UPDATE, DELETE, SET_MAIN_PAGINATION_TOTAL, SEARCH } from '../../../../store/type'
import { ENTITY_NAME, COMMERCIAL_TERMS_RULE, CONTRACT_COMMERCIAL_TERMS } from "./type";
import HttpClient from "../../../../util/HttpClient";
import { api } from "../../../../util/api";
import { API_METHODS } from "../../../../constant";

export const actions = {
    [ENTITY_NAME + READ]: async({ state, rootState, commit }, paramsIn = null) => {
        try {
            if (paramsIn.id) {
                return await api(API_METHODS.GET_FINANCIAL_CONDITION, paramsIn, rootState)
            } else {
                const res = await api(API_METHODS.GET_ALL_FINANCIAL_CONDITION, paramsIn, rootState)
                commit(ENTITY_NAME + READ, {
                    data: res.object,
                    fieldInfoList: res.fieldInfoList
                })
                commit(SET_MAIN_PAGINATION_TOTAL, {
                    countPage: res.countPage,
                })
                return res
            }
        } catch (err) {
            return (err)
        }
    },
    [ENTITY_NAME + UPDATE]: async({ commit }, paramsIn = null) => {
        try {
            return await api(API_METHODS.UPDATE_FINANCIAL_CONDITION, paramsIn)
        } catch (err) {
            return (err)
        }
    },
    [ENTITY_NAME + CREATE]: async({ commit }, paramsIn = null) => {
        try {
            return await api(API_METHODS.CREATE_FINANCIAL_CONDITION, paramsIn)
        } catch (err) {
            return (err)
        }
    },
    [ENTITY_NAME + DELETE]: async({}, paramsIn = null) => {
        try {
            return await api(API_METHODS.DELETE_FINANCIAL_CONDITION, paramsIn)
        } catch (err) {
            return (err)
        }
    },

    [CONTRACT_COMMERCIAL_TERMS + READ]: async({ state, rootState, commit }, paramsIn = null) => {
        try {
            const res = await api(API_METHODS.GET_ALL_CHILD_TYPE_CONTACT, paramsIn, rootState)
            commit(ENTITY_NAME + READ, {
                data: res.object,
                fieldInfoList: res.fieldInfoList
            })
            commit(SET_MAIN_PAGINATION_TOTAL, {
                countPage: res.countPage,
            })
            return res
        } catch (err) {
            return err
        }
    },


    [CONTRACT_COMMERCIAL_TERMS + DELETE]: async({}, paramsIn = null) => {
        try {
            return await api(API_METHODS.DELETE_CHILD_TYPE_CONTACT, paramsIn)
        } catch (err) {
            return err
        }
    },






    [COMMERCIAL_TERMS_RULE + READ]: async({ state, rootState, commit }, paramsIn = null) => {
        try {
            if (paramsIn.id) {
                return await api(API_METHODS.GET_FINANCIAL_CONDITION_RULE, paramsIn, rootState)
            } else {
                const res = await api(API_METHODS.GET_ALL_FINANCIAL_CONDITION_RULE, paramsIn, rootState)
                commit(ENTITY_NAME + READ, {
                    data: res.object,
                    fieldInfoList: res.fieldInfoList
                })
                commit(SET_MAIN_PAGINATION_TOTAL, {
                    countPage: res.countPage,
                })
                return res
            }
        } catch (err) {
            return (err)
        }
    },
    [COMMERCIAL_TERMS_RULE + UPDATE]: async({ commit }, paramsIn = null) => {
        try {
            return await api(API_METHODS.UPDATE_FINANCIAL_CONDITION_RULE, paramsIn)
        } catch (err) {
            return (err)
        }
    },
    [COMMERCIAL_TERMS_RULE + CREATE]: async({ commit }, paramsIn = null) => {
        try {
            return await api(API_METHODS.CREATE_FINANCIAL_CONDITION_RULE, paramsIn)
        } catch (err) {
            return (err)
        }
    },
    [COMMERCIAL_TERMS_RULE + DELETE]: async({}, paramsIn = null) => {
        try {
            return await api(API_METHODS.DELETE_FINANCIAL_CONDITION_RULE, paramsIn)
        } catch (err) {
            return (err)
        }
    },



};