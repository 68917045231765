
import BrandTablePage from "./brand/BrandTablePage.vue"
import BrandEditPageDynamic from "./brand/BrandEditPageDynamic.vue"
export const META_BRAND = {
    typeCompany: 'Brand',
    routeNameTable: "TableBrand",
    routeNameShow: "BrandShowPage",
    routeNameCreate: "BrandCreatePage",
    routeNameEdit: "BrandEditPage"
}

export default [
    {
        path: "brand",
        name: META_BRAND.routeNameTable,
        component: BrandTablePage,
        meta: META_BRAND
    },
    {
        path: "brand/:CompanyId/edit",
        name: META_BRAND.BrandEditPage,
        component: BrandEditPageDynamic,
        meta: META_BRAND
    },
    {
        path: "brand/:CompanyId/show",
        name:  META_BRAND.routeNameShow,
        component: BrandEditPageDynamic,
        meta: META_BRAND
    },
    {
        path: "brand/create",
        name:  META_BRAND.routeNameCreate,
        component: BrandEditPageDynamic,
        meta: META_BRAND
    },


]