// import { API_METHODS }  from  GET_DATA_MULTISELEC
import { API_METHODS } from "../../constant";
export const multiSelectApi = (
  _getAll,
  _get,
  _update,
  _create,
  _delete,
  paramsIn
) => {
  return {
    [API_METHODS.GET_DATA_MULTISELEC]: () => {
      let urlReq = `/MultiSelect/${paramsIn.mainType}/${paramsIn.id}/${paramsIn.type}`;
      return _get(urlReq);
    }
}}