import { READ, UPDATE, DELETE, SET_MAIN_PAGINATION_TOTAL, CREATE } from '../../../../store/type'
import { SABRE_REMARK } from "./type";
import { api } from "../../../../util/api";
import { API_METHODS } from "../../../../constant";

export const actions = {

    [SABRE_REMARK + READ]: async({ state, rootState, commit }, paramsIn = null) => {
        try {
            if (paramsIn.SubjectParamId) {
                return await api(API_METHODS.GET_SABRE_REMARK, paramsIn, rootState)
            } else {
                const res = await api(API_METHODS.GET_ALL_SABRE_REMARK, paramsIn, rootState)
                commit(SABRE_REMARK + READ, {
                    data: res.object,
                    fieldInfoList: res.fieldInfoList
                })
                commit(SET_MAIN_PAGINATION_TOTAL, {
                    countPage: res.countPage,
                })
                return res
            }
        } catch (err) {
            return (err)
        }
    },
    [SABRE_REMARK + CREATE]: async({ commit, rootState }, paramsIn = null) => {
        try {
            return await api(API_METHODS.CREATE_SABRE_REMARK, paramsIn, rootState)
        } catch (err) {
            return (err)
        }
    },
    [SABRE_REMARK + UPDATE]: async({ commit, rootState }, paramsIn = null) => {
        try {
            return await api(API_METHODS.UPDATE_SABRE_REMARK, paramsIn, rootState)
        } catch (err) {
            return (err)
        }
    },

    [SABRE_REMARK + DELETE]: async({ rootState }, paramsIn = null) => {
        try {
            return await api(API_METHODS.DELETE_SABRE_REMARK, paramsIn, rootState)
        } catch (err) {
            return (err)
        }
    },
};