import { READ, UPDATE, DELETE, SET_MAIN_PAGINATION_TOTAL} from '../../../../store/type'
import {LOAD_ROLE_USER, UPDATE_ROLE_USER} from "./type";
import HttpClient from "../../../../util/HttpClient";
import {api} from "../../../../util/api";
import {API_METHODS} from "../../../../constant";

export const actions = {
  [LOAD_ROLE_USER]: async ({commit,rootState}, paramsIn = null) => {
    try {
      let res =  await api(API_METHODS.GET_ROLE_USER, paramsIn, rootState )
      commit(LOAD_ROLE_USER, {
        data: res.object,
        fieldInfoList: res.fieldInfoList,
        updateData: paramsIn.updateData
      })
      return res
    } catch(err) {
      return (err)
    }
  },
  [UPDATE_ROLE_USER]: async ({commit, rootState}, paramsIn) => {
    try {
      return await api(API_METHODS.USER_UPDATE_ROLE, paramsIn, rootState)
    } catch(err) {
      return (err)
    }
  }
};
