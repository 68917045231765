import { API_METHODS } from "../../constant";

export const statisticApi = (
  _getAll,
  _get,
  _update,
  _create,
  _delete,
  _getFileStatistic,
  paramsIn,
  _getFile
) => {
  return {
    [API_METHODS.GET_ALL_STATISTIC_HR_FEED]: () => {
      let urlReq = `/Statistics/HrFeed`;
      return _getAll(urlReq, paramsIn.paramsDop ? paramsIn.paramsDop: {} );
    },
    [API_METHODS.GET_STATISTIC_HR_FEED]: () => {
      let urlReq = `/Statistics/HrFeed/${paramsIn?.paramsDop?.contragentId}`;
      return _getAll(urlReq, paramsIn.paramsDop ? paramsIn.paramsDop: {} );
    },


    [API_METHODS.GET_ALL_STATISTIC_GetCatalogUnit]: () => {
      let urlReq = `/Statistics/GetCatalogUnit`;
      return _getAll(urlReq,  paramsIn.paramsDop ? paramsIn.paramsDop: {});
    },
    [API_METHODS.GET_STATISTIC_GetCatalogUnit]: () => {
      let urlReq = `/Statistics/GetCatalogUnit/${paramsIn?.paramsDop?.receiverSystemCode}`;
      return _getAll(urlReq, paramsIn.paramsDop ? paramsIn.paramsDop: {} );
    },
    [API_METHODS.SAVE_STATISTIC]: () => {
      let urlReq = `/Statistics/GetCatalogUnit/Save`;
      return _getAll(urlReq, paramsIn.paramsDop ? paramsIn.paramsDop: {} );
    },

    [API_METHODS.GET_ALL_STATISTIC_HR_FEED_EXPORT]: () => {
      let urlReq = `/Statistics/HrFeedExport`;
      return _getAll(urlReq, paramsIn.paramsDop ? paramsIn.paramsDop: {} );
    },
    [API_METHODS.GET_STATISTIC_HR_FEED_EXPORT]: () => {
      let urlReq = `/Statistics/HrFeedExport/${paramsIn?.paramsDop?.contragentId}`;
      return _getAll(urlReq, paramsIn.paramsDop ? paramsIn.paramsDop: {} );
    },

    [API_METHODS.GET_LOAD_STATISTIC]: () => {
      let urlReq = `/Statistics/GetCatalogUnit/Save/${paramsIn?.paramsDop?.receiverSystemCode}`;
      return _getFile(urlReq, paramsIn.paramsDop ? paramsIn.paramsDop: {} );
    },

  };
};
