<template>
  <common-page-table
    :apiName="apiName"
    :nameRole="nameRoleEntity"
    :titleTable="$t('Адреса')"
    :paramsGetData="paramsGetData"
    :fieldLand="fieldLang"
    :keyTable="'id'"
    :deleteParams="deleteParams"
    :apiDeleteName="apiDeleteName"
    :alternativeConverter="convertData"
    :isDefaultConverter="false"
    :prefixEdit="prefixEdit"
    :prefixShow="prefixShow"
    :linkCreate="linkCreate"
    :hasReadOnlyInTable="hasReadOnlyInTable"
  />
</template>
<script>
import { NAME_OBJECT } from "../../../../RoleConstanans";
import CommonPageTable from "../../commonElement/CommonPageTable.vue";
import { API_METHODS } from "../../../../constant";
import convertLangDataToTable from "../../../../helpers/converters/convertLangDataToTable";
export default {
  name: "AddresTable",
  components: { CommonPageTable },
  props: {
    hasReadOnlyInTable: { type: Boolean, default: true },
    routeField: { type: String, default: "CompanyId" }
  },
  data() {
    return {
      fieldLang: "",
      apiName: API_METHODS.GET_ALL_ADDRES,
      apiDeleteName: API_METHODS.DELETE_ADDRES
    };
  },
  computed: {
    paramsGetData() {
      return { SubjectId: this.$route.params[this.routeField] };
    },
    prefixEdit() {
      const route = this.$route;
      return `/company/${route.meta.typeCompany}/${route.params.CompanyId}/address`;
    },
    prefixShow() {
      return this.prefixEdit;
    },
    linkCreate() {
      return this.prefixEdit + "/create";
    },
    nameRoleEntity() {
      const nameRoute = this.$route.name;
      let role = NAME_OBJECT.CompanyAddress;

      switch (nameRoute) {
        case "AgentCreatePageContact":
        case "AgentShow":
        case "AgentEdit": {
          role = NAME_OBJECT.AddressAgency;
          break;
        }
        case "ClientCreatePageContact":
        case "ClientShow":
        case "ClientEdit": {
          role = NAME_OBJECT.AddressClient;
          break;
        }
      }

      return role;
    }
  },
  methods: {
    convertData(dataNow, langNow) {
      let res = convertLangDataToTable(dataNow, langNow, "", false);
      return res;
    },
    deleteParams(id) {
      return { SubjectId: this.$route.params[this.routeField], id: id };
    }
  }
};
</script>
