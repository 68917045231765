<template>
  <sidebar-item :link="{name: $t('Персоны'), icon: 'nc-icon nc-circle-09'}">
    <sidebar-item :link="{name:  $t('Персоны'), path: `/person`}" ></sidebar-item>
  </sidebar-item>
</template>
<script>
    export default {
        name: 'PersonMenu',
    }
</script>
