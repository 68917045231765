import { CREATE, READ, UPDATE, DELETE, SET_MAIN_PAGINATION_TOTAL, SEARCH } from '../../../../store/type'
import { ENTITY_NAME } from "./type";
import HttpClient from "../../../../util/HttpClient";
import { api } from "../../../../util/api";
import { API_METHODS } from "../../../../constant";

export const actions = {
    [ENTITY_NAME + READ]: async({ state, rootState, commit }, paramsIn = null) => {
        try {
            if (paramsIn.id) {
                return await api(API_METHODS.GET_CONTRACT, paramsIn, rootState)
            } else {
                const res = await api(API_METHODS.GET_ALL_CONTRACT, paramsIn, rootState)
                commit(ENTITY_NAME + READ, {
                    data: res.object,
                    countPage: res.countPage,
                    fieldInfoList: res.fieldInfoList
                })
                commit(SET_MAIN_PAGINATION_TOTAL, {
                    countPage: res.countPage,
                })
                return res
            }
        } catch (err) {
            return (err)
        }
    },
    [ENTITY_NAME + UPDATE]: async({ commit }, paramsIn = null) => {
        try {
            return await api(API_METHODS.UPDATE_CONTRACT, paramsIn)
        } catch (err) {
            return (err)
        }
    },

    [ENTITY_NAME + DELETE]: async({}, paramsIn = null) => {
        try {
            return await api(API_METHODS.DELETE_CONTRACT, paramsIn)
        } catch (err) {
            return (err)
        }
    },
};