<template>
  <el-dialog
    center
    @open="initData"
    :title="$t('История изменений')"
    :visible.sync="modals[nameModal]">
    <table-history :tableData="loadData"
                   :fieldInfoList="fieldInfoList"
                   :load="load"
                   :updateData="updateData"
                   :searchStart="searchStart"
                   :sortData="sortData"
                   :setSort="demo"
                   :backData="backData"
                   configName = "clientPersonContact"
    >
    </table-history>
    <span slot="footer" class="dialog-footer">
              <button class="btn btn-danger" @click="toggleModal(nameModal)">Закрыть</button>
            </span>
  </el-dialog>
</template>
<script>
  import {HISTORY_CONTACT,  RETURN_CONTACT} from "./store/type";
  import {historyModal} from "../../../mixins/history/HistoryModal";

  export default {
    name: 'HistoryPersonContact',
    mixins: [historyModal],
    data : function () {
      return {
        history : HISTORY_CONTACT,
        return : RETURN_CONTACT,
        paramsReturn : {
          PersonId: this.$route.params.PersonId
        },
        paramsGet : {
          ContactId: this.$route.params.ContactId,
          PersonId: this.$route.params.PersonId,
          self: this
        }
      }
    },
  }
</script>
