import notify from "../helpers/notify/notify";
import { TYPE_ERRORS, TOKEN_TIME_START, TIME_MS_EXPIRED } from "../constant";

export default (res, self) => {
  try {
    let errors = [];
    if (res) {
      if ("data" in res) {
        errors = res.data.errors;
      }

      if ("response" in res) {
        if (res.response && "data" in res.response) {
          if (res.response.data && "errors" in res.response.data) {
            errors = res.response.data.errors;
            if (errors) {
              if (!(errors instanceof Array)) {
                errors = Object.entries(errors);
              }
              errors = errors.map(er => {
                if (er instanceof Array) {
                  return er[0] + " " + er[1].join(" ");
                }
                return er;
              });
            }
          } else {
            errors = ["Нет данных в ответе."];
          }
        } else {
          // errors = ['Нет данных в ответе.']
        }
      }
    } else {
      errors = ["Токен устарел."];
    }
    if (res && "message" in res && res.message == "Network Error") {
      errors = [...errors, ...["Нет ответа от сервера. Возможно нет доступа!"]];
    }

    if (errors && self && self.$notify) {
      errors.forEach(err => {
        notify(self, self.$t(err), TYPE_ERRORS.DANGER);
      });
    }

    // проверка усторевания токена
    try {
      let timeMsSet =  localStorage.getItem(TOKEN_TIME_START);
      let nowms = Date.now();
      if (
        self &&
        timeMsSet &&
        nowms > timeMsSet * 1 + TIME_MS_EXPIRED &&
        self.$router &&
        self.$route.name &&
        self.$route.name !== "Login"
      ) {
        console.warn("токен просрочен");
        self.$router.push("/login");
      }
    } catch (e) {
        console.error("ошибка в проверке врменени  жизни токена!!!")
    }
  } catch (e) {
    console.error("err ", e);
    if (self && self.$notify) {
      let err = self.$t("Ошибка в обработчике ошибок! ") + e;
      notify(self, err, TYPE_ERRORS.DANGER);
    }
  }
};
