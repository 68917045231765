import { actions } from "./actions";
import { mutations } from "./mutations";
const state = {
    form: {},
    data: {},
    fieldInfoList: [],
    filters: null,
    listEntity: [],
    fieldTable: {},
    tabnow: 'contract'
};

export default {
    state,
    actions,
    mutations
};