<template>
  <div class="wrapper" :class="{'nav-open': $sidebar.showSidebar}">
    <notifications></notifications>
    <side-bar :title="$t(title)" @click="testClick()">

      <template slot-scope="props" slot="links" v-if="userRoleLouded && isProfileLoaded" >
        <AgentMenu   v-if="showMenu(nameObject.agent)"  />
        <EventLogMenu v-if="showMenu(nameObject.EventLog)" />
        <entity-menu  v-if="showMenu(nameObject.Reference)"/>

        <!-- <sidebar-item :link="{name: 'Расписание', icon: 'vip_icon icon_crm', path: '/shedule'}"></sidebar-item> -->

        <company-menu v-if="showMenu(nameObject.clients) ||
                      showMenu(nameObject.brand) ||
                      showMenu(nameObject.travelers)"
                      :nameObject="nameObject"
                      :showMenu="showMenu"
        ></company-menu>

        <contract-menu v-if="showMenu(nameObject.contracts)"/>

        <corp-polity-menu v-if="showMenu(nameObject.corpPolity)"/>
        <commercial-terms-menu v-if="showMenu(nameObject.commercialTerms)" />
        <AttrEntityMenu  v-if="showMenu(nameObject.attrEntity)"/>
        <ImportMenu v-if="showMenu(nameObject.importFile)" />
        <BusMenu v-if="showMenu(nameObject.Esb)" />

        <sidebar-item :link="{name: $t('CRM'), icon: 'vip_icon icon_crm', path: '/crm'}" v-if="showMenu(nameObject.CrmHolding)"  >
          <sidebar-item :link="{name: $t('Agreements'), path: '/crm/Agreements'}"></sidebar-item>
          <sidebar-item :link="{name: $t('Counterparties'), path: '/crm/Counterparty'}"></sidebar-item>
          <sidebar-item :link="{name: $t('Counterparty Bank Details'), path: '/crm/CounterpartyBankDetails'}"></sidebar-item>
        </sidebar-item>
        <crm-corp-menu />
        <CfoMenu />
        <ReceiverSystemMenu />
        <SettingMenu v-if="showMenu(nameObject.Admin)" />

        <sidebar-item :link="{name: 'Dashboard Demo', icon: 'vip_icon icon_agency', path: '/admin/overview'}" v-if="showDemo">
          <sidebar-item :link="{name: 'Generator Forms', path: '/generator/generator'}"></sidebar-item>
          <sidebar-item :link="{name: 'Icons', path: '/components/icons'}"></sidebar-item>
        </sidebar-item>
        <LogoutMenu />
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>
      <dashboard-content @click.native="toggleSidebar" v-if="isProfileLoaded">
      </dashboard-content>
      <content-footer></content-footer>
    </div>
  </div>
</template>
<script>
  import TopNavbar from './TopNavbar.vue'
  import ContentFooter from './ContentFooter.vue'
  import DashboardContent from './Content.vue'
  import MobileMenu from './Extra/MobileMenu.vue'
  import UserMenu from './Extra/UserMenu.vue'
  import {NAME_SITE} from '@/constant.js'
  import EntityMenu from "../../../components/modules/entity/EntityMenu.vue";
  import CompanyMenu from "../../../components/modules/company/CompanyMenu.vue";
  import AgentMenu from "../../../components/modules/company/AgentMenu";
  import PersonMenu from "../../../components/modules/person/PersonMenu.vue";
  import ContractMenu from "../../../components/modules/contract/ContractMenu.vue";
  import UsersMenu from "../../../components/modules/user/UserMenu.vue";
  import CorpPolityMenu from "../../../components/v2/module/corpPolicy/CorpPolityMenu"
  import CommercialTermsMenu from "../../../components/v2/module/commercialTerms/CommercialTermsMenu"
  import SettingMenu from "../../../components/modules/setting/SettingMenu"
  import AttrEntityMenu from "../../../components/v2/module/attrEntity/AttrEntityMenu"
  import ImportMenu from "../../../components/modules/importData/ImportMenu"
  import BusMenu from "../../../components/modules/bus/BusMenu"
  import EventLogMenu from "../../../components/modules/eventLog/EventLogMenu"
  import LogoutMenu from "../../../components/modules/user/LogoutMenu.vue"


  import {NAME_OBJECT, NAME_ACTION} from "../../../RoleConstanans";

  import CfoMenu from "../../../components/v2/module/cfoCommon/CfoMenu.vue";
  import ReceiverSystemMenu from "../../../components/v2/module/receiverSystem/ReceiverSystemMenu";
  import CrmCorpMenu from '../../../components/v2/module/crmCorp/CrmCorpMenu.vue';

  export default {
    components: {
      ContractMenu,
      PersonMenu,
      CompanyMenu,
      EntityMenu,
      TopNavbar,
      ContentFooter,
      DashboardContent,
      MobileMenu,
      UserMenu,
      UsersMenu,
      CorpPolityMenu,
      CommercialTermsMenu,
      SettingMenu,
      AttrEntityMenu,
      ImportMenu,
      BusMenu,
      EventLogMenu,
      AgentMenu,
      LogoutMenu,
      CfoMenu,
      ReceiverSystemMenu,
      CrmCorpMenu
    },
    computed:{
      userRoleLouded(){
        return this.$store.getters.userRoleLouded
      },
      isProfileLoaded() {
        return this.$store.getters.isProfileLoaded
      }
    },
    data() {
      return {
        title: NAME_SITE,
        showDemo: false,
        nameObject: NAME_OBJECT
      }
    },
    created() {
      if (location.hostname == "localhost" ) {
        this.showDemo = true;
      }
    },
    methods: {
      testClick(){
        console.log('testClick')
      },
      showMenu(name) {
          let isActionAccess  = this.$store.getters.isActionAccess
          return isActionAccess(name, NAME_ACTION.isMenu, name, 'showMenu'  )
      },

      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false)
        }
      }
    }
  }

</script>
