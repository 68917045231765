<template>
  <common-page-table
    :apiName="apiName"
    :nameRole="nameRoleEntity"
    :titleTable="$t('Соглашения')"
    :fieldLand="fieldLang"
    :keyTable="'agreementId'"
    :deleteParams="deleteParams"
    :alternativeConverter="convertData"
    :isDefaultConverter="false"
    :forceIsEdit="false"
    :prefixShow="prefixShow"
    :linkCreate="linkCreate"
    :forceIsCreate="false"
    :forceDelete="false"

    :paramsGetData="paramsGetData"
    :hasReadOnlyInTable="hasReadOnlyInTable"
  />
</template>
<script>
import { NAME_OBJECT } from "../../../../RoleConstanans";
import CommonPageTable from "../../commonElement/CommonPageTable.vue";
import { API_METHODS, FIELD_LANG_COMPANY} from "../../../../constant";
import convertLangDataToTable from "../../../../helpers/converters/convertLangDataToTable";
export default {
  name: "AgreementsCounterpartyPage",
  components: { CommonPageTable },
  data() {
    return {
      fieldLang: FIELD_LANG_COMPANY,
      apiName: API_METHODS.GET_COUNTERPARTY_ALL_AGREEMENTS,
      // apiDeleteName: API_METHODS.DELETE_COUNTERPARTY
    };
  },
  props: {
    hasReadOnlyInTable: { type: Boolean, default: true },
    routeField: { type: String, default: "CounterpartyId" }
  },
  computed: {
    paramsGetData() {
      return { id: this.$route.params[this.routeField] };
    },
    prefixShow() {
      const route = this.$route;
      return `/crm/Counterparty/${route.params.CounterpartyId}/agreement`;
    },
    linkCreate() {
      const route = this.$route;
      return `/crm/CounterpartyId/${route.params.CounterpartyId}/agreement/create`;
    },
    nameRoleEntity() {
      return NAME_OBJECT.clients
    }
  },
  methods: {
    convertData(dataNow, langNow) {
      let res = convertLangDataToTable(dataNow, langNow, FIELD_LANG_COMPANY)
      return res;
    },
    deleteParams(id) {
      return { id: id };
    },
  }
};
</script>

