<template>
  <common-page-edit
    :title="title"
    :nameRoleEntity="nameRoleEntity"
    :apiNameGet="apiNameGet"
    :apiNameCreate="apiNameCreate"
    :apiNameUpdate="apiNameUpdate"
    routeNameShow="ShowPerson"
    routeNameCreate="CreateDocument"
    :keyRoute="'PersonId'"
    :backUrl="pathBack"
    @set-loadData="setLoadData"
    @set-sheme="setSheme"
    :readDataFromParent="true"
    :loadDataIn="loadData"
    :schemeIn="scheme"
    :convertLoadData="convertLoadData"
    :paramsSave="paramsSave"
    :isDefaultConverter="false"
    :loadIn="load"
    :controlLoad="true"
    @set-load="setLoad"

    :paramsSend="paramsGet"
  >
  </common-page-edit>
</template>
<script>
import {
  API_METHODS,
  CODE_TYPE_DOCUMENT_BIRTH_CERTIFICATE, CODE_TYPE_DOCUMENT_NATIONAL_PASSPORT,
  CODE_TYPE_DOCUMENT_PASPORT_RF,
  CODE_TYPE_DOCUMENT_ZAGRANPASPORT
} from "../../../../../constant";
import {NAME_OBJECT} from "../../../../../RoleConstanans";
import CommonPageEdit from "../../../commonElement/CommonPageEdit.vue";
import convertFieldInfoListToScheme from "../../../../../helpers/converters/convetFieldInfoListToScheme";
import clearReactive from "../../../../../helpers/clearReactive"
import {TYPE_COMPONETS} from "@/helpers/generatorForm/constants";
import {api} from "../../../../../util/api"

export default {
  name: "EditPageDynamic",
  components: {
    CommonPageEdit
  },
  data() {
    return {
      wait: false,
      scheme: {},
      fieldInfoList: null,
      preResult: null,
      load: true,

      routeNameShow: "ShowDocument",
      routeNameCreate: "CreateDocument",
      pathBack: `/contract/${this.$route.params.ContractId}/person/${this.$route.params.PersonId}/edit`,
      modals: {
        history: false
      },
      apiNameGet: API_METHODS.GET_DOCUMENT,
      apiNameCreate: API_METHODS.CREATE_DOCUMENT,
      apiNameUpdate: API_METHODS.UPDATE_DOCUMENT,
      loadData: null,

      oldLoadData: null,
      documentList: [],
      block: true
    };
  },
  computed: {
    nameEnity() {
      return this.$route.params.clientPerson;
    },
    title() {
      let text1 = this.$t("Просмотр документа");
      let text2 = this.$t("Просмотр документа");
      let text3 = this.$t("Редактирование документа");

      let findShow = this.$route.path.indexOf("show");
      if (findShow != -1) {
        return text1;
      }
      let findCreate = this.$route.path.indexOf("create");
      if (findCreate != -1) {
        return text2;
      }
      let findEdit = this.$route.path.indexOf("edit");
      if (findEdit != -1) {
        return text3;
      }
      return text1;
    },
    nameRoleEntity() {
      return NAME_OBJECT.attrEntity;
    },
    paramsGet() {
      return {
        id: this.$route.params.DocumentId || "0",

        PersonId: this.$route.params.PersonId
      }
    }
  },

  watch: {
    loadData: {
      deep: true,
      handler() {
        // if(!this.block) {
        let oldVal = this.oldLoadData;
        let newVal = this.loadData;
        if (oldVal && newVal && newVal.typeId !== oldVal.typeId) {
          this.configFormTypeDoc(newVal.typeId);
        }
        this.oldLoadData = clearReactive(this.loadData);
        // }
      }
    }
  },
 async mounted() {
   await this.loadDropDownListDocuments()
  },
  methods: {
    setLoad(load) {
      this.load = load;
    },
    convertLoadData(res) {
      let loadData = res.object;
      let fieldInfoList = res.fieldInfoList;
      let scheme = {configForm: []};
      this.oldLoadData = clearReactive(loadData);
      fieldInfoList[0].code = "typeId";
      scheme.configForm = convertFieldInfoListToScheme({
        fieldInfoList,
        hasLang: false
      });
      loadData.personId = this.$route.params.PersonId;
      // this.loadData = loadData
      // this.scheme = scheme

      this.$nextTick(() => {
        this.configFormTypeDoc(loadData.typeId, false);
      })
      return {
        loadData,
        fieldInfoList,
        scheme
      };
    },
    setLoadData(loadData) {
      this.loadData = loadData;
    },
    setSheme(scheme) {
      this.scheme = scheme;
    },
    paramsSave() {
      return {
        id: this.$route.params.PersonId,
        ContractId: this.$route.params.ContractId
      };
    },

    /**
     * Изменяет параметры формы относительно данных которые выбрали
     * правила
     * тип - паспорт РФ  документы  10 цифр  только цифры
     * тип - загранпаспорт  документы  9 цифр  только цифры
     * тип - все остальные  документы  нет  только цифры и латиница
     *
     * надо получить код типо и от низ уже отсчитывать а не от id
     */
    async configFormTypeDoc(typeDocumentId, resetData = true) {
      this.load = false;
      const typeDocumentIdPasportID = this.getDocumentEntityId(
        CODE_TYPE_DOCUMENT_PASPORT_RF
      );
      const typeDocumentIdZagran = this.getDocumentEntityId(
        CODE_TYPE_DOCUMENT_ZAGRANPASPORT
      );
      const typeDocumentIdBirthCertificate = this.getDocumentEntityId(
        CODE_TYPE_DOCUMENT_BIRTH_CERTIFICATE
      );
      const typeDocumentIdNationalPassport = this.getDocumentEntityId(
        CODE_TYPE_DOCUMENT_NATIONAL_PASSPORT
      );


      if (resetData) {
        this.$nextTick(() => {
          this.loadData.citizenshipId = null;
          this.loadData.issuerCountryId = null;
        });
      }
      this.scheme.configForm = this.scheme.configForm.map(item => {
        if (item.fieldName === "number") {
          if (typeDocumentId === typeDocumentIdZagran) {
            return {
              ...item,
              type: TYPE_COMPONETS.INPUT_MASK.nameC,
              validationRule: {
                required: true,
                digits: 9
              },
              mask: "#########"
            };
          } else if (typeDocumentId === typeDocumentIdPasportID) {
            return {
              ...item,
              type: TYPE_COMPONETS.INPUT_MASK.nameC,
              validationRule: {
                required: true,
                digits: 10
              },
              mask: "##########"
            };
          } else if (typeDocumentId === typeDocumentIdBirthCertificate) {
            return {
              ...item,
              type: TYPE_COMPONETS.INPUT_MASK.nameC,
              validationRule: {
                required: true,
                regex: "^[XVIxvi]{1,6}[А-Яа-я]{2}\\d{6}$"
              }
            };
          } else if(typeDocumentId === typeDocumentIdNationalPassport){
            return {
              ...item,
              type: TYPE_COMPONETS.INPUT_MASK.nameC,
              validationRule: {
                required: true,
                regex: "^[a-zA-Z0-9-]{1,16}$"
              }
            };
          }
          else {
            return {
              ...item,
              type: TYPE_COMPONETS.INPUT.nameC,
              validationRule: {
                required: true,
                regex: "^[a-zA-Z0-9]+$"
              }
            };
          }
        }
        return item;
      });

      if (typeDocumentId === typeDocumentIdPasportID) {
        await this.setCitynzenShipRF();
      }

      if (resetData) {
        this.loadData.number = "";
        this.loadData.typeCode = this.getDocumentEntityCode(typeDocumentId);
      }
      this.load = false;
      this.$nextTick(() => {
        this.load = true;
      });
    },
    getDocumentEntityId(entityCode) {
      const find = this.documentList.find(
        item => item.entityCode === entityCode
      );
      return find ? find.entityId : 0;
    },
    getDocumentEntityCode(entityId) {
      const find = this.documentList.find(item => item.entityId === entityId);
      return find ? find.entityCode : "";
    },
    async loadDropDownListDocuments() {
      try {
        const paramIn = {
          self: this,
          relative: "DocumentTypeNoVisa"
        };
        let res = await api(API_METHODS.BASE_V_SELECT_LOAD_DATA, paramIn);
        this.documentList = res.object;
      } catch (e) {
        console.error("неудалось получить список документов", e);
      }
    },
    async setCitynzenShipRF() {
      try {
        const paramIn = {
          self: this,
          relative: "Country",
          query: {
            search: "RU",
            lang: this.$store.state.common.lang
          }
        };
        let res = await api(API_METHODS.BASE_V_SELECT_LOAD_DATA, paramIn);
        let findRF = res.object
          ? res.object.find(item => item.entityCode == "RU")
          : null;
        if (findRF) {
          let entityId = findRF.entityId;
          this.$nextTick(() => {
            this.loadData.citizenshipId = entityId;
            this.loadData.issuerCountryId = entityId;
          });
        }
      } catch (e) {
        console.error("неудалось получить гражданство", e);
      }
    }
  }
};
</script>
