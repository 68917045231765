<template>
  <common-page-edit
    :title="title"
    :nameRoleEntity="nameRoleEntity"
    :apiNameGet="apiNameGet"
    :apiNameCreate="apiNameCreate"
    :apiNameUpdate="apiNameUpdate"
    routeNameShow="CfoShow"
    routeNameCreate="CfoEdit"
    :keyRoute="'id'"
    :backUrl="pathBack"
    @set-loadData="setLoadData"
    :convertLoadData="convertLoadData"
    :paramsSave="paramsSave"
    :isDefaultConverter="false"
    :paramsSend="paramsSend"
    :forceNoShowEdit="true"


    :showHistory="true"
    :apiNameGetHistory="apiNameGetHistory"
    :keyRouteHistory="keyRouteHistory"
  >
   
  </common-page-edit>
</template>
<script>
import { API_METHODS, FIELD_LANG_COMPANY } from "../../../../../constant";
import { NAME_OBJECT } from "../../../../../RoleConstanans";
import CommonPageEdit from "../../../commonElement/CommonPageEdit.vue";
import CompanyData from "../../company/CompanyData.vue";

import convertFieldInfoListToScheme from "../../../../../helpers/converters/convetFieldInfoListToScheme";

export default {
  name: "ClientEditPageDynamic",
  components: {
    CommonPageEdit,
    CompanyData
  },
  data() {
    return {
      wait: false,
      scheme: {},
      loadData: null,
      fieldInfoList: null,
      preResult: null,
      load: true,

      routeNameShow: "CbdShow",
      routeNameCreate: "CbdCreate",

      // pathBack: "/crm/CounterpartyBankDetails",
      apiNameGet: API_METHODS.GET_CFO,
      apiNameCreate: API_METHODS.CREATE_CFO,
      apiNameUpdate: API_METHODS.UPDATE_CFO,

      keyRouteHistory: "id",
      apiNameGetHistory: API_METHODS.GET_HISTORY_CFO,
      loadData: null
    };
  },
  computed: {
   
    cfoType() {
      const { CfoType = "Cfo" } = this.$route.params;
      return CfoType;
    },
    titleName() {
      return this.$t(this.cfoType+'_element');
    },
    title() {
      let text1 = this.$t("show_"+this.titleName);
      let text2 = this.$t("create_"+this.titleName);
      let text3 = this.$t("edit_"+this.titleName);

      let findShow = this.$route.path.indexOf("show");
      if (findShow != -1) {
        return text1;
      }
      let findCreate = this.$route.path.indexOf("create");
      if (findCreate != -1) {
        return text2;
      }
      let findEdit = this.$route.path.indexOf("edit");
      if (findEdit != -1) {
        return text3;
      }
      return text1;
    },
    pathBack() {
      return `/cfo/${this.cfoType}`
    },
    nameRoleEntity() {
      return NAME_OBJECT.cfo;
    },
    paramsSend() {
      return {
        id: this.$route.params.id || "0",
        CfoType: this.cfoType
      };
    }
  },

  methods: {
    convertLoadData(res) {
      let loadData = res.object;
      loadData.cfoType = this.cfoType;
      let fieldInfoList = res.fieldInfoList;
      let scheme = { configForm: [] };

      scheme.configForm = convertFieldInfoListToScheme({
            fieldInfoList,
            hasLang: false
      });

      return {
        loadData,
        fieldInfoList,
        scheme
      };
    },
    setLoadData(loadData) {
      this.loadData = loadData;
    },

    paramsSave() {
      return {
        id: this.$route.params.id,
        CfoType: this.cfoType
      };
    }
  }
};
</script>
