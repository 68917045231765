import moment from "moment";
import { GLOBAL_DATE_FORMAT } from "../../constant";
/**
 * FORMAT DATA
 * @param data
 * @param format
 * @returns {string}
 */
export default (
  data,
  format = GLOBAL_DATE_FORMAT,
  formatIn = "YYYY-MM-DDTHH:mm:ss.SSS"
) => {
  return data
    ? moment(data, moment.HTML5_FMT.DATETIME_LOCAL_MS).format(format)
    : "";
};
