import {TYPE_COMPONETS} from '../constants'
import extendComponents from '../extendComponents'
let info = {
  ...extendComponents(TYPE_COMPONETS.INFO)
}
delete info.name
delete info.default
delete info.label
delete info.validationRule
delete info.errName
delete info.fieldName
delete info.readonly
delete info.main
export default info
