import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue'
const EditPagePersoneCodeValue = () =>
    import ('./EditPagePersoneCodeValue.vue')

export default {
    path: '/',
    component: DashboardLayout,
    children: [{
            path: 'contract/:ContractId/person/:PersonId/personcodevalue/create',
            name: 'Createpersoncodevalue',
            component: EditPagePersoneCodeValue
        },
        {
            path: 'contract/:ContractId/person/:PersonId/personcodevalue/:CodeId/show',
            name: 'Showpersoncodevalue',
            component: EditPagePersoneCodeValue
        },
        {
            path: 'contract/:ContractId/person/:PersonId/personcodevalue/:CodeId/edit',
            name: 'Editpersoncodevalue',
            component: EditPagePersoneCodeValue
        }
    ]
}