
import ClientTablePage from "./ClientTablePage"
import ClientEditPageDynamic from "./ClientEditPageDynamic.vue"
import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue"


const META_CLIENT = {
    typeCompany: 'client'
}

export default {
    path: "/company",
    component: DashboardLayout,
    children: [
        {
            path: "client",
            name: "ClientTable",
            component: ClientTablePage
        },
        {
            path: "client/:CompanyId/edit",
            name: "ClientEdit",
            component: ClientEditPageDynamic,
            meta: META_CLIENT
        },
        {
            path: "client/:CompanyId/show",
            name: "ClientShow",
            component: ClientEditPageDynamic,
            meta: META_CLIENT
        },
        {
            path: "client/create",
            name: "ClientCreate",
            component: ClientEditPageDynamic
        },
    ]
}