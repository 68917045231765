import CounterpartyBankDetailsTable from "./CbdTablePage.vue"
import CbdEditPageDynamic from "./CbdEditPageDynamic.vue"
import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue"

export default {
  path: "/crm",
  component: DashboardLayout,
  children: [{
    path: "CounterpartyBankDetails",
    name: "CounterpartiesBankDetails",
    component: CounterpartyBankDetailsTable
  },
  {
    path: "CounterpartyBankDetails/:CompanyId/edit",
    name: "CounterpartyBankDetailsEdit",
    component: CbdEditPageDynamic,
  },
  {
    path: "CounterpartyBankDetails/:CompanyId/delete",
    name: "CounterpartyBankDetailsDelete",
    component: CbdEditPageDynamic,
  },
  {
    path: "CounterpartyBankDetails/:CompanyId/show",
    name: "CounterpartyBankDetailsShow",
    component: CbdEditPageDynamic,
  },
  {
    path: "CounterpartyBankDetails/create",
    name: "CounterpartyBankDetailsCreate",
    component: CbdEditPageDynamic
  },
  ]
}
