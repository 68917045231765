<template>
  <common-page-edit
    :title = "title"
    :nameRoleEntity="nameRoleEntity"
    :apiNameGet="apiNameGet"
    :apiNameUpdate="''"
    :apiNameCreate="''"
    :routeNameShow="routeNameShow"
    :routeNameCreate="routeNameCreate"
    :keyRoute="'CompanyId'"
    :backUrl="pathBack"
    @set-loadData="setLoadData"
    :convertLoadData="convertLoadData"
    :paramsSave="paramsSave"
    :isDefaultConverter="false"
    :paramsSend="paramsSend"
    :forceNoShowEdit="true"
    :showHistoryFunction="showHistoryFunction"

    :showHistory="true"
    :apiNameGetHistory="apiNameGetHistory"
    :keyRouteHistory="keyRouteHistory"
  >
  </common-page-edit>
</template>
<script>
import { API_METHODS, FIELD_LANG_COMPANY } from "../../../../../constant";
import { NAME_OBJECT } from "../../../../../RoleConstanans";
import CommonPageEdit from "../../../commonElement/CommonPageEdit.vue";
import CompanyData from "../../company/CompanyData.vue";

import convertFieldInfoListToScheme from "../../../../../helpers/converters/convetFieldInfoListToScheme";

export default {
  name: "AgreementsVstEditPageDynamic",
  components: {
    CommonPageEdit,
    CompanyData
  },
  data() {
    return {
      wait: false,
      scheme: {},
      loadData: null,
      fieldInfoList: null,
      preResult: null,
      load: true,

      routeNameShow: "AgreementsVstEditPageDynamic",
      routeNameCreate: "AgreementsVstEditCreate",

      pathBack: "/vst/agreements",
      apiNameGet: API_METHODS.GET_AGREEMENTS,


      apiNameGetHistory: API_METHODS.GET_HISTORY_AGREEMNTS,
      keyRouteHistory: "agreementId"

    };
  },
  computed: {
    title() {
      let text1 = this.$t("Просмотр соглашения");
      let text2 = this.$t("Создание соглашения");
      let text3 = this.$t("Редактирование соглашения");

      let findShow = this.$route.path.indexOf("show");
      if (findShow != -1) {
        return text1;
      }
      let findCreate = this.$route.path.indexOf("create");
      if (findCreate != -1) {
        return text2;
      }
      let findEdit = this.$route.path.indexOf("edit");
      if (findEdit != -1) {
        return text3;
      }
      return text1;
    },
    nameRoleEntity() {
      return NAME_OBJECT.clients;
    },
    paramsSend() {
      return {
        id: this.$route.params.CompanyId || "0"
      };
    }
  },

  methods: {
    convertLoadData(res) {
      let loadData = res.object;
      let fieldInfoList = res.fieldInfoList;
      let scheme = { configForm: [] };

      scheme.configForm = convertFieldInfoListToScheme({
        fieldInfoList,
        langFieldName: FIELD_LANG_COMPANY,
        hasLang: false
      });

      return {
        loadData,
        fieldInfoList,
        scheme
      };
    },
    showHistoryFunction() {

    },
    setLoadData(loadData) {
      this.loadData = loadData;
    },

    paramsSave() {
      return {
        id: this.$route.params.CompanyId
      };
    }
  }
};
</script>
