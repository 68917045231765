import { API_METHODS } from "../../constant";
export const contactApi = (
  _getAll,
  _get,
  _update,
  _create,
  _delete,
  paramsIn
) => {
  return {
    [API_METHODS.GET_CONTACT]: () => {
      let urlReq = `/${paramsIn.SubjectId}/Contact/${paramsIn.id}`;
      return _get(urlReq);
    },
    [API_METHODS.GET_ALL_CONTACT]: () => {
      let urlReq = `/${paramsIn.SubjectId}/Contact`;
      return _getAll(urlReq);
    },
    [API_METHODS.UPDATE_CONTACT]: () => {
      let urlReq = `/${paramsIn.SubjectId}/Contact`;
      return _update(urlReq);
    },
    [API_METHODS.CREATE_CONTACT]: () => {
      let urlReq = `/${paramsIn.SubjectId}/Contact`;
      return _create(urlReq);
    },
    [API_METHODS.DELETE_CONTACT]: () => {
      let urlReq = `/${paramsIn.SubjectId}/Contact/${paramsIn.id}`;
      return _delete(urlReq);
    }
  };
};
