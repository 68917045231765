export const ENTITY_NAME = 'CONTRACT_'
export const SET_TAB_CONTRACT = 'SET_TAB_CONTRACT'
export const CONTRACT_CONNECT = 'CONTRACT_CONNECT'

export const BUDGET_CODE = "BUDGET_CODE"
export const CONTRACT_CONNETCT_CODE = "CONTRACT_CONNETCT_CODE"
export const CONTRACT_UNCONNECT_CODE = "CONTRACT_UNCONNECT_CODE"

export const STRUCTORE_CODE_CONTRACT = "STRUCTORE_CODE_CONTRACT"
export const CONTRACT_BUDGET_CODE_VALUE = "CONTRACT_BUDGET_CODE_VALUE"


export const DELETE_CODE_CONTRACT = "DELETE_CODE_CONTRACT"