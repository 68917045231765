import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue";
import StatisticHrFeedExportTable from "./StatisticHrFeedExportTable.vue";
import StatisticHrFeedExportTableDetail from "./StatisticHrFeedExportTableDetail.vue";
export default {
  path: "/statisticHrFeedExport",
  component: DashboardLayout,
  children: [
    {
      path: "/",
      component: StatisticHrFeedExportTable
    },
    {
      path: ":contragentId/:dateFrom/:dateTo/show",
      name: "StatisticHrFeedExportTableDetail",
      component: StatisticHrFeedExportTableDetail
    }
  ]
};
