import {READ} from '../../../../store/type'
import {HISTORY_CODE, SET_HISTORY_PAGE, SET_HISTORY_SEARCH, SET_HISTORY_PER_PAGE} from "./type";

export const mutations = {
  [HISTORY_CODE + READ]: (state, { countPage, object, fieldInfoList}) => {
    state.data = object
    state.pagination.total = countPage * state.pagination.perPage
    state.fieldInfoList = fieldInfoList
  },
  [SET_HISTORY_PAGE]: (state, val) => {
    state.pagination.currentPage = val
  },
  [SET_HISTORY_SEARCH]: (state, val) => {
    state.search = val
  },
  [SET_HISTORY_PER_PAGE]: (state, val) => {
    state.pagination.perPage = val
  }
};
