import { actions } from "./actions";
import { mutations } from "./mutations";
const state = {
    data: [],
    fieldInfoList: [],
    fieldTable: {},
    nowTab: null,
    externalSystemId: null,

    listtabs: []
};

export default {
    state,
    actions,
    mutations
};