import {TYPE_COMPONETS} from '../constants'
import extendComponents from '../extendComponents'
let space = {
  ...extendComponents(TYPE_COMPONETS.SPACE)
}
delete space.placeholder
delete space.name
delete space.default
delete space.label
delete space.validationRule
delete space.errName
delete space.fieldName
delete space.readonly
delete space.main
export default space
