<template>
  <div :class="generateClass">
    <fg-input
      :label="config.label"
      :name="config.name"
      :data-cy="config.cyData"
      :placeholder="config.placeholder"
      v-model="form[config.fieldName]"
      :readonly="config.readonly"
      :valueKey="config.selectLabel">
      <el-select class="select-default" v-model="form[config.fieldName]">
        <el-option class="select-default" :value="val.id" v-for="val in option" :key="val.id">{{val[config.selectLabel]}}</el-option>
      </el-select>
    </fg-input>
  </div>
</template>
<script>

  import {Select, Option} from 'element-ui'
  import {generateClass} from  '../../../../mixins/baseComponets/generateClass'
  export default {
    name: 'BaseSimpleSelect',
    components: {
      [Select.name]: Select,
      [Option.name]: Option
    },
    props: {
      config: {type: Object, required: true},
      form: {type: Object}
    },
    data() {
      return {
        option: [{id: 1, label: 'demo1'}, {id: 2, label: 'demo2'}]
      }
    },
    created() {
      // тут нужен запрос задных или типо того
    },
    mixins: [generateClass]
  }
  //
</script>
