<template>
  <div class="row">
    <load-block :load="load"></load-block>
    <div class="col-12 pl-0 pr-0">
      <card :title="titleTable">
        <p class="text-warning" v-if="demotext">{{ demotext }}</p>
        <div>
          <filter-block
            :searchData="searchData"
            :setPerPage="setPerPage"
            :link-create="linkCreate"
            :forceIsCreate="forceIsCreate"
            :is-create="configRole ? configRole.create : isCreate"
            :manualMode="manualMode"
            :allPaginator="allPaginator"
          ></filter-block>
          <div class="col-sm-12">
            <el-table
              stripe
              @sort-change="setSort"
              style="width: 100%;"
              :data="tableData"
              border
              v-if="tableColumns"
              :empty-text="$t('Нет данных!')"
              :row-class-name="tableRowClassName"
               @cell-click="cellClick"
            >
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                sortable="custom"
                :prop="column.prop"
                :label="column.label"
                :class-name="hasFieldInData(column.prop) ? '' : 'errortable'"
              >
                <template slot-scope="props">
                  {{
                    column.prop in props.row
                      ? props.row[column.prop]
                      : $t("ОШИБКА! Данные для поля не найдены")
                  }}
                </template>
              </el-table-column>
              <el-table-column
                :min-width="180"
                fixed="right"
                align="center"
                :label="$t(textAction)"
                class="noHiddenText"
                class-name="noHidden"
              >
                <template slot-scope="props">
                  <router-link
                    class="btn-info btn-simple btn-link tableBtn mr-2"
                    v-if="forceIsShow && (configRole ? configRole.show : isShow)"
                    :to="
                      `${prefixShow ? prefixShow : $route.path}/${
                        props.row[fieldId]
                      }/show`
                    "
                    ><i class="table_icon icon_eye" v-tooltip="$t('Посмотреть')"></i>
                  </router-link>
                  <router-link
                    class="btn-warning btn-simple btn-link tableBtn mr-2"
                    v-if="forceIsEdit && (configRole ? configRole.edit : isEdit)"
                    :to="
                      `${prefixEdit ? prefixEdit : $route.path}/${
                        props.row[fieldId]
                      }/edit`
                    "
                    ><i class="table_icon icon_edit" v-tooltip="$t('Редактировать')"></i>
                  </router-link>
                  <a
                    v-if="forceDelete && (configRole ? configRole.delete : isDelete)"
                    class="btn-danger btn-simple btn-link tableBtn"
                    @click="handleDelete(props.row[fieldId], props.row)"
                    ><i class="table_icon icon_delete" v-tooltip="$t('Удалить')"></i
                  ></a>
                     <a
                    v-if="isDetail"
                    class="btn-info btn-simple btn-link tableBtn"
                    @click="detalShow(props.row)"
                    ><i class="table_icon icon_eye" v-tooltip="$t('Посмотреть')"></i
                  ></a>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div slot="footer" class="col-12">
          <pagination-block
            :setPerPage="setPerPage"
            :forceIsCreate="forceIsCreate"
            :is-create="configRole ? configRole.create : isCreate"
            :updateData="updateData"
            :manualMode="manualMode"
            :allPaginator="allPaginator"
            :currentPage="currentPage"
          ></pagination-block>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn } from "element-ui";
import { Loading } from "element-ui";
import {
  SET_MAIN_PAGINATION,
  SET_MAIN_PAGINATION_PAGE
} from "../../src/store/type";
import clearReactive from "../helpers/clearReactive";
import LoadBlock from "./shared/LoadBlock.vue";
import FilterBlock from "./shared/theTablePage/FilterBlock.vue";
import PaginationBlock from "./shared/theTablePage/PaginationBlock.vue";
import swal from "sweetalert2";
import {NAME_ACTION} from "../RoleConstanans"
export default {
  components: {
    PaginationBlock,
    FilterBlock,
    LoadBlock,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  props: {
    titleTable: { type: String },
    tableData: { required: true },
    fieldId: { type: String, default: "refEntityId" },
    updateData: { type: Function, required: true },
    searchStart: { type: Function, required: true },
    sortData: { type: Object, required: true },
    setSort: { type: Function, required: true },
    fieldInfoList: { type: Array },
    load: { type: Boolean, default: false },
    deleteEl: { type: Function, required: true },
    prefixEdit: { type: String, default: "" },
    prefixShow: { type: String, default: "" },
    linkCreate: { type: String, default: "" },
    isShow: { type: Boolean, default: false },
    isEdit: { type: Boolean, default: true },
    isCreate: { type: Boolean, default: true },
    isDelete: { type: Boolean, default: true },
    configName: { type: String, default: null },
    demotext: { type: String, default: null },
    forceIsCreate: { type: Boolean, default: true },
    forsePerpage: { type: Boolean, default: true },
    isSelect: { type: Boolean, default: false },
    setSelect: { type: Function },
    selectId: { type: Number },

    isDetail: { type: Boolean, default: false },
    toggleModal: { type: Function },

    //manual controll
    actionPaginator: { type: String, default: "SET_MAIN_PAGINATION_PAGE" },
    currentPageStore: { default: null },

    manualMode: { type: Boolean, default: false },
    allPaginator: {
      type: Object,
      default: () => {
        return {
          pagination: {
            perPage: 10,
            currentPage: 1,
            perPageOptions: [5, 10, 25, 50],
            total: 0
          },
          sortData: {
            prop: "refEntityId",
            order: "descending"
          },
          search: ""
        };
      }
    },
    textAction: {type: String, default: 'Действия'},
    roleName: {type: String},
    forceIsShow:{ type: Boolean, default: true },
    forceIsEdit:{ type: Boolean, default: true },
    forceDelete:{ type: Boolean, default: true },
  },

  data() {
    return {};
  },

  computed: {
    configRole() {
      const userRoleLouded = this.userRoleLouded 
      const isActionAccess = this.isActionAccess
      const roleName = this.roleName
      if(userRoleLouded) {
      return  {
          create: isActionAccess(roleName, NAME_ACTION.isCreate),
          show: isActionAccess(roleName, NAME_ACTION.isRead),
          edit: isActionAccess(roleName, NAME_ACTION.isEdit),
          delete: isActionAccess(roleName, NAME_ACTION.isDelete),
        }
      }
      return null
    },
    userRoleLouded(){
        return this.$store.getters.userRoleLouded
    },
    tableColumns() {
      let fieldInfoList = this.fieldInfoList;
      let tableColumns = fieldInfoList
        ? fieldInfoList.map(el => {
            return {
              prop: el.code,
              label: el.name,
              minWidth: el.sizePercent + "%"
            };
          })
        : [];
      return tableColumns;
    },
    currentPage: {
      get() {
        return this.allPaginator.pagination.currentPage;
      },
      set(val) {
        this.allPaginator.pagination.currentPage = val;
      }
    }
  },
  methods: {
    isActionAccess(name,action){
      let isActionAccess  = this.$store.getters.isActionAccess
      return isActionAccess(name, action)
    },
   detalShow(row) {
      if (this.isDetail) {
        this.toggleModal(row, this.fieldInfoList);
      }
    },
    cellClick(row) {
      if(this.isSelect){
         this.setSelect(row[this.fieldId], row)
      }
    },
    tableRowClassName({ row, rowIndex }) {
      if (this.selectId) {
        if (row && row[this.fieldId] && row[this.fieldId] == this.selectId) {
          return "selectRow";
        }
      }
      return false;
    },
    hasFieldInData(field) {
      if (this.tableData && this.tableData.length > 0) {
        return this.tableData[0] && field in this.tableData[0] ? true : false;
      }
      return true;
    },
    setPerPage() {
      this.currentPage = 1;
      this.updateData();
    },
    searchData(val) {
      this.currentPage = 1;
      this.searchStart(val);
    },
    handleDelete(id, row) {
      swal({
        title: this.$t("Вы уверены?"),
        text: this.$t("Вы не сможете вернуть это!"),
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: this.$t("Да удалить!"),
        cancelButtonText: this.$t("Закрыть"),
        buttonsStyling: false
      }).then(res => {
        if (res.value && res.value == true) {
          this.deleteEl(id, row);
        }
      });
    }
  }
};
</script>
