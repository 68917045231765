import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue'
const EditPageDynamic = () =>
    import ('./EditPageDynamic.vue')
import EditPageDocument from './personDocument/EditPageDocument.vue'
import EditPageBonusCard from './bonusCard/EditPageBonusCard.vue'
import EditPageContact from './contactPerson/EditPageContact.vue'
// import routeCreditCard from "./routeCreditCard"
import routePrefData from "./routePrefData"
import routeVisa from "./routeVisa"
import routeSabreRemark from "./routeSabreRemark"
export default {
    path: '/',
    component: DashboardLayout,
    children: [{
            path: 'contract/:ContractId/person/create',
            name: 'CreatePerson',
            component: EditPageDynamic
        },
        {
            path: 'contract/:ContractId/person/:PersonId/edit',
            name: 'EditPerson',
            component: EditPageDynamic
        },
        {
            path: 'contract/:ContractId/person/:PersonId/show',
            name: 'ShowPerson',
            component: EditPageDynamic
        },

        {
            path: 'contract/:ContractId/person/:PersonId/document/create',
            name: 'CreateDocument',
            component: EditPageDocument
        },
        {
            path: 'contract/:ContractId/person/:PersonId/document/:DocumentId/edit',
            name: 'EditDocument',
            component: EditPageDocument
        },
        {
            path: 'contract/:ContractId/person/:PersonId/document/:DocumentId/show',
            name: 'ShowDocument',
            component: EditPageDocument
        },

        {
            path: 'contract/:ContractId/person/:PersonId/bonusCard/create',
            name: 'CreateBonusCard',
            component: EditPageBonusCard
        },
        {
            path: 'contract/:ContractId/person/:PersonId/bonusCard/:BonusCardId/edit',
            name: 'EditBonusCard',
            component: EditPageBonusCard
        },
        {
            path: 'contract/:ContractId/person/:PersonId/bonusCard/:BonusCardId/show',
            name: 'ShowBonusCard',
            component: EditPageBonusCard
        },


        
        {
            path: 'contract/:ContractId/person/:PersonId/contact/create',
            name: 'CreateContact',
            component: EditPageContact
        },



        {
            path: 'contract/:ContractId/person/:PersonId/contact/:ContactId/edit',
            name: 'EditContact',
            component: EditPageContact
        },
        {
            path: 'contract/:ContractId/person/:PersonId/contact/:ContactId/show',
            name: 'ShowContact',
            component: EditPageContact
        },
    //     ...routeCreditCard,
        ...routePrefData,
        ...routeVisa,
        ...routeSabreRemark
    ]
}