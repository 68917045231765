<template>
  <div class="row controls">
    <div class="col-sm-4 col-md-3" >
      <l-button
        type="default"
        v-if="!forceNoShowReturn"
        wide block
        @click="back()">
          {{ textBtnBack ? textBtnBack : $t("Назад") }}</l-button>
    </div>

    <div class="col-sm-4 col-md-3" v-if="pathBack2">
      <l-button type="default" wide block @click="back(pathBack2)">{{
        textBtnBack2
      }}</l-button>
    </div>

    <div
      class="col-sm-4 offset-sm-0 col-md-3 "
      :class="{ 'offset-md-3': !pathBack2 }"
    >
      <l-button
        type="warning"
        v-if="hasHistory"
        wide
        block
        @click="openHistory()"
        >{{ $t("История") }}</l-button
      >
    </div>
    <div class="col-sm-4 col-md-3 ">
      <l-button
        type="success"
        v-if="showSaveBtn && showBtnSaveIfReadOnly"
        :disabled="wait"
        wide
        block
        @click="saveData"
        >{{ $t("Сохранить") }}</l-button
      >
      <l-button
        type="success"
        v-if="showBtnGoEdit && showBtnSaveIfReadOnly"
        :disabled="wait"
        wide
        block
        @click="goEdit"
        >{{ $t("К редактированию") }}</l-button
      >
    </div>
  </div>
</template>
<script>
import { PAGE_MODE } from "../constant";
import { NAME_ACTION, NAME_OBJECT } from "../RoleConstanans";
//
export default {
  name: "TheControlsEditPage",
  props: {
    wait: { type: Boolean, required: true, default: true },
    loadData: { type: Object },
    saveData: { type: Function },
    pathBack: { type: String },
    openHistory: { type: Function },
    showHistory: { type: Boolean, default: false },
    configName: { type: String }, // устарело
    pageMode: { type: String }, //create, edit, show  ,
    roleName: { type: String }, // это главное поле на что надо орентироваться
    hasHistory: { type: Boolean, default: false },

    pathBack2: { type: String },
    textBtnBack2: { type: String },
    textBtnBack: { type: String },

    hasKeyReadOnly: { type: Boolean, default: true },
    forceNoShowEdit: { type: Boolean, default: false },
    forceNoShowReturn: { type: Boolean, default: false },
  },
  methods: {
    back(url) {
      const backUrl = url ? url : this.pathBack;
      this.$store.commit("CLEAR_TABLE_PAGINATOR", { delUrl: this.$route.path });
      this.$router.push({ path: backUrl });
    },
    isActionAccess(name, action) {
      let isActionAccess = this.$store.getters.isActionAccess;
      return isActionAccess(name, action);
    },
    goEdit() {
      const show = this.$route.fullPath.indexOf("show");
      const editUrl = this.$route.fullPath.substr(0, show) + "edit";
      this.$router.push({ path: editUrl });
    }
  },
  data() {
    return {
      showBtnSaveIfReadOnly: true
    };
  },
  created() {
    let vm = this;

    const { hasKeyReadOnly, loadData } = vm;
    let show = true;
    if (hasKeyReadOnly && loadData) {
      if ("readOnly" in loadData) {
        show = !loadData.readOnly;
      }
    }
    let store = vm.$store.state;
    if (
      store.user &&
      store.user.profile &&
      store.user.profile.roleCode == "Admin"
    ) {
      show = true;
    }
    vm.showBtnSaveIfReadOnly = show;
  },

  computed: {
    showHistoryBtn() {
      const PM = this.pageMode;
      if (PM) {
        if (PM == PAGE_MODE.CREATE) {
          return false;
        }
      }
      return this.showHistory;
    },
    showSaveBtn() {
      const PM = this.pageMode;
      const roleName = this.roleName;
      if (PM && roleName) {
        if (PM == PAGE_MODE.CREATE) {
          return this.isActionAccess(roleName, NAME_ACTION.isCreate);
        }
        if (PM == PAGE_MODE.SHOW) {
          return false;
        }
        if (PM == PAGE_MODE.EDIT) {
          return this.isActionAccess(roleName, NAME_ACTION.isEdit);
        }
      }
      return true;
    },
    showBtnGoEdit() {
      const PM = this.pageMode;
      const roleName = this.roleName;
      if (this.forceNoShowEdit) {
        return false
      }
      if (PM && roleName) {
        if (PM == PAGE_MODE.CREATE) {
          return false;
        }
        if (PM == PAGE_MODE.SHOW) {
          return this.isActionAccess(roleName, NAME_ACTION.isEdit);
        }
        if (PM == PAGE_MODE.EDIT) {
          return false;
        }
      }
      return true;
    },

    config() {
      return this.configName
        ? this.$store.state.common.config[this.configName]
        : null;
    }
  }
};
</script>
