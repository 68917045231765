import { READ, FORM_UPDATE } from '../../../../store/type'
import { ENTITY_NAME, SET_TAB_CLIENT, SUPPLIER_SERVICE } from "./type";
// import Vue from "vue";

export const mutations = {
    [ENTITY_NAME + READ]: (state, { entity, data, countPage, fieldInfoList }) => {
        state.data = data
        state.fieldInfoList = fieldInfoList
    },
    [ENTITY_NAME + FORM_UPDATE]: (state, form) => {
        state.form = form
    },
    [SET_TAB_CLIENT]: (state, tab) => {
        state.tabnow = tab
    },
    [SUPPLIER_SERVICE + READ]: (state, { data, fieldInfoList }) => {
        state.data = data
        state.fieldInfoList = fieldInfoList
    },
};