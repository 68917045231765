import { READ, UPDATE, DELETE, SET_MAIN_PAGINATION_TOTAL } from '../../../../store/type'
import { ATTR_VALUE } from "./type";
import { api } from "../../../../util/api";
import { API_METHODS } from "../../../../constant";

export const actions = {

    [ATTR_VALUE + READ]: async({ state, rootState, commit }, paramsIn = null) => {
        try {
            if (paramsIn.id) {
                return await api(API_METHODS.GET_ATTR_VALUE, paramsIn, rootState)
            } else {
                const res = await api(API_METHODS.GET_ALL_ATTR_VALUE, paramsIn, rootState)
                commit(ATTR_VALUE + READ, {
                    data: res.object,
                    fieldInfoList: res.fieldInfoList
                })
                commit(SET_MAIN_PAGINATION_TOTAL, {
                    countPage: res.countPage,
                })
                return res
            }
        } catch (err) {
            return (err)
        }
    },

    [ATTR_VALUE + UPDATE]: async({ commit }, paramsIn = null) => {
        try {
            return await api(API_METHODS.UPDATE_ATTR_VALUE, paramsIn)
        } catch (err) {
            return (err)
        }
    },

    [ATTR_VALUE + DELETE]: async({}, paramsIn = null) => {
        try {
            return await api(API_METHODS.DELETE_ATTR_VALUE, paramsIn)
        } catch (err) {
            return (err)
        }
    },
};