import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue";
import StatisticGetCatalogUnitTable from "./StatisticGetCatalogUnitTable.vue";
import StatisticGetCatalogUnitTableDetail from "./StatisticGetCatalogUnitTableDetail.vue";

export default {
  path: "/statisticGetCatalogUnit",
  component: DashboardLayout,
  children: [
    {
      path: "/",
      component: StatisticGetCatalogUnitTable
    },
    {
      path: ":receiverSystemCode/:dateFrom/:dateTo/show",
      name: "StatisticGetCatalogUnitTableDetail",
      component: StatisticGetCatalogUnitTableDetail
    }
  ]
};
