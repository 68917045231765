import { actions } from "./actions";
import { mutations } from "./mutations";
const state = {
  data: {},
  fieldInfoList:[],
  fieldTable: {}
};

export default {
  state,
  actions,
  mutations
};
