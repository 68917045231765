import { CREATE, READ, UPDATE, DELETE, SET_MAIN_PAGINATION_TOTAL, SEARCH } from '../../../../store/type'
import { ENTITY_NAME, LOAD_LIST_TABS, LOAD_TABS_DATA } from "./type";
import HttpClient from "../../../../util/HttpClient";
import { api } from "../../../../util/api";
import { API_METHODS } from "../../../../constant";

export const actions = {
    [ENTITY_NAME + READ]: async({ state, rootState, commit }, paramsIn = null) => {
        try {
            if (paramsIn.id) {
                return await api(API_METHODS.GET_ADMIN_ROUTE_MANAGER, paramsIn, rootState)
            } else {
                const res = await api(API_METHODS.LOAD_TABS_DATA, paramsIn, rootState)
                commit(ENTITY_NAME + READ, {
                    data: res.object,
                    fieldInfoList: res.fieldInfoList
                })
                commit(SET_MAIN_PAGINATION_TOTAL, {
                    countPage: res.countPage,
                })
                return res
            }
        } catch (err) {
            return (err)
        }
    },



    [ENTITY_NAME + UPDATE]: async({ commit }, paramsIn = null) => {
        try {
            return await api(API_METHODS.UPDATE_ADMIN_ROUTE_MANAGER, paramsIn)
        } catch (err) {
            return (err)
        }
    },
    [ENTITY_NAME + CREATE]: async({ commit }, paramsIn = null) => {
        try {
            return await api(API_METHODS.CREATE_ADMIN_ROUTE_MANAGER, paramsIn)
        } catch (err) {
            return (err)
        }
    },

    [ENTITY_NAME + DELETE]: async({ rootState }, paramsIn = null) => {
        try {
            return await api(API_METHODS.DELETE_ADMIN_ROUTE_MANAGER, paramsIn, rootState)
        } catch (err) {
            return (err)
        }
    },


    [LOAD_LIST_TABS]: async({ commit, rootState }, paramsIn) => {
        try {
            let res = await api(API_METHODS.LOAD_LIST_TABS, paramsIn, rootState)
            commit(LOAD_LIST_TABS, res.object)
            return res
        } catch (err) {
            console.error(err)
            return err
        }
    },
    // [CONTRACT_CONNECT]: async({}, paramsIn = null) => {
    //     try {
    //         return await api(API_METHODS.CREATE_CHILD_TYPE_CONTACT, paramsIn)
    //     } catch (err) {
    //         return (err)
    //     }
    // }
};