<template>
  <div class="container">
    <InputAttachFile
      name="file"
      @treatErrors="treatErrors"
      @treatWarnings="treatWarnings"
    />
    <SettingsForm
      v-if="settingIsLoad"
      :hrSettingsInfoList="hrSettingsInfoList"
      :hrFieldSettings="hrFieldSettings"
      @new-settings="setSetting"
    ></SettingsForm>
    <CustomDialog
      :warningData="logWarnings"
      :errorData="logErrors"
      :fileName="fileName"
      :dialogTableVisible="showDialog"
      @showToggle="closeDialog"
    />
  </div>
</template>

<script>
import InputAttachFile from "./InputAttachFile.vue";
import CustomDialog from "./CustomDialog";
import SettingsForm from "./SettingsForm.vue";
import { TYPE_ERRORS, API_METHODS } from "../../../../constant";
import notify from "../../../../helpers/notify/notify";
import { ApiService } from "../../../../util/ApiService";

export default {
  name: "SettingHrFeed",
  components: { InputAttachFile, CustomDialog, SettingsForm },
  data() {
    return {
      fileName: null,
      logErrors: [],
      showDialog: false,
      logWarnings: [],

      hrFieldSettings: {},
      hrSettingsInfoList: {},
      settingIsLoad: false
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    treatErrors(errors, fileName) {
      this.fileName = fileName;
      this.logErrors = errors;
      this.showDialog = true;
    },
    treatWarnings(warnings, fileName) {
      this.fileName = fileName;
      this.logWarnings = warnings;
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
    async loadData() {
      const paramsIn = {
        self: this,
        SubjectId: this.$route.params.CompanyId
      };
      this.settingIsLoad = false;
      let res = await ApiService(API_METHODS.GET_HR_SETTING, {
        ...paramsIn
      });
      console.log(res);
      this.hrFieldSettings = res.object;
      this.hrSettingsInfoList = res.fieldInfoList;
      this.settingIsLoad = true;
    },
    async setSetting(val) {
      let saveData = val;
      const paramsIn = {
        id: this.$route.params.FieldId || "0",
        SubjectId: this.$route.params.CompanyId || "0",
        saveData: saveData,
        self: this
      };
      let res = await ApiService(API_METHODS.UPDATE_HR_SETTING, {
        ...paramsIn
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
