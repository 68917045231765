import {TYPE_COMPONETS} from '../constants'
import extendComponents from '../extendComponents'
let checkbox = {
  ...extendComponents(TYPE_COMPONETS.CHECKBOX)
}

delete checkbox.name
delete checkbox.placeholder
delete checkbox.default

export default checkbox
