import { CHANGE_LOCAL, LOAD_LOGIN_USER_ROLE,  } from "../../type";
import i18n from '../../../i18n'
import {api} from "../../../util/api";
import {API_METHODS} from "../../../constant";


export const actions = {
    [CHANGE_LOCAL]: ({ commit }, lang) => {
        i18n.locale = lang
        localStorage.setItem("lang", lang);
        commit(CHANGE_LOCAL, lang);
    },
    [LOAD_LOGIN_USER_ROLE]: async ({commit,rootState}, params= null) => {
        try {
            let RoleId = rootState.user.profile.roleId
            const paramsIn =  {
                self : params.self,
                RoleId
            }
          let res =  await api(API_METHODS.GET_ROLE_USER, paramsIn, rootState )
          commit(LOAD_LOGIN_USER_ROLE, res.object)
          return res
        } catch(err) {
          return (err)
        }
      },
};
