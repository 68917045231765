<template>
 <div>
  <load-block :load="load"></load-block>  
  <router-view></router-view>
</div>

</template>
<script>
import { GET_LIST_ENTITY } from "src/components/modules/entity/store/type";
import { LOAD_LOGIN_USER_ROLE } from "./store/type";
import { mapState } from "vuex";
import LoadBlock from "./components/shared/LoadBlock";
export default {
  components: {
    LoadBlock
  },
  data() {
    return {
      load: false
    }
  },
  created() {
    try {
      this.load = true
      this.$store
        .dispatch("AUTH_TOKEN", {})
        .then(async () => {
          await this.$store.dispatch(GET_LIST_ENTITY, this);
          this.$store
            .dispatch("AUTH_ME", { self: this })
            .then(res => {
               this.load = false
              if (res == false) {
                if (this.$route.path !== "/login") {
                  this.$router.push({ path: "/login" });
                }
              } else {
                this.$store.dispatch(LOAD_LOGIN_USER_ROLE, { self: this });
              }
            })
            .catch(err => {
               this.load = false
              if (this.$route.path !== "/login") {
                this.$router.push({ path: "/login" });
              }
            });
        })
        .catch(err => {
              this.load = false
          if (this.$route.path !== "/login") {
            this.$router.push({ path: "/login" });
          }
        });
    } catch (e) {
      this.load = false
      console.warn(e);
    }
  },
  async beforeUpdate() {
    try {
      await this.$store.dispatch("AUTH_TOKEN", {});
    } catch (e) {
      console.warn("ошибка авторизации");
    }
  },
  computed: {
    ...mapState({
      lang: state => state.common.lang
    })
  },
  watch: {
    lang: {
      immediate: true,
      handler() {
        this.setTitle();
        this.$validator.localize(this.lang);
      }
    }
  },
  methods: {
    setTitle() {
      document.title = this.$t("VipService");
    }
  }
};
</script>
