export const ENTITY_NAME = 'COMPANY_';
export const CLIENT = 'CLIENT_';
export const SUPPLIER = "SUPPLIER_";
export const COMPANY_CONNETCT_CODE = "COMPANY_CONNETCT_CODE"
export const COMPANY_UNCONNECT_CODE = "COMPANY_UNCONNECT_CODE"


export const SET_TAB_CLIENT = "SET_TAB_CLIENT"

export const SUPPLIER_SERVICE = "SUPPLIER_SERVICE_";
export const SUPPLIER_SERVICE_CONNECT = "SUPPLIER_SERVICE_CONNECT";

export const BRAND = "BRAND_"
