<template>
  <common-page-edit
    :title="title"
    :nameRoleEntity="nameRoleEntity"
    :apiNameGet="apiNameGet"
    :apiNameCreate="apiNameCreate"
    :apiNameUpdate="apiNameUpdate"
    routeNameShow="CounterpartyShow"
    routeNameCreate="CounterpartyEdit"
    :keyRoute="'CounterpartyId'"
    :backUrl="pathBack"
    @set-loadData="setLoadData"
    :convertLoadData="convertLoadData"
    :paramsSave="paramsSave"
    :isDefaultConverter="false"
    :paramsSend="paramsSend"
    :forceNoShowEdit="true"

    :showHistory="true"
    :apiNameGetHistory="apiNameGetHistory"
    :keyRouteHistory="keyRouteHistory"
  >
    <template v-slot:block2>
        <counterparty-data
          :isCreditCard="true"
          :loadDataIn="loadData"
          v-if="loadData"
          :activeShowMenu="true">
        </counterparty-data>
    </template>
  </common-page-edit>
</template>
<script>
import { API_METHODS, FIELD_LANG_COMPANY } from "../../../../constant";
import { NAME_OBJECT } from "../../../../RoleConstanans";
import CommonPageEdit from "../../commonElement/CommonPageEdit.vue";
import CounterpartyData from "./CounterpartyData.vue";

import convertFieldInfoListToScheme from "../../../../helpers/converters/convetFieldInfoListToScheme";

export default {
  name: "CounterpartiesEditPageDynamic",
  components: {
    CommonPageEdit,
    CounterpartyData
  },
  data() {
    return {
      apiNameGetHistory: API_METHODS.GET_HISTORY_COUNTERPARTY,
      keyRouteHistory: "CounterpartyId",


      wait: false,
      scheme: {},
      loadData: null,
      fieldInfoList: null,
      preResult: null,
      load: true,

      routeNameShow: "CounterpartyShow",
      routeNameCreate: "CounterpartyCreate",

      pathBack: "/crm/Counterparty",
      apiNameGet: API_METHODS.GET_COUNTERPARTY,
      apiNameCreate: API_METHODS.CREATE_COUNTERPARTY,
      apiNameUpdate: API_METHODS.UPDATE_COUNTERPARTY,
      loadData: null
    };
  },
  computed: {
    title() {
      let text1 = this.$t("Просмотр контрагента");
      let text2 = this.$t("Создание контрагента");
      let text3 = this.$t("Редактирование контрагента");

      let findShow = this.$route.path.indexOf("show");
      if (findShow != -1) {
        return text1;
      }
      let findCreate = this.$route.path.indexOf("create");
      if (findCreate != -1) {
        return text2;
      }
      let findEdit = this.$route.path.indexOf("edit");
      if (findEdit != -1) {
        return text3;
      }
      return text1;
    },
    nameRoleEntity() {
      return NAME_OBJECT.clients;
    },
    paramsSend() {
      return {
        id: this.$route.params.CounterpartyId || "0"
      };
    }
  },

  methods: {
    convertLoadData(res) {
      let loadData = res.object;
      let fieldInfoList = res.fieldInfoList;
      let scheme = { configForm: [] };

      scheme.configForm = convertFieldInfoListToScheme({
        fieldInfoList,
        langFieldName: FIELD_LANG_COMPANY,
        hasLang: false
      });

      return {
        loadData,
        fieldInfoList,
        scheme
      };
    },
    setLoadData(loadData) {
      this.loadData = loadData;
    },

    paramsSave() {
      return {
        id: this.$route.params.CounterpartyId,
      };
    }
  }
};
</script>
