import { READ } from '../../../../store/type';
import { ENTITY_NAME, LOAD_LIST_TABS, SET_TAB_ADMIN_MANAGER, SET_SISTEM_ID_ADMIN_MANAGER } from "./type";

export const mutations = {
    [ENTITY_NAME + READ]: (state, { data, fieldInfoList }) => {
        state.data = data
        state.fieldInfoList = fieldInfoList
    },
    [LOAD_LIST_TABS]: (state, listtabs) => {
        state.listtabs = listtabs
    },
    [SET_TAB_ADMIN_MANAGER]: (state, tab) => {
        state.nowTab = tab
    },
    [SET_SISTEM_ID_ADMIN_MANAGER]: (state, externalSystemId) => {

        state.externalSystemId = externalSystemId
    },
};