import {TYPE_COMPONETS} from '../constants'
import extendComponents from '../extendComponents'

let vselect = {
...extendComponents(TYPE_COMPONETS.VSELECT),
  relative: null,
  search: false,
  selectLabel: 'label',
  SearchType: null,
  enable: false
}

export default vselect
