import { API_METHODS } from "../../constant";
export const corpPolicyApi = (
  _getAll,
  _get,
  _create,
  _update,
  _delete,
  paramsIn
) => {
  return {
    [API_METHODS.GET_POLICY]: () => {
      let urlReq = `/Policy/${paramsIn.id}`;
      return _get(urlReq);
    },
    [API_METHODS.GET_ALL_POLICY]: () => {
      let urlReq = `/Policy`;
      return _getAll(urlReq);
    },
    [API_METHODS.UPDATE_POLICY]: () => {
      let urlReq = `/Policy`;
      return _update(urlReq);
    },
    [API_METHODS.CREATE_POLICY]: () => {
      let urlReq = `/Policy`;
      return _create(urlReq);  
    },
    [API_METHODS.DELETE_POLICY]: () => {
      let urlReq = `/Policy/${paramsIn.id}`;
      return _delete(urlReq);
    }
  }
}
