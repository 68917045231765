<template>
  <common-page-edit
    :title="title"
    :nameRoleEntity="nameRoleEntity"
    :apiNameGet="apiNameGet"
    :apiNameCreate="apiNameCreate"
    :apiNameUpdate="apiNameUpdate"
    :routeNameShow="routeNameShow"
    :routeNameCreate="routeNameCreate"
    :backUrl="'/HrFeedFields'"
    :hasLang="true"
    :langFieldName="'langFieldName'"
    :convertLoadData="convertLoadData"
    :isDefaultConverter="false"
    :isDefaultConverterSaveData="false"
    :convertorSaveData="convertorSaveData"
  >
  </common-page-edit>
</template>
<script>
import { API_METHODS } from "../../../../constant";
import { NAME_OBJECT } from "../../../../RoleConstanans";
import CommonPageEdit from "../../commonElement/CommonPageEdit.vue";
import { api } from "../../../../util/api";
import convertFieldInfoListToScheme from "../../../../helpers/converters/convetFieldInfoListToScheme";
import clearReactive from "../../../../helpers/clearReactive";
import getSchemeComponet from "../../../../helpers/generatorForm/getSchemeComponet";

export default {
  name: "EditPageHrFeedFields",
  components: {
    CommonPageEdit
  },
  data() {
    return {
      wait: false,
      scheme: {},
      loadData: null,
      fieldInfoList: null,
      preResult: null,
      load: true,

      routeNameShow: "EditPageHrFeedFieldsShow",
      routeNameCreate: "EditPageHrFeedFieldsCreate",

      apiNameGet: API_METHODS.GET_HrFeedFields,
      apiNameCreate: API_METHODS.CREATE_HrFeedFields,
      apiNameUpdate: API_METHODS.UPDATE_HrFeedFields,
      loadData: null,
      templateValcodeId: null,
      templateConfig: null,
      blockWatch: true
    };
  },
  computed: {
    title() {
      let text1 = this.$t("Просмотр HrFeedFields");
      let text2 = this.$t("Создание HrFeedFields");
      let text3 = this.$t("Редактирование HrFeedFields");

      let findShow = this.$route.path.indexOf("show");
      if (findShow != -1) {
        return text1;
      }
      let findCreate = this.$route.path.indexOf("create");
      if (findCreate != -1) {
        return text2;
      }
      let findEdit = this.$route.path.indexOf("edit");
      if (findEdit != -1) {
        return text3;
      }
      return text1;
    },
    meta() {
      return this.$route.meta;
    },
    nameRoleEntity() {
      return NAME_OBJECT.HrFeedFields;
    },
    pathBack() {
      return `/${this.meta.pathBackStart}/${
        this.$route.params[this.meta.fieldRoute]
      }/edit`;
    },
    paramsSend() {
      return {
        id: this.$route.params.AttrValueId || "0",
        SubjectId: this.$route.params[this.meta.fieldRoute]
      };
    }
  },
  created() {
    // this.routeNameShow = this.meta.routeNameShow;
    // this.routeNameCreate = this.meta.routeNameCreate;
  },
  watch: {},
  methods: {
    convertLoadData(res) {
      let loadData = {
        ...res.object,
        langFieldName: [
          {
            languageCode: "ru",
            name: res.object.nameRu
          },
          {
            languageCode: "en",
            name: res.object.nameEn
          }
        ]
      };
      // const idF = res.fieldInfoList.find(el => el.code == "id");
      const codeF = res.fieldInfoList.find(el => el.code == "code");
      const nameF = res.fieldInfoList.find(el => el.code.includes("name"));
      const validatorF = res.fieldInfoList.find(el => el.code == "validator");

      let fieldInfoList = [
        // idF,
        codeF,
        {
          code: "name",
          colWidth: 4,
          isDynamicRef: false,
          isLang: true,
          isReadOnlyAdd: false,
          isReadOnlyEdit: false,
          name: nameF.name,
          refEntityCode: null,
          required: true,
          sizePercent: 25,
          subListId: 0,
          type: "String",
          watchAttr: null
        }
      ];
      if (validatorF) {
        fieldInfoList.push(validatorF);
      }

      let scheme = { configForm: [] };

      scheme.configForm = convertFieldInfoListToScheme({
        fieldInfoList,
        langFieldName: "langFieldName",
        hasLang: true
      });

      return {
        loadData,
        fieldInfoList,
        scheme
      };
    },
    convertorSaveData(_res) {
      const nameRu = _res.langFieldName.find(el => el.languageCode == "ru");
      const nameEn = _res.langFieldName.find(el => el.languageCode == "en");
      let res = {
        code: _res.code,
        id: _res.id,
        nameEn: nameEn.name,
        nameRu: nameRu.name,
        validator: _res.validator
      };
      return res;
    }
  }
};
</script>
