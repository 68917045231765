import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import {NAME_TOKEN} from '../../../constant'


const state = {
  token: localStorage.getItem(NAME_TOKEN) || "",
  status: "",
  hasLoadedOnce: false
};

export default {
  state,
  getters,
  actions,
  mutations
};
