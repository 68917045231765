<template>
  <div :class="classElement">
    <LoadBlock :load="load" />
    <label>{{ $t(labelText) }}</label>
    <div class="form-group has-label">
      <div class="el-input">
        <v-select
          :label="labelSelect"
          v-model="selectCode"
          :options="option"
          :disabled="isBlock || forseDis"
          @search="onSearch"
        >
          <span slot="no-options">{{ $t("Результатов не найдено") }}</span>
        </v-select>
      </div>
    </div>
  </div>
</template>
<script>
import HttpClient from "../../../../../util/HttpClient";
import { api } from "../../../../../util/api";
import { API_METHODS } from "../../../../../constant";
import LoadBlock from "../../../../shared/LoadBlock.vue";

export default {
  components: { LoadBlock },
  name: "CustomSelector",
  props: {
    url: { type: String },
    labelSelect: { type: String, default: "entityName" },
    keyId: { type: String, default: "entityId" },
    labelText: { type: String, default: "Код" },
    classElement: { type: String, default: "col-md-4" },
    isBlock: { type: Boolean, default: false },
    onlyLoad:{ type: Boolean, default: false },
    forseDis:  { type: Boolean, default: false },
    dopParams: {},
    form: {} ,
    nameFiels: {}

  },
  data() {
    return {
      selectCode: null,
      option: [],
      load: false,
      isModeInit: false
    };
  },
  created() {
    if (this.isBlock) {
      return;
    }
    this.initData();
  },
  watch: {
    isBlock() {
      if (this.isBlock == false) {
        this.initData();
      }
    },
    selectCode() {
      this.$emit("selectCode", this.selectCode, this.isModeInit);
      this.isModeInit = false
    }
  },
  methods: {
    async initData() {
      this.load = true;
      try {
        const res = await this.loadData();
        if(this.form && this.form[this.nameFiels]) {
          const loadData =  await this.loadData(null, this.form[this.nameFiels]);
          this.isModeInit = true
          this.selectCode = loadData.object
        }
        this.load = false;
      } catch (e) {
        this.load = false;
      }
    },

    onSearch(search, loading, event) {
      if (search.length < 3) return false;

      loading(true);
      this.search(loading, search, this);
    },
    search(loading, search, vm) {
      vm.loadData(search)
        .then(() => {
          loading(false);
        })
        .catch(() => {
          loading(false);
        });
    },
    async loadData(search = null, id=null) {
      try {
        const params = {
          search: search,
          lang: this.$store.state.common.lang,
          ...this.dopParams,
        }; 
        const HttpClientInit = new HttpClient(this);
        const idurl = id?'/'+id:""
        let res = await HttpClientInit.getRequest(this.url+idurl, params);
        let responseData = res.object;
        if (!("map" in responseData)) {
          responseData = [responseData];
        }
        if(this.onlyLoad) {
            this.option = responseData
        } else {
            this.addOptions(responseData);
        }
        
        return res;
      } catch (err) {
        console.error(err);
        return err;
      }
    },
    addOptions(result) {
      result.forEach(item => {
        let find = this.option.find(el => el[this.keyId] == item[this.keyId]);
        if (!find) {
          this.option.push(item);
        }
      });
    }
  }
};
</script>
