<template>
  <common-page-table
    :apiName="apiName"
    :nameRole="nameRoleEntity"
    :titleTable="$t('Соглашение')"
    :paramsGetData="paramsGetData"
    :fieldLand="fieldLang"
    :keyTable="'agreementId'"
    :deleteParams="deleteParams"
    :apiDeleteName="''"
    :forceIsEdit="false"
    :forceIsCreate="false"
    :forceDelete="false"

  />
</template>
<script>
import { NAME_OBJECT } from "../../../../../RoleConstanans";
import CommonPageTable from "../../../commonElement/CommonPageTable.vue";
import { API_METHODS, FIELD_LANG_COMPANY} from "../../../../../constant";
import formatData from "../../../../../helpers/converters/formatData";
import convertLangDataToTable from "../../../../../helpers/converters/convertLangDataToTable";
export default {
  name: "AgreementsVstTablePage",
  components: { CommonPageTable },
  data() {
    return {
      fieldLang: FIELD_LANG_COMPANY,
      apiName: API_METHODS.GET_ALL_AGREEMENTS_COMMON,
      apiDeleteName: API_METHODS.DELETE_COMPANY
    };
  },
  computed: {
    paramsGetData() {
      return {
        dopParams: {
          crmType: 'vst',
        }
      };
    },
    nameRoleEntity() {
      return NAME_OBJECT.agent
    }
  },
  methods: {
    deleteParams(id) {
      return { id: id };
    },
  }
};
</script>
