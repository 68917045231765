import Checkbox from './shared/Inputs/Checkbox.vue'
import Radio from './shared/Inputs/Radio.vue'
import FormGroupInput from './shared/Inputs/formGroupInput.vue'
import Switch from './shared/Switch.vue'
import Progress from './shared/Progress.vue'

import DropDown from './shared/Dropdown.vue'
import Table from './shared/Table.vue'

import FadeRenderTransition from './shared/Transitions/FadeRenderTransition.vue'

import Card from './shared/Cards/Card.vue'
import ChartCard from './shared/Cards/ChartCard.vue'
import StatsCard from './shared/Cards/StatsCard.vue'

import Breadcrumb from './shared/Breadcrumb/Breadcrumb.vue'
import BreadcrumbItem from './shared/Breadcrumb/BreadcrumbItem.vue'

import Pagination from './shared/Pagination.vue'

import SidebarPlugin from './shared/SidebarPlugin'

let components = {
  Checkbox,
  Switch,
  Progress,
  Pagination,
  Radio,
  FormGroupInput,
  Card,
  ChartCard,
  StatsCard,
  Table,
  DropDown,
  SidebarPlugin,
  FadeRenderTransition,
  Breadcrumb,
  BreadcrumbItem
}
export {
  Checkbox,
  Switch,
  Progress,
  Pagination,
  Radio,
  FormGroupInput,
  Card,
  ChartCard,
  StatsCard,
  Table,
  DropDown,
  SidebarPlugin,
  FadeRenderTransition,
  Breadcrumb,
  BreadcrumbItem
}

export default components
