/**
 * Расширение для валидации
 * @type {{methods: {getError: (function(*=))}}}
 */
export const validateExt = {
    methods: {
        getErrorMas(fieldName){
            return this.errors.collect(fieldName)
        },
        getError(fieldName) {
            return this.errors.first(fieldName)
        },
    }
}