import { API_METHODS } from "../../constant";
export const historyApi = (
  _getAll,
  _get,
  _update,
  _create,
  _delete,
  paramsIn
) => {
  return {
    [API_METHODS.GET_HISTORY_AGREEMNTS]: () => {
      let urlReq = `/Agreements/History/${paramsIn.id}`;
      return _getAll(urlReq);
    },
    [API_METHODS.GET_HISTORY_CFO]: () => {
      let urlReq = `/CfoTables/History/${paramsIn.id}`;
      return _getAll(urlReq);
    },
    [API_METHODS.GET_HISTORY_COUNTERPARTY]: () => {
      let urlReq = `/Counterparties/History/${paramsIn.id}`;
      return _getAll(urlReq);
    },
    [API_METHODS.GET_HISTORY_COUNTERPARTY_BANK_DETAILS]: () => {
      let urlReq = `/CounterpartyBankDetails/History/${paramsIn.id}`;
      return _getAll(urlReq);
    },
  };
};