<template>
  <common-page-table
    :apiName="apiName"
    :nameRole="nameRoleEntity"
    :titleTable="titleName"
    :paramsGetData="paramsGetData"
    :fieldLand="fieldLang"
    :keyTable="'receiverSystemId'"
    :deleteParams="deleteParams"
    :apiDeleteName="apiDeleteName"
    :alternativeConverter="convertData"
    :isDefaultConverter="false"
    :forceIsEdit="true"
    :forceIsCreate="true"
    :forceDelete="true"
  />
</template>
<script>
import { NAME_OBJECT } from "../../../../../RoleConstanans";
import CommonPageTable from "../../../commonElement/CommonPageTable.vue";
import { API_METHODS , GLOBAL_DATE_FORMAT} from "../../../../../constant";
import convertLangDataToTable from "../../../../../helpers/converters/convertLangDataToTable";

//   GET_RECEIVER_SYSTEM: "GET_RECEIVER_SYSTEM",
//   UPDATE_RECEIVER_SYSTEM: "UPDATE_RECEIVER_SYSTEM",
//   CREATE_RECEIVER_SYSTEM: "CREATE_RECEIVER_SYSTEM",
//   DELETE_RECEIVER_SYSTEM: "DELETE_RECEIVER_SYSTEM",
//   GET_ALL_RECEIVER_SYSTEM: "GET_ALL_RECEIVER_SYSTEM"

export default {
  name: "ReceiverSystemTablePage",
  components: { CommonPageTable },
  data() {
    return {
      fieldLang: "",
      apiName: API_METHODS.GET_ALL_RECEIVER_SYSTEM,
      apiDeleteName: API_METHODS.DELETE_RECEIVER_SYSTEM
    };
  },
  computed: {
   
    titleName() {
      return this.$t('ReceiverSystem');
    },
    paramsGetData() {
      return {  };
    },
    nameRoleEntity() {
      return NAME_OBJECT.ReceiverSystems;
    }
  },

  methods: {
    convertDataEl(val, def=null) {
      return  val ? this.$moment(val).format(
            GLOBAL_DATE_FORMAT
          ) : def
    },
    convertData(dataNow, langNow) {
      let res = convertLangDataToTable(dataNow, langNow, "", false);    
      const convertDataEl = this.convertDataEl
      // res = res.map(item=> {
      //   return {
      //     ...item,
         
      //   }
      // })
      return res;
    },
    deleteParams(id) {
      return { id: id };
    }
  }
};
</script>
