import Vue from 'vue'

Vue.directive('price', {
  bind(el, binding) {
    if ( !binding.value ) return false;
    el.oninput = function (e) {
      if (!e.isTrusted) {
        return;
      }
      let tempPrice = new String(this.value)
      let contempPrice = tempPrice.replace(",",".");
      contempPrice = contempPrice.replace("б",".");
      contempPrice = contempPrice.replace("Б",".");
      contempPrice = contempPrice.replace("ю",".");
      contempPrice = contempPrice.replace("Ю",".");

      this.value = contempPrice
      let price = this.value.replace(/[^\d\.]+/g, '');
      let parts = price.split(".");

      if (parts.length > 2) {
        price = parts[0] + "." + parts[1];
      }

      parts = price.split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      if (parts.length == 2 && parts[1].length > 2) {
          parts[1] = parts[1].substr(0,2)
      }
      price = parts.join(".");
      this.value = price;
      el.dispatchEvent(new Event('input'));
    }
  }
})
